'use strict';

angular
	.module('ui')
	.directive('validationController', ['$parse', function ($parse) {

		return {
			restrict: 'A',
			link: function ($scope, element, attrs) {

				if (!$scope.form || !$scope.name) {

					return;
				}

				// Update the validation status on the element
				var update = function () {

					var invalid = false;

					// Elements only display validation after being
					// touched or form is submitted
					if (
						!_.isEmpty($scope.form[$scope.name].$error) && 
						($scope.form[$scope.name].$touched || $scope.form.$submitted)
					) {

						// Make a copy of the errors we can work with
						var errors = angular.copy($scope.form[$scope.name].$error);

						// If there's a failed required validation, remove
						if (errors.required) {

							delete errors.required;

							// Only show required validation if the form
							// has been submitted
							if ($scope.form.$submitted) {

								invalid = true;
							}
						}

						// The remaining errors always show
						if (!_.isEmpty(errors)) {

							invalid = true;
						}
					}

					// Set invalid status on the view
					if (invalid) {

						element.addClass('invalid');
					}

					else {

						element.removeClass('invalid');
					}
				};

				// Kill the watches when we no longer have $scope.form
				$scope.$watch(function() {

					return $scope.form;
				}, function (form) {

					if (typeof form === 'undefined') {

						// Kill watchers by calling them as functions
						elementWatcher();
						submitWatcher();
					}

				});

				// Update when the element changes
				var elementWatcher = $scope.$watchCollection(function () {

					return $scope.form[$scope.name];
				}, update);

				// Update when the form is submitted
				var submitWatcher = $scope.$watch(function () {

					return $scope.form.$submitted;
				}, update);

			}
		};
	}]);
