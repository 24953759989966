(function() {
  'use strict';

  angular
  .module('architizer.app')
  .directive('autoexpand', autoexpand);

  function autoexpand () {
    return {
      restrict: 'A',
      replace: false,
      require: '^ngModel',
      scope: {
        ngModel: '=',
      },
      link: link,
    };

    function link ($scope, element) {
      $scope.$watch('ngModel', () => expand(element));

      function expand (element){
        let count = element.val().split('\n').length;
        element.attr('rows', count);
      }
    }
  }
})();