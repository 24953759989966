(function() {
  'use strict';

  angular
  .module('architizer.app')
  .run(RunAnalyticsPageHelper);

  RunAnalyticsPageHelper.$inject = [
    'GlobalView',
    'SegmentioService',
    '$location',
    '$state',
    '$timeout',
    '$transitions',
  ];

  function RunAnalyticsPageHelper (
    GlobalView,
    SegmentioService,
    $location,
    $state,
    $timeout,
    $transitions
  ) {

    $transitions.onFinish( { }, function(transition) {
      let toState = transition.to();

      $timeout(function () {

        var category = '';
        var path = $location.url();

        // App (BPM)
        if ($state.includes('source.app.bpm')) { category = 'BPM'; }

        // App (Architect)
        else if ($state.includes('source.app.arc')) { category = 'Specifier'; }

        // Public
        else if (
          $state.is('source') ||
          $state.includes('source.opportunities')
        ) {
          category = 'Public';
        }

        // Send the page call to Segment
        SegmentioService.page(
          category,
          {
            title: GlobalView.title,
            path: path,
          }
        );
      }, 100);
    });
  }
})();
