'use strict';

angular
  .module('architizer.app')
  .factory('User', ['$resource', '$http', 'Config', function($resource, $http, Config) {
    var User = $resource(Config.apiUrlBase + 'users/:id', { id: '@id' }, {
			save: {
				method: 'POST',
			},
      updateMe: {
        method: 'PATCH',
        url: Config.apiUrlBase + 'users/me'
      },
      me: {
        method: 'GET',
        url: Config.apiUrlBase + 'users/me',
        params: {
          max_recursion_depth: 0,
          force: 'images,phone_numbers',
        },
      },
      publicUser: {
        method: 'GET',
        params: {
          for_firm: '@firmId',
        },
      },
      singularToken: {
        method: 'GET',
        url: Config.apiUrlBase + 'singular-token',
        interceptor: {
          response: (response) => response.data
        }
      }
    });

    return User;
  }]);
