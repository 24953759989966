(function () {
  'use strict';

  angular
  .module('architizer.app')
  .controller('SourcePublicOpportunitiesController', SourcePublicOpportunitiesController);

  SourcePublicOpportunitiesController.$inject = [
    '$location',
    '$scope',
    '$state',
    'LudwigService',
    'ProductRequest',
    'ProjectService',
    'SearchstaxService',
    'user',
    'UtilsService',
  ];

  function SourcePublicOpportunitiesController (
    $location,
    $scope,
    $state,
    LudwigService,
    ProductRequest,
    ProjectService,
    SearchstaxService,
    user,
    UtilsService
  ) {

    const vm = this;

    // FacetField constructor, for elegantly managing the dropdown elements and their options/currently-selected values
    class FacetField {
      constructor (data = {}) {
        // Don't create if incomplete
        const complete = data.solrField && data.label && data.urlParameter && data.options;
        if (!complete) { return {}; }

        this.solrField = data.solrField || '';
        this.label = data.label || '';
        this.urlParameter = data.urlParameter || '',
        this.options = data.options || [];
      }

      get value () {
        let values = [];

        const selectedOptions = this.options.filter(option => option.selected);
        selectedOptions.forEach((option) => {
          values.push(option.value);
        });

        return values;
      }

      set value (values) {
        // Unselect all options
        this.options.forEach(option => option.selected = false);

        // Select what we passed in
        values.forEach((value) => {
          const matchingOption = this.options.find((option) => option.value === value);
          if (matchingOption) {
            matchingOption.selected = true;
          }
        });
      }
    }

    // Data
    vm.user = user;
    vm.filterValues = filterValues();
    vm.paginationData = paginationData();
    vm.opportunities = [];
    vm.hasFetched = false;

    // Functions
    vm.searchstaxTrackClick = searchstaxTrackClick;
    vm.onFavoriteClick = onFavoriteClick;
    vm.onLoadMoreClick = onLoadMoreClick;
    vm.setMetaValue = setMetaValue;
    vm.isFavorite = isFavorite;
    vm.hasReplied = hasReplied;
    vm.hasDraft = hasDraft;

    // Exposed for testing
    vm._FacetField = FacetField;
    vm._filterValues = filterValues;
    vm._buildSolrQuery = buildSolrQuery;
    vm._buildUrlParams = buildUrlParams;
    vm._parseUrlParams = parseUrlParams;
    vm._reselectFilterValues = reselectFilterValues;
    vm._updateOptions = updateOptions;

    init();

    ////////////////////////////////////////////////////////////////////////////////
    // Functions
    ////////////////////////////////////////////////////////////////////////////////

    function init () {
      // Get opportunities first, to populate .options on filterValues.facetFields (so we can reselect things)
      getOpportunities()
      .then(() => {
        // Grab and parse parameters and values from URL
        const urlParams = $location.search();
        const parsedValues = parseUrlParams(urlParams);

        // Determine what we need to reselect based on what was in the URL
        const valuesToReselect = reselectFilterValues(parsedValues);

        // Set up a watch to updateView on filterValues change
        $scope.$watch(() => vm.filterValues, updateView, true);

        // Reselect the values we need to reselect from the URL value (and trigger the watch)
        vm.filterValues = valuesToReselect;
      });
    }

    // Update view when filter values change
    function updateView (filterValues, old) {
      angular.element('#opportunitiesList').scrollTo(0, 0);
      vm.paginationData = paginationData();

      // Determine whether we need to send a SearchStax "track" event for this update
      const firstLoad = (!vm.hasFetched);
      const searchQueryChanged = (filterValues.searchQuery.value !== old.searchQuery.value);
      const sendToSearchstax = (firstLoad || searchQueryChanged);

      // Never append when filter values change
      const append = false;

      // Push the update to the URL with updated parameters
      $state.transitionTo('source.opportunities', buildUrlParams(filterValues), { notify: false, reload: false, location: 'replace' });

      // Get Opportunities from the API
      getOpportunities(filterValues, append, sendToSearchstax);
    }

    // For turning a parameterized URL back into selected filterValues
    function parseUrlParams (urlParams = $location.search()) {
      const parsedValues = {};

      Object.keys(urlParams).forEach((key) => {

        // Separate parameter values on quoted strings (string '"New York, New York", "Portland, Oregon"' becomes array ['"New York, New York"', '"Portland, Oregon"'])
        parsedValues[key] = urlParams[key].match(/\w+|"[^"]+"/g);

        // Strip off the surrounding quotes
        parsedValues[key] = parsedValues[key].map((value) => {
          const valueIsQuoted = value.charAt(0) === '"' && value.charAt(value.length - 1) === '"';

          if (valueIsQuoted) {
            const valueWithoutQuotes = value.substr(1, value.length - 2);
            value = valueWithoutQuotes;
          }

          return value;
        });
      });

      return parsedValues;
    }

    // For reselecting FacetField options from a parsedValues object
    function reselectFilterValues (
      parsedValues = {},
      filterValues = vm.filterValues
    ) {
      filterValues = filterValues;

      // Separate parsedValues
      const { meta, q, ...facetFields } = parsedValues;

      // Handle 'q' for searchQuery
      if (q) {
        // (this comes as an array like all the others, so we use .join() to convert to a string)
        filterValues.searchQuery.value = q.join('');
      }

      // Handle 'meta' for meta
      if (meta) {
        filterValues.meta.value = meta;
      }

      // Handle facetFields
      Object.keys(facetFields).forEach((key) => {
        filterValues.facetFields[key].value = facetFields[key];
      });

      return filterValues;
    }

    // For creating the URL Parameters when filters are changed
    function buildUrlParams (filterValues = vm.filterValues) {
      const urlParams = {};

      // Grab "meta" field
      const metaField = filterValues.meta;

      // Grab facetFields and convert them to an array so we can iterate below, because JavaScript
      const facetFields = [];
      Object.keys(filterValues.facetFields).forEach((key) => {
        facetFields.push(filterValues.facetFields[key]);
      });

      // Combine them together
      const fieldsToCheck = [...facetFields, metaField];

      // Push their values into urlParams correctly
      Object.keys(fieldsToCheck).forEach((key) => {
        const field = fieldsToCheck[key];
        const urlParameter = field.urlParameter;

        // Wrap values in quotes because values can contain commas (ex. 'Portland, Oregon'), which screws with delimiting when we parse the URL later
        const quotedValues = field.value.map(v => `"${v}"`);

        urlParams[urlParameter] = quotedValues.join(',');
      });

      // Handle searchQuery
      const searchQuery = filterValues.searchQuery.value;
      if (searchQuery) {
        urlParams['q'] = `"${searchQuery}"`;
      }

      return urlParams;
    }

    // Get Opportunities from the API
    function getOpportunities (
      filterValues = vm.filterValues,
      append = false,
      sendToSearchstax = false
    ) {
      const solrQuery = buildSolrQuery(filterValues);

      return LudwigService.getProductRequests(solrQuery)
      .then(solrData => onGetOpportunitiesSuccess(solrData, append))
      .catch(error => onGetOpportunitiesError(error));

      function onGetOpportunitiesSuccess (solrData, append) {
        // Set flag to tell empty state that the first call has been made
        vm.hasFetched = true;

        // Throw error if Solr response documents don't exist
        const solrError = JSON.stringify(solrData.error);
        if (solrData.error) throw new Error(solrError);

        // Store the opportunities
        const fetchedOpportunities = solrData.response.docs;
        if (append) {
          vm.opportunities = vm.opportunities.concat(fetchedOpportunities);
        } else {
          vm.opportunities = fetchedOpportunities;
        }

        // Send a SearchStax "track" event if search query has changed
        if (sendToSearchstax) {
          const ssAppName = 'opportunitiesFeedSearch';
          const trackData = {
            key: SearchstaxService.getApiKey(ssAppName),
            session: SearchstaxService.getSession(),
            query: solrData.responseHeader.params.q || '*:*',   // Pass '*:*' when no query (their API returns a 400 if query is empty)
            shownHits: vm.opportunities.length,
            totalHits: solrData.response.numFound,
            latency: solrData.responseHeader.QTime,
          };

          SearchstaxService.track(trackData);
        }

        // Store the pagination data
        vm.paginationData.start = solrData.response.start;
        vm.paginationData.count = solrData.response.numFound;
        vm.paginationData.cursorMark = solrData.nextCursorMark;

        // Update options on facetFields
        vm.filterValues.facetFields = updateOptions(solrData, vm.filterValues.facetFields);
      }

      function onGetOpportunitiesError (error) {
        Raven.captureMessage({
          level: 'error',
          extra: {
            'View': 'Perform an opportunities search',
            'Error': error,
          }
        });
      }
    }

    // When "Load More" button is clicked
    function onLoadMoreClick () {
      let append = true;
      getOpportunities(vm.filterValues, append);
    }

    // For updating options on filterValues.facetFields[i].options from a Solr response
    function updateOptions (
      solrData = {},
      facetFields = vm.filterValues.facetFields
    ) {
      // Handle bad inputs
      if (!solrData || !solrData.facet_counts || !solrData.facet_counts.facet_fields) {
        return facetFields;
      }
      // Shortcut to the fields in solrData
      const solrFields = solrData.facet_counts.facet_fields;

      // Go through each FacetField amd update options if it matches
      Object.keys(facetFields).forEach((ff) => {
        const solrField = facetFields[ff].solrField;  // To grab the "solrField" from the FacetField instance (ex. "pseudotiers_ss" or "phase_s" etc)
        const match = solrFields[solrField];          // This is truthy if there is a FacetField whose solrField matches a key in the solrData

        // Options come back from Solr without any concept of whether they were previously selected in the query,
        // so we need to reselect what is currently "checked" in the filters:
        const reselect = (value) => facetFields[ff].value.includes(value); // Returns true if the option was previously selected

        // Set options on the FacetField if we have a match
        if (match) {
          facetFields[ff].options = UtilsService.unwrapFacets(match, reselect);
        }
      });

      return facetFields;
    }

    // Function to send analytics event to SearchStax analytics when a user clicks on a search result
    function searchstaxTrackClick (opportunity) {
      const ssAppName = 'opportunitiesFeedSearch';
      const trackClickData = {
        key: SearchstaxService.getApiKey(ssAppName),
        session: SearchstaxService.getSession(),
        query: vm.filterValues.searchQuery.value || '*:*',   // Pass '*:*' when no query (their API returns a 400 if query is empty)
        shownHits: vm.opportunities.length,
        totalHits: vm.paginationData.count,
        position: vm.opportunities.indexOf(opportunity),
        cDocId: opportunity.django_id,
        cDocTitle: opportunity.name_s,
      };

      SearchstaxService.trackClick(trackClickData);
    }

    // For storing pagination data from Solr response
    function paginationData () {
      return {
        count: 0,
        perPage: 40,
        cursorMark: '*',
        sort: 'approved_dt desc, id desc',
      };
    }

    // For changing "Meta" value on the left sidebar
    function setMetaValue (metaValue = '') {
      vm.filterValues.meta.value = [metaValue];
    }

    // For storing filter values
    function filterValues () {
      return {
        searchQuery: {
          value: '',  // This has an explicitly defined 'value' because it isn't a FacetField instance
        },
        meta: new FacetField({
          // Note: This isn't _really_ a "facet field" in Solr's terms
          // We are just using the FacetField constructor so meta will have a .value getter that returns "selected" options
          label: 'My Opportunities',
          urlParameter: 'meta',
          solrField: 'none',  // Again, not a "facet field" for Solr - this is disregarded
          options: [
            { label: 'Favorited', value: 'favorited', selected: false },
            { label: 'Drafts', value: 'drafts', selected: false },
            { label: 'Replied', value: 'replied', selected: false },
            { label: 'Shortlisted', value: 'shortlisted', selected: false },
          ],
        }),
        facetFields: {
          categories:       new FacetField({label: 'Categories',        solrField: 'pseudotiers_ss',     options: [], urlParameter: 'categories' }),
          phases:           new FacetField({label: 'Project Phase',     solrField: 'phase_display_s',    options: [], urlParameter: 'phases' }),
          price:            new FacetField({label: 'Price',             solrField: 'price_s',            options: [], urlParameter: 'price' }),
          project_location: new FacetField({label: 'Project Location',  solrField: 'project_location_s', options: [], urlParameter: 'project_location' }),
          firm_location:    new FacetField({label: 'Firm Location',     solrField: 'firm_location_s',    options: [], urlParameter: 'firm_location' }),
          project_type:     new FacetField({label: 'Project Type',      solrField: 'project_types_ss',   options: [], urlParameter: 'project_type' }),
        }
      }
    }

    // For building the query to be sent to Solr
    function buildSolrQuery (
      filterValues = {},
      paginationData = vm.paginationData,
      user = user
    ) {
      const defaults = {
        searchQuery: '*:*',
        meta: [],
        facetFields: {},
      }

      // Extend filterValues with defaults
      filterValues = Object.assign(defaults, filterValues);

      // Build params object for Solr (from filterValues, user, and paginationData)
      let paramsForSolr = {
        q: q(filterValues.searchQuery.value),
        fq: fq(filterValues, user),
        'facet.field': facetFields(filterValues),
        'facet.limit': 1000,
        rows: paginationData.perPage,
        sort: paginationData.sort,
        cursorMark: paginationData.cursorMark,
      }

      return paramsForSolr;

      // Helpers
      // Get facet.field value from filterValues.facetFields
      function facetFields (filterValues) {
        let fields = [];
        Object.keys(filterValues.facetFields).forEach((key) => {
          fields.push(filterValues.facetFields[key].solrField);
        });
        return fields;
      }
      // Build Query (q=) string
      function q (searchQuery) {
        let query = '*:*';
        if (searchQuery) {
          query = searchQuery;
        }
        return query;
      }

      // Build filterQuery (fq=) string
      function fq (
        filterValues = {},
        user = vm.user
      ) {
        let filterQuery = [];

        // Unpack facetFields object into Solr-friendly values for fq
        Object.keys(filterValues.facetFields).forEach((key) => {
          let field = filterValues.facetFields[key];
          let solrField = field.solrField;
          let quotedValues = field.value.map(value => `"${value}"`);

          // Push into filterQuery if this field has a value
          if (field.value.length) {
            filterQuery.push(`${solrField}:(${quotedValues.join(' OR ')})`);
          }
        });

        // Unpack meta value into user-specific data
        const meta = filterValues.meta.value; // Shortcut for readability below
        if (user && meta.length) {
          let metaQuery = []; // This is where we will keep track of the pieces of the "meta" query

          // Handle "Drafts/Favorites/Replied" filters
          const idSet = new Set(); // Where we will keep all the IDs of Drafts/Favorites/Replied Product Requests
          let draftIds = user.product_request_ids_drafts;
          let favoriteIds = user.product_request_ids_favorites;
          let repliedIds = user.product_request_ids_replied;

          if (meta.includes('drafts')) {
            draftIds.forEach(id => idSet.add(id));
          }

          if (meta.includes('favorited')) {
            favoriteIds.forEach(id => idSet.add(id));
          }

          if (meta.includes('replied')) {
            repliedIds.forEach(id => idSet.add(id));
          }

          // Build the `django_id:()` string with IDs of drafts/favorites/replied Product Requests
          let ids = Array.from(idSet);
          if (ids.length) {
            let djangoIds = `django_id:(${ids.join(' ')})`;

            metaQuery.push(djangoIds);
          } else {
            // There were no drafts/favorites/replied IDs, There's no django_id "hotdog" so this will return no results
            const stopgap = `django_id:(hotdog)`;
            metaQuery.push(stopgap);
          }

          // Handle "Shortlisted" filter
          let brandIds = getBrandIds(user);
          if (meta.includes('shortlisted')) {
            metaQuery.push(`shortlist_brand_ids_is:(${brandIds})`);
          }

          // Join the metaQuery items with ' OR ', then push this joined string into filterQuery
          let metaString = `(${metaQuery.join(' OR ')})`; // (This is enclosed in parentheses!)
          filterQuery.push(metaString);
        }

        // Construct the fq= value by joining the pieces on " AND "
        return filterQuery.join(' AND ');

        // Helpers
        function getBrandIds (user) {
          let brandIds = [ 0 ]; // This is effectively a stopgap if the user isn't a member of a brand
          if (Object.keys(user.permissions.brands).length) {
            brandIds = Object.keys(user.permissions.brands);
          }
          return brandIds.join(' ');
        }
      }
    }

    function onFavoriteClick ($event, opportunity) {
      $event.stopPropagation();
      $event.preventDefault();

      ProductRequest
      .get({ id: opportunity.django_id })
      .$promise
      .then((request) => {
        if (isFavorite(opportunity.django_id)) {
          // If favorited, unfavorite it:
          request.$clear();
        } else {
          // If not favorited, favorite it:
          request.$favorite();
        }
      });
    }

    function isFavorite (opportunityId) {
      if (vm.user) {
        return vm.user.product_request_ids_favorites.includes(parseInt(opportunityId));
      }
    }

    function hasReplied (opportunityId) {
      if (vm.user) {
        return vm.user.product_request_ids_replied.includes(parseInt(opportunityId));
      }
    }

    function hasDraft (opportunityId) {
      if (vm.user) {
        return vm.user.product_request_ids_drafts.includes(parseInt(opportunityId));
      }
    }
  }
})();