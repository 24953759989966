(function () {
  'use strict';

  angular
  .module('architizer.app')
  .factory('FileService', FileService);

  FileService.$inject = [
    '$filter',
    'ApiService',
  ];

  function FileService (
    $filter,
    ApiService
  ) {

    var service = this;

    // Data

    // Functions
    service.add = add;
    service.format = format;
    service.getThumbnail = getThumbnail;
    service.getUploadPolicy = getUploadPolicy;
    service.makeFilename = makeFilename; 
    service.prepareThumbnail = prepareThumbnail;
    service.remove = remove;
    service.setHero = setHero;

    return service;

    ////////////////////////////////////////////////////////////////////////////////
    // Functions
    ////////////////////////////////////////////////////////////////////////////////

    /**
     * Fetch signed keys from the API to allow uploading directly to S3
     */
    function getUploadPolicy () {
      return ApiService
      .get('upload_policy')
      .then((response) => response.data);
    }

    /**
     * Convert 'original-filename.ext' to the format "1450126917086-original-filename-clipped.ext"
     * with a unique string of numbers (the date the file was uploaded) appended to the front.
     *
     * This prevents "empty" filenames, like in the case of a filename with only
     * foreign characters - these don't get parsed correctly by the slug() function, which seems to
     * simply omit characters it can't convert. In these cases, the filename will just become "1450126917086-.ext"
     */
    function makeFilename (file) {
     
      // Get extension
      var extension = file.name.split('.').pop();

      // set _date to Date.now() so we can add this to front of filename (to ensure filename is unique)
      var date = Date.now().toString();

      // get the part of filename before .extension
      var filename = file.name.split('.').slice(0,-1).join();

      // construct and trim filename to be less than 256 characters total (including .extension)
      return date + '-' + filename.substring(0, (255 - extension.length - date.length)) + '.' + extension;

    }

    /**
     * Format a file from Dropzone upload for Architizer API format
     */
    function format (dropzoneFile) {
      let formattedFile = {
        s3_key: dropzoneFile.upload.url + encodeURIComponent(dropzoneFile.upload.filename), // upload.url is set in success() in Dropzone
        tempThumbnail: dropzoneFile.tempThumbnail,
        original_name: dropzoneFile.name,
        is_hero: false,
        reference_key: Date.now(), // This is needed on the back end when heroing a newly-uploaded image
      };

      return formattedFile;
    }

    /**
     * Add this file to all files
     */
    function add (thisFile, allFiles = []) {
      // Set hero if no file is set to hero
      let heroImage = allFiles.find((file) => file.is_hero);
      if (!heroImage && allFiles.length) {
        thisFile.is_hero = true;
      }

      // Add this file
      allFiles.push(thisFile);

      return allFiles;
    }

    /**
     * Remove this file from all files
     */
    function remove (thisFile, allFiles = []) {
      // Remove this file
      if (allFiles.find((file) => file === thisFile)) {
        let i = allFiles.indexOf(thisFile);
        allFiles.splice(i, 1);
      }

      // Set a new hero image if the one we removed was previously the hero
      let heroImage = allFiles.find((file) => file.is_hero);
      if (!heroImage && allFiles.length) {
        allFiles[0].is_hero = true;
      }

      return allFiles;
    }

    /**
     * Prepare temporary thumbnail for this file from Dropzone dataUrl
     *
     * Dropzone processes thumbnails for newly-uploaded files automatically
     * We copy the base64 dataUrl so we can show this thumbnail among the 
     * existing files, which already have processed thumbnails from Imgix.
     *
     * We also change PDF thumbnails to a standard placeholder because the
     * processor can't handle PDFs (only images)
     */
    function prepareThumbnail (thisFile, dataUrl = '', pdfPlaceholderPath = '/images/pdf-placeholder.png') {
      if (thisFile.type === 'application/pdf') {
        thisFile.tempThumbnail = pdfPlaceholderPath;
      } else {
        thisFile.tempThumbnail = dataUrl;
      }

      return thisFile;
    }

    /**
     * Get thumbnail for this file, or return placeholder
     */
    function getThumbnail (thisFile, placeholderPath = '/images/placeholder-product-4-3.png') {
      let thumbnailPath = placeholderPath;

      if (thisFile.tempThumbnail) {
        thumbnailPath = thisFile.tempThumbnail;
      } else {
        thumbnailPath = $filter('imgix')(thisFile, 'square230', placeholderPath);
      }

      return thumbnailPath;
    }

    /**
     * Set this file to be the hero in all files
     */
    function setHero (thisFile, allFiles = []) {
      allFiles.map((file) => file.is_hero = false);
      thisFile.is_hero = true;

      return allFiles;
    }
  }
})();