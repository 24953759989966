'use strict';

angular
  .module('architizer.app')
  .factory('Invitation', ['$resource', '$http', 'Config', function($resource, $http, Config) {

    var Invitation = $resource(Config.apiUrlBase + 'users/invitations/:id', { id: '@id' }, {
			update: {
				method: 'PATCH'
			}
		});

		Invitation.prototype.$resend = function() {

			// send an empty object to work around Django REST bug
			// https://github.com/tomchristie/django-rest-framework/issues/2761
			return $http.patch(
				Config.apiUrlBase + 'users/invitations/' + this.id + '?action=resend',
				{}
			);
		}

    return Invitation;
  }]);
