(function () {
  'use strict';

  class actionbarController {

    /**
     * Controller for the ActionBar component <action-bar></action-bar>
     *
     * This controller listens for the scope events CustomEventsService.ACTION_BAR_UPDATE and
     * CustomEventsService.events.ACTION_BAR_CLEAR and updates the data in the action-bar.html
     * template accordingly.
     */

    constructor($scope, $state, CustomEventsService) {
      this.$scope = $scope;
      this.$state = $state;
      this.CustomEventsService = CustomEventsService;
    }

    $onInit() {
      const {
        ACTION_BAR_UPDATE,
        ACTION_BAR_CLEAR
      } = this.CustomEventsService.events;
      let actionbarController = this;

      // Update viewTitle & rightLinks on CustomEventsService.events.ACTION_BAR_UPDATE broadcasts
      this.updateListener = this.$scope.$on(ACTION_BAR_UPDATE, function (event, data) {
        actionbarController.breadcrumbs = (data.breadcrumbs) ? data.breadcrumbs : null;
        actionbarController.viewIcon = (data.viewIcon) ? data.viewIcon : null;
        actionbarController.viewTitle = (data.viewTitle) ? data.viewTitle : null;
        actionbarController.dropdownLinks = (data.dropdownLinks) ? data.dropdownLinks : null;
        actionbarController.rightLinks = (data.rightLinks) ? data.rightLinks : null;
      });

      // Clear viewTitle & rightLinks on CustomEventsService.events.ACTION_BAR_CLEAR broadcasts
      this.clearListener = this.$scope.$on(ACTION_BAR_CLEAR, function (event, data) {
        actionbarController.breadcrumbs = null;
        actionbarController.viewIcon = null;
        actionbarController.viewTitle = null;
        actionbarController.dropdownLinks = null;
        actionbarController.rightLinks = null;
      });

    }

    $postLink() {
      // Destroy scope listeners
      this.$scope.$on('$destroy', this.updateListener && this.clearListener);
    }

  }

  actionbarController.$inject = ['$scope', '$state', 'CustomEventsService'];

  angular
    .module('ui')
    .component('actionBar', {
      templateUrl: '/views/source/shared/action-bar.html',
      bindings: {
        breadcrumbs: '<',
        viewTitle: '@',
        viewIcon: '@',
        dropdownLinks: '<',
        rightLinks: '<',
      },
      controller: actionbarController,
    });
})();
