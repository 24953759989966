(function () {
  'use strict';

  angular
  .module('architizer.app')
  .filter('textToHtml', textToHtml)
  .filter('autolink', autolink)
  .filter('autolinkHref', autolinkHref)
  .filter('commaSeparate', commaSeparate)
  ;

  function textToHtml () {
    return function (input, classes = '') {

      if (!input) {
        return '';
      }

      // Split into paragraphs on new lines
      var paragraphs = input.split('\n');
      
      // Remove empty paragraphs
      paragraphs = _.filter(paragraphs, (paragraph) => !!paragraph.trim().length);

      // Wrap in paragraph tags
      angular.forEach(paragraphs, (paragraph, i) => paragraphs[i] = '<p class="' + classes + '">' + paragraph + '</p>');

      // Stringify paragraphs
      paragraphs = paragraphs.join('');

      // If Autolinker is not available on window
      if (!window.Autolinker) {
        return paragraphs;
      // If Autolinker is present, return the paragraph with links 
      } else {
        return window.Autolinker.link(paragraphs, {
          newWindow: true,
        });
      }
    };
  }

  function autolink () {
    return function (input) {
      // If Autolinker is not available on window
      if (!window.Autolinker) {
        return input;
      // If Autolinker is present, return the paragraph with links 
      } else {
        return window.Autolinker.link(input, {
          newWindow: true,
        });
      }
    };
  }

  /**
   * autolinkHref just returns the href="" part of an autolinked URL as a string
   *
   * Useful when inputs may be stored in varying formats: 
   * andrew.co/items/123 or
   * //andrew.co/items/123 or
   * http://www.andrew.co/items/123
   *
   * But the output will become http://andrew.co/items/123
   *
   * Example: 
   * <a href="{{ link.href | autolinkHref }}">View Item</a>
   */
  function autolinkHref () {
    return (input) => {
      if (!window.Autolinker) {
        return input;
      } else {
        return window.Autolinker.link(input, {
          replaceFn: (match) => match.getAnchorHref(),
        });
      }
    };
  }

  function commaSeparate () {
    return (input) => {
      if (Array.isArray(input)) {
        return input.join(', ');
      } else {
        return input;
      }
    };
  }
})();

