'use strict';

angular
  .module('architizer.app')
  .factory('UserSettings', ['$resource', '$http', 'Config', function($resource, $http, Config) {

    var UserSettings = $resource(Config.apiUrlBase + 'users/:userId/settings/:organizationType/:organizationId', {}, {
      update: {
        method: 'PUT'
      }
    });

    return UserSettings;
  }]);
