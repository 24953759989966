(function () {
  'use strict';

  angular
  .module('architizer.app')
  .filter('imgix', imgix);

  imgix.$inject = [
    '$httpParamSerializer',
  ];

  /**
   * This filter allows the use of imgix query parameters on images served from the Architizer API.
   *
   * file: the serialized media item attribution from the Architizer API
   * options: 
   * - string: ['hero','large','medium','thumbnail','square230']
   * - object: is serialized into query parameters that are passed directly to imgix
   *
   * returns a string: base_imgix_url + (imgix query parameters)
   */
  function imgix (
    $httpParamSerializer
  ) {
    return (file, options, pathForPlaceholder = '', filePathOnly = false) => {

      // Stop if no file
      if (!file) {
        return pathForPlaceholder;
      }

      // Default size presets (matches the Django media model)
      const sizes = {
        'hero': {
          'w': 1680
        },
        'large': {
          'w': 1080
        },
        'medium': {
          'w': 520
        },
        'thumbnail': {
          'w': 200
        },
        'square230': {
          'w': 230,
          'h': 230
        },
        'square40': {
          'w': 40,
          'h': 40
        }
      };

      // Default parameters for all sizes
      const defaultParams = {
        'q': 60,
        'auto': 'format,compress',
        'cs': 'strip',
        'fit': 'crop',
      };

      // Parse options
      const optionsIsString = (typeof options === 'string' && sizes.hasOwnProperty(options));
      const optionsIsObject = (typeof options === 'object');

      // Check if file is formatted like old (pre-imgix) media item attributions
      const fileHasOldApiFields = (file.hasOwnProperty('hero')) ||
                                  (file.hasOwnProperty('large')) ||
                                  (file.hasOwnProperty('medium')) ||
                                  (file.hasOwnProperty('thumbnail')) ||
                                  (file.hasOwnProperty('square230'));

      // Handle the file correctly
      if (filePathOnly) {
        // This is when we send just an imgix URL and want it serialized
        return serializedFilePath(file);

      } else if (file.hasOwnProperty('base_imgix_url')) {
        // This is the expected API response; handle like imgix file paths
        return pathForImgix(file);

      } else if (fileHasOldApiFields) {
        // This is an old API response; handle like old API file paths
        return pathForOldApi(file);

      } else if (file.hasOwnProperty('hero_image_url_s')) {
        return pathForHeroImage(file);
        // This is for the Project view hero image URLs (served from Solr)
        
      } else {
        // Fallback
        return pathForPlaceholder;
      }

      /**
       * Get path for imgix file with serialized parameters added to the end
       */
      function serializedFilePath (path) {
        if (optionsIsString) {
          return path + '?' + $httpParamSerializer(Object.assign(defaultParams, sizes[options]));

        } else if (optionsIsObject) {
          return path + '?' + $httpParamSerializer(options);

        } else {
          // Fallback
          return pathForPlaceholder;
        }
      }

      /**
       * Get path for imgix files
       */
      function pathForImgix (file) {

        if (file.base_imgix_url === null) {
          return pathForPlaceholder;

        } else if (optionsIsString) {
          // Options is a string: find the properties from sizes and serialize the file path
          return file.base_imgix_url + '?' + $httpParamSerializer(Object.assign(defaultParams, sizes[options]));

        } else if (optionsIsObject) {
          // Options is an object: just serialize the file path
          return file.base_imgix_url + '?' + $httpParamSerializer(options);

        } else {
          // Fallback
          return pathForPlaceholder;
        }
      }

      /**
       * Get path for Projects view hero images (served from Solr)
       */
      function pathForHeroImage (file) {

        if (file.hero_image_url_s === null) {
          return pathForPlaceholder;

        } else if (optionsIsString) {
          // Options is a string: find the properties from sizes and serialize the file path
          return file.hero_image_url_s + '?' + $httpParamSerializer(Object.assign(defaultParams, sizes[options]));

        } else if (optionsIsObject) {
          // Options is an object: just serialize the file path
          return file.hero_image_url_s + '?' + $httpParamSerializer(options);

        } else {
          // Fallback
          return pathForPlaceholder;
        }
      }

      /**
       * Get path for old API files (pre-imgix)
       *
       * This is needed because we have some cases in our data where files are serialized in old API formats
       *
       * (Example: Conversation messages with media item attributions inside objects in structured_data can have files in old formats)
       */
      function pathForOldApi (file) {
        if (optionsIsString) {
          // Options is a string: return the old field for this size
          let size = options;
          return file[size];

        } else {
          // Fallback
          return pathForPlaceholder;
        }
      }

    };
  }
})();
