(function () {
  'use strict';

  angular
  .module('architizer.app')
  .filter('currencySymbol', currencySymbol);

  currencySymbol.$inject = [
    'CurrencyService'
  ];

  function currencySymbol (CurrencyService) {

    return function (value) {
      var currency = CurrencyService.currencies[value]();

      if (!currency) {
        return null;
      }

      return currency.symbol_native;
    };
  }

})();

