'use strict';

angular
  .module('architizer.app')
  .factory('Attribution', ['$resource', 'Config', function($resource, Config) {

    var Attribution = $resource(Config.apiUrlBase + 'media/attributions/:id', { id: '@id' }, {
			update: {
				method: 'PATCH'
			}
    });

    return Attribution;
  }]);