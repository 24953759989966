'use strict';

/*
 * Configures response to state change events
 */
angular
  .module('architizer.app')
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    function ($stateProvider, $urlRouterProvider)
  {
    $stateProvider
    .state({
      name: '403',
      data: {
        showIntercom: true,
        fixedHeader: true,
        showFooter: true,
      },
      resolve: {
        title: function () {
          return 'Oops!';
        },
        message: function () {
          return '<span class="strong">Not authorized</span><br />You don\'t appear to have access<br />to this page.';
        }
      },
      views: {
        'root@': {
          templateUrl: '/views/400.html',
          controller: ['$scope', '$urlRouter', 'title', 'message', 'AuthenticationService', function ($scope, $urlRouter, title, message, authentication) {
            $scope.title = title;
            $scope.message = message;
            $scope.$watchCollection(function() { return authentication.user; }, function(newValue, oldValue) {
              // If a user has just logged in, refresh the page
              if (!oldValue && newValue) {
                $urlRouter.sync();
              }
            });
          }],
        }
      },
    })

    .state({
      name: '404',
      data: {
        showIntercom: true,
        fixedHeader: true,
        showFooter: true,
      },
      resolve: {
        title: function () {
          return 'Oops!';
        },
        message: function () {
          return '<span class="strong">404 error</span><br />We can\'t seem to find the page<br />you are looking for.';
        }
      },
      views: {
        'root@': {
          templateUrl: '/views/400.html',
          controller: ['$scope', 'title', 'message', function ($scope, title, message) {
            $scope.title = title;
            $scope.message = message;
          }],
        }
      }
    })

    .state({
      name: '500',
      views: {
        'root@': {
          templateUrl: '/views/500.html',
        }
      }
    });

    $urlRouterProvider.otherwise(function ($injector, $location) {
      var state = $injector.get('$state');
      state.go('404');
      return $location.path();
    });
  }]);
