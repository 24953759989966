'use strict';

angular
	.module('ui')
	.directive('uiLoader', [function () {

		return {
			replace: true,
			restrict: 'E',
			transclude: true,
			template: '<canvas class="uiLoader"></canvas>',
			scope: {
				foreColor: '@',
				backColor: '@'
			},
			link: function ($scope, element) {

				// The canvas/context
				var _canvas, _context;

				// The length of the loop phase (milliseconds)
				var _loopTime = 600;

				// The draw function
				var animate = function (canvas, context, startTime) {

					// Update
					var time = (new Date()).getTime() - startTime;

					// Reset loop if required
					if (time > _loopTime) {

						startTime += _loopTime;
					}

					// Form the loader during the loop phase
					var loader = positionLoop(canvas, context, time);

					// Clear canvas
					clear(canvas, context);

					// Draw the next frame
					drawLoader(canvas, context, loader);

					requestAnimFrame(function () {

						animate(canvas, context, startTime);
					});
				};

				var clear = function (canvas, context) {

					context.clearRect(0, 0, canvas.width, canvas.height);
				};

				var positionLoop = function (canvas, context, time) {

					var loader = {};

					// Loader position


					// Loop progress
					loader.progress = time / _loopTime;

					return loader;
				};

				var drawLoader = function (canvas, context, loader) {

					context.beginPath();

					var x = canvas.width/2;
					var y = canvas.height/2;
					var radius = canvas.height > canvas.width ? canvas.width/2 : canvas.height/2;
					var thickness = canvas.height > canvas.width ? canvas.width/8 : canvas.height/8;
					var length = 0.18;

					var start = loader.progress*2*Math.PI;
					var end = start + length*2*Math.PI;

					// Draw background
					context.beginPath();
					context.fillStyle = $scope.backColor;
					context.arc(x, y, radius, 0, 2*Math.PI, false);
					context.arc(x, y, radius - thickness, 0, 2*Math.PI, true);
					context.fill();

					// Draw arc
					context.beginPath();
					context.fillStyle = $scope.foreColor;
					context.arc(x, y, radius, start, end, false);
					context.arc(x, y, radius - thickness, end, start, true);
					context.fill();
				};

				// Set up the canvas
				var _initCanvas = function () {

					_canvas = element[0];
					_context = _canvas.getContext('2d');

					// Query the various pixel ratios
					var devicePixelRatio = window.devicePixelRatio || 1,
						backingStoreRatio = _context.webkitBackingStorePixelRatio ||
							_context.mozBackingStorePixelRatio ||
							_context.msBackingStorePixelRatio ||
							_context.oBackingStorePixelRatio ||
							_context.backingStorePixelRatio || 1,
						ratio = devicePixelRatio / backingStoreRatio;

					// Upscale the canvas if the two ratios don't match
					if (devicePixelRatio !== backingStoreRatio) {

						var oldWidth = _canvas.width;
						var oldHeight = _canvas.height;

						_canvas.width = oldWidth * ratio;
						_canvas.height = oldHeight * ratio;

						_canvas.style.width = oldWidth + 'px';
						_canvas.style.height = oldHeight + 'px';

						// Now scale the context to counter
						// the fact that we've manually scaled
						// our canvas element
						// _context.scale(ratio, ratio);
					}

					return _canvas;
				};

				// Initialise the loader
				var init = (function () {

					_initCanvas();

					var startTime = (new Date()).getTime();
					animate(_canvas, _context, startTime);
				})();
			}
		};
	}]);