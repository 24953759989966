(function() {
  'use strict';

  angular
  .module('architizer.app')
  .controller('PartnersController', [
    PartnersController
  ]);

  function PartnersController () {
    var vm = this;

    vm.partners = [
      {
        'name':'Dezeen',
        'logo':'images/partners/dezeen.png',
        'description':'Dezeen is the world\'s most popular and influential architecture and design magazine.',
        'url':'http://www.dezeen.com/',
        'tier':'1',
      },
      {
        'name':'PIN-UP',
        'logo':'images/partners/pinup.png',
        'description':'PIN–UP is a biannual publication that celebrates architecture and design in the context of culture at large.',
        'url':'http://pinupmagazine.org/',
        'tier':'1',
      },
      {
        'name':'Phaidon',
        'logo':'images/partners/phaidon.png',
        'description':'Phaidon is the premier global publisher of the creative arts with over 1,500 titles in print.',
        'url':'http://www.phaidon.com/',
        'tier':'1',
      },
      {
        'name':'Brick & Wonder',
        'logo':'images/partners/brickandwonder.png',
        'description':'Brick & Wonder is a curated platform of the highest quality homes for sale worldwide.',
        'url':'https://brickandwonder.com/',
        'tier':'1',
      },
      {
        'name':'urbanNext',
        'logo':'images/partners/urbannext.png',
        'description':'urbanNext is a multi-platform aimed at developing and distributing architectural content.',
        'url':'https://urbannext.net/',
        'tier':'1',
      },
      {
        'name':'v2com',
        'logo':'images/partners/v2com.png',
        'description':'v2com is the international newswire that distributes design, architecture and lifestyle press kits to the media.',
        'url':'http://v2com-newswire.com/en',
        'tier':'1',
      },
      {
        'name':'Curbed',
        'logo':'images/partners/curbed.png',
        'description':'With coverage across 14 American cities, Curbed provides analysis, coverage and insight on the housing industry and the built environment.',
        'url':'http://www.curbed.com/',
        'tier':'1',
      },
      {
        'name':'Spirit of Space',
        'logo':'images/partners/spiritofspace.png',
        'description':'Spirit of Space is an award-winning filmmaking collaborative seeking to communicate the spirit of the people and places that build contemporary architecture, art, and design.',
        'url':'http://spiritofspace.com/',
        'tier':'1',
      },
      {
        'name':'BuiltWorlds',
        'logo':'images/partners/builtworlds.png',
        'description':'BuiltWorlds is a media network and community committed to exploring, understanding, and driving innovation across the built environment, from architecture to engineering, construction to finance, real estate and government.',
        'url':'http://www.builtworlds.com/',
        'tier':'1',
      },
      {
        'name':'Are.na',
        'logo':'images/partners/arena.png',
        'description':'Are.na is a platform for collaborative and independent research.',
        'url':'http://www.are.na/',
        'tier':'1',
      },
      {
        'name':'Chicago Architecture Biennial',
        'logo':'images/partners/cab.jpg',
        'description':'The Chicago Architecture Biennial provides a platform for groundbreaking architectural projects and spatial experiments.',
        'url':'http://www.chicagoarchitecturebiennial.org/',
        'tier':'1',
      },
      {
        'name':'NeoCon',
        'logo':'images/partners/neocon.png',
        'description':'',
        'url':'http://www.neocon.com/',
        'tier':'2',
      },
      {
        'name':'ICFF',
        'logo':'images/partners/icff.png',
        'description':'',
        'url':'http://www.icff.com/',
        'tier':'2',
      },
      {
        'name':'KBIS',
        'logo':'images/partners/kbis.png',
        'description':'',
        'url':'https://www.kbis.com/',
        'tier':'2',
      },
    ];

    }
})();
