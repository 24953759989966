'use strict';

angular
  .module('architizer.app')
  .factory('RouteHelperService', ['$q', 'AuthenticationService', function ($q, authentication) {

    var routeHelperService = {};

    /*
     * Helper function for handling auth on a route.
     *
     * Accepts an auth function from the state definition
     * which does the actual authorization. Authentication
     * is checked automatically. The auth function checks
     * authorization and returns a boolean indicating the
     * outcome of the check
     *
     * @param function fnAuth The auth function
     * @return promise
     */

    routeHelperService.checkAuth = function (authorizationFn, userPromise = authentication.getCurrentUser) {

      let deferred = $q.defer();

      userPromise()
        .then(function (user) {

          // Check if the user has permission
          if (authorizationFn(user)) {

            deferred.resolve(user);
          }

          // Not authorized
          else {

            deferred.reject({ reason: 'notAuthorized' });
          }
        }, function (error) {

          switch (error.code) {
            case 403:

              authentication
                .presentSigninModal()

              break;

            default:
              deferred.reject({ reason: 'serverError' })
              break;
          }
        });

      return deferred.promise;
    };

    return routeHelperService;
  }]);
