'use strict';

angular
  .module('architizer.app')
  .config(['$stateProvider', function ($stateProvider) {

    /**
     * Define States
     */
    $stateProvider

    ////////////////////////////////////////////////////////////////////////////////
    // App: BPM
    ////////////////////////////////////////////////////////////////////////////////

    // App: BPM Root
    .state({
      name: 'source.app.bpm',
      abstract: true,
    })

    // App: BPM Conversations List
    .state({
      name: 'source.app.bpm.conversations',
      resolve: {
        conversations: ResolveConversations,
        selectedConversation: ResolveSelectedConversation,
        actionBar: ['$state', 'user', ($state, user) => {
          let brands = [];
          if (user) {
            brands = user.brands.map(({ name, slug }) => ({ name, slug }));
          }

          return {
            breadcrumbs: [],
            viewIcon: '',
            viewTitle: viewTitle(),
            dropdownLinks: dropdownLinks(),
            rightLinks: rightLinks(),
          };

          function viewTitle() {
            let title = 'Opportunities';
            if (brands.length === 1) {
              title = brands[0].name;
            }
            return title;
          }

          function dropdownLinks() {
            let links = [];
            const viewLink = { label: 'View Brand' };
            const editLink = { label: 'Edit Brand' };

            // Only show this menu to users with one brand
            if (brands.length === 1) {

              // Set link destinations
              viewLink.ngClick = () => window.location.href = `/brands/${brands[0].slug}`;
              editLink.ngClick = () => window.location.href = `/brands/${brands[0].slug}/edit/details/`;

              links.push(viewLink);
              links.push(editLink);
            }

            return links;
          }

          function rightLinks() {
            let links = [];
            if (user) {
              links.push({ label: 'Opportunities', state: 'source.opportunities' });
              links.push({ label: 'Messages', state: 'source.app.bpm.conversations' });
            }
            return links;
          }
        }]
      },
      views: {
        'root@': {
          templateUrl: '/views/source/bpm/conversations.layout.html',
          controller: 'SourceBpmConversationsController',
          controllerAs: 'ConversationsCtrl',
        },
        'sidebar@source.app.bpm.conversations': {
          templateUrl: '/views/source/bpm/conversations.sidebar.conversations-list.html',
          controller: 'SourceBpmConversationsSidebarConversationsListController',
          controllerAs: 'ListCtrl',
        },
      },
      data: {
        isFullscreen: true,
        showIntercom: true,
        useAdk: true,
      },
    })

    // App: BPM Conversation Single
    .state({
      name: 'source.app.bpm.conversations.conversation',
      resolve: {
        conversation: ResolveConversation,
        messages: ResolveMessages,
        productRequest: ResolveProductRequest,
      },
      views: {
        'content@source.app.bpm.conversations': {
          templateUrl: '/views/source/shared/conversation.content.html',
          controller: 'SourceBpmConversationContentController',
          controllerAs: 'ConversationCtrl',
        },
        'actionBar@source.app.bpm.conversations': {
          templateUrl: '/views/source/bpm/conversation.action-bar.html',
          controller: 'SourceBpmConversationActionBarController',
          controllerAs: 'ActionBarCtrl',
        },
        'sendMessage@source.app.bpm.conversations': {
          templateUrl: '/views/source/bpm/conversation.send-message.html',
          controller: 'SourceBpmConversationSendMessageController',
          controllerAs: 'SendMessageCtrl',
        },
      },
      data: {
        showUiLoaderIn: 'content',
      },
    })

    // App: BPM Conversation Single: Sent Products
    .state({
      name: 'source.app.bpm.conversations.conversation.products',
      resolve: {
        productResponses: ResolveProductResponses,
      },
      views: {
        'content@source.app.bpm.conversations': {
          templateUrl: '/views/source/bpm/conversation.sent-products.html',
          controller: 'SourceBpmConversationSentProductsController',
          controllerAs: 'SentProductsCtrl',
        },
        'actionBar@source.app.bpm.conversations': {
          templateUrl: '/views/source/bpm/conversation.action-bar.html',
          controller: 'SourceBpmConversationActionBarController',
          controllerAs: 'ActionBarCtrl',
        },
        'sendMessage@source.app.bpm.conversations': {
          template: '',
        },
      },
    })

    ;

    /**
     * Resolve Functions
     */

    // Conversations
    ResolveConversations.$inject = ['Conversation'];
    function ResolveConversations (Conversation) {
      return Conversation.mine({
        force: 'project',
      }).$promise;
    }

    // Conversation
    ResolveConversation.$inject = ['Conversation', '$transition$'];
    function ResolveConversation (Conversation, $transition$) {
      return Conversation.get({
        id: $transition$.params().conversation,
        force: 'project,images',
      }).$promise;
    }

    // Selected Conversation
    ResolveSelectedConversation.$inject = ['$transition$'];
    function ResolveSelectedConversation ($transition$) {
      if ($transition$.params().conversation) {
        return {id: parseInt($transition$.params().conversation)};
      } else {
        return {};
      }
    }

    // Messages
    ResolveMessages.$inject = ['Message', '$transition$'];
    function ResolveMessages (Message, $transition$) {
      return Message.query({
        conversationId: $transition$.params().conversation,
        force: 'images',
        per_page: 20,
      }).$promise;
    }

    // Product Request
    ResolveProductRequest.$inject = ['ProductRequest', '$transition$'];
    function ResolveProductRequest (ProductRequest, $transition$) {
      return ProductRequest
      .query({
        conversation_id: $transition$.params().conversation,
        include_closed: true,
        force: 'images,types',
      })
      .$promise
      .then(function (productRequests) {

        /**
         * Return just the first item in the array
         *
         * This resource normally returns a list, not a single item,
         * so we are overriding the regular behavior of $resource query() calls
         * with this logic.
         */
        if (productRequests.length) {
          return productRequests[0];
        } else {
          return [];
        }
      });
    }

    // Product Responses
    ResolveProductResponses.$inject = ['Conversation', '$transition$'];
    function ResolveProductResponses (Conversation, $transition$) {
      return Conversation.productResponses({
        conversation_id: $transition$.params().conversation,
        sort: '-created',
      }).$promise;
    }

  }]);
