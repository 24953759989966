'use strict';

// formats a google geolocation object into a short format
angular
	.module('architizer.app')
	.filter('shortAddress', [function () {

		return function (input) {

			var output = '';
			
			if (!input) {
				
				return output;
			}

			if (input.administrative_area_level_1 && input.locality) {

				output += input.administrative_area_level_1 + ', ' + input.locality;
			}

			else if (input.administrative_area_level_1 && input.administrative_area_level_2) {

				output += input.administrative_area_level_1 + ', ' + input.administrative_area_level_2;
			}

			else if (input.administrative_area_level_2 && input.locality) {

				output += input.administrative_area_level_2 + ', ' + input.locality;
			}

			else if (input.locality && input.country) {

				output += input.locality + ', ' + input.country;
			}

			else {

				output += input.country;
			}

			return output;
		};
	}]);
