'use strict';

angular
	.module('architizer.app')
	.factory('Config', ['$window', function ($window) {

		// make sure there is an application config provided by the backend
		if (!$window._arcConfig) {

			throw 'A valid configuration was not found. Check the environment variable NODE_ENV is defined.';
		}

		// stitch together the api endpoint
		var apiUrlBase = '//' + $window._arcConfig.api.host + $window._arcConfig.api.base;

		// define and return configuration object
		return {
			apiUrlBase: apiUrlBase,
			architizerBaseUrl: $window._arcConfig.legacyBaseUrl,
			intercom: $window._arcConfig.intercom,
			measuredSearch: $window._arcConfig.measuredSearch,
		};
	}]);
