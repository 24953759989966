(function () {
  'use strict';

  angular
  .module('architizer.app')
  .controller('RequestTaggerController', RequestTaggerController);

  RequestTaggerController.$inject = [
    '$timeout',
    'CustomDataService',
    'FlashManager',
    'productRequest',
    'productRequestSolrDoc',
  ];

  function RequestTaggerController (
    $timeout,
    CustomDataService,
    FlashManager,
    productRequest,
    productRequestSolrDoc
  ) {

    let vm = this;

    // Data
    vm.form = {};
    vm.fields = fields();
    vm.productRequest = productRequest;
    vm.productTypes = productTypes();
    vm.saving = false;

    // Functions
    vm.isDataPointFieldRequired = isDataPointFieldRequired;
    vm.onSubmitClick = onSubmitClick;

    ////////////////////////////////////////////////////////////////////////////////
    // Functions
    ////////////////////////////////////////////////////////////////////////////////

    function productTypes () {
      const thereAreProductTypes =
      productRequestSolrDoc &&                          // There's a Solr document for this product request
      productRequestSolrDoc.product_type_ent_ss &&      // There's a product_type_ent_ss field on the document
      productRequestSolrDoc.product_type_ent_ss.length; // There are values in the product_type_ent_ss field

      let types = [];

      if (thereAreProductTypes) {
        types = productRequestSolrDoc.product_type_ent_ss;
      }

      return types;
    }

    function fields () {
      let dataPoints = CustomDataService.getRequestTaggerDataPoints();

      // Extend each structured data point with the template the data was created from
      CustomDataService.extendInstancesWithTemplates(productRequest.structured_data);

      // Dedupe and fill in the fields
      productRequest.structured_data.forEach((filledInField) => {
        let itMatches = dataPoints.find(whereNameMatches);

        if (itMatches) {
          let whereItMatches = dataPoints.indexOf(dataPoints.find(whereNameMatches));
          dataPoints[whereItMatches] = filledInField;
        }

        // Helper for checking if field names match
        function whereNameMatches (emptyField) { return emptyField.name === filledInField.name; }
      });

      return dataPoints;
    }

    /**
     * Check whether an optional criteria datapoint field should validate as "required"
     */
    function isDataPointFieldRequired (dataPoint) {
      if (!vm.form) { return; }

      var formSubmitted = vm.form.$submitted;
      var allFieldsAreRequired = dataPoint.requireAllFields;

      if (formSubmitted && allFieldsAreRequired) {
        var fieldsWithValues = dataPoint.fields.filter((field) => !!field.value);
        return fieldsWithValues.length;
      } else {
        return false;
      }
    }

    function onSubmitClick () {
      vm.form.$setSubmitted(true);

      $timeout(() => {

        // Invalid form
        if (!vm.form.$valid) {

          // Find the first element with an error
          let formError = vm.form.$error[Object.keys(vm.form.$error)[0]][0].$name;
          let errorElement = angular.element(document.getElementById(formError));

          // Find the scrolling element
          let pageElement = angular.element('main');

          // Tell the page element to scroll to the first error element
          pageElement.scrollToElement(errorElement, 150, 250);
          return;
        }

        // Valid form
        if (vm.form.$valid) {
          vm.saving = true;
          let productRequest = angular.copy(vm.productRequest);

          // Prepare Structured Data
          productRequest.structured_data = CustomDataService.prepareForAPI(vm.fields);
          productRequest.reference_brands = getReferenceBrands();

          // Save with API
          productRequest.$updatePublished(success, error);
        }

        ////////////////////////////////////////////////////////////////////////////////
        // Helpers
        ////////////////////////////////////////////////////////////////////////////////

        function success (search) {
          // Saved successfully. Set the `save` flag to false
          vm.saving = false;

          // Render a flash
          FlashManager.push({
            message: search.name + ' was saved',
            type: 'success',
            lifespan: 5000
          });
        }

        function error (data) {
          // Failed to save. Set the `save` flag to false
          vm.saving = false;
          console.error('Failed to save search: ', data);
        }

        /**
         * Helper function for finding "reference brands" field
         *
         * If a criterion called "referenceBrands" exists, this field is re-mapped to the
         * Product Request's "reference_brands" field on the back end.
         */
        function getReferenceBrands () {
          let referenceBrands = '';

          angular.forEach(angular.fromJson(vm.fields), (field) => {
            if (field.name === 'referenceBrands') {
              referenceBrands = field.fields[0].value;
            }
          });

          return referenceBrands;
        }
      });
    }
  }
})();