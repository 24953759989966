'use strict';

angular
	.module('architizer.app')
	.factory('ScrollService', ['$document', function ($document) {

		return {
			scrollToElement: function (element, yOffset, speed) {

				yOffset = yOffset || 0;
				speed = speed || 1000;

				if (angular.isString(element)) {

					element = angular.element(document.getElementById(element));
				}

				if (!element.length) {

					return;
				}

				$document.scrollToElement(element, yOffset, speed);
			}
		}
	}]);