'use strict';

angular
	.module('ui')
	.directive('uiText', ['$parse', '$timeout', function ($parse, $timeout) {

		return {
			replace: true,
			restrict: 'E',
			templateUrl: '/views/uikit/ui-text.html',
			require: [
				'?ngModel',
				'?ngDisabled'
			],
			scope: {
				ngModel: '=',
				modelOptions: '=ngModelOptions',
				label: '@',
				inlineLabel: '@',
				placeholder: '@',
				ngDisabled: '=',
				form: '=',
				name: '@',
				outerType: '@type',
				flasher: '=',
				mask: '@',
				maxChars: '@',
				customMessagesTemplate: '@',
			},
			compile: function (element, attrs) {

				return {
					pre: function ($scope, element) {

						// Default ng-model-options
						$scope.ngModelOptions = $scope.modelOptions || {};
					},

					post: function ($scope, element, attrs, controllers) {

						/*
						 * View params
						 */

						$scope.type = 'text';
						$scope.isHover = false;
						$scope.isFocussed = false;
						$scope.hasText = false;
						$scope.isInlineLabelGlyph = attrs.hasOwnProperty('inlineLabelGlyph');

						// The input model. We proxy this to the ui-text model.
						$scope.value = '';

						// Flag to indicate destroy is in process
						var _destroy = false;


						/*
						 * Input types and validation
						 * When the input is type="email", Angular applies its in-built validator.
						 * This interferes with our validation and prevents the correct
						 * behaviour of our float labels.
						 *
						 * This is a variation on http://stackoverflow.com/questions/17953908/angular-validate-input-type-email-as-type-text?rq=1
						 * However we use a timeout instead of a directive priority, to prevent
						 * us having to add an extra directive to the inside of the ui-text
						 * viewscript.
						 */

						$timeout(function() {

							$scope.type = $scope.outerType;
						});

						/*
						 * UI methods
						 */

						// update placeholder when text is changed
						// cannot rely on $modelValue because of debounce
						// so we will also call the handler upon typing
						function updatePlaceholder() {

							var input = element.children().eq(1).children().get(1);
							$scope.hasText = !!input.value;
						};

						// perform paint operations
						function reposition() {

							// position the placeholder correctly according to the
							// width of the inline label
							var floatingLabel = element.children().eq(0).children().eq(0);
							var inlineLabel = element.children().eq(1).children().eq(0);

							// could get calculated css, but hard code for the sake of performance.
							var inlineLabelMargin;
							if ($scope.inlineLabel) {

								if ($scope.isInlineLabelGlyph) {

									inlineLabelMargin = 10;
								}

								else {

									inlineLabelMargin = 4;
								}
							}

							floatingLabel.css('left', (inlineLabel.width() + inlineLabelMargin) + 'px');
						};

						// Begin the position loop
						(function positionLoop () {

							// Only run positioning loop until control is destroyed
							if (!_destroy) {

								requestAnimFrame(positionLoop);
								reposition();
							}
						})();

						// Clean up
						$scope.$on('$destroy', function () {

							_destroy = true;
						});


						/*
						 * UI interaction
						 */

						$scope.onMouseEnter = function () {

							$scope.isHover = true;
						};

						$scope.onMouseOut = function () {

							$scope.isHover = false;
						};

						$scope.onFocus = function () {

							$scope.isFocussed = true;
						};

						$scope.onBlur = function () {

							$scope.isFocussed = false;
							setTouched();
						};

						// Pipe provided events on directive to inner input
						var events = ['click', 'mouseout', 'mouseenter', 'blur', 'focus', 'keydown', 'keyup'];
						angular.forEach(events, function (event) {

							// Parse a handler out of attribute
							var ngEvent = 'ng' + event.charAt(0).toUpperCase() + event.slice(1);
							var attrHandler = $parse(attrs[ngEvent]);

							// Raw JS event handler
		          var handler = function (e) {

		            $scope.$apply(function () {

		              attrHandler($scope.$parent, { $event: e });
		            });
		          };

		          var useCapture = (['blur', 'focus'].indexOf(event) !== -1);

		          // Attach handler to js event
		          element.children().eq(1).children()[1].addEventListener(event, handler, useCapture);
						});


						/*
						 * Data methods
						 */

						// end here if no ng-model
						var ngModel = controllers[0];
						if (!controllers[0]) {

							return;
						}

						var setTouched = function () {

							ngModel.$setTouched();
						};

						$scope.$watch(function () {

							return ngModel.$viewValue;
						}, updatePlaceholder);
					}
				}
			}
		};
	}]);
