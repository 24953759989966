(function () {
  'use strict';

  angular
  .module('architizer.app')
  .factory('FileClick', FileClick);

  FileClick.$inject = [
    'ImageService',
    'PopupManager',
    '$window',
  ];

  function FileClick (
    ImageService,
    PopupManager,
    $window
  ) {

    var service = this; 

    // Functions 
    service.openFileModal = openFileModal;
    service.onFileDownloadClick = onFileDownloadClick;
    
    return service;

    ////////////////////////////////////////////////////////////////////////////////
    // Functions
    ////////////////////////////////////////////////////////////////////////////////
    /**
     * Open file modal
     */
    function openFileModal (resource, fileIndex) {

      var files = resource.images.gallery;

      if (!files) { return; }

      // Return if no files or if image's media_status isn't ready
      if (!files[fileIndex] || files[fileIndex].media_status !== 'R') { return; }

      // Define options for ImageService
      var options = files[fileIndex];
      options.title = resource.name;
      options.showAd = false;
      options.actions = [
        {
          label: 'Download',
          click: onFileDownloadClick(files[fileIndex].base_imgix_url)
        }
      ];

      /**
       * Tell the ImageService a base z-fileIndex at which to open any Image modals
       * so we can render the Image modal above any showing PopupManager modal(s)
       *
       * PopupManager defines the base z-fileIndex at 10000, then each additional popup
       * opens at (baseZ + n).
       *
       * We'll use 10001 as an "offset" for the z-fileIndex since there will have to be
       * at least one popup open when this function is called.
       */
      options.baseZ = (10001 + PopupManager.count());

      // Open the image modal
      ImageService.openImage(files[fileIndex].id, options);
    }

    /**
     * On file download click
     */
    function onFileDownloadClick (url) {
      let filename = url.substring(url.lastIndexOf('/')+1);

      return function DownloadFile ($event) {
        $event.stopPropagation();

        // Add ?dl= parameter to force Imgix to send the file as an attachment
        $window.location.href = url + '?dl=' + filename;
      };
    }
  }
})();


