(function () {
  'use strict';

  angular
  .module('architizer.app')
  .factory('StructuredDataService', StructuredDataService);

  // UFO Constructor
  /*
    🛸🛸🛸🛸🛸🛸🛸🛸🛸🛸🛸
    WHAT ARE UFOs?!
    UFO stands for "UI Field Object" because we couldn't come up with a good name for this concept.
    UFOs are basically the data that the UI needs in order to properly display the field for input.

    It's literally named UFO because we didn't want to name it (seems fitting 🚀)
    🛸🛸🛸🛸🛸🛸🛸🛸🛸🛸🛸

    This class has to be declared before it is used because prototype methods are function expressions 
    and must be defined before they are used (they don't get hoisted, but the constructor does)
  */
  class UFO {
    constructor(data = {}) {
      this.label = data.label;
      this.name = data.name;
      this.fields = data.fields || [];

      // Filter out anything in .fields that isn't a UFOField instance
      this.fields = this.fields.filter((field) => field instanceof UFOField);

      // Don't make a UFO if data is incomplete
      if (
        !this.label ||
        !this.name
      ) {
        return {}; // Return an empty object so that (newUfo instanceof UFO) evaluates to false
      }

      // Optional properties
      this.decorator = data.decorator || 'text';    // TODO: Remove this, just use .value on the UIs where this matters and don't switch on "decorators"
      this.requireAllFields = data.requireAllFields || false;
      this.unit = data.unit || '';
    }

    // .value method on UFOs to get human-readable / display-friendly "value" string from field values
    get value () {
      // Don't throw an error if there are no fields
      if (!Array.isArray(this.fields) || !this.fields.length) {
        return undefined;
      }

      // Check if all fields are filled in
      let emptyFields = this.fields.filter(({ value }) => {
        if (Array.isArray(value)) {
          return !value.length;
        } else {
          return !value;
        }
      });

      // Return undefined for .value if a field is empty
      if (emptyFields.length) {
        return undefined;
      }

      // Construct return string
      let values = [];
      let separator = '';

      this.fields.forEach((field) => {
        let fieldHasArrayValue = Array.isArray(field.value);
        let fieldHasUnitValue = field.value;

        if (fieldHasArrayValue) {
          field.value.forEach((value) => values.push(value));
          separator = ', '; // Join on commas if it's an array (ex. 'red, green, blue')
        } else if (fieldHasUnitValue) {
          values.push(field.value);
          separator = ' '; // Join on spaces if it's a "unit" (ex. '12 in')
        }
      });

      return values.join(separator);
    }

    get hasApiField () {
      return !!(this.fields.filter((field) => field.apiField).length);
    }
  }

  // UFOField Constructor
  // These are the objects that are found in .fields of a UFO
  class UFOField {
    constructor(data = {}) {
      const allowedUis = ['select', 'selectize', 'text', 'textarea'];

      // Don't make a UFOField if data is incomplete
      if (
        !data.label ||
        !data.ui ||
        !allowedUis.includes(data.ui)
      ) {
        return {};  // Return an empty object so that (newUfoField instanceof UFOField) evaluates to false
      }

      this.label = data.label;
      this.ui = data.ui;

      // Optional properties
      this.apiField = data.apiField || undefined; // Determines whether this field should be saved directly on the model rather than in structured_data
      this.type = data.type         || undefined; // Determines whether to do <input type="number"> or <input type="text"> etc

      // Add UI-specific properties
      let text      = (this.ui === 'text');
      let textarea  = (this.ui === 'textarea');
      let select    = (this.ui === 'select');
      let selectize = (this.ui === 'selectize');

      if (text) {
        this.name = data.name || 'value';
        this.value = data.value || '';
        this.type = data.type || 'text';
        this.placeholder = data.placeholder || 'Enter a value';

      } else if (textarea) {
        this.name = data.name || 'value';
        this.value = data.value || '';
        this.placeholder = data.placeholder || '';

      } else if (select) {
        this.name = data.name || 'value';
        this.value = data.value || '';
        this.placeholder = data.placeholder || 'Please choose…';
        this.options = data.options || [];

      } else if (selectize) {
        this.name = 'value';
        this.value = data.value || [];
        this.config = data.config || selectizeConfig();
        this.options = data.options || [];
        this.placeholder = data.placeholder || 'Choose from the dropdown or type anything';
      }

      // Helpers 
      // Configuration for selectize elements
      function selectizeConfig () {
        return {
          create: true,
          createOnBlur: true,
          selectOnTab: false,
          hideSelected: true,
          searchField: ['label', 'value'],
          highlight: true,
          labelField: 'label',
          valueField: 'value',
          delimiter: ',',
          sortField: [{ field: 'count', direction: 'desc' }],
        };
      }
    }
  }

  // Structured Data Service
  /**
    This service handles transformation of structured_data (from API) into objects usable for forms (UFOs) and vice versa

    About serialize()/deserialize():

    - Passing an array of UFOs into the serialize() method should return a structured_data array to be sent to the API
    - Passing a structured_data array AND an array of ufosToShow (as strings, like ['color', 'material', 'height'])
      into the deserialize() method should return a UFOs array for use on a form

    structured_data should look like this from the API:
    [
      {
        name: 'color',
        fields: [
          { name: 'value', value: ['red', 'blue', 'green'] }
        ]
      },
      {
        name: 'height',
        fields: [
          { name: 'value', value: '12' },
          { name: 'unit', value: 'in' },
        ]
      }
    ]

    After calling the function like `deserialize(structured_data, ['color', 'height'])`,
    the equivalent UFOs for the above would look like this:
    [
      {
        name: 'color',
        label: 'Color',
        decorator: 'array',
        fields: [
          {
            label: 'Color',
            name: 'value',
            ui: 'selectize',
            value: ['red', 'blue', 'green'],
            config: selectizeConfig(),
            options: options('color'),
            placeholder: 'Choose from the dropdown or type anything'
          }
        ]
      },
      {
        name: 'height',
        label: 'Height',
        decorator: 'unit',
        requireAllFields: true,
        fields: [
          {
            label: 'Height',
            name: 'value',
            value: '12',
            ui: 'text',
            type: 'text',
            placeholder: 'Enter a value'
          },
          {
            label: 'Unit Type',
            name: 'unit',
            value: 'in',
            ui: 'select',
            options: UnitsService.linear.options,
            placeholder: 'Please choose…'
          }
        ]
      }
    ]

   */

  StructuredDataService.$inject = [
    'LudwigService',
    'UnitsService',
  ];

  function StructuredDataService (
    LudwigService,
    UnitsService
  ) {

    const service = this;

    // Functions
    service.serialize = serialize;
    service.deserialize = deserialize;

    // UFOs (Data Points)
    service.UFO = UFO;            // To expose the UFO constructor for testing purposes
    service.UFOField = UFOField;  // To expose the UFOField constructor for testing purposes

    service.UFOs = {
      width: width,
      length: length,
      height: height,
      mass: mass,
      volume: volume,
      unitPrice: unitPrice,
      unit_price: unitPrice, // this exists because there is an issue displaying old requests with unit_price defined as field name
      color: color,
      material: material,
      finish: finish,
      application: application,
      brandUrl: brandUrl,
      format: format,
      description: description,
      performance: performance,
      productType: productType,
      product_type: productType,   // NOTE: This is snake_case because our Entity Extraction pipeline merges this data into an existing "product_type" list
      colorName: colorName,
      modelNumber: modelNumber,
      productUrl: productUrl,
      productName: productName,
      referenceBrands: referenceBrands,
      internalNotes: internalNotes,
      leadTime: leadTime,
      price: price,
    };

    return service;

    ////////////////////////////////////////////////////////////////////////////////
    // Functions
    ////////////////////////////////////////////////////////////////////////////////

    // UFO to API
    // Takes array of UFOs from a form and returns structured_data for the API
    function serialize (ufos) {

      let structured_data = ufos
      .filter(notApiFields)   // Don't serialize UFOs that should be saved on the model (instead of in structured_data)
      .filter(notEmptyFields) // Don't serialize UFOs that are incomplete or have empty values
      .map(nameAndFields);    // Create the name/fields objects that we store in structured_data

      return structured_data;

      // Helpers
      function notApiFields (ufo) { return !ufo.hasApiField; }

      function notEmptyFields (ufo) { return !!ufo.value; }

      function nameAndFields (ufo) {
        let structured_data_point = {};

        structured_data_point.name = ufo.name;
        structured_data_point.fields = ufo.fields.map(({ name, value }) => ({ name, value }));

        return structured_data_point;
      }
    }

    // API to UFO
    /*
    - Takes structured_data array from API
    - Takes ufosToShow array of strings to determine both:
      1. which UFOs to return
      2. in what order

      Example:
        ['color', 'material', 'finish'] passed as ufosToShow
        will return in these fields in this order, and they will be populated with values from structured_data if they exist

    - Returns an array of UFOs instantiated and populated with values from structured_data
    */
    function deserialize (structured_data = [], ufosToShow = []) {

      let ufos = ufosToShow
      .map(createUfos)                    // Create the UFOs in order
      .filter(onlyUfosThatActuallyExist)  // Don't show UFOs from ufosToShow that aren't found
      .map(repopulateValues);             // Merge values from structured data into UFO fields

      return ufos;

      // Helpers
      function createUfos (fieldName) { return getUFO(fieldName); }

      function onlyUfosThatActuallyExist (ufo) { return ufo instanceof UFO; }

      function repopulateValues (ufo) {
        let sdMatch = structured_data.find(({ name }) => name === ufo.name);

        if (sdMatch) {
          sdMatch.fields.map((sdField) => {
            let ufoField = ufo.fields.find(({ name }) => name === sdField.name);
            if (ufoField) {
              ufoField.value = sdField.value;
            }
          });
        }

        return ufo;
      }
    }

    ////////////////////////////////////////////////////////////////////////////////
    // "UFOs" (UI Field Objects, formerly "data points" or "optional criteria")
    ////////////////////////////////////////////////////////////////////////////////

    function color () {
      const ufoData = {};
      const selectizeField = new UFOField({ label: 'Color', ui: 'selectize', options: options('color') });

      ufoData.name = 'color';
      ufoData.label = 'Color';
      ufoData.decorator = 'array'; // TODO: Refactor decorators in light of .value on UFOs

      ufoData.fields = [ selectizeField ];

      return new UFO(ufoData);
    }

    function material () {
      const ufoData = {};
      const selectizeField = new UFOField({ label: 'Material', ui: 'selectize', options: options('material') });

      ufoData.name = 'material';
      ufoData.label = 'Material';
      ufoData.decorator = 'array'; // TODO: Refactor decorators in light of .value on UFOs

      ufoData.fields = [ selectizeField ];

      return new UFO(ufoData);
    }

    function finish () {
      const ufoData = {};
      const selectizeField = new UFOField({ label: 'Finish', ui: 'selectize', options: options('finish') });

      ufoData.name = 'finish';
      ufoData.label = 'Finish';
      ufoData.decorator = 'array'; // TODO: Refactor decorators in light of .value on UFOs

      ufoData.fields = [ selectizeField ];

      return new UFO(ufoData);
    }

    function application () {
      const ufoData = {};
      const selectizeField = new UFOField({ label: 'Application', ui: 'selectize', options: options('application') });

      ufoData.name = 'application';
      ufoData.label = 'Application';
      ufoData.decorator = 'array'; // TODO: Refactor decorators in light of .value on UFOs

      ufoData.fields = [ selectizeField ];

      return new UFO(ufoData);
    }

    function format () {
      const ufoData = {};
      const selectizeField = new UFOField({ label: 'Format', ui: 'selectize', options: options('format') });

      ufoData.name = 'format';
      ufoData.label = 'Format';
      ufoData.decorator = 'array'; // TODO: Refactor decorators in light of .value on UFOs

      ufoData.fields = [ selectizeField ];

      return new UFO(ufoData);
    }

    function productType () {
      const ufoData = {};
      const selectizeField = new UFOField({ label: 'Product Type', ui: 'selectize', options: options('product_type'), placeholder: 'Enter product type' });

      ufoData.name = 'product_type'; // NOTE: This is snake_case because our Entity Extraction pipeline merges this data into an existing "product_type" list
      ufoData.label = 'Product Type';
      ufoData.decorator = 'array'; // TODO: Refactor decorators in light of .value on UFOs

      ufoData.fields = [ selectizeField ];

      return new UFO(ufoData);
    }

    function performance () {
      const ufoData = {};
      const selectizeField = new UFOField({ label: 'Performance', ui: 'selectize', options: options('performance'), placeholder: 'Enter performance criteria' });

      ufoData.name = 'performance';
      ufoData.label = 'Performance';
      ufoData.decorator = 'array'; // TODO: Refactor decorators in light of .value on UFOs

      ufoData.fields = [ selectizeField ];

      return new UFO(ufoData);
    }

    function description () {
      const ufoData = {};
      const textareaField = new UFOField({ label: 'Description', ui: 'textarea', apiField: 'description', placeholder: 'Anything else we should know? Performance criteria, logistical information, etc.' });

      ufoData.name = 'description';
      ufoData.label = 'Description';
      ufoData.decorator = 'text';

      ufoData.fields = [ textareaField ];

      return new UFO(ufoData);
    }

    function referenceBrands () {
      const ufoData = {};
      const textField = new UFOField({ label: 'Reference Brands or Products', ui: 'text', apiField: 'reference_brands', type: 'text', placeholder: 'Are there any brands or products that you have already considered for this search?' });

      ufoData.name = 'referenceBrands';
      ufoData.label = 'Reference Brands';
      ufoData.decorator = 'text';

      ufoData.fields = [ textField ];

      return new UFO(ufoData);
    }

    function width () {
      const ufoData = {};
      const valueTextField = new UFOField({ label: 'Width', ui: 'text' });
      const unitSelectField = new UFOField({ label: 'Unit Type', ui: 'select', name: 'unit', options: UnitsService.linear.options });

      ufoData.name = 'width';
      ufoData.label = 'Width';
      ufoData.decorator = 'unit';
      ufoData.requireAllFields = true;

      ufoData.fields = [ valueTextField, unitSelectField ];

      return new UFO(ufoData);
    }

    function length () {
      const ufoData = {};
      const valueTextField = new UFOField({ label: 'Length', ui: 'text' });
      const unitSelectField = new UFOField({ label: 'Unit Type', ui: 'select', name: 'unit', options: UnitsService.linear.options });

      ufoData.name = 'length';
      ufoData.label = 'Length';
      ufoData.decorator = 'unit';
      ufoData.requireAllFields = true;

      ufoData.fields = [ valueTextField, unitSelectField ];

      return new UFO(ufoData);
    }

    function height () {
      const ufoData = {};
      const valueTextField = new UFOField({ label: 'Height', ui: 'text' });
      const unitSelectField = new UFOField({ label: 'Unit Type', ui: 'select', name: 'unit', options: UnitsService.linear.options });

      ufoData.name = 'height';
      ufoData.label = 'Height';
      ufoData.decorator = 'unit';
      ufoData.requireAllFields = true;

      ufoData.fields = [ valueTextField, unitSelectField ];

      return new UFO(ufoData);
    }

    function mass () {
      const ufoData = {};
      const valueTextField = new UFOField({ label: 'Mass', ui: 'text' });
      const unitSelectField = new UFOField({ label: 'Unit Type', ui: 'select', name: 'unit', options: UnitsService.mass.options });

      ufoData.name = 'mass';
      ufoData.label = 'Mass';
      ufoData.decorator = 'unit';
      ufoData.requireAllFields = true;

      ufoData.fields = [ valueTextField, unitSelectField ];

      return new UFO(ufoData);
    }

    function volume () {
      const ufoData = {};
      const valueTextField = new UFOField({ label: 'Volume', ui: 'text' });
      const unitSelectField = new UFOField({ label: 'Unit Type', ui: 'select', name: 'unit', options: UnitsService.volume.options });

      ufoData.name = 'volume';
      ufoData.label = 'Volume';
      ufoData.decorator = 'unit';
      ufoData.requireAllFields = true;

      ufoData.fields = [ valueTextField, unitSelectField ];

      return new UFO(ufoData);
    }

    function unitPrice () {
      const ufoData = {};
      const priceLowTextField = new UFOField({ label: 'Price low', name: 'price_low', ui: 'text', type: 'number' });
      const priceHighTextField = new UFOField({ label: 'Price high', name: 'price_high', ui: 'text', type: 'number'});

      ufoData.name = 'unit_price';
      ufoData.label = 'Unit Price';
      ufoData.decorator = 'range';
      ufoData.requireAllFields = true;
      ufoData.unit = '$'; // TODO: Deprecate or roll into another part of the field (?)

      ufoData.fields = [ priceLowTextField, priceHighTextField ];

      return new UFO(ufoData);
    }

    function price () {
      const ufoData = {};
      const minPriceTextField = new UFOField({ label: 'Min Price', ui: 'text', type: 'number', apiField: 'price_low', placeholder: '', preLabel: '$' });
      const maxPriceTextField = new UFOField({ label: 'Max Price', ui: 'text', type: 'number', apiField: 'price_high', placeholder: '', preLabel: '$' });

      ufoData.name = 'price';
      ufoData.label = 'Price';
      ufoData.decorator = 'range';
      ufoData.requireAllFields = true;

      ufoData.fields = [ minPriceTextField, maxPriceTextField ];

      return new UFO(ufoData);
    }

    function leadTime () {
      const ufoData = {};
      const minLeadTimeTextField = new UFOField({ label: 'Min Lead Time', ui: 'text', type: 'number', apiField: 'lead_time_low', placeholder: '', postLabel: 'weeks' });
      const maxLeadTimeTextField = new UFOField({ label: 'Max Lead Time', ui: 'text', type: 'number', apiField: 'lead_time_high', placeholder: '', postLabel: 'weeks' });

      ufoData.name = 'leadTime';
      ufoData.label = 'Lead Time';
      ufoData.decorator = 'range';
      ufoData.requireAllFields = true;
      ufoData.unit = '';  // TODO: Deprecate or roll into another part of the field (?)

      ufoData.fields = [ minLeadTimeTextField,  maxLeadTimeTextField ];

      return new UFO(ufoData);
    }

    function modelNumber () {
      const ufoData = {};
      const modelNumberTextField = new UFOField({ label: 'Model Number', ui: 'text', placeholder: 'Enter model number' });

      ufoData.name = 'modelNumber';
      ufoData.label = 'Model Number';
      ufoData.decorator = 'text';

      ufoData.fields = [ modelNumberTextField ];

      return new UFO(ufoData);
    }

    function colorName () {
      const ufoData = {};
      const colorNameTextField = new UFOField({ label: 'Color Name', ui: 'text', placeholder: 'Enter color name' });

      ufoData.name = 'colorName';
      ufoData.label = 'Color Name';
      ufoData.decorator = 'text';

      ufoData.fields = [ colorNameTextField ];

      return new UFO(ufoData);
    }

    function productUrl () {
      const ufoData = {};
      const productUrlTextField = new UFOField({ label: 'Product URL', ui: 'text' });

      ufoData.name = 'productUrl';
      ufoData.label = 'Product URL';
      ufoData.decorator = 'link';

      ufoData.fields = [ productUrlTextField ];

      return new UFO(ufoData);
    }

    function brandUrl () {
      const ufoData = {};
      const brandUrlTextField = new UFOField({ label: 'Brand URL', ui: 'text' });

      ufoData.name = 'brandUrl';
      ufoData.label = 'Brand URL';
      ufoData.decorator = 'link';

      ufoData.fields = [ brandUrlTextField ];

      return new UFO(ufoData);
    }

    function productName () {
      const ufoData = {};
      const productNameTextField = new UFOField({ label: 'Product Name', ui: 'text' });

      ufoData.name = 'productName';
      ufoData.label = 'Product Name';
      ufoData.decorator = 'text';

      ufoData.fields = [ productNameTextField ];

      return new UFO(ufoData);
    }

    function internalNotes () {
      const ufoData = {};
      const internalNotesTextareaField = new UFOField({ label: 'Notes', ui: 'textarea', placeholder: 'Leave feedback for our internal team' });

      ufoData.name = 'internalNotes';
      ufoData.label = 'Internal Notes';
      ufoData.decorator = 'longform';

      ufoData.fields = [ internalNotesTextareaField ];

      return new UFO(ufoData);
    }

    ////////////////////////////////////////////////////////////////////////////////
    // Helpers
    ////////////////////////////////////////////////////////////////////////////////

    // Get the UI Field Object (UFO)
    function getUFO (name) {
      if (service.UFOs[name]) {
        return service.UFOs[name]();
      }
    }

    // Function to populate options inside of Selectize data point fields
    function options (field) {
      if (!field) { return []; }

      return LudwigService.getProductTags(field)  // Try to get product tags
      .then(mapApiResponseToSelectizeValues)      // Translate API response into Selectize-friendly options
      .catch(() => fallbackOptions()[field]);     // Use fallback options if not available
    }

    /**
     * The API returns "options" in the format { name: 'wall-mounted', count: 12 }
     *
     * Selectize needs "options" in the format { label: 'wall-mounted', value: 'wall-mounted' }
     *
     * This function translates the API response into an array of Selectize-friendly options
     */
    function mapApiResponseToSelectizeValues (options) {
      return options.map((option) => {
        let realValue = '';

        // Find the true value of the option
        if (option.value) {
          realValue = option.value;
        } else if (option.label) {
          realValue = option.label;
        } else if (option.name) {
          realValue = option.name;
        }

        // Give Selectize-friendly properties to the options
        option.value = realValue;
        option.label = realValue;

        return option;
      });
    }

    function fallbackOptions () {
      return {
        color: [
          { label: 'beige', value: 'beige' },
          { label: 'black', value: 'black' },
          { label: 'blue', value: 'blue' },
          { label: 'bronze', value: 'bronze' },
          { label: 'brown', value: 'brown' },
          { label: 'chrome', value: 'chrome' },
          { label: 'copper', value: 'copper' },
          { label: 'cream', value: 'cream' },
          { label: 'gold', value: 'gold' },
          { label: 'green', value: 'green' },
          { label: 'grey - light', value: 'grey - light' },
          { label: 'grey - medium', value: 'grey - medium' },
          { label: 'grey - dark', value: 'grey - dark' },
          { label: 'multicolor', value: 'multicolor' },
          { label: 'orange', value: 'orange' },
          { label: 'pink', value: 'pink' },
          { label: 'purple', value: 'purple' },
          { label: 'red', value: 'red' },
          { label: 'tan', value: 'tan' },
          { label: 'white', value: 'white' },
          { label: 'yellow', value: 'yellow' },
          { label: 'wood - light', value: 'wood - light' },
          { label: 'wood - medium', value: 'wood - medium' },
          { label: 'wood - dark', value: 'wood - dark' },
          { label: 'lighting - warm', value: 'lighting - warm' },
          { label: 'lighting - cool', value: 'lighting - cool' },
          { label: 'lighting - multicolor', value: 'lighting - multicolor' },
        ],
        format: [
          { label: 'large format', value: 'large format' },
          { label: 'tile', value: 'tile' },
          { label: 'plank', value: 'plank' },
          { label: 'panel', value: 'panel' },
          { label: 'applied', value: 'applied' },
          { label: 'poured', value: 'poured' },
          { label: 'blocks', value: 'blocks' },
          { label: 'bricks', value: 'bricks' },
          { label: 'field', value: 'field' },
          { label: 'freestanding', value: 'freestanding' },
          { label: 'large', value: 'large' },
          { label: 'oval', value: 'oval' },
          { label: 'sheet', value: 'sheet' },
          { label: 'pendant', value: 'pendant' },
          { label: 'slatted', value: 'slatted' },
          { label: 'round', value: 'round' },
          { label: 'cylindrical', value: 'cylindrical' },
          { label: 'square', value: 'square' },
          { label: 'rectangle', value: 'rectangle' },
          { label: 'hexagon', value: 'hexagon' },
          { label: 'slab', value: 'slab' },
          { label: 'wide', value: 'wide' },
          { label: 'straight laid', value: 'straight laid' },
          { label: 'herringbone', value: 'herringbone' },
        ],
        material: [
          { label: 'acrylic', value: 'acrylic' },
          { label: 'aluminum', value: 'aluminum' },
          { label: 'brass', value: 'brass' },
          { label: 'cement', value: 'cement' },
          { label: 'ceramic', value: 'ceramic' },
          { label: 'chrome', value: 'chrome' },
          { label: 'clay', value: 'clay' },
          { label: 'concrete', value: 'concrete' },
          { label: 'copper', value: 'copper' },
          { label: 'epoxy', value: 'epoxy' },
          { label: 'fabric', value: 'fabric' },
          { label: 'felt', value: 'felt' },
          { label: 'fiberglass', value: 'fiberglass' },
          { label: 'foam', value: 'foam' },
          { label: 'glass', value: 'glass' },
          { label: 'gypsum', value: 'gypsum' },
          { label: 'granite', value: 'granite' },
          { label: 'iron', value: 'iron' },
          { label: 'laminate', value: 'laminate' },
          { label: 'limestone', value: 'limestone' },
          { label: 'marble', value: 'marble' },
          { label: 'metal', value: 'metal' },
          { label: 'nylon', value: 'nylon' },
          { label: 'plaster', value: 'plaster' },
          { label: 'plastic', value: 'plastic' },
          { label: 'polyester', value: 'polyester' },
          { label: 'porcelain', value: 'porcelain' },
          { label: 'quartz', value: 'quartz' },
          { label: 'resin', value: 'resin' },
          { label: 'rubber', value: 'rubber' },
          { label: 'slate', value: 'slate' },
          { label: 'solid surface', value: 'solid surface' },
          { label: 'stainless steel', value: 'stainless steel' },
          { label: 'steel', value: 'steel' },
          { label: 'stone', value: 'stone' },
          { label: 'travertine', value: 'travertine' },
          { label: 'urethane', value: 'urethane' },
          { label: 'velvet', value: 'velvet' },
          { label: 'veneer', value: 'veneer' },
          { label: 'wood', value: 'wood' },
          { label: 'wood - natural', value: 'wood - natural' },
          { label: 'wood - engineered', value: 'wood - engineered' },
          { label: 'terrazzo', value: 'terrazzo' },
        ],
        finish: [
          { label: 'anodized', value: 'anodized' },
          { label: 'brushed', value: 'brushed' },
          { label: 'clear-coated', value: 'clear-coated' },
          { label: 'frosted', value: 'frosted' },
          { label: 'glazed', value: 'glazed' },
          { label: 'gloss', value: 'gloss' },
          { label: 'high-gloss', value: 'high-gloss' },
          { label: 'honed', value: 'honed' },
          { label: 'lapped', value: 'lapped' },
          { label: 'matte', value: 'matte' },
          { label: 'metallic', value: 'metallic' },
          { label: 'mirror', value: 'mirror' },
          { label: 'natural', value: 'natural' },
          { label: 'non-slip', value: 'non-slip' },
          { label: 'oiled', value: 'oiled' },
          { label: 'painted', value: 'painted' },
          { label: 'polished', value: 'polished' },
          { label: 'powder coated', value: 'powder-coated' },
          { label: 'satin', value: 'satin' },
          { label: 'semi-gloss', value: 'semi-gloss' },
          { label: 'stain resistant', value: 'stain resistant' },
          { label: 'stained', value: 'stained' },
          { label: 'unfinished', value: 'unfinished' },
          { label: 'varnished', value: 'varnished' },
        ],
        application: [
          { label: 'exterior', value: 'exterior' },
          { label: 'interior', value: 'interior' },
          { label: 'wet', value: 'wet' },
          { label: 'dry', value: 'dry' },
          { label: 'wall', value: 'wall' },
          { label: 'ceiling', value: 'ceiling' },
          { label: 'floor', value: 'floor' },
          { label: 'roof', value: 'roof' },
          { label: 'stairs', value: 'stairs' },
          { label: 'stair treads', value: 'stair treads' },
          { label: 'facade', value: 'facade' },
          { label: 'cabinets', value: 'cabinets' },
          { label: 'millwork', value: 'millwork' },
          { label: 'countertops', value: 'countertops' },
          { label: 'paneling', value: 'paneling' },
          { label: 'partition', value: 'partition' },
          { label: 'balustrade', value: 'balustrade' },
          { label: 'guardrail infill', value: 'guardrail infill' },
        ]
      };
    }

  }
  
})();