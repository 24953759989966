(function() {
  'use strict';

  angular
    .module('architizer.app')
    .controller('ProjectSearchCreateModalController', ProjectSearchCreateModalController);

  ProjectSearchCreateModalController.$inject = [
    '$filter',
    '$modalInstance',
    '$scope',
    '$timeout',
    '$window',
    'Config',
    'LudwigService',
    'ProductRequest',
    'productRequests',
    'project',
    'SearchstaxService',
    'user',
  ];

  function ProjectSearchCreateModalController (
    $filter,
    $modalInstance,
    $scope,
    $timeout,
    $window,
    Config,
    LudwigService,
    ProductRequest,
    productRequests,
    project,
    SearchstaxService,
    user
  ) {

    var vm = this;

    // Data
    vm.form = {};
    vm.firm = firm();
    vm.models = models();
    vm.productRequests = productRequests;
    vm.project = project;
    vm.user = user;
    vm.requestNameSuggestions = [];
    vm.SELECTED_CLASS = 'selected';
    vm.draftSaved = false;
    vm.isSaving = false;

    // Functions
    vm.onCancelClick = onCancelClick;
    vm.onSaveClick = onSaveClick;
    vm.getRequestNameSuggestions = getRequestNameSuggestions;
    vm.clickSelect = clickSelect;

    ////////////////////////////////////////////////////////////////////////////////
    // Functions
    ////////////////////////////////////////////////////////////////////////////////

    function models () {
      return {
        name: '',
      };
    }

    // Display autoComplete on input focus
    angular.element('.requestNameInput').on('focus', function () {
      var SUGGESTION_LIST = angular.element('.suggestion-list');
      SUGGESTION_LIST.fadeIn(50);
    });

    // Add event listeners to detect 'up' and 'down' arrow keys
    angular.element(document).bind('keydown', function (e) {
      switch (e.keyCode) {
        // 'Up' arrow key
        case 38:
          moveUp();
          break;
        // 'Down' arrow key
        case 40:
          moveDown();
          break;
        // 'Enter' key (when selecting option)
        case 13:
          enterSelect();
          break;
      }
    });

    // Unregister event listeners from controller
    $scope.$on('$destroy', unregisterListeners);

    // Update Model & View when users key through typeahead options
    function updateSearchNameModel () {
      const ACTIVE_ITEM_TEXT = angular
        .element('.selected')
        .find('.suggestion-label')
        .text()
        .trim();
      $scope.$apply(() => {
        vm.models.name = ACTIVE_ITEM_TEXT;
      });
    }

    // Enables users to select dropdown options using the 'Down' arrow key
    function moveUp () {
      var SELECTED_ITEM = angular.element('.selected');
      var SUGGESTION_ITEM = angular.element('.suggestion-item li');
      var PREVIOUS_SUGGESTION = SELECTED_ITEM.parent('a').prev('a').find('li');
      var LAST_SUGGESTION_ITEM = angular.element('.suggestion-item:last-child li');

      // If nothing selected, select first element
      if(SELECTED_ITEM.length < 1) {
        SUGGESTION_ITEM.eq(0).addClass(vm.SELECTED_CLASS).focus();
        // Scroll to selected element
        scrollIntoView();
      }

       // If there is no link above selected link, select last link
      if(PREVIOUS_SUGGESTION.length < 1) {
        SELECTED_ITEM.removeClass(vm.SELECTED_CLASS);
        LAST_SUGGESTION_ITEM.addClass(vm.SELECTED_CLASS).focus();
        // Scroll to selected element
        scrollIntoView();
      // If there is a link above the currently selected link, select that link
      } else {
        SELECTED_ITEM.removeClass(vm.SELECTED_CLASS);
        PREVIOUS_SUGGESTION.eq(0).addClass(vm.SELECTED_CLASS).focus();
        // Scroll to selected element
        scrollIntoView();
      }

      updateSearchNameModel();
    }

    // Enables users to select dropdown options using the 'Down' arrow key
    function moveDown () {
      var SELECTED_ITEM = angular.element('.selected');
      var SUGGESTION_ITEM = angular.element('.suggestion-item li');
      var NEXT_SUGGESTION = SELECTED_ITEM.parent('a').next('a').find('li');

      // If nothing selected, select first element
      if(SELECTED_ITEM.length < 1) {
        SUGGESTION_ITEM.eq(0).addClass(vm.SELECTED_CLASS).focus();
        // Scroll to selected element
        scrollIntoView();
      }

       // If there is no link below selected link, select first link
      if(NEXT_SUGGESTION.length < 1) {
        SELECTED_ITEM.removeClass(vm.SELECTED_CLASS);
        SUGGESTION_ITEM.eq(0).addClass(vm.SELECTED_CLASS).focus();
        // Scroll selected element into view
        scrollIntoView();
      // If there is a link below the currently selected link, select that link
      } else {
        SELECTED_ITEM.removeClass(vm.SELECTED_CLASS);
        NEXT_SUGGESTION.eq(0).addClass(vm.SELECTED_CLASS).focus();
        // Scroll selected element into view
        scrollIntoView();
      }

      updateSearchNameModel();
    }

    // Function to scroll selected element into view
    function scrollIntoView () {
      if(angular.element('.selected').length) {
        angular.element('.selected').get(0).scrollIntoView();
      }
    }

    // Unregisters event listeners from controller
    function unregisterListeners () {
      angular.element('.requestNameInput').off('focus');
      angular.element(document).off('keydown');
    }

    // Enables users to select dropdown options using the enter key
    function enterSelect () {
      var SELECTED_ITEM = $('.selected');
      var SUGGESTION_LIST = angular.element('.suggestion-list');

      if(vm.models.name) {
        // If user selects a dropdown option
        if(SELECTED_ITEM.length) {
          var SELECTED_ITEM_TEXT = SELECTED_ITEM.find('.suggestion-label').text().trim();
          // Get the position of the search result element in the suggestion list, starting at 1
          var position = Array.prototype.indexOf.call(SUGGESTION_LIST.get(0).children, SELECTED_ITEM.get(0).parentElement) + 1;
          // Track selected suggestion to Measured Search
          trackSearchSelection(vm.models.name, SELECTED_ITEM_TEXT, position, vm.requestNameSuggestions.length);

          // Set name to selected suggestion
          vm.models.name = SELECTED_ITEM_TEXT;

          // Hide dropdown after user selects an option
          SUGGESTION_LIST.fadeOut();
        } else {
          // If user creates own request name instead, hide dropdown
          trackSearchSelection(vm.models.name, vm.models.name, position, vm.requestNameSuggestions.length)
          SUGGESTION_LIST.fadeOut();
        }
      }
    }

    // Enables users to select dropdown options via mouseclick
    function clickSelect($event) {
      $event.preventDefault();
      var SELECTED_ITEM = $event.delegateTarget;
      var SELECTED_ITEM_TEXT = SELECTED_ITEM.querySelector('.suggestion-label').innerText.trim();
      var SUGGESTION_LIST = angular.element('.suggestion-list');

      // Get the position of the search result element in the suggestion list, starting at 1
      var position = Array.prototype.indexOf.call(SUGGESTION_LIST.get(0).children, SELECTED_ITEM.parentElement) + 1;
      // Track selected suggestion to Measured Search
      trackSearchSelection(vm.models.name, SELECTED_ITEM_TEXT, position, vm.requestNameSuggestions.length);

      // Set name to selected suggestion
      vm.models.name = SELECTED_ITEM_TEXT;

      // Hide dropdown after user selects an option
      SUGGESTION_LIST.fadeOut();
    }

    // Function to get entities suggestions from Ludwig API
    function getRequestNameSuggestions (query) {
      if (!query) {
        vm.requestNameSuggestions = [];
        return;
      }

      LudwigService
        .getProductResponseFacets(query, 'product_type_ent_ss')
        .then((data) => {
          // Add result to requestNameSuggestions
          vm.requestNameSuggestions = data.map((productResponse) => {
            // Adding `24` to the products count for vanity cc Thomas
            productResponse.count = productResponse.count + 24;
            productResponse.count;
            return productResponse;
          });

          // Track query to Measured Search
          trackSearchQuery(query, vm.requestNameSuggestions.length);

          // Reveal typeahead dropdown, scroll to top of list
          angular
            .element('.suggestion-list')
            .fadeIn()
            .scrollTo(0, 0);
        })
        .catch(() => {
          vm.requestNameSuggestions = [];
        });
    }

    /**
     * trackSearchQuery() sends a tracking event to Measured Search for an entered query
     * @param {String} query - the text the user entered/searched for
     * @param {Int} totalHits - the number of search results fetched/displayed
     * @return {Function} SearchstaxService.track - calls SearchstaxService.track to send event
     */
    function trackSearchQuery (query, totalHits) {
      const ssAppName = 'requestNamesTypeahead';
      const shownHits = Math.min(4, totalHits); // The dropdown height means that 4 results is the maximum that can ever be visible to the user
      const trackData = {
        key: SearchstaxService.getApiKey(ssAppName),
        session: SearchstaxService.getSession(),
        query: query,
        shownHits: shownHits,
        totalHits: totalHits,
      };
      return SearchstaxService.track(trackData);
    }

    /**
     * trackSearchSelection() sends a tracking event to Measured Search for a selected result
     * @param {String} query - the text the user entered/searched for
     * @param {String} selected - the suggestion that was selected by the user
     * @param {Int} position - the position in the suggestions list of `selected`
     * @param {Int} totalHits - the number of search results fetched/displayed
     * @return {Function} SearchstaxService.trackClick - calls SearchstaxService.trackClick to send event
     */
    function trackSearchSelection (query, selected, position, totalHits) {
      const ssAppName = 'requestNamesTypeahead';
      const shownHits = Math.min(4, totalHits); // The dropdown height means that 4 results is the maximum that can ever be visible to the user
      const trackClickData = {
        key: SearchstaxService.getApiKey(ssAppName),
        session: SearchstaxService.getSession(),
        query: query,
        cDocTitle: selected,
        position: position,
        shownHits: shownHits,
        totalHits: totalHits,
      };
      return SearchstaxService.trackClick(trackClickData);
    }

    /**
     * Get the firm for this product search
     *
     * How:
     * Loop through the user's firms and the project's firms and find the one that matches
     *
     * Why:
     * - The project can have multiple firms
     * - The user can have access to multiple firms
     */
    function firm () {
      var foundFirm;

      angular.forEach(user.firms, (userFirm) => {
        angular.forEach(project.firms, (projectFirm) => {
          if (userFirm.id === projectFirm.id) {
            foundFirm = userFirm;
          }
        });
      });
      return foundFirm;
    }

    /**
     * On Cancel button click, dismiss the modal
     */
    function onCancelClick () {
      $modalInstance.dismiss();
    }

    /**
     * Save Draft Search helper function (used by "Save Draft" and "Continue" buttons)
     */
    function saveDraftSearch () {

      return new Promise((resolve, reject) => {

        let newSearch = new ProductRequest();

        newSearch.firm_id = vm.firm.id;
        newSearch.project_id = vm.project.id;
        newSearch.name = vm.models.name;
        newSearch.structured_data = [];

        // Save with the API and resolve/reject the promise
        newSearch.$createDraft((createdSearch) => resolve(createdSearch), (error) => reject(error));
      });
    }

    /**
     * On 'Save Draft' or 'Continue' button click
     *
     * Pass a truthy value as saveAndContinue to trigger redirect to Edit Search form after Save
     * (redirect logic lives in the Searches controller)
     */
    function onSaveClick (saveAndContinue = null) {

      vm.form.$setSubmitted(true);
      // Show loading message
      vm.isSaving = true;

      $timeout(() => {
        if (vm.form.$valid) {

          saveDraftSearch()
          .then(saveSuccess)
          .catch(saveError);
        }

        function saveSuccess (createdSearch) {
          let modalData = {
            newSearch: createdSearch,
            goToNewSearch: saveAndContinue, // Set to 'true' if we clicked the "Continue" button
          };

          // If continuing to create detailed search
          if (saveAndContinue) {
             $modalInstance.close(modalData);

          // If saving as a draft
          } else {
            // Once modal data is set, we reset the values and
            // and show the confirmation message to add another search
            // while leaving the modal opened.
            $modalInstance.opened.then(
              () => {
                // Send draft to productRequests resource manager
                productRequests.push(createdSearch);

                // Reset the form
                vm.form.$setPristine();
                vm.models = models();

                // Show confirmation message (scaffold)
                vm.draftSaved = true;
                vm.isSaving = false;

                // Make green success callout dissappear after 1100 ms
                $timeout(() => {
                  vm.draftSaved = false;
                }, 1100);
              })
              .catch((error) => console.error(error));
          }
        }

        function saveError (error) {
          console.error(error);
        }
      });
    }
  }
}());
