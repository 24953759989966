(function () {
  'use strict';

  angular
  .module('architizer.app')
  .filter('units', units)
  .filter('unit', unit);


  /**
   * Return the plural unit for the unit code
   */
  units.$inject = [
    'UnitsService'
  ];

  function units (UnitService) {
    return function (input) {
      var unit = UnitService.getUnitForCode(input);
      return (unit ? unit.plural : 'units');
    };
  }

  /**
   * Return the singular unit for the unit code
   */
  unit.$inject = [
    'UnitsService'
  ];

  function unit (UnitService) {
    return function (input) {
      var unit = UnitService.getUnitForCode(input);
      return (unit ? unit.singular : 'unit');
    };
  }
})();