(function () {
  'use strict';

  angular
    .module('architizer.app')
    .factory('ProductResponseDataTransformer', ProductResponseDataTransformer);

    function ProductResponseDataTransformer () {

      function transform ({
        project_id_l,
        created_dt,
        response_id_i,
        creator_id_l,
        creator_first_name_s,
        creator_last_name_s,
        creator_email_s,
        brand_id_l,
        brand_name_s,
        brand_slug_s = '',
        brand_website_s = '',
        description_s = '',
        brand_verified_b,
        brand_logo_image_url_s = '',
        request_id_i,
        media_items_t = [],
        request_name_s,
        lead_time_low_i,
        lead_time_high_i,
        name_s,
        productUrl_ent_ss = [],
        price_s,
        recommender_id_i,
        marking_s,
        recommender_type_s,
        tier1_tags_ss = [],
        product_type_ent_ss = [],
      }, override = {}) {
        const data = {
          id: response_id_i,
          recommender_type: recommender_type_s,
          created: created_dt,
          modified: '',
          creator_id: creator_id_l,
          creator: {
            id: creator_id_l,
            created: '',
            modified: '',
            featured: '',
            slug: '',
            first_name: creator_first_name_s,
            last_name: creator_last_name_s,
            images: {
              hero: {},
              avatar: [],
              gallery: [],
            },
            geolocations: [
              {
                id: '',
                name: '',
                street_number: '',
                route: '',
                country: '',
                country_code: '',
                administrative_area_level_1: '',
                administrative_area_level_2: '',
                administrative_area_level_3: '',
                locality: '',
                postal_code: '',
                latitude: '',
                longitude: ''
              }
            ],
            phone_numbers: [
              {
                id: '',
                type: '',
                number: ''
              }
            ],
            email: creator_email_s
          },
          brand_id: brand_id_l,
          brand: {
            id: brand_id_l,
            created: '',
            modified: '',
            name: brand_name_s,
            description: '',
            images: {
              hero: {},
              avatar: {},
              gallery: []
            },
            self_status: '',
            slug: brand_slug_s,
            website: brand_website_s,
            facebook: '',
            twitter: '',
            logo: brand_logo_image_url_s,
            verified: brand_verified_b,
            premium_source: '',
            product_ids: [],
            project_ids: [],
            reseller_status: '',
            email_addresses: [],
            phone_numbers: [
            ]
          },
          product_request_id: request_id_i,
          product_request_name: request_name_s,
          tags: product_type_ent_ss,
          workflow_status: '',
          name: name_s,
          description: description_s,
          lead_time_low: lead_time_low_i,
          lead_time_high: lead_time_high_i,
          price_low: null,
          price_high: null,
          price_currency: 'USD',
          price_units: '',
          structured_data: [
            {
              name: 'productUrl',
              fields: [{
                name: 'value',
                value: productUrl_ent_ss[0] || ''
              }]
            }
          ],
          marking: marking_s,
          supplier_contacted: false,
          /* Additional datapoints */
          price: price_s,
          category: tier1_tags_ss[0] || '',
          project_id: project_id_l,
          recommender_id: recommender_id_i,
        };

        if (media_items_t.length) {
          data.images = JSON.parse(media_items_t[0]);
        } else {
          data.images = {
            gallery: [],
            hero: {}
          };
        }

        return Object.assign({}, data, override);
      }

      return transform;
    }
})();
