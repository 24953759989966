(function () {
  'use strict';

  angular
  .module('architizer.app')
  .controller('RequestSamplesController', RequestSamplesController);

  RequestSamplesController.$inject = [
    '$modal',
    '$modalInstance',
    '$rootScope',
    '$timeout',
    'conversation',
    'Conversation',
    'currentlySelectedProductResponseId',
    'CustomEventsService',
    'productRequest',
    'productResponses',
    'user',
  ];

  function RequestSamplesController (
    $modal,
    $modalInstance,
    $rootScope,
    $timeout,
    conversation,
    Conversation,
    currentlySelectedProductResponseId,
    CustomEventsService,
    productRequest,
    productResponses,
    user
  ) {

    var vm = this;

    const {
      CONVERSATION_MESSAGE_SEND_COMPLETE
    } = CustomEventsService.events;

    // Data
    vm.conversation = conversation;
    vm.productRequest = productRequest;
    vm.bpmName = `${ conversation.sides[1].users[0].first_name } ${ conversation.sides[1].users[0].last_name }`;
    vm.bpmAvatar = conversation.sides[1].users[0].images.avatar;
    vm.elementConfig = elementConfig();
    vm.form = {};
    vm.models = models();
    vm.productResponses = productResponses;
    vm.saving = false;
    vm.user = user;

    // Functions
    vm.onSubmitClick = onSubmitClick;
    vm.onCancelClick = onCancelClick;

    ////////////////////////////////////////////////////////////////////////////////
    // Functions
    ////////////////////////////////////////////////////////////////////////////////

    function models () {
      return {
        product: currentlySelectedProductResponseId || null,
        address: '',
        message: '',
      };
    }

    /**
     * Emit tracking event for `ObjectActionTracker`
     */
    function trackAction (trackingEventName, props) {
      $rootScope.$emit(trackingEventName, props);
    }

    /**
     * Prepare & return baseline tracking properties for this controller
     */
    function trackingProperties () {
      let { id: product_request_id, project_id } = vm.productRequest;
      let { id: conversation_id } = vm.conversation;
      
      return {
        conversation_id,
        product_request_id,
        project_id
      };
    }

    function elementConfig () {
      return {
        brand: getBrand(),
        products: prepareProducts(),
      };
       
      // Get brand from product responses
      function getBrand () {
        return productResponses[0].brand;
      }

      // Prepare product responses to be displayed in <select> element
      function prepareProducts () {
        var products = [];

        angular.forEach(productResponses, (product) => {
          products.push({
            label: product.name,
            value: product.id,
          });
        });

        return products;
      }
    }

    function onCancelClick () {
      $modalInstance.dismiss();
    }

    function onSubmitClick () {
      vm.form.$setSubmitted(true);

      // Check after a timeout so the form's validity will be properly updated
      $timeout(() => {
        if (vm.form.$valid) {
          vm.saving = true;

          // Get the product response that we're requesting samples for
          let productResponse = vm.productResponses.find((pr) => pr.id === vm.models.product);

          Conversation.requestSamples({
            id: conversation.id,
            content: vm.models.message,
            structured_data: {
              objects: [ { id: vm.models.product } ],
              properties: { address: vm.models.address },
            },
          })
          .$promise
          .then(success)
          .then(({ id: sample_request_id }) => {
            let trackingProps = Object.assign(
              {},
              trackingProperties(),
              { sample_request_id },
              {
                brand_id: vm.elementConfig.brand.id,
                brand_name: vm.elementConfig.brand.name,
                product_request_id: vm.productRequest.id,
                product_request_name: vm.productRequest.name,
                product_response_creator_email: productResponse.creator.email,
                product_response_creator_first_name: productResponse.creator.first_name,
                product_response_creator_id: productResponse.creator.id,
                product_response_creator_last_name: productResponse.creator.last_name,
                product_response_id: productResponse.id,
                product_response_name: productResponse.name,
                product_response_recommender_id: productResponse.recommender_id,
                user_email: vm.user.email,
                user_first_name: vm.user.first_name,
                user_id: vm.user.id,
                user_last_name: vm.user.last_name,
              }
            );
            trackAction('sample_request_sent', trackingProps);
          })
          .catch(error);
        } else {
          return;
        }
      });

      /**
       * FIXME: Don't use modals to show success or error when requesting samples
       *
       * Perhaps include this form inline on the Product Response content modal
       * and submit it directly from there
       */

      /**
       * Request Samples Success
       */
      function success (data) {
        vm.saving = false;
        $rootScope.$broadcast(CONVERSATION_MESSAGE_SEND_COMPLETE, { message: data });

        var successModal = $modal.open({
          template: successModalTemplate(),
          controller: SuccessModalController,
          controllerAs: 'mc',
          resolve: {
            brand: () => vm.elementConfig.brand
          }
        });

        successModal
        .result
        .then(() => $modalInstance.close());

        // Success Modal Template
        function successModalTemplate () {
          return `
          <p>You have successfully requested samples from {{ mc.brand.name }}.</p>
          <!-- Footer (Buttons) -->
          <div class="footer">
            <div class="row align-center">
              <!-- Close  -->
              <div class="small-6 medium-4 columns">
                <button class="small expanded button"
                        ng-click="mc.onCloseClick()"
                        type="button">Close</button>
              </div>
            </div>
          </div>
          `;
        }

        // Success Modal Controller
        SuccessModalController.$inject = ['$modalInstance', '$state', 'brand'];
        function SuccessModalController ($modalInstance, $state, brand) {
          this.brand = brand;
          this.onCloseClick = () => $modalInstance.close();
          this.onGoToMessagesClick = () => $state.go('source.app.arc.project.conversations.conversation', { project: vm.project.id, conversation: conversation.id }, { inherit: false, reload: true });
        }
      
        return data;
      }

      /**
       * Request Samples Error
       */
      function error (data) {
        vm.saving = false;
        var errorModal = $modal.open({
          template: errorModalTemplate(),
          controller: ErrorModalController,
          controllerAs: 'mc',
          resolve: {
            error: () => data,
          }
        });

        errorModal
        .result
        .then(() => $modalInstance.close());

        // Error Modal Template
        function errorModalTemplate () {
          return `
          <p>There was an issue requesting samples from this brand.</p>
          <p>{{ mc.error }}</p>
          <!-- Footer (Buttons) -->
          <div class="footer">
            <div class="row align-center">
              <!-- Close  -->
              <div class="small-6 medium-4 columns">
                <button class="small expanded button"
                        ng-click="mc.onCloseClick()"
                        type="button">Close</button>
              </div>
            </div>
          </div>
          `;
        }

        // Error Modal Controller
        ErrorModalController.$inject = ['$modalInstance', 'error'];
        function ErrorModalController ($modalInstance, error) {
          this.error = error;
          this.onCloseClick = () => $modalInstance.close();
        }
      }
    }
  }
})();
