'use strict';

angular
	.module('ui')
	.directive('uiSelect', ['$parse', '$compile', '$document', '$window', '$timeout', function ($parse, $compile, $document, $window, $timeout) {

		var _dropdownTemplate = '<div class="uiSelectDropdown" ng-class="{ hasValue: (multi ? current.length : current.value), hover: isHover, focus: isFocussed, invalid: invalid, multi: multi, hasOptions: !!options.length }">' +
				'<div ng-if="!grouped" ui-scroll-dot ui-scroll-mask>' +
					'<div class="options scroll">' +
						'<div id="ui-select-option-{{ option.value }}" class="option" ng-click="onOptionClicked(option)" ng-repeat="option in options">' +
							'<span ng-if="!multi" class="body block">{{ option.label }}</span>' +
							'<ui-checkbox ng-model="multiModels[option.value]" ng-if="multi">{{ option.label }}</span>' +
						'</div>' +
					'</div>' +
				'</div>' +

				'<div ng-if="grouped" ui-scroll-dot ui-scroll-mask>' +
					'<div class="options scroll">' +
						'<div ng-repeat="option in options" ng-include="\'/views/uikit/ui-select-group.html\'"></div>' +
					'</div>' +
				'</div>' +

				'<div ng-if="action" ng-hide="loading" class="action" ng-click="onActionClick()">' +
					'<div class="text">' +
						'<span class="primary body block">{{ action }}</span>' +
					'</div>' +
				'</div>' +
			'</div>';

		return {
			replace: true,
			restrict: 'E',
			templateUrl: '/views/uikit/ui-select.html',
			require: [
				'?ngModel',
				'?ngDisabled'
			],
			scope: {
				ngModel: '=',
				ngDisabled: '=',
				form: '=',
				name: '@',
				options: '=',
				label: '@',
				inlineLabel: '@',
				placeholder: '@',
				grouped: '@',
				multi: '@',
				flasher: '=',
				action: '=',
				customMessagesTemplate: '@'
			},
			link: function ($scope, element, attrs, controllers) {

				/*
				 * View params
				 */

				$scope.isHover = false;
				$scope.isFocussed = false;
				$scope.isInlineLabelGlyph = attrs.hasOwnProperty('inlineLabelGlyph');
				$scope.dropdownOpen = false;
				$scope.current = null;

				// A hash of models used by the checkboxes in a multi-select
				$scope.multiModels = {};

				// The dropdown element
				var _dropdown;

				// Flag to indicate destroy is in process
				var _destroy = false;


				/*
				 * UI interaction
				 */

				// Used to cancel the blur event
				var _cancelClose = false;

				$scope.onMouseEnter = function () {

					$scope.isHover = true;
				};

				$scope.onMouseOut = function () {

					$scope.isHover = false;
				};

				$scope.onFocus = function () {

					$scope.isFocussed = true;
				};

				$scope.onBlur = function () {

					// Allow a short delay before closing.
					// This is important in the case that a dropdown
					// option is clicked - click must occur before the
					// dropdown closes as a result of the blur()
					// Also, allow the close to be cancelled outright,
					// so that multiple options can be selected in multis
					$timeout(function () {

						if (_cancelClose) {

							// Refocus on the control
							element.children()[1].focus();

							// Reset the flag
							_cancelClose = false;
						}

						else {

							// Blur and close
							$scope.isFocussed = false;
							closeDropdown();
						}
					}, 200);
				};

				$scope.onElementClicked = function () {

					toggleDropdown();
				};

				$scope.onOptionClicked = function (option) {

					selectOption(option);

					// Don't close the dropdown if multi
					if ($scope.multi) {

						_cancelClose = true;
					}

					// Or close immediately if a single
					else {

						closeDropdown();
					}
				};

				$scope.onActionClick = function ($event) {

					var attrHandler = $parse(attrs.actionClick);

					attrHandler($scope.$parent, { $event: $event });

					closeDropdown();

					// Set control touched
					setTouched();
				};

				$scope.onOptionRemove = function (option, e) {

					// Stop the underlying control from receiving the click
					e.preventDefault();
					e.stopPropagation();

					// Stop the dropdown from closing if option
					_cancelClose = true;

					// Find the option in the current options list and deselect
					$scope.multiModels[option.value] = false;

					// Trigger an update
					selectOption(null);
				};


				/*
				 * UI methods
				 */

				var openDropdown = function () {

					$scope.dropdownOpen = true;

					// Compile the dropdown template and link to scope
					// The dropdown will share the same scope as the
					// main select element
					if (!_dropdown) {

						var linkFn = $compile(_dropdownTemplate);
						_dropdown = angular.element(linkFn($scope));

						// We use the error state if the invalid class is on
						// on the element. However since the dropdown is out
						// of document flow, we cannot target using CSS. Set
						// a flag on the scope instead.
						$scope.invalid = element.hasClass('invalid');

						// Append to the document
						angular.element($document[0].body).append(_dropdown);
					}
				};

				var closeDropdown = function () {

					$scope.dropdownOpen = false;

					// Remove the dropdown
					if (_dropdown) {

						_dropdown.remove();
						_dropdown = null;
					}

					setTouched();
				};

				var toggleDropdown = function () {

					if ($scope.dropdownOpen) {

						closeDropdown();
					}

					else {

						openDropdown();
					}
				};

				// Position the placeholder correctly according to the
				// width of the inline label
				var repositionPlaceholder = function () {

					var floatingLabel = element.children().eq(0).children().eq(0);
					var inlineLabel = element.children().eq(1).children().eq(0);

					// could get calculated css, but hard code for the sake of performance.
					var inlineLabelMargin;
					if ($scope.inlineLabel) {

						if ($scope.isInlineLabelGlyph) {

							inlineLabelMargin = 10;
						}

						else {

							inlineLabelMargin = 4;
						}
					}

					floatingLabel.css('left', (inlineLabel.width() + inlineLabelMargin) + 'px');
				};

				// Reposition the dropdown
				var repositionDropdown = function () {

					if (_dropdown) {

						// Get the location of the select element
						var rect = element.children()[1].getBoundingClientRect();

						var scrollX = $window.scrollX || $window.pageXOffset;
						var scrollY = $window.scrollY || $window.pageYOffset;

						// Position the dropdown
						_dropdown
							.css('left', rect.left + scrollX + 'px')
							.css('top', rect.bottom + scrollY + 'px')
							.css('width', rect.width + 'px');

						// Reset max-height on .options to prevent dropdown elements from going below bottom of viewport
						// if distance between bottom of select and bottom of window is less than 200px
						if ($window.innerHeight - rect.bottom - 10 <= 200 && _dropdown.children()[0]) {

							$(_dropdown.children()[0].children[0]).css('max-height', ($window.innerHeight - rect.bottom - 10) + 'px');
						}

						// otherwise, use the normal max-height value
						else if (_dropdown.children()[0]) {

							/* WARNING: 200px is hardcoded here; this needs to match the value of
							* $formsSelectDropdownMaxHeight in /styles/partials/forms/_select.scss
							*/
							$(_dropdown.children()[0].children[0]).css('max-height', '200px');
						}
					}
				};

				var reposition = function () {

					repositionPlaceholder();

					if ($scope.dropdownOpen) {

						repositionDropdown();
					}
				};

				// Begin the position loop
				(function positionLoop () {

					// Only position until destroyed
					if (!_destroy) {

						requestAnimFrame(positionLoop);
						reposition();
					}
				})();


				/*
				 * Data methods
				 */

				// end here if no ng-model
				if (!controllers[0]) {

					return;
				}

				var ngModel = controllers[0];

				// Sets touched state on model
				var setTouched = function () {

					ngModel.$setTouched();
				};

				var selectOption = function (option) {

					// If single-select, set the model to the value of the option
					if (!$scope.multi) {

						ngModel.$setViewValue(option.value);
						ngModel.$render();
					}

					// If multi-select, form up a list of the selected values
					else {

						var checked = _
							.chain($scope.multiModels)
							.pick(function (selected) { return selected; })
							.keys()
							.value();

						ngModel.$setViewValue(checked);

						// Trigger validation. Since the model is an
						// array, we need to do this manually as the
						// $watch won't pick the change up.
						ngModel.$validate();

						ngModel.$render();
					}
				};

				// Find the option hash that has the given value
				var getOptionByValue = function (value) {

					// Don't error if no value is passed
					if (!value) { return; }

					// Search one level deep if not grouped
					if (!$scope.grouped) {

						return _.findWhere($scope.options, { value:value });
					}

					// Search at the second level deep if grouped
					else {

						// Join all child options into one big options array
						var allOptions = []
						_.forEach($scope.options, function (option) {

							allOptions = allOptions.concat(option.options);
						});

						// Search in that array
						return _.findWhere(allOptions, { value:value });
					}
				};

				// Find an array of option hashes corresponding to the
				// provided array of values
				var getOptionsByValues = function (values) {

					var options = [];
					_.forEach(values, function (value) {

						var option = getOptionByValue(value);

						if (option) {

							options.push(option);
						}
					});

					return options;
				};

				ngModel.$render = function () {

					// Pass the current option(s) to the view
					if ($scope.multi) {

						$scope.current = getOptionsByValues(ngModel.$viewValue);
					}

					else {

						$scope.current = getOptionByValue(ngModel.$viewValue);
					}
				};

				// When the value is updated from the controller, set the
				// multi models
				ngModel.$formatters.push(function(values) {

					if ($scope.multi) {

						$scope.multiModels = {};
						angular.forEach(values, function (value) {

							$scope.multiModels[value] = true;
						});
					}

					return values;
				});

				// Re-render when the options change. This is important in the
				// case of options being loaded asynchronously - if the value
				// is set before the options are loaded, the selected options
				// will not show up.
				$scope.$watchCollection('options', function () {

					ngModel.$render();
				});

				// Clean up
				$scope.$on('$destroy', function () {

					_destroy = true;
				});


				/*
				 * Change event handler
				 */

				// Parse a handler out of attribute
				var attrHandler = $parse(attrs['ng-change']);

				// Call the handler when the value changes
        $scope.$watch(function () {

        	return ngModel.$viewValue;
        }, function (value) {

          attrHandler($scope.$parent, { value: value });
        }, true);
			}
		};
	}]);
