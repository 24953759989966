'use strict';

angular
  .module('architizer.app')
  .factory('Brand', ['$resource', 'Config', function($resource, Config) {

    var Brand = $resource(Config.apiUrlBase + 'brands/:id', { id: '@id' }, {
      query: {
				isArray: true,
				
				// Pagination
        interceptor: {
          response: function (response) {

            response.resource.$pagination = response.data.$pagination;
            return response.resource;
          }
        }
			},
			update: {
				method: 'PATCH'
			}
    }, { cancellable: true });

    return Brand;
  }]);
