(function () {
  'use strict';

  angular
  .module('architizer.app')
  .controller('SourceArchitectProjectMembersModalController', SourceArchitectProjectMembersModalController);

  SourceArchitectProjectMembersModalController.$inject = [
    '$modalInstance',
    'AuthorizationService',
    'firmMembers',
    'projectMembers',
  ];

  function SourceArchitectProjectMembersModalController (
    $modalInstance,
    AuthorizationService,
    firmMembers,
    projectMembers
  ){

    var vm = this;

    // Data
    vm.projectMembers = angular.copy(projectMembers);
    vm.firmMembers = angular.copy(firmMembers);

    // Functions
    vm.onCancelClick = onCancelClick;
    vm.onSubmitClick = onSubmitClick;
    vm.isProjectMember = isProjectMember;
    vm.add = add;
    vm.remove = remove;
    vm.printRoles = printRoles;

    ////////////////////////////////////////////////////////////////////////////////
    // Functions
    ////////////////////////////////////////////////////////////////////////////////

    function onCancelClick () {
      $modalInstance.dismiss();
    }

    function onSubmitClick () {
      // Close the modal and return the new array of project members as the resolve
      $modalInstance.close(vm.projectMembers);
    }

    function add (firmMember) {
      vm.projectMembers.push(firmMember);
    }

    function remove (firmMember) {
      let index = vm.projectMembers.findIndex((user) => user.id === firmMember.id);
      vm.projectMembers.splice(index, 1);
    }

    function isProjectMember (firmMember) {
      return vm.projectMembers.map((user) => user.id).includes(firmMember.id);
    }

    function printRoles (user) {
      let roles = AuthorizationService.getRolesForPermissions('firm', user.permissions);
      return roles.map((role) => AuthorizationService.FIRM_ROLES[role]['name']).join(', ');
    }
  }
})();