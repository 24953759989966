'use strict';

angular
  .module('architizer.app')
  .factory('Message', ['$resource', 'Config', function ($resource, Config) {

    var Message = $resource(
      Config.apiUrlBase + 'conversations/:conversationId/messages/:id',
      {
        conversationId: '@conversation_id',
        id: '@id',
      },
      {

        /**
         * For development of activity cards, uncomment below and set message.message_type to desired type
         */
        // query: {
        //   method: 'GET',
        //   responseType: 'json',
        //   isArray: true,
        //   transformResponse: function (response) {
        //     var count = 0;
        //     angular.forEach(response.data, function(message) {
        //       if (count % 5 === 0 && count % 3 === 0) {
        //         message.message_type = 'action';
        //       }
        //       else if (count % 5 === 0) {
        //         message.message_type = 'medium';
        //       }
        //       else if (count % 3 === 0) {
        //         message.message_type = 'small';
        //       }
        //       else {
        //         message.message_type = '';
        //       }
        //       count++;
        //     });
        //     return response;
        //   }
        // }
      }
    );

    return Message;
  }]);