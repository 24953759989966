(function() {
  'use strict';

  angular
  .module('architizer.app')
  .controller('SourceProductSearchModalController', SourceProductSearchModalController);

  SourceProductSearchModalController.$inject = [
    '$modalInstance',
    'FileClick',
    'productRequest',
  ];

  function SourceProductSearchModalController (
    $modalInstance,
    FileClick,
    productRequest
    ) {

    var vm = this;

    // Data
    vm.productRequest = productRequest;
    vm.heroImage = setHeroImage();

    // Functions
    vm.onCloseClick = onCloseClick;
    vm.onFileClick = FileClick.openFileModal;
    vm.setHeroImage = setHeroImage;
    vm.onFileDownloadClick = FileClick.onFileDownloadClick;
    ////////////////////////////////////////////////////////////////////////////////
    // Functions
    ////////////////////////////////////////////////////////////////////////////////

    /**
     * Set Hero Image
    */
    function setHeroImage (image) {
      if (image) {
        vm.heroImage = image;
        return image;
      } else if (vm.productRequest.images.hero) {
        return vm.productRequest.images.hero;
      } else {
        return null;
      }
    }

    /**
     * On Cancel button click, dismiss the modal
     */
    function onCloseClick () {
      $modalInstance.dismiss();
    }
  }
})();