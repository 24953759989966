'use strict';

['dragstart', 'dragenter', 'dragover', 'dragleave', 'drop', 'dragend'].forEach(function (eventName) {

  angular
    .module('ui')
    .directive(eventName, ['$parse', function ($parse) {

      return {
        restrict: 'A',
        link: function (scope, element, attrs) {

          var attrHandler = $parse(attrs[eventName]);

          var handler = function (e) {

            scope.$apply(function () {

              attrHandler(scope, { $event: e });
            });
          };

          element[0].addEventListener(eventName, handler, false);
        }
      };
  }]);
});