'use strict';

angular
	.module('ui')
	.directive('equals', ['$parse', function ($parse) {

		return {
			replace: false,
			restrict: 'A',
			require: '?ngModel',
			link: function ($scope, element, attrs, ngModel) {

				// end here if no ng-model
				if (!ngModel) {

					return;
				}

				// The referenced comparison value
				var _comparisonValue;

				// When the referenced comparison value changes,
				// save the value and validate
				$scope.$watch(function () {

					return $parse(attrs.equals)($scope);
				}, function (value) {

					_comparisonValue = value;
					ngModel.$validate();
				});

				ngModel.$validators.equals = function (value) {

					return value == _comparisonValue;
				};
			}
		};
	}]);