(function() {
  'use strict';

  angular
  .module('architizer.app')
  .controller('SourceBpmConversationsController', SourceBpmConversationsController);

  SourceBpmConversationsController.$inject = [
    '$scope',
    '$state',
    '$timeout',
    'AuthenticationService',
    'conversations',
    'selectedConversation',
    'user',
  ];

  function SourceBpmConversationsController (
    $scope,
    $state,
    $timeout,
    AuthenticationService,
    conversations,
    selectedConversation,
    user
  ) {

    var vm = this;

    // Data
    vm.conversations = conversations;
    vm.user = user;
    vm.selectedConversation = selectedConversation;
    vm.activateMainPane = false;
    vm.$state = $state;

    // Functions
    vm.emptyStateButton = emptyStateButton();

    // Watches
    /**
     * Watch for changes to user (when user signs out)
     */
    $scope.$watch(
      function WatchUser () { return AuthenticationService.user; }, onUserChange);

    /**
     * Watch for changes to selection in sidebar
     *
     * Used for responsive display of .splitPaneMain
     *
     * When viewing a conversation on a small screen, the conversation should take up the whole view and hide the sidebar when it is shown.
     * See the style rules for .activateMainPane in split-pane.scss
     */
    $scope.$watch(
      function WatchSidebarSelection () { return $state.includes('**.conversation.**'); },
      function SetActivateMainPane (result) { vm.activateMainPane = result; }
    );

    // Initialize
    init();


    ////////////////////////////////////////////////////////////////////////////////
    // Functions
    ////////////////////////////////////////////////////////////////////////////////

    /**
     * Initialization
     */
    function init () {
       $timeout(() => {
        if (!vm.selectedConversation.id && vm.conversations.length) {
          // Find most recent conversation
          $state.go('source.app.bpm.conversations.conversation', { conversation: vm.conversations[0].id });
        }
      });
    }

    /**
     * On User Change (used by $watch above)
     */
    function onUserChange (user) {
      vm.user = user;

      // If user signs out, go to Source homepage
      if (vm.user === null) {
        $state.go('source');
      }
    }

    /**
     * Button for empty state
     */
    function emptyStateButton() {
      return {
        class: 'button primary fill mt-s',
        label: 'Find an opportunity',
        ngClick: () => $state.go('source.opportunities'),
      };
    }
  }
})();
