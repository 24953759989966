'use strict';

angular
  .module('architizer.app')
  .config(['$stateProvider', function ($stateProvider) {

    $stateProvider
    .state({
      name: 'about',
      url: '/about/',
      views: {
        'root@': {
          templateUrl: '/views/static/about.html'
        },
      },
      data: {
        showIntercom: false,
        fixedHeader: true,
        showFooter: true,
      },
    });
  }]);
