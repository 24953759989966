'use strict';

angular
  .module('architizer.app')
  .factory('Project', ['$resource', '$rootScope', 'Config', function($resource, $rootScope, Config) {
    var $scope = $rootScope.$new();

    var Project = $resource(Config.apiUrlBase + 'projects/:id', { id: '@id' }, {
      save: {
        method: 'POST',
      },
      update: {
        method: 'PATCH',
      },
      fromSearchEndpoint: {
        url: Config.apiUrlBase + 'search/projects/select/',
        params: {
          q: '*',
          rows: 100,
        },
        method: 'GET'
      },
      categories: {
        url: Config.apiUrlBase + 'projects/tags',
        isArray: true,
        cache: true
      },
      products: {
        /*FIXME `rows` is arbitrarily passed as `300` - pagination work required.*/
        url: Config.apiUrlBase + 'search/project_responses/select/?q=:id&rows=300'
      },
    });

    return Project;
  }]);