'use strict';

angular
  .module('architizer.app')
  .config(['$stateProvider', function ($stateProvider) {

    /**
     * Define the possible query parameters for the /a/ route
     */
    var QUERY_PARAMETER_OPTIONS = [
      'project',                // Project ID
      'request',                // Product Request ID
      'conversation',           // Conversation ID
      'message',                // Message ID
      'view',                   // View Mode
      'adk',                    // Design Kit Development Build Hash
      'response',               // Product Response ID
    ];

    /**
     * Define States
     */
    $stateProvider

    ////////////////////////////////////////////////////////////////////////////////
    // Public
    ////////////////////////////////////////////////////////////////////////////////

    // Public: Source Homepage (redirects to /go/source/architects/ or
    // source.app based on the resolved user)
    .state({
      name: 'source',
      url: '/source/',
      resolve: {
        user: ResolveUser,
        actionBar: () => {},
      },
      data: {
        showIntercom: true,
        isFullscreen: true,
        useAdk: true,
        hasActionBar: true,
      },
      redirectTo: (transition) => {
        let userPromise = transition.injector().getAsync('user'),
            $window = transition.injector().get('$window')

        return userPromise
          .then(() => {
            return { state: 'source.app', params: transition.params() }
          })
          .catch(() => {
            // FIXME: DRY this redirect to Webflow into a subview or a function
            $window.location.href = '/go/source/architects';
            return;
          });
      },
    })

    ////////////////////////////////////////////////////////////////////////////////
    // App Root
    ////////////////////////////////////////////////////////////////////////////////

    // App: Root
    .state({
      name: 'source.app',
      url: 'a/?' + QUERY_PARAMETER_OPTIONS.join('&'),
      params: {
        project: { dynamic: true },                // Project ID
        request: { dynamic: true },                // Product Request ID
        conversation: { dynamic: true },           // Conversation ID
        message: { dynamic: true },                // Message ID
        view: { dynamic: true },                   // View Mode
        response: { dynamic: true },               // Product Response ID
        adk: { dynamic: true },                    // Design Kit Development Build Hash
      },
      data: {
        showUiLoaderOutgoing: false,
        showIntercom: true,
        hideFooter: true,
      },
      redirectTo: (transition) => {
        let $window = transition.injector().get('$window'),
            AuthorizationService = transition.injector().get('AuthorizationService'),
            userPromise = transition.injector().getAsync('user')

        return userPromise
          .then((user) => {
            let userType = AuthorizationService.userType(user),
                params = transition.params()

            switch (userType) {
              case AuthorizationService.USER_TYPES.FIRM:
                return handleFirmViews(params, transition);
                break;
              case AuthorizationService.USER_TYPES.BRAND:
                return handleBrandViews(params);
                break;
              default:
                return handlePublicViews(params, $window);
            }
          })
          .catch(() => {
            $window.location.href = '/go/source/architects';
            return;
          });
      },
    })

    // Public: New Opportunities Feed (from Solr)
    .state({
      name: 'source.opportunities',
      url: 'requests/?categories&phases&price&project_location&firm_location&project_type&meta&q',
      params: {
        categories: { dynamic: true },        // Product Categories
        phases: { dynamic: true },            // Project Phases
        price: { dynamic: true },             // Price
        project_location: { dynamic: true },  // Project Location
        firm_location: { dynamic: true },     // Firm Location
        project_type: { dynamic: true },      // Project Type
        meta: { dynamic: true },              // Meta (Drafts, Favorited, Replied)
        q: { dynamic: true },                 // Search Query
      },
      views: {
        'root@': {
          templateUrl: '/views/source/public/opportunities.html',
          controller: 'SourcePublicOpportunitiesController',
          controllerAs: 'OpportunitiesCtrl',
        },
      },
      data: {
        hideFooter: true,
        showDelighted: true,
      },
      resolve: {
        actionBar: ['$state', '$window', 'user', ($state, $window, user) => {
          let brands = [];
          if (user) {
            brands = user.brands.map(({ name, slug }) => ({ name, slug }));
          }

          return {
            breadcrumbs: [],
            viewIcon: '',
            viewTitle: viewTitle(),
            dropdownLinks: dropdownLinks(),
            rightLinks: rightLinks(),
          };

          function viewTitle() {
            let title = 'Opportunities';
            if (brands.length === 1) {
              title = brands[0].name;
            }
            return title;
          }

          function dropdownLinks() {
            let links = [];
            const viewLink = { label: 'View Brand' };
            const editLink = { label: 'Edit Brand' };

            // Only show this menu to users with one brand
            if (brands.length === 1) {

              // Set link destinations
              viewLink.ngClick = () => window.location.href = `/brands/${brands[0].slug}`;
              editLink.ngClick = () => window.location.href = `/brands/${brands[0].slug}/edit/details/`;

              links.push(viewLink);
              links.push(editLink);
            }

            return links;
          }

          function rightLinks () {
            let links = [];
            if (user) {
              links.push({ label: 'Opportunities', state: 'source.opportunities' });
              links.push({ label: 'Messages', state: 'source.app.bpm.conversations' });
            }
            return links;
          }
        }]
      }
    })


    // Legacy: /source/supplier/requests/
    // This is needed because source.architizer.com was originally pointed to this URL as a 301 (cached in users' browsers)
    .state({
      name: 'source.supplierRequests',
      url: 'supplier/requests/',
      views: {
        'root@': {
          controller: RedirectToRequests,
        }
      },
      data: {
        showUiLoaderIn: 'root',
      }
    })

    // Public: Opportunity View
    .state({
      name: 'source.opportunities.opportunity',
      url: ':request/',
      resolve: {
        productRequest: ResolveProductRequest,
      },
      views: {
        'root@': {
          templateUrl: '/views/source/public/opportunity.html',
          controller: 'SourcePublicOpportunityController',
          controllerAs: 'OpportunityCtrl',
        },
      },
      data: {
        useAdk: true,
        hasActionBar: true,
        isFullscreen: true,
        fixedHeader: false,
      },
    })

    ;

    /**
     * Resolve Functions
     */

    // User
    ResolveUser.$inject = ['AuthenticationService'];
    function ResolveUser (AuthenticationService) {
      return AuthenticationService.getCurrentUser()
      .then(function (user) { return user; })
      .catch(function () { return null; });
    }

    // Product Request
    ResolveProductRequest.$inject = ['ProductRequest', '$transition$'];
    function ResolveProductRequest (ProductRequest, $transition$) {
      return ProductRequest.get({
        id: $transition$.params().request,
        force: 'project,firm,creator,product_responses,brand,images,types'
      }).$promise;
    }

    /**
     * Routing Functions
     */

    // Redirect to source.opportunities
    // (for legacy routes like source.architizer.com --> /source/supplier/requests as a 301)
    RedirectToRequests.$inject = ['$state'];
    function RedirectToRequests ($state) {
      $state.go('source.opportunities');
    }

    function handlePublicViews (params, $window) {
       // Request: Send to public request
       if (params.request) {
         return { state: 'source.opportunities.opportunity', params: { request: params.request } };
       }
       // Otherwise redirect to Source homepage
       else {
         $window.location.href = '/go/source/architects';
         return;
       }
    }

    function handleFirmViews (params, transition) {
       if (params.project) {
         return handleProjectViews(params);
       } else if (params.request) {
         return handleProductRequestViews(params, transition);
       } else if (params.conversation) {
         return handleConversationViews(params, transition);
       } else {
          //Go to Projects list
         return { state: 'source.app.arc.projects', params: params };
       }
    }

    function handleBrandViews (params) {
      if (params.message) {
        // Get conversation for this message ID
        // Go to conversation with this message selected
        // return { state: 'source.app.bpm.conversations.conversation', params: { conversation: conversation.id, message: params.message } };
      } else if (params.conversation) {
        // Check if view parameter (for sent products tab)
        if (params.view && params.view === 'products') {
          // Go to sent products tab
          return { state: 'source.app.bpm.conversations.conversation.products', params: { conversation: params.conversation } };
        } else {
          // Go to conversation
          return { state: 'source.app.bpm.conversations.conversation', params: { conversation: params.conversation } };
        }
      } else if (params.request) {
        // Go to request
        return { state: 'source.opportunities.opportunity', params: { request: params.request } };
      } else {
        // Go to requests feed
        return { state: 'source.opportunities' };
      }
    }

    function handleProjectViews(params) {
      if (params.project && params.request && params.response) {
          return { state: 'source.app.arc.project.request.product', params: { project: params.project, request: params.request, response: params.response } };
      }
      else if (params.project && params.request) {
        switch (params.view) {
          case 'editSearch':
            return { state: 'source.app.arc.project.request.edit', params: { project: params.project, request: params.request } };
          default:
            return { state: 'source.app.arc.project.request.products', params: { project: params.project, request: params.request } };
        }
      } else if (params.project && params.conversation) {
        // go to conversation
        return { state: 'source.app.arc.project.conversations.conversation', params: { project: params.project, conversation: params.conversation } };
      } else if (params.project && params.conversation && params.message) {
        // go to conversation with message selected
        return { state: 'source.app.arc.project.conversations.conversation', params: { project: params.project, conversation: params.conversation, message: params.message } };
      } else if (params.project && params.message) {
        // Get conversation for this message ID
        // NOTE: Message deep-linking is not yet implemented
        // Go to this conversation with message selected:
        // return { state: 'source.app.arc.project.conversations.conversation', params: { project: conversation.project.id, conversation: conversation.id, message: params.message } };
      } else if (params.project && params.view) {
        switch (params.view) {
          case 'editProject':
            return { state: 'source.app.arc.project.edit', params: { project: params.project } };
          case 'messages':
            return { state: 'source.app.arc.project.conversations', params: { project: params.project } };
          case 'newSearch':
            return { state: 'source.app.arc.project.requests.new', params: { project: params.project } };
          case 'responses':
            return { state: 'source.app.arc.project.responses', params: { project: params.project } };
          default:
            return { state: 'source.app.arc.project.requests', params: { project: params.project } };
        }
      } else if (params.project) {
        return { state: 'source.app.arc.project.requests', params: { project: params.project } };
      }
    }

    function handleProductRequestViews(params, transition) {
      let ProductRequest = transition.injector().get('ProductRequest')

      // Fetch this Product Request
      // FIXME: This is required as legacy product requests may not have project_id. Move this logic to
      // ResolveProductRequest in the app.arc.routes configuration.

      return ProductRequest
      .get({id: params.request})
      .$promise
      .then((request) => {
        if (!request.project_id) {
          return { state: 'source.opportunities.opportunity', params: { request: request.id } };
        }

        // Go to Products view
        return { state: 'source.app.arc.project.request.products', params: { project: request.project_id, request: request.id } };

      }, (error) => {
        // Show the correct error page
        switch (error.status) {
          case 404:
            return '404';
          case 401:
          case 403:
            return '403';
          default:
            return '500';
        }
      });
    }

    function handleConversationViews(params, transition) {
      // Get project by conversation ID
      let Project = transition.injector().get('Project')

      return Project
      .query({ conversation_id: params.conversation })
      .$promise
      .then((projects) => {
        if (!projects.length) {
          // Redirect user to 404 if no projects are found for this conversation ID
          return '404';
        } else {
          // Otherwise, go to the conversation:
          return { state: 'source.app.arc.project.conversations.conversation', params: { project: projects[0].id, conversation: params.conversation } };
        }
      });
    }

  }]);
