(function() {
  'use strict';

  angular
  .module('architizer.app')
  .controller('SourceBpmConversationSentProductsController', SourceBpmConversationSentProductsController);

  SourceBpmConversationSentProductsController.$inject = [
    '$filter',
    '$modal',
    'conversation',
    'FileClick',
    'productRequest',
    'productResponses',
    'user',
  ];

  function SourceBpmConversationSentProductsController (
    $filter,
    $modal,
    conversation,
    FileClick,
    productRequest,
    productResponses,
    user
    ) {

    var vm = this;

    // Data
    vm.conversation = conversation;
    vm.mySide = 'B';
    vm.user = user;
    vm.productRequest = productRequest;
    vm.productResponses = productResponses.filter((productResponse) => productResponse.workflow_status !== 'R');

    // Functions
    vm.onFileClick = FileClick.openFileModal;
    vm.getBackgroundImage = getBackgroundImage;
    vm.showProductResponseContentModal = showProductResponseContentModal;

    ////////////////////////////////////////////////////////////////////////////////
    // Functions
    ////////////////////////////////////////////////////////////////////////////////

    /**
     * Get Background Image for Product Card
     */
    function getBackgroundImage (product) {
      if (product.images.hero.base_imgix_url) {
        return $filter('imgix')(product.images.hero, 'medium');
      } else if (product.images.gallery.length) {
        return $filter('imgix')(product.images.gallery[0], 'medium');
      } else {
        return '/images/placeholder-product-4-3.png';
      }
    }

    /**
     * Show Product Reponse Content modal
     */
    function showProductResponseContentModal (productResponse) {
      $modal.open({
        templateUrl: '/views/source/shared/product-response.content.modal.html',
        controller: 'SourceProductResponseModalController',
        controllerAs: 'ProductResponseModalCtrl',
        resolve: {
          project: () => null,
          productRequest: () => vm.productRequest,
          productResponse: () => productResponse,
          mySide: () => vm.mySide,
          user: () => vm.user,
        }
      });
    }
  }
})();
