'use strict';

angular
	.module('architizer.app')
	.filter('numeral', function () {

		return function (input, format) {

			if (!input) {

				return;
			}

			if (isNaN(parseFloat(input))) {

				return input;
			}

			return numeral(input).format(format);
		};
	});
