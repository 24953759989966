(function () {
  'use strict';

  angular
  .module('architizer.app')
  .controller('ProjectSearchEditModalController', ProjectSearchEditModalController);

  ProjectSearchEditModalController.$inject = [
    '$modal',
    '$modalInstance',
    '$q',
    '$state',
    '$timeout',
    'Attribution',
    'CustomDataService',
    'FileService',
    'FlashManager',
    'LudwigService',
    'mode',
    'productRequest',
    'ProductRequest',
    'project',
    'StructuredDataService',
    'UnitsService',
    'user',
  ];

  function ProjectSearchEditModalController (
    $modal,
    $modalInstance,
    $q,
    $state,
    $timeout,
    Attribution,
    CustomDataService,
    FileService,
    FlashManager,
    LudwigService,
    mode,
    productRequest,
    ProductRequest,
    project,
    StructuredDataService,
    UnitsService,
    user
  ) {

    var vm = this;

    // Data
    vm.modes = modes();
    vm.models = models();
    vm.elementConfig = elementConfig();
    vm.mode = mode;
    vm.validationMode = '';
    vm.user = user;
    vm.firm = firm();
    vm.productRequest = productRequest;
    vm.project = project;
    vm.form = {};
    vm.saving = false;
    vm.isDescriptionVisible = false;

    // Functions
    vm.FileService = FileService;
    vm.onAddUfoClick = onAddUfoClick;
    vm.onRemoveUfoClick = onRemoveUfoClick;
    vm.isUfoFieldRequired = isUfoFieldRequired;
    vm.onRecommendationClick = onRecommendationClick;
    vm.onSubmitClick = onSubmitClick;
    vm.onDeleteSearchClick = onDeleteSearchClick;
    vm.onCancelClick = onCancelClick;
    vm.onShowDescriptionClick = onShowDescriptionClick;
    vm.onPriceRangeOptionClick = onPriceRangeOptionClick;

    // Initialize
    init();

    ////////////////////////////////////////////////////////////////////////////////
    // Functions
    ////////////////////////////////////////////////////////////////////////////////

    /**
     * On Cancel button click, dismiss the modal
     */
    function onCancelClick () {
      let isDraft = (vm.mode === vm.modes.DRAFT);
      $modalInstance.dismiss({ isDraft: isDraft });
    }

    /**
     * Initialization
     *
     * Initialize the form with the data from vm.productRequest
     */
    function init () {
      const ufoConfig = {
        color:            { removable: false, visible: true   },
        material:         { removable: false, visible: true   },
        finish:           { removable: false, visible: true   },
        application:      { removable: false, visible: true   },
        format:           { removable: false, visible: true   },
        length:           { removable: true,  visible: false  },
        width:            { removable: true,  visible: false  },
        height:           { removable: true,  visible: false  },
        mass:             { removable: true,  visible: false  },
        volume:           { removable: true,  visible: false  },
        referenceBrands:  { removable: true,  visible: false  },
      };

      const ufosToShow = Object.keys(ufoConfig);

      // Default values
      const defaultPrice = vm.elementConfig.priceOptions
        .find(({ label }) => label === 'Mid').value;

      // Copy basic data
      vm.models.name = vm.productRequest.name;
      vm.models.description = vm.productRequest.description;
      vm.models.quantity = vm.productRequest.quantity;
      vm.models.quantityUnits = vm.productRequest.quantity_units || vm.models.quantityUnits;
      vm.models.price = vm.productRequest.price || defaultPrice;
      vm.models.files = vm.productRequest.images.gallery;

      // If description exists on model, mark it as such
      if (vm.models.description.length) {
        toggleDescription(true);
      }

      // Prepare UFOs
      vm.models.ufos = StructuredDataService
      .deserialize(productRequest.structured_data, ufosToShow)
      .map(populateApiFieldValues)
      .map(extendWithUfoConfig)
      .map(setVisibleIfValue);

      // Get recommendations for UFOs
      pollForRecommendations();

      // Poller setup
      let count = 0;
      let delay = 2000;
      let maxTries = 15;

      // Function to poll for recommendations if the API returns "processing" response (424)
      function pollForRecommendations () {
        $timeout(() => {
          count++;

          LudwigService
          .getEntityRecommendations(productRequest.id)
          .then((recommendations) => {

            // Populate recommendations on UFO
            vm.models.ufos.forEach(populateRecommendations);

            // Helpers
            function populateRecommendations (ufo) {
              if (recommendations[ufo.name]) {
                ufo.recommendations = recommendations[ufo.name];

                // Dedupe recommendations that already appear in value of the UFOField
                ufo.recommendations = ufo.recommendations.filter((recommendation) => {
                  const ufoField = ufo.fields.find(({name}) => name === 'value');

                  if (ufoField.value.length && ufoField.value.includes(recommendation.name)) {
                    return;
                  }

                  return recommendation;
                });
              }
            }
          })
          .catch(() => {
            // Call again
            if (count < maxTries) {
              pollForRecommendations();
            } else {
              // Don't show recommendations
            }
          });
        }, delay);
      }


      // Helpers
      function populateApiFieldValues (ufo) {
        if (ufo.hasApiField) {
          ufo.fields.forEach((ufoField) => {
            // Set the UFOField value from the productRequest model instance
            ufoField.value = vm.productRequest[ufoField.apiField];
          });
        }

        return ufo;
      }

      function extendWithUfoConfig (ufo) {
        // Merge removable/visible states into UFOs
        ufo = Object.assign(ufo, ufoConfig[ufo.name]);
        return ufo;
      }

      function setVisibleIfValue (ufo) {
        // Set UFO to visible if there is a value
        ufo.visible = ufo.visible || !!ufo.value; // (Don't overwrite the defaults if "true" is already set from extendWithUfoConfig)
        return ufo;
      }
    }

    // Add recommendation to UFO
    function onRecommendationClick (ufo, recommendation) {
      // Find the "value" UFOField to update
      let ufoField = ufo.fields.find(({name}) => name === 'value');

      if (ufoField) {
        // Push the recommendation into the UFOField value
        ufoField.value.push(recommendation.name);

        // Splice it out of the recommendations
        ufo.recommendations.splice(ufo.recommendations.indexOf(recommendation), 1);
      }
    }

    /**
     * Initial data for vm.elementConfig
     */
    function elementConfig () {
      return {
        quantityUnitsOptions: UnitsService.quantityUnits,
        priceOptions: [
          { label: 'Low', value: '$' },
          { label: 'Mid', value: '$$' },
          { label: 'High', value: '$$$' }
        ],
        ufoDropdownValue: '',
      };
    }

    /**
     * Simple utility to show Description field
     */
    function onShowDescriptionClick () {
      toggleDescription(true);
    }

    /**
     * Toggle Description field
     */
    function toggleDescription (visibility) {
      vm.isDescriptionVisible = visibility;
    }

    /**
     * Handler for Price Ranfe form input
     */
    function onPriceRangeOptionClick (priceLabel) {
      vm.models.price = vm.elementConfig.priceOptions
        .find(({ label }) => label === priceLabel)
        .value;
    }

    /**
     * Initial data for vm.modes
     */
    function modes () {
      return {
        DRAFT: 'draft',           // updating a draft request
        PUBLISHED: 'published',   // updating a published request
      };
    }

    /**
     * Initial data for vm.models
     */
    function models () {
      return {
        name: '',
        description: '',
        quantity: '',
        quantityUnits: '',
        ufos: [],
        files: [],
        price: '',
      };
    }

    /**
     * Get the firm for this product search
     *
     * How:
     * Loop through the user's firms and the project's firms and find the one that matches
     *
     * Why:
     * - The project can have multiple firms
     * - The user can have access to multiple firms
     */
    function firm () {
      var foundFirm;

      angular.forEach(user.firms, (userFirm) => {
        angular.forEach(project.firms, (projectFirm) => {
          if (userFirm.id === projectFirm.id) {
            foundFirm = userFirm;
          }
        });
      });
      return foundFirm;
    }

    /**
     * Click for UFO options in dropdown
     */
    function onAddUfoClick (ufoName) {
      let ufo = vm.models.ufos.find((ufo) => ufo.name === ufoName);
      if (!ufo) { return; }
      ufo.visible = true;

      // Reset ufoDropdownValue
      vm.elementConfig.ufoDropdownValue = '';
      angular.element('#ufoDropdown').blur();
    }

    /**
     * Click for removing UFO fields
     */
    function onRemoveUfoClick (ufo) {
      ufo.visible = false;
    }

    /**
     * Check whether an UFO field should validate as "required"
     */
    function isUfoFieldRequired (ufo) {
      if (!vm.form) { return; }

      var formSubmitted = vm.form.$submitted;
      var tryingToPublish = vm.validationMode === 'publish';
      var allFieldsAreRequired = ufo.requireAllFields;

      if (formSubmitted && tryingToPublish && allFieldsAreRequired) {
        var fieldsWithValues = ufo.fields.filter((field) => !!field.value);
        return fieldsWithValues.length;
      } else {
        return false;
      }
    }

    function onSubmitClick (sendMode) {
      // sendMode can be 'draft' or 'publish' - this determines which $resource action we call
      vm.validationMode = sendMode;
      vm.form.$setSubmitted(true);

      $timeout(() => {

        // Invalid form
        if (!vm.form.$valid) {

          // Find the first element with an error
          let formError = vm.form.$error[Object.keys(vm.form.$error)[0]][0].$name;
          let errorElement = angular.element(document.getElementById(formError));

          // Find the scrolling element
          let pageElement = angular.element('main');

          // Tell the page element to scroll to the first error element
          pageElement.scrollToElement(errorElement, 150, 250);
          return;
        }

        // Valid form
        if (vm.form.$valid) {
          vm.saving = true;
          let isDraft = (vm.mode === vm.modes.DRAFT);

          let productRequest = prepareProductRequestForAPI();

          let action = '';
          // Set the correct action to call with the API
          if ( isDraft && sendMode === 'draft')    { action = 'updatingDraft'; }     // This is a draft Search, and the user clicked "Save Draft"
          if ( isDraft && sendMode === 'publish')  { action = 'publishingDraft'; }   // This is a draft Search, and the user clicked "Publish"
          if (!isDraft && sendMode === 'publish')  { action = 'updatingPublished'; } // This is a published Search, and the user clicked "Save Changes"

          // Call the right action with the API
          switch (action) {
            case 'updatingDraft':
              productRequest.$updateDraft(success, error);
              break;
            case 'publishingDraft':
              productRequest.$publishDraft(success, error);
              break;
            case 'updatingPublished':
              productRequest.$updatePublished(success, error);
              break;
          }
        }

        ////////////////////////////////////////////////////////////////////////////////
        // Helpers
        ////////////////////////////////////////////////////////////////////////////////

        function success (search) {
          // Saved successfully. Set the `save` flag to false
          vm.saving = false;
          var isDraft = (search.workflow_status === 'R');

          // Render a flash
          FlashManager.push({
            message: search.name + (isDraft ? ' was saved' : ' was published'),
            type: 'success',
            lifespan: 1000
          });

          let modalData = {
            search: search,
            isDraft: isDraft,
          };

          $modalInstance.close(modalData);
        }

        function error (data) {
          // Failed to save. Set the `save` flag to false
          vm.saving = false;
          console.error('Failed to save search: ', data);
        }
      });
    }

    /**
     * On "Delete this search" click
     */
    function onDeleteSearchClick () {

      // Create the Delete Search modal
      var deleteSearchModal = $modal.open({
        templateUrl: '/views/source/architect/project.search.search-delete.modal.html',
        controller: 'SourceArchitectProductSearchDeleteModalController',
        controllerAs: 'DeleteSearchCtrl',
        resolve: {
          productRequest: () => vm.productRequest,
        },
      });

      // Take the result of the modal and go forward accordingly
      deleteSearchModal
      .result
      .then(() => $modalInstance.close({ isDeleted: true }));
    }

    /**
     * Construct the API request from the form data
     */
    function prepareProductRequestForAPI () {

      // This is what we'll send to the API
      const productRequest = new ProductRequest();

      // Set firm and project
      productRequest.firm_id = vm.firm.id;
      productRequest.project_id = vm.project.id;

      // Set up all the data on the Product Request
      productRequest.description = vm.models.description;
      productRequest.id = vm.productRequest.id;
      productRequest.media_data = vm.models.files;
      productRequest.media_data_includes_existing = true; // This flag tells the API that media_data will include existing MediaItemAttributions for this object (required)
      productRequest.name = vm.models.name;
      productRequest.price = vm.models.price;
      productRequest.quantity = parseFloat(vm.models.quantity);
      productRequest.quantity_units = vm.models.quantityUnits;
      productRequest.structured_data = StructuredDataService.serialize(vm.models.ufos.filter(({ visible }) => visible));

      // Handle UFOs that have API Fields where their data is saved
      vm.models.ufos.map(saveApiFieldsToModel(productRequest));

      return productRequest;

      // Helpers
      function saveApiFieldsToModel (productRequest) {
        return function useApiField (ufo) {
          if (ufo.hasApiField) {
            ufo.fields.forEach((field) => {
              if (ufo.visible) {
                productRequest[field.apiField] = field.value;
              } else {
                productRequest[field.apiField] = '';
              }
            });
          }
        };
      }

    }
  }
})();
