(function() {
  'use strict';

  angular
    .module('architizer.app')
    .controller('SourceArchitectProjectSearchesController', SourceArchitectProjectSearchesController);

  SourceArchitectProjectSearchesController.$inject = [
    '$modal',
    '$scope',
    '$state',
    'CustomDataService',
    'CustomEventsService',
    'productRequests',
    'project',
    'user',
  ];

  function SourceArchitectProjectSearchesController(
    $modal,
    $scope,
    $state,
    CustomDataService,
    CustomEventsService,
    productRequests,
    project,
    user
  ) {

    const {
      PROJECT_SCHEDULE_REQUEST_COMPLETE
    } = CustomEventsService.events;

    var vm = this;

    // Data
    vm.productRequests = productRequests;
    vm.project = project;
    vm.sourceProjectEmptyStateButton = sourceProjectEmptyStateButton();
    vm.nonSourceProjectEmptyStateButton = nonSourceProjectEmptyStateButton();
    vm.getProductResponseHeroImages = getProductResponseHeroImages;
    vm.user = user;

    // Functions
    vm.isDraft = isDraft;
    vm.onSearchCardClick = onSearchCardClick;

    // Watch for updates to Product Requests
    $scope.$watch(
      () => productRequests.length,
      () => { vm.productRequests = productRequests; init(); }
    );

    // Initialize
    init();

    ////////////////////////////////////////////////////////////////////////////////
    // Functions
    ///////////////////////////////////////////////////////////////////////////////

    /**
     * Initialization
     */
    function init () {
      /**
       * Set up sorting - the following buckets show in this order and are internally sorted by
       * the number of product responses for each search.
       *
       * 1. Draft Searches
       * 2. Open Searches
       * 3. Pending Searches
       * 4. Closed Searches
       */

      // Give each search its "status" and "responses" sorting order
      angular.forEach(vm.productRequests, (request) => assignSortOrder(request) );

      function assignSortOrder (request) {
        var workflow_statuses = {
          'R': 1, // Draft
          'O': 2, // Open
          'P': 3, // Pending
          'C': 4, // Closed
        };

        if (!workflow_statuses.hasOwnProperty(request.workflow_status)) { return; }

        // Assign "statusSort" and "responsesLength" property to use with orderBy
        request.statusSort = workflow_statuses[request.workflow_status];
        request.responsesLength = request.product_responses ? request.product_responses.length : 0;
      }

    }

    /**
     * Given a productRequest, this function returns an array of MediaItemAttributions
     * of the hero image of productResponses submitted to this productRequest.
     * The hero item must be an 'image' filetype, e.g. not a PDF, ZIP, etc.
     */
    function getProductResponseHeroImages(productRequest) {
      let images = [],
          imageExtensions = ['jpg','jpeg','gif','png','tif','tiff'];  // Our API always returns extensions lowercased

      if (productRequest.product_responses) {
        productRequest.product_responses.forEach((response) => {
          if (response.images.hero && imageExtensions.includes(response.images.hero.extension)) {
            images.push(response.images.hero);
          }
        });
      }
      return images;
    }

    /**
     * Button for empty state (Source project)
     */
    function sourceProjectEmptyStateButton() {
      return {
        class: 'button primary fill mt-s',
        label: '+ Search',
        ngClick: () => $state.go('source.app.arc.project.requests.new', { project: vm.project.id }),
      };
    }

    /**
     * Button for empty state (non-Source project)
     */
    function nonSourceProjectEmptyStateButton() {
      return {
        class: 'button primary fill mt-s',
        label: 'Join The Waitlist Now',
        ngClick: () => showInterestModal(),
      };
    }

    /**
     * Show 'Thank you for your interest' modal
     */
    function showInterestModal () {
      var interestModal = $modal.open({
        templateUrl: '/views/source/architect/project.search.interest.modal.html',
        controller: 'ProjectSearchInterestModalController',
        controllerAs: 'InterestModalCtrl',
        resolve: {
          project: () => vm.project,
        },
      });

      // Emit this event for ObjectActionTracker when opened
      interestModal
      .opened
      .then(() => $scope.$emit(PROJECT_SCHEDULE_REQUEST_COMPLETE, { 'id': vm.project.id }));

      interestModal
      .result
      .then(() => $state.reload());
    }

    // Helper function to determine whether a product request is a draft
    function isDraft (productRequest) {
      return productRequest.workflow_status === 'R';
    }

    /**
     * On click function for Search Cards
     *
     * Send drafts to Edit form
     * Send non-drafts to Products view
     */
    function onSearchCardClick (productRequest) {
      if (isDraft(productRequest)) {
        $state.go('source.app.arc.project.request.edit', { request: productRequest.id, view: 'editSearch' });
      } else {
        $state.go('source.app.arc.project.request.products', { request: productRequest.id, view: 'products' });
      }
    }
  }
}());
