'use strict';

angular
	.module('ui')
	.directive('uiLinkButton', ['$parse', function ($parse) {

		return {
			replace: true,
			restrict: 'E',
			transclude: true,
			templateUrl: '/views/uikit/ui-link-button.html',
			scope: {
				color: '@',
				ngDisabled: '=',
				regularTooltip: '@',
				disabledTooltip: '@'
			},
			link: function ($scope, element, attrs, ngModel) {

				/*
				 * View params
				 */



				/*
				 * UI interaction
				 */
				 
				// Stop click event from bubbling on disabled if button is disabled
				// Disabled buttons don't receive click events so this is applied to
				// the dummy container element.
				$scope.onClick = function ($event) {

					if ($scope.ngDisabled) {

						$event.stopPropagation();
					}
				};

				// Pipe provided events on directive to inner button
				var events = ['click'];
				angular.forEach(events, function (event) {

					// Parse a handler out of attribute
					var ngEvent = 'ng' + event.charAt(0).toUpperCase() + event.slice(1);
					var attrHandler = $parse(attrs[ngEvent]);

					// Raw JS event handler
          var handler = function (e) {

            $scope.$apply(function () {

              attrHandler($scope, { $event: e });
            });
          };

          // Attach handler to js event
          element.children()[0].addEventListener(event, handler, false);
				});
				

				/*
				 * Data methods
				 */

				// end here if no ng-model
				if (!ngModel) {

					return;
				}
			}
		};
	}]);