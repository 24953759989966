'use strict';

angular
  .module('architizer.app')
  .controller('SettingsFirmUpdateUserSettingsController', [
  'userSettings',
  'subject',
  'firm',
  'team',
	'$scope',
	'$state',
        '$transition$',
	'PopupManager',
	'UserSettings',
	'AuthorizationService',
	'FlashManager',
	function (userSettings, subject, firm, team, $scope, $state, $transition$, popupManager, UserSettings, authorization, flashManager) {

		$scope.viewbag = {
			user: subject,
			settings: userSettings,
			models: {
				permissions: {
					architizer_admin: false,
					specifier: false,
					member: true
				}
			},
			elementConfig: {
				permissions: {
					architizer_admin: {
						disabled: false,
					},
					specifier: {
						disabled: false,
					}
				}
			},
			firm: firm,
			working: false,
			lastAdmin: false
		};

		var init = function () {

			_populate();

			// If the user being edited is an admin
			if (userSettings.permissions.indexOf(authorization.FIRM_PERMISSIONS.CAN_MANAGE_FIRM) !== -1) {

				// Is the user the last admin?
				var admins = [];
				angular.forEach(team, function (user) {

					if (user.permissions.indexOf(authorization.FIRM_PERMISSIONS.CAN_MANAGE_FIRM) !== -1) {

						admins.push(user);
					}
				});

				// Indicate to the view, and disabled admin checkbox
				if (admins.length <= 1) {

					$scope.viewbag.lastAdmin = true;
					$scope.viewbag.elementConfig.permissions.architizer_admin.disabled = true;
				}
			}
		};

		var _populate = function () {

			var roles = authorization.getRolesForPermissions('firm', $scope.viewbag.settings.permissions);
			angular.forEach(roles, function (role) {

				$scope.viewbag.models.permissions[role] = true;
			});
		};

		$scope.onCancelClick = function () {

			$state.go('settingsFirm.team.list');
		};

		$scope.onSaveClick = function () {

			// Update loading status
      $scope.viewbag.working = true;

			// Prepare permissions
			var permissions = [];
			angular.forEach($scope.viewbag.models.permissions, function (value, key) {

				if (value) {

					var rolePermissions = authorization.getPermissionsForRole('firm', key);
					permissions = _.union(permissions, rolePermissions);
				}
			});

			// Update the invite
		 	UserSettings.update({
        userId: $transition$.params().userId,
        organizationType: 'firm',
        organizationId: firm.id
      }, {
        permissions: permissions
      }, function (settings) {

 	 			// Update the team list
 	 			var index;
 	 			_.find(team, function (u, i) {

 	 				if (u.id == subject.id) { index = i; return true; }
 	 			});

 	 			var updatedUser = angular.copy(team[index]);
 	 			updatedUser.permissions = settings.permissions;
 	 			team[index] = updatedUser;

 	 			// Present flash
				flashManager.push({
					message: 'Settings for ' + subject.first_name + ' ' + subject.last_name + ' were updated',
					type: 'success'
				});

	 	 		$state.go('settingsFirm.team.list');
	 	 	}, function () {

	 	 		// Update loading status
    		$scope.viewbag.working = false;
	 	 	});
		};

		$scope.onRemoveClick = function () {

			var _cancelButton = {
				label: 'Cancel',
				onClick: function () {

					popupManager.pop();
				}
			};

			var _removeButton = {
				label: 'Remove',
				color: 'danger'
			};

			_removeButton.onClick = function () {

				// Indicate loading status
				_removeButton.loading = true;

				// Clear permissions
			 	UserSettings.update({
          userId: $transition$.params().userId,
          organizationType: 'firm',
          organizationId: firm.id
        }, {
	 	 			permissions: []
	 	 		}, function () {

	 	 			// Update the team list
	 	 			var index;
	 	 			_.find(team, function (u, i) {

	 	 				if (u.id == subject.id) { index = i; return true; }
	 	 			});
	 	 			team.splice(index, 1);

	 	 			// Present flash
					flashManager.push({
						message: subject.first_name + ' ' + subject.last_name + ' was removed from your team',
						type: 'warning'
					});

					popupManager.pop();

		 	 		$state.go('settingsFirm.team.list');
		 	 	}, function () {

		 	 		// Update loading status
					_removeButton.loading = false;
		 	 	});
			};

			popupManager.push({
				heading: 'Remove ' + (subject ? subject.first_name + ' ' + subject.last_name : ''),
				size: 'small',
				content: '<span class="body">Do you want to remove ' + (subject ? subject.first_name + ' ' + subject.last_name : '') + ' from your team?</span>',
				buttons: [_cancelButton, _removeButton]
			});
		};

		// When permissions are changed
		$scope.$watch('viewbag.models.permissions.architizer_admin', function (value) {

			if (value) {

				// "Specifier" permissions only apply to firms with Source access
				if (firm.product_source_access === 'A') {
					$scope.viewbag.models.permissions.specifier = true;
				}

				$scope.viewbag.elementConfig.permissions.specifier.disabled = true;
			}

			else {

				$scope.viewbag.elementConfig.permissions.specifier.disabled = false;
			}
		});

		init();
	}]);
