'use strict';

angular
	.module('architizer.app')
	.factory('PreloaderService', ['$timeout', function ($timeout) {

		var preloader = {

			// the urls of files which have already been preloaded
			preloaded: []
		};

		preloader.preloadImage = function (url, success, failure) {

			// immediately call success callback if already preloaded
			if (preloader.preloaded.indexOf(url) !== -1) {

				success();
			}

			// preload
			$(new Image())

				// when the image has been preloaded
				.on('load', function () {

					// store the preloaded url
					preloader.preloaded.push(url);

					// call the success callback on the next digest cycle
					$timeout(success);
				})

				// if the preload fails
				.on('error', failure)

				// begin the preload
				.prop('src', url);
		};

		return preloader;
	}]);