'use strict';

angular
  .module('architizer.app')
  .filter('isImage', [function() {
    
    var IMAGE_TYPES = [
      'jpg',
      'jpeg',
      'png',
      'bmp',
      'tif',
      'tiff',
      'gif'
    ];

    return function(file) {

      if (!file) {

        return false;
      }

      var ext = file.extension || '';
      return (IMAGE_TYPES.indexOf(ext) !== -1);
    };
  }])

  .filter('isPdf', [function() {
    
    var PDF_TYPES = [
      'pdf',
    ];

    return function(file) {

      if (!file) {

        return false;
      }

      var ext = file.extension || '';
      return (PDF_TYPES.indexOf(ext) !== -1);
    };
  }]);

