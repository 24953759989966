'use strict';

angular
	.module('ui')
	.directive('uiTextarea', ['$window', '$document', function ($window, $document) {

		return {
			replace: true,
			restrict: 'E',
			templateUrl: '/views/uikit/ui-textarea.html',
			require: [
				'?ngModel',
				'?ngDisabled'
			],
			scope: {
				ngModel: '=',
				label: '@',
				placeholder: '@',
				ngDisabled: '=',
				form: '=',
				name: '@',
				type: '@',
				flasher: '=',
				customMessagesTemplate: '@',
			},
			link: function ($scope, element, attrs, controllers) {

				/*
				 * View params
				 */

				$scope.isHover = false;
				$scope.isFocussed = false;

				// The input model. We proxy this to the ui-text model.
				$scope.value = '';

				// The textarea element
				var textarea = angular.element(element[0].querySelector('textarea'));

				// The ruler element used to size the textarea
				var ruler = null;

				/*
				 * View methods
				 */

				var createRuler = function () {

					// Remove an old ruler
					if (ruler) {

						ruler.remove();
					}

					// Create a new ruler
					ruler = angular
						.element('<div />')
						.css('position', 'absolute')
						.css('left', '-10000px')
						.css('top', '0')
						.css('white-space', 'pre-wrap');

					// Append to the document
					angular.element($document[0].body).append(ruler);
				};

				var setElementHeightFromRuler = function (textarea) {

					// Create a dummy ruler element if required
					if (!ruler) {

						createRuler();
					}

					// Set dimensions of ruler to the textarea
					var computedStyle = $window.getComputedStyle(textarea[0], null);
					var width = textarea[0].offsetWidth;
					var borderTopWidth = computedStyle.getPropertyValue('border-top-width');
					var borderBottomWidth = computedStyle.getPropertyValue('border-bottom-width');
					var paddingTop = computedStyle.getPropertyValue('padding-top');
					var paddingBottom = computedStyle.getPropertyValue('padding-bottom');
					var fontSize = computedStyle.getPropertyValue('font-size');
					var fontWeight = computedStyle.getPropertyValue('font-weight');
					var lineHeight = computedStyle.getPropertyValue('line-height');
					ruler.css('width', width + 'px');
					ruler.css('font-size', fontSize);
					ruler.css('font-weight', fontWeight);
					ruler.css('line-height', lineHeight);

					// Set the text in the ruler to the textarea
					// Append a dot to ensure a blank new line is added
					ruler.text(textarea.val() + '.');

					// Get the height of the ruler and set the element height
					var height = ruler[0].offsetHeight +
						parseInt(borderBottomWidth) +
						parseInt(borderTopWidth) +
						parseInt(paddingTop) +
						parseInt(paddingBottom);

					textarea.css('height', height + 'px');
				};


				/*
				 * UI interaction
				 */

				$scope.onMouseEnter = function () {

					$scope.isHover = true;
				};

				$scope.onMouseOut = function () {

					$scope.isHover = false;
				};

				$scope.onFocus = function () {

					$scope.isFocussed = true;
				};

				$scope.onBlur = function () {

					$scope.isFocussed = false;
					setTouched();
				};

				/*
				 * Data methods
				 */

				// end here if no ng-model
				var ngModel = controllers[0];
				if (!controllers[0]) {

					return;
				}

				var setTouched = function () {

					ngModel.$setTouched();
				};

				// Watch function to resize the textarea when the model data changes
				$scope.$watchGroup([
					function() { return ngModel.$viewValue; },
					function() { return textarea.css('width'); }
				], function() {

					setElementHeightFromRuler(textarea);
				});
			}
		};
	}]);
