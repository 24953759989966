(function () {
  'use strict';

  angular
  .module('architizer.app')
  .config(['$httpProvider', function($httpProvider) {

    // Disallow deprecated success/error methods
    $httpProvider.useLegacyPromiseExtensions = false;

    // Remove metadata from the API response
    $httpProvider.interceptors.push(parseApiResponse);

    // Don't send certain errors to Sentry
    $httpProvider.interceptors.push(ignoredErrors);

    // Add CSRF compatibility
    $httpProvider.defaults.xsrfCookieName = 'csrftoken';
    $httpProvider.defaults.xsrfHeaderName = 'X-CSRFToken';

    /**
     * Interceptor to transform API responses
     */
    function parseApiResponse () {
      return {
        response: function(response) {
          // If this looks like an api response, return the data key only
          if (angular.isObject(response.data) && response.data.data != null) {
            var metadata = response.data.metadata;
            response.data = response.data.data;
            if (metadata && metadata.pagination) {
              response.data.$pagination = metadata.pagination;
            }
          }

          return response;
        }
      };
    }

    /**
     * Interceptor to prevent sending certain errors to Sentry
     *
     * This allows us to reduce noise in Sentry for API calls we expect to fail,
     * like 403 errors when a user is logged out and hits the users/me endpoint
     */
    ignoredErrors.$inject = [ '$q' ];
    function ignoredErrors ($q) {
      return {
        responseError: function (error) {

          var ignoredResponses = [
            {
              'method': 'GET',
              'regex': /\/api\/v[0-9]+\.[0-9]+\/users\/me$/i,
              'status': 403,
            }
          ];


          var ignoreThisError = false;

          // Test if we should ignore this error
          angular.forEach(ignoredResponses, function (ignoredResponse) {

            var matchMethod = error.config.method === ignoredResponse.method;
            var matchStatus = error.status === ignoredResponse.status;
            var matchUrl = ignoredResponse.regex.exec(error.config.url) !== null;

            ignoreThisError = matchMethod && matchStatus && matchUrl;

          });

          // Don't send errors for canceled XHR requests
          var canceled = error.status === -1;
          if (canceled) { ignoreThisError = true; }

          // If we aren't ignoring this error, send it to Sentry:
          if (!ignoreThisError) {
            Raven.captureException(new Error(`${error.config.method} ${error.config.url} returned ${error.status}`), {
              extra: {
                config: error.config,
                status: error.status,
                payload: null, // TODO: log the payload
                response: error.data
              }
            });
          }

          return $q.reject(error);
        }
      };
    }
  }]);
})();
