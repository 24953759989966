'use strict';

angular
	.module('ui')
	.directive('minOptions', [function () {

		return {
			replace: false,
			restrict: 'A',
			require: '?ngModel',
			link: function ($scope, element, attrs, ngModel) {

				var min = attrs['minOptions'] || 0;

				/*
				 * Data methods
				 */

				// end here if no ng-model
				if (!ngModel) {

					return;
				}

				ngModel.$validators.minOptions = function (value) {

					return value.length >= min;
				};
			}
		};
	}]);