'use strict';

/**
 * @ngdoc function
 * @name architizerWebsiteApp.controller:MainCtrl
 * @description
 * # MainCtrl
 * Controller of the architizerWebsiteApp
 */
angular
	.module('architizer.app')
  .controller('MainCtrl', function () {
    
  });
