
angular
	.module('architizer.app')
	.factory('LocationService', function () {

		var locationService = {};

		locationService.countries = [
			{
				text: 'United States',
				value: 'US',
				children: [
					{
						text: 'Northeast',
						value: 'US_NORTHEAST',
						children: [
							{
								text: 'Connecticut',
								value: 'US_CT'
							},
							{
								text: 'Maine',
								value: 'US_ME'
							},
							{
								text: 'Massachusetts',
								value: 'US_MA'
							},
							{
								text: 'New Hampshire',
								value: 'US_NH'
							},
							{
								text: 'New Jersey',
								value: 'US_NJ'
							},
							{
								text: 'New York',
								value: 'US_NY'
							},
							{
								text: 'Pennsylvania',
								value: 'US_PA'
							},
							{
								text: 'Rhode Island',
								value: 'US_RI'
							},
							{
								text: 'Vermont',
								value: 'US_VT'
							}
						]
					},
					{
						text: 'Midwest',
						value: 'US_MIDWEST',
						children: [
							{
								text: 'Illinois',
								value: 'US_IL'
							},
							{
								text: 'Indiana',
								value: 'US_IN'
							},
							{
								text: 'Iowa',
								value: 'US_IA'
							},
							{
								text: 'Kansas',
								value: 'US_KS'
							},
							{
								text: 'Michigan',
								value: 'US_MI'
							},
							{
								text: 'Minnesota',
								value: 'US_MN'
							},
							{
								text: 'Missouri',
								value: 'US_MO'
							},
							{
								text: 'Nebraska',
								value: 'US_NE'
							},
							{
								text: 'North Dakota',
								value: 'US_ND'
							},
							{
								text: 'Ohio',
								value: 'US_OH'
							},
							{
								text: 'South Dakota',
								value: 'US_SD'
							},
							{
								text: 'Wisconsin',
								value: 'US_WI'
							}
						]
					},
					{
						text: 'South',
						value: 'US_SOUTH',
						children: [
							{
								text: 'Alabama',
								value: 'US_AL'
							},
							{
								text: 'Arkansas',
								value: 'US_AR'
							},
							{
								text: 'Delaware',
								value: 'US_DE'
							},
							{
								text: 'Florida',
								value: 'US_FL'
							},
							{
								text: 'Georgia',
								value: 'US_GA'
							},
							{
								text: 'Kentucky',
								value: 'US_KY'
							},
							{
								text: 'Louisiana',
								value: 'US_LA'
							},
							{
								text: 'Maryland',
								value: 'US_MD'
							},
							{
								text: 'Mississippi',
								value: 'US_MS'
							},
							{
								text: 'North Carolina',
								value: 'US_NC'
							},
							{
								text: 'Oklahoma',
								value: 'US_OK'
							},
							{
								text: 'South Carolina',
								value: 'US_SC'
							},
							{
								text: 'Tennessee',
								value: 'US_TN'
							},
							{
								text: 'Texas',
								value: 'US_TX'
							},
							{
								text: 'Virginia',
								value: 'US_VA'
							},
							{
								text: 'Washington D.C.',
								value: 'US_DC'
							},
							{
								text: 'West Virginia',
								value: 'US_WV'
							}
						]
					},
					{
						text: 'West',
						value: 'US_WEST',
						children: [
							{
								text: 'Alaska',
								value: 'US_AK'
							},
							{
								text: 'Arizona',
								value: 'US_AZ'
							},
							{
								text: 'California',
								value: 'US_CA'
							},
							{
								text: 'Colorado',
								value: 'US_CO'
							},
							{
								text: 'Hawaii',
								value: 'US_HI'
							},
							{
								text: 'Idaho',
								value: 'US_ID'
							},
							{
								text: 'Montana',
								value: 'US_MT'
							},
							{
								text: 'Nevada',
								value: 'US_NV'
							},
							{
								text: 'New Mexico',
								value: 'US_NM'
							},
							{
								text: 'Oregon',
								value: 'US_OR'
							},
							{
								text: 'Utah',
								value: 'US_UT'
							},
							{
								text: 'Washington',
								value: 'US_WA'
							},
							{
								text: 'Wyoming',
								value: 'US_WY'
							}
						]
					},
					{
						text: 'Offshore',
						value: 'US_OFFSHORE',
						children: [
							{
								text: 'American Samoa',
								value: 'US_AS'
							},
							{
								text: 'Guam',
								value: 'US_GU'
							},
							{
								text: 'Northern Mariana Islands',
								value: 'US_MP'
							},
							{
								text: 'Puerto Rico',
								value: 'US_PR'
							},
							{
								text: 'Virgin Islands',
								value: 'US_VI'
							}
						]
					}
				]
			}
		];

		/*
		 * Get the country for a location code
		 */
		locationService.getCountryForCode = function (code) {

			// Get the first two letters (country code)
			var countryCode = code.substr(0,2);
			var country;

			angular.forEach(locationService.countries, function (c) {

				if (c.value == countryCode) {

					country = c;
				}
			});
			
			return country;
		};

		/*
		 * Get the countries for a list of location codes
		 */
		locationService.getCountriesForCodes = function (codes) {

			var countries = [];

			angular.forEach(codes, function (code) {

				var country = locationService.getCountryForCode(code);

				// Not a valid code if no country
				if (!country) {

					return;
				}

				// Add to countries list if not present
				if (countries.indexOf(country) === -1) {

					countries.push(country);	
				}
			});

			return countries;
		};

		/*
		 * Returns a list of locations corresponding to the list
		 * of location codes.
		 */
		locationService.getLocationsForCodes = function (codes) {

			var locations = {
				countries: [],
				states: []
			};

			angular.forEach(codes, function (code) {

				var country = locationService.getCountryForCode(code);

				// Not a valid code if no country
				if (!country) {

					return;
				}

				// Add to countries list if not present
				if (locations.countries.indexOf(country) === -1) {

					locations.countries.push(country);	
				}
				
				var state;
				angular.forEach(country.children, function (region) {

					angular.forEach(region.children, function (s) {

						if (s.value == code) {

							state = s;
						}
					});
				});

				// Add to states list if not present
				if (locations.states.indexOf(state) === -1) {

					locations.states.push(state);	
				}
			});

			return locations;
		};

		locationService.convertGoogleLocationToArchitizer = function (googloc) {

			// Initialise Architizer location object
			var arcloc = {
				name: googloc.formatted_address,
				street_number: '',
        route: '',
        country: '',
        country_code: '',
        administrative_area_level_1: '',
        administrative_area_level_2: '',
        administrative_area_level_3: '',
        locality: '',
        postal_code: '',
        latitude: null,
        longitude: null
			};

			// Set coords
			if (googloc.geometry && googloc.geometry.location) {

				arcloc.latitude = googloc.geometry.location.lat;
				arcloc.longitude = googloc.geometry.location.lng;	
			}

			// Set address components
			angular.forEach(googloc.address_components, function (component) {

				if (component.types.indexOf('street_number') !== -1) {

					arcloc.street_number = component.long_name;
				}

				else if (component.types.indexOf('route') !== -1) {

					arcloc.route = component.long_name;
				}

				else if (component.types.indexOf('route') !== -1) {

					arcloc.route = component.long_name;
				}

				else if (component.types.indexOf('administrative_area_level_3') !== -1) {

					arcloc.administrative_area_level_3 = component.long_name;
				}

				else if (component.types.indexOf('administrative_area_level_2') !== -1) {

					arcloc.administrative_area_level_2 = component.long_name;
				}

				else if (component.types.indexOf('administrative_area_level_1') !== -1) {

					arcloc.administrative_area_level_1 = component.long_name;
				}

				else if (component.types.indexOf('country') !== -1) {

					arcloc.country_code = component.short_name;
					arcloc.country = component.long_name;
				}

				else if (component.types.indexOf('locality') !== -1) {

					arcloc.locality = component.long_name;
				}

				else if (component.types.indexOf('locality') === -1 &&
					component.types.indexOf('sublocality') !== -1) 
				{

					arcloc.locality = component.long_name;
				}

				else if (component.types.indexOf('postal_code') !== -1) {

					arcloc.postal_code = component.long_name;
				}
			});

			return arcloc;
		};

		return locationService;
	});