(function() {
  'use strict';

  angular
  .module('architizer.app')
  .filter('opportunitySortOrder', [function () {

    /**
     * Assigns a sortOrder to each product response according to brand paying/non-paying status
     *
     * 1 --> paying (verified)
     * 2 --> non-paying (!verified)
     */

    return function (productResponse) {

      if (!productResponse.brand) return;

      productResponse.statusSort = productResponse.brand.verified ? 1 : 2;

      return productResponse;
    };
  }])
})();
