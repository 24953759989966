
(function() {
  'use strict';

  angular
  .module('architizer.app')
  .controller('SourceArchitectProjectRequestProductsController', SourceArchitectProjectRequestProductsController);

  SourceArchitectProjectRequestProductsController.$inject = [
    '$filter',
    '$log',
    '$modal',
    '$rootScope',
    '$scope',
    '$state',
    '$timeout',
    'CustomDataService',
    'CustomEventsService',
    'FileClick',
    'LudwigService',
    'productRecommendations',
    'productRequest',
    'productResponses',
    'project',
    'SearchstaxService',
    'user'
  ];

  function SourceArchitectProjectRequestProductsController (
    $filter,
    $log,
    $modal,
    $rootScope,
    $scope,
    $state,
    $timeout,
    CustomDataService,
    CustomEventsService,
    FileClick,
    LudwigService,
    productRecommendations,
    productRequest,
    productResponses,
    project,
    SearchstaxService,
    user
  ) {

    var vm = this;

    // Map of `marking`s
    const markings = {
      SHORTLIST: 'F',
      DISMISSED: 'D',
      INREVIEW: 'N',
    };

    // Custom event names
    const {
      PRODUCT_RESPONSE_DISMISS_START,
      PRODUCT_RESPONSE_DISMISS_COMPLETE,
      PRODUCT_RECOMMENDATION_ADD_TO_PRODUCTS_START,
      PRODUCT_RECOMMENDATION_ADD_TO_PRODUCTS_COMPLETE
    } = CustomEventsService.events;

    // Data
    vm.productRequest = productRequest;
    vm.products = productResponses;
    vm.recommendations = productRecommendations;
    vm.recommendationsStatus = recommendationsStatus();
    vm.project = project;
    vm.sortReverse = false;
    vm.emptyStateButton = emptyStateButton();
    vm.mySide = 'A';
    vm.user = user;
    vm.heroImage = setHeroImage();
    vm.saving = false;
    vm.hasHeroImage = Object.keys(vm.heroImage).length !== 0;
    vm.rootScopeUnbindRefs = [];

    // Functions
    vm.showAddProductModal = showAddProductModal;
    vm.showProductResponseContentModal = showProductResponseContentModal;
    vm.onAddToProducts = onAddToProducts;
    vm.setShortlisted = setShortlisted;
    vm.getBackgroundImage = getBackgroundImage;
    vm.onFileClick = FileClick.openFileModal;
    vm.onFileDownloadClick = FileClick.onFileDownloadClick;
    vm.setHeroImage = setHeroImage;
    vm.placeholderShortlistItems = createPlaceholderListItems(markings.SHORTLIST);
    vm.placeholderDismissedItems = createPlaceholderListItems(markings.DISMISSED);
    vm.showIntercom = showIntercom;
    vm.getShortlistCount = getShortlistCount;
    vm.getDismissedCount = getDismissedCount;
    vm.isDraftProductRequest = (vm.productRequest.workflow_status === 'R');

    // Initialize
    init();

    ////////////////////////////////////////////////////////////////////////////////
    // Functions
    ////////////////////////////////////////////////////////////////////////////////
    /**
     * Initialization
     */
    function init () {
      // Prepare request structured data points for view in request info modals
      if(vm.productRequest.structured_data.length){
        CustomDataService.prepareForDisplay(angular.fromJson(vm.productRequest.structured_data));
      }

      bindEventListeners();

      angular.element(document).ready(() => {
        vm.placeholderShortlistItems = createPlaceholderListItems(markings.SHORTLIST);
        vm.placeholderDismissedItems = createPlaceholderListItems(markings.DISMISSED);
      });

      // Poll for Product Recommendations if they are still processing
      if (vm.recommendationsStatus === 'processing') {
        pollForProductRecommendations();
      }

      // Poller setup
      let count = 0;
      let delay = 5000; // Poll every 5 seconds
      let maxTries = 10; // Max number of times to poll

      // Function to poll for productRecommendations if the API returns "processing" response
      function pollForProductRecommendations () {
        $timeout(() => {
          count++;

          // Get product recommendations from the API
          LudwigService
          .getProductRecommendations(vm.productRequest.id)
          .then((recommendations) => {
            vm.recommendations = recommendations;
            vm.recommendationsStatus = 'loaded';
          })
          .catch(() => {
            // Call again
            if (count < maxTries) {
              pollForProductRecommendations();
            } else {
              vm.recommendationsStatus = 'failed';
            }
          });
        }, delay);
      }
    }

    function recommendationsStatus () {
      if (productRecommendations.processing) {
        return 'processing';
      } else if (!productRecommendations.processing && !productRecommendations.length) {
        return 'none';
      } else if (productRecommendations.length) {
        return 'loaded';
      } else {
        return 'failed';
      }
    }

    function getShortlistCount () {
      return vm.products.filter(({ marking }) => marking === markings.SHORTLIST).length;
    }

    function getDismissedCount () {
      return vm.products.filter(({ marking }) => marking === markings.DISMISSED).length;
    }

    /**
     * Function that unbinds event listeners when controller $scope is destroyed.
     */
    function unbindEventListeners () {
      vm.rootScopeUnbindRefs.forEach(unbindFn => unbindFn());
    }

    /**
     * Function that encapsulate event listeners for this controller.
     */
    function bindEventListeners () {
      /**
       * Product Response Dismiss Modal Controller emits an event upon starting
       * the dismissal of a product. We listen to that event here
       * to set a `pending` UI state.
       */
      const UNBIND_PRODUCT_RESPONSE_DISMISS_START = $rootScope.$on(PRODUCT_RESPONSE_DISMISS_START, ($event, { id : dismissedId }) => {
        $event.stopPropagation();
        const dismisedProductResponse = vm.products.find(({ id }) => id === dismissedId);
        if (dismisedProductResponse) {
          dismisedProductResponse.saving = true;
        }
      });

      /**
       * Product Response Dismiss Modal Controller emits an event upon completion of
       * the dismissal of a product. We listen to that event here to update
       * both the Shortlist, if necessary, and the Products Grid
       * as well as to unset a `pending` UI state.
       */
      const UNBIND_PRODUCT_RESPONSE_DISMISS_COMPLETE = $rootScope.$on(PRODUCT_RESPONSE_DISMISS_COMPLETE, ($event, { id : dismissedId }) => {
        $event.stopPropagation();
        const dismisedProductResponse = vm.products.find(({ id }) => id === dismissedId);
        if (dismisedProductResponse) {
          dismisedProductResponse.saving = false;
          vm.placeholderShortlistItems = createPlaceholderListItems(markings.SHORTLIST);
          vm.placeholderDismissedItems = createPlaceholderListItems(markings.DISMISSED);
        }
      });

      /**
       * Product Response Content Modal Controller emits an event upon user click
       * on `Add To My Products` button. We listen to that event here
       * to set a sync with server
       */
      const UNBIND_PRODUCT_RECOMMENDATION_ADD_TO_PRODUCTS_START = $rootScope.$on(PRODUCT_RECOMMENDATION_ADD_TO_PRODUCTS_START, ($event, product) => {
        $event.stopPropagation();
        onAddToProducts(product);
      });

      // Hold a reference to `unbind` functions for all $rootScope events
      vm.rootScopeUnbindRefs.push(
        UNBIND_PRODUCT_RESPONSE_DISMISS_START,
        UNBIND_PRODUCT_RESPONSE_DISMISS_COMPLETE,
        UNBIND_PRODUCT_RECOMMENDATION_ADD_TO_PRODUCTS_START
      );

      // Unbind all $rootScope event listeners on controller $destroy event
      $scope.$on('$destroy', unbindEventListeners);
    }

    /**
     * Set Hero Image
    */
    function setHeroImage (image) {
      if (image) {
        vm.heroImage = image;
        return image;
      } else if (vm.productRequest.images.hero) {
        return vm.productRequest.images.hero;
      } else {
        return null;
      }
    }

    /**
     * Check if product brand is verified
    */
    function isBrandVerified (product) {
      if (product.brand.verified) {
        return true;
      } else {
        return false;
      }
    }

    /**
     * Get Background Image for `listItems` in Shortlist and Product Cards.
     */
    function getBackgroundImage (product, uiMode) {
      if (!(['listItem', 'card'].includes(uiMode))) {
        return;
      }

      const placeholder = {
        listItem: '/images/placeholder-product-1-1.png',
        card: '/images/placeholder-product-4-3.png'
      };
      const imgixSizeParam = {
        listItem: 'square40',
        card: 'medium'
      };
      if(!product || !product.images) {
        return placeholder[uiMode];
      } else if (product.images.hero.base_imgix_url) {
        return $filter('imgix')(product.images.hero, imgixSizeParam[uiMode]);
      } else if (product.images.gallery.length) {
        return $filter('imgix')(product.images.gallery[0], imgixSizeParam[uiMode]);
      } else {
        return placeholder[uiMode];
      }
    }

    /**
     * Show Add Products modal
     */
    function showAddProductModal () {
      var addProductModal = $modal.open({
        templateUrl: '/views/source/architect/logged-product.modal.html',
        controller: 'LoggedProductModalController',
        controllerAs: '$ctrl',
        resolve: {
          mode: () => 'new',
          productRequest: () => vm.productRequest,
          productResponse: null,
        }
      });

      addProductModal
      .result
      .then(() => $state.reload());
    }

    /**
     * Show Product Reponse Content modal
     */
    function showProductResponseContentModal (productResponse) {
      var productResponseContentModal = $modal.open({
        templateUrl: '/views/source/shared/product-response.content.modal.html',
        controller: 'SourceProductResponseModalController',
        controllerAs: 'ProductResponseModalCtrl',
        resolve: {
          project: () => vm.project,
          productRequest: () => vm.productRequest,
          productResponse: () => productResponse,
          mySide: () => vm.mySide,
          user: () => vm.user,
        }
      });

      productResponseContentModal
      .result
      .then((response) => markResponse(response, response.marking));
    }

    /**
     * Mark response in the view (used for shortlisting/dismissing)
     */
    function markResponse (markedProduct, marking) {
      // Find response in vm.products and set the marking on it
      vm.products.forEach((product) => {
        if (product.id === markedProduct.id) {
          markedProduct.marking = marking;
        }
      });
    }

    /**
     * Set a product response as `shortlist`ed
     */
    function setShortlisted(response, $event) {
      response.saving = true;
      // Stop propagation - the parent element has an ng-click on it too
      $event.stopPropagation();

      response
        .$shortlist()
        .then(({ data: { status, content_object_modified } }) => {
          vm.placeholderShortlistItems = createPlaceholderListItems(markings.SHORTLIST);
          response.modified = content_object_modified;
          markResponse(response, status);
        })
        .catch((error) => console.error('Failed to shortlist product: ', error))
        .finally(() => { response.saving = false; });
    }

    /**
     * Object containing Media Item Attribution of media items to copy.
     * We need to copy media items to the Product Recommendation
     * being logged as a Product Response for current Project.
     */
    function getCopiedMediaDataForProduct ({ images: { hero, gallery } }) {
      let heroData = {};
      let galleryData = [];

      if (hero && hero.id) {
        heroData = {
          media_item_attribution_id: hero.id,
          type: 'hero',
        };
      }

      if (gallery.length) {
        galleryData = gallery.map(({ id }) => {
          return { 
            media_item_attribution_id: id,
            type: 'gallery',
          };
        });
      }

      return [heroData, ...galleryData];
    }

    /**
     * Log recommended product
     */
    function onAddToProducts (product, $event) {
      // Don't allow product to be added multiple times
      if (product.saving === true) { return; }

      // Stop propagation, if invoked by user click event
      // the parent element has an ng-click on it too
      if ($event) {
        $event.stopPropagation();
      }
      
      // Extract `id` and `name` from current Product Request
      const {
        id: product_request_id,
        name: product_request_name
      } = vm.productRequest;

      // Extract `id` and `brand` from Product Recommendation being Logged
      const {
        id: original_response_id,
        brand
      } = product;

      /*
       * Enrich object with keys required to log Product Recommendation as Response
       * These keys are documented in `application/product_source/api_views #OfferingList`
       */
      const recommendedProduct = Object.assign(product, {
        product_request_id,
        product_request_name,
        brand_id: brand.id,
        original_response_id,
        recommender_id: vm.user.id,
        copied_media_data: getCopiedMediaDataForProduct(product),
        saving: true, // Flag for disabling UI button
      });

      /* 
        TODO Currently we use `cahcedProductData` to add fields to the product
        that is currently missing from server response (e.g: `images`)
        So we add those fields on the front-end to support UI State
      */
      const cachedProductData = Object.assign({}, recommendedProduct);

      recommendedProduct.$createLoggedProduct()
        .then((resource) => {
          const loggedRecommendedProduct = Object.assign(
            resource,
            cachedProductData,
            { saving: false }
          );
          vm.products.push(loggedRecommendedProduct);
          vm.recommendations.splice(vm.recommendations.indexOf(resource), 1);
        })
        .then(() => {
          // Send SearchStax "Add To Cart" event
          const ssAppName = 'productRecommendations';
          const addToCartData = {
            key: SearchstaxService.getApiKey(ssAppName),
            session: SearchstaxService.getSession(),
            id: product.id,
            name: product.name,
          };

          SearchstaxService.addToCart(addToCartData);
        })
        .catch((error) => {
          // If server sync fails, set `saving` flag to false
          recommendedProduct.saving = false;
          console.error('Failed to log product:', error);
        })
        .finally(() => {
          $rootScope.$broadcast(PRODUCT_RECOMMENDATION_ADD_TO_PRODUCTS_COMPLETE);
        });

    }

    /**
     * Button for empty state
     */
    function emptyStateButton() {
      return {
        class: 'button primary fill mt-s',
        label: '+ Add Product',
        ngClick: () => showAddProductModal(),
      };
    }

    /**
     * Function that adds empty List Items to fill the entire height of listview UI
     */
    function createPlaceholderListItems (tab = markings.SHORTLIST) {
      const listViewEl = angular.element('.shortlist-dismissed-sidebar');
      const listViewHeight = listViewEl.height();
      // `4.5rem` is set as `min-height` for list items.
      const itemHeight = 72;
      const itemCount = vm.products.filter(({ marking }) => marking === tab).length;
      const emptyListHeight = listViewHeight - (itemCount * itemHeight);
      // Creating an empty object to populate the placeholders array with
      const placeholder = {};
      // An array storing empty objects, used to populate placeholder list items with `ng-repeat`
      const placeholderItemCount =  Math.max(0, Math.floor(emptyListHeight / itemHeight));
      let placeholderArray = [];

      for(let i = 0; i < placeholderItemCount; i ++) {
        placeholderArray.push(placeholder);
      }
      return placeholderArray;
    }

    /**
     * Function to open Intercom chat window
     */
    function showIntercom(){
      window.Intercom('showNewMessage');
    }
  }
}());
