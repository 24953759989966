'use strict';

// requestAnimFrame shim
window.requestAnimFrame = (function(){

  return window.requestAnimationFrame  ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame    ||
    window.oRequestAnimationFrame      ||
    window.msRequestAnimationFrame     ||
    function(/* function */ callback, /* DOMElement */ element){
      window.setTimeout(callback, 1000 / 60);
    };
})();

angular
	.module('architizer.app')
	.directive('fixedSidebar', ['$window', function ($window) {

		return {
			restrict: 'A',
			replace: false,
			link: function ($scope, element) {

				// store the original Y offset value
				var _originalY = element.offset().top;

				// store the last scroll to determine scroll direction
				var _lastScroll = $window.scrollY;

				// the code to update the position of the sidebar
				var update = function () {

					// determine scroll direction
					var down = $window.scrollY > _lastScroll;

					// store the scroll position
					_lastScroll = $window.scrollY;

					// determine how position of sidebar relates to window
					var smallerThanWindow = element.outerHeight() < $($window).height();
					var bottomVisible = (element.offset().top - $window.scrollY + element.outerHeight()) < $($window).height();
					var topVisible = element.offset().top > $window.scrollY;
					var contentContainerHeight = element.parent().outerHeight();
					var top;

					// calculate the top offset to have the bottom glued to the bottom of the window
					top = $window.scrollY - _originalY;

					// prevent sidebar ever being higher than original position
					top = Math.max(0, top);

					// prevent sidebar ever being lower than the document height
					top = Math.min(contentContainerHeight - element.outerHeight(), top);

					// set the offset
					element.css('transform', 'translateY(' + top + 'px)');
				};

				// create a continuous update loop utilising requestAnimFrame
				(function animLoop() {

					requestAnimFrame(animLoop);
					update();
				})();
			}
		};
	}]);