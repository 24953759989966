(function() {
  'use strict';

  /**
   * show-ui-loader
   *
   * Include the 'show-ui-loader' directive on ui-view <div>'s that may need to show ui-loader's while their data 
   * is being resolved during state changes.
   *
   * Usage instructions:
   *
   *
   * 1. In the template, include 'show-ui-loader' on the ui-view <div> in which a ui-loader may need to be shown:
   *
   * <div ui-view="sidebar" show-ui-loader></div>
   *
   *
   * 2. In the $state definition, include a 'showUiLoaderIn' property with the name of the ui-view <div> in which 
   * the loader should show while this $state's data is being resolved:
   *
   * $state({
   *   name: 'projects.project.requests',
   *   ...
   *   showUiLoaderIn: 'sidebar',
   * })
   *
   * Extra:
   *
   * showUiLoaderOutgoing:
   *
   * If you want to override the 'showUiLoaderIn' behavior and just show the standard page-wide loader,
   * specify 'showUiLoaderOutgoing' in the state definition.
   *
   * This will show a standard GlobalView loader when navigating away from the state on which it is specified.
   */

  angular
  .module('architizer.app')
  .directive('showUiLoader', ['GlobalView', '$compile', '$transitions', function showUiLoader (GlobalView, $compile, $transitions) {
    return {
      restrict: 'A',
      scope: {},
      link: function (scope, element) {

        // Create a loader element to put into a ui-view <div>
        var loader = angular.element($compile('<div class="showUiLoader"><ui-loader width="50" height="50" back-color="#eef1f2" fore-color="#bdc7cd"></ui-loader></div>')(scope));

        $transitions.onStart( { to: 'source.app.**' }, function(transition) {
          let toState = transition.to(),
              fromState = transition.from();

          // Check for 'outgoing' loader flag
          if (fromState.data && fromState.data.showUiLoaderOutgoing === true) {
            GlobalView.loading = true;
          }
          else if (toState.data && toState.data.showUiLoaderIn === element.attr('ui-view')) {
            /**
             * If the destination state specifies this ui-view as the location of its loader,
             * add the loader element
             */

            // Add .showUiLoader-loading because we need to hide overflow while loading
            element.addClass('showUiLoader-loading');

            // Add the loader to the ui-view <div> element
            element.html(loader);
          }
        });

        /**
         * When the state change is finished, remove the loader element from the <div>
         */
        $transitions.onSuccess( { to: 'source.app.**' }, function(transition) {
          let toState = transition.to(),
              fromState = transition.from();

          // Check for 'outgoing' loader flag
          if (fromState.data && fromState.data.showUiLoaderOutgoing === true) {
            GlobalView.loading = false;
          } else if (toState.data && toState.data.showUiLoaderIn === element.attr('ui-view')) {

            // Remove .showUiLoader-loading class
            element.removeClass('showUiLoader-loading');

            // Remove the loader
            loader.remove();
          }
        });
      }
    };
  }]);
})();