'use strict';

angular
	.module('ui')
	.directive('uiPopup', [
		'$compile', '$controller',
		'$rootScope',
		'$templateCache',
		'$window',
		'CustomEventsService',
		'PopupManager',
		function (
			$compile,
			$controller,
			$rootScope,
			$templateCache,
			$window,
			CustomEventsService,
			popupManager
		) {

			const {
				MODAL_BUTTON_CLICK
 			} = CustomEventsService.events;

		return {
			replace: true,
			restrict: 'E',
			transclude: false,
			templateUrl: '/views/uikit/ui-popup.html',
			scope: {
				buttons: '=',				// The buttons in the popup
				anchor: '@',				// The direction to anchor
				x: '=',							// X-coord of anchor point
				y: '=',							// Y-coord of anchor point
				heading: '=',				// The title of the modal
				note: '=',					// HTML to be displayed below the heading
				content: '=',				// HTML to be displayed by popup
				contentUrl: '=',		// URL of template to be displayed by popup
				controller: '@',		// Name of the controller to manage the popup
				type: '@',					// The type of popup (modal/helper)
				size: '@',					// The size variation of the popup
				action: '=',				// The action button configuration
				externals: '=',			// Custom scope vars used in the modal
				useAdk: '=',				// Apply design kit styles to modal
			},
			link: function ($scope, element, attrs, ngModel) {
				/*
				 * View params
				 */

				$scope.hasButtons = false;

				// Load the controller if a controller name is provided
				if ($scope.controller) {

					// Create the controller
					var controller = $controller($scope.controller, { '$scope': $scope });

					// Attach to the DOM
					element.data('$ngControllerController', controller);
        	element.children().data('$ngControllerController', controller);
				}

				/*
				 * UI interaction
				 */

				var _buttonClicked = function (button) {

					// Broadcast message
					$rootScope.$broadcast(MODAL_BUTTON_CLICK, button);

					// Call onClick handler
					if (button.onClick) {

						button.onClick();
					}
				};

				// Provide default click handlers for buttons.
				// This is a wrapper around the onClick handler,
				// if provided.
				//
				// By broadcasting the event, the modal controller
				// may react to button clicks.

				$scope.$watchCollection('buttons', function () {

					angular.forEach($scope.buttons, function (button) {

						button.wrappedOnClick = function () {

							_buttonClicked(button);
						};
					});
				});

				var _handleEnterKey = function () {

					var primary;

					// Find the best most applicable button
					// Primary action buttons are typically success or danger color
					// If no primary action button can be found, use a default button
					angular.forEach($scope.buttons, function (button) {

						if (button.color == 'success' || button.color == 'danger') {

							primary = button;
						}
					});

					if (!primary) {

						angular.forEach($scope.buttons, function (button) {

							if (button.color == 'default' || !button.color) {

								primary = button;
							}
						});
					}

					// Call the handler for the button
					if (primary) {

						_buttonClicked(primary);
					}
				};

				var _handleEscapeKey = function () {

					// Close the modal
					popupManager.pop();
				};

				// When a key is pressed in the popup
				$scope.onKeyDown = function(event) {

					// If the target isn't a textarea or selectize element, send to keypress handlers

					var textarea = (event.target.tagName === 'TEXTAREA' ? true : false);
					var selectize = event.target.parentElement.classList.contains('selectize-input');

					if (!textarea && !selectize) {
						switch (event.which) {
							case 13: 		// Enter key
								_handleEnterKey(event);
								break;

							case 27: 		// Escape key
								_handleEscapeKey(event);
								break;
						}
					}
				};



				/*
				 * View methods
				 */

				var reposition = function () {

					var header = element[0].children[0];
					var content = element[0].children[1].children[0];
					var footer = element[0].children[2];

					var naturalHeight = 0;

					if (header) {

						naturalHeight += header.offsetHeight;
					}

					if (content) {

						// Calculate content wrapper padding
						var style = $window.getComputedStyle(content, null);
						naturalHeight += parseInt(style.getPropertyValue('padding-top'));
						naturalHeight += parseInt(style.getPropertyValue('padding-bottom'));

						if (content.children.length) {

							angular.forEach(content.children, function (child) {

								naturalHeight += child.offsetHeight;
							});
						}
					}

					if (footer) {

						naturalHeight += footer.offsetHeight;
					}

					element.css('height', naturalHeight + 'px');
				};


				/*
				 * Initialisation
				 */

				var injectContent = function (response) {

					// Compile into a linking function
					var linkFn = $compile(response);

					// Link and inject scope
					var content = linkFn($scope);

					// Add to the modal
					angular.element(element[0].children[1].children[0]).append(content);
				};

				// Load content viewscript from the templateCache if
				// content-url attribute is present
				if ($scope.contentUrl) {

					var template = $templateCache.get($scope.contentUrl);	
					if (template) {
					
						injectContent(template);
					}
				}
				
				// Focus on the modal to receive keyboard interaction
				element.focus();

				// IE requires a set height on flexbox elements.
				// Detect if required and start a reposition loop
				if (Modernizr.flexboxtweener) {

					// Request end loop flag
					var _end = false;

					// Animation loop
					(function animLoop() {

						if (!_end) {

							requestAnimFrame(animLoop);
							reposition();
						}
					})();

					// Clean up loop on destroy
					$scope.$on('$destroy', function () {

						_end = true;
					});
				}
			}
		};
	}]);
