(function () {
  'use strict';
  /**
   * adk-empty-state Directive
   *
   * Wrapper for the empty state template
   *
   * Takes in:
   * - iconUrl 
   * - title 
   * - description
   * - button (optional)
   *
   * Notes:
   * 
   */

  angular
  .module('architizer.app')
  .directive('adkEmptyState', adkEmptyState);

  adkEmptyState.$inject = [];

  function adkEmptyState () {
    return {
      restrict: 'E',
      scope: {
        iconUrl: '@',
        title: '@',
        description: '@',
        button: '=',
        fullWidth: '=',
      },
      templateUrl: '/views/source/shared/adk-empty-state.html',
    };
  }
})();