(function() {
  'use strict';

  angular
  .module('architizer.app')
  .controller('SourceArchitectProductSearchDeleteModalController', SourceArchitectProductSearchDeleteModalController);

  SourceArchitectProductSearchDeleteModalController.$inject = [
    '$filter',
    '$modalInstance',
    'productRequest',
    'ProductRequest',
  ];

  function SourceArchitectProductSearchDeleteModalController (
    $filter,
    $modalInstance,
    productRequest,
    ProductRequest
    ) {

    var vm = this;

    // Data
    vm.productRequest = productRequest;
    vm.canBeDeleted = canBeDeleted();
    vm.deleting = false;

    // Functions
    vm.onDeleteClick = onDeleteClick;
    vm.onCancelClick = onCancelClick;

    ////////////////////////////////////////////////////////////////////////////////
    // Functions
    ////////////////////////////////////////////////////////////////////////////////
    /**
     * Allow search to be deleted only if search is a draft or the close date is in the past
     */
    function canBeDeleted () {
      var isClosed = $filter('inPast')(vm.productRequest.close);
      var isDraft = (vm.productRequest.workflow_status === 'R');

      return (isClosed || isDraft);
    }

    /**
     * On Delete button click
     */
    function onDeleteClick () {
      if (vm.canBeDeleted) {
        // Set the `deleting` flag to true
        vm.deleting = true;
        ProductRequest
        .delete({ id: vm.productRequest.id })
        .$promise
        .then((deletedSearch) => {
          $modalInstance.close(deletedSearch);
          // Successfully deleted. Set the `deleting` flag back to false 
          vm.deleting = false;
        })
        .catch((error) => {
          $modalInstance.dismiss(error);
          // Failed to delete. Set the `deleting` flag back to false
          vm.deleting = false;
        });
      }
    }

    /**
     * On Cancel button click
     */
    function onCancelClick () {
      $modalInstance.dismiss();
    }
  }
})();