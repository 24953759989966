
'use strict';

angular
	.module('architizer.app')
	.factory('ImageService', ['$compile', '$rootScope', function ($compile, $rootScope) {
		
		var _imageModals = [];
		 
		/**
		 * Open an image in an image modal
		 */
		 
		var _openImage = function (id, options) {
		
			var html = '<arc-image id="id" options="options"></arc-image>';
			
			// Create a new scope for the image modal
			var scope = $rootScope.$new();
			scope.id = id;
			scope.options = options;
			
			// Compile into a linking function
			var linkFn = $compile(html);
			
			// Link and inject scope
			var content = linkFn(scope);
			
			// Add the modal
			$('body').append(content);
			
			// Track the modal for removal
			_imageModals.push(content);
		};
		
		
		/**
		 * Close any open image modals
		 */
		 
		var _closeImages = function () {
		
			angular.forEach(_imageModals, function (modal) {
				
				modal.remove();
			});
		};
		
		
		/**
		 * Return service
		 */
		return {
			openImage: _openImage,
			closeImages: _closeImages
		};
	}]);