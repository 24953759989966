'use strict';

angular
  .module('architizer.app')
  .factory('Conversation', ConversationResource);

  ConversationResource.$inject = [
    '$http',
    '$resource',
    '$rootScope',
    'Config',
    'CustomEventsService',
  ];

  function ConversationResource (
    $http,
    $resource,
    $rootScope,
    Config,
    CustomEventsService
  ) {

    const {
      CONVERSATION_MESSAGE_READ_COMPLETE
    } = CustomEventsService.events;

    var Conversation = $resource(Config.apiUrlBase + 'conversations/:id', { id: '@id', per_page: 100 }, {
      query: {
        isArray: true,
        // Pagination
        interceptor: {
          response: function (response) {
            response.resource.$pagination = response.data.$pagination;
            return response.resource;
          },
        },
      },
      mine: {
        method: 'GET',
        url: Config.apiUrlBase + 'conversations/mine',
        isArray: true
      },
      update: {
        method: 'PATCH'
      },
      sendMessage: {
        method: 'POST',
        params: { id: '@id' },
        url: Config.apiUrlBase + 'conversations/:id/messages',
      },
      productResponses: {
        method: 'GET',
        params: { force: 'brand,images,creator' },
        url: Config.apiUrlBase + 'product_responses',
        isArray: true,
      },
      requestSamples: {
        method: 'POST',
        url: Config.apiUrlBase + 'conversations/:id/messages',
        transformRequest: AddMessageType,
        params: {
          id: '@id',
        }
      },
    });

    function AddMessageType (data) {
      // Add message type to message payload
      data.message_type = 'product_response_samples_requested';

      return angular.toJson(data);
    }

    /**
     * Mark conversation as read
     */
    Conversation.prototype.$markRead = function() {

      // Emit an event to say that a message was read
      $rootScope.$emit(CONVERSATION_MESSAGE_READ_COMPLETE, { messageId: this.id });

      return $http.patch(Config.apiUrlBase + 'conversations/' + this.id + '/usersettings/mine', { last_viewed: 'now' });
    };

    return Conversation;
  }
