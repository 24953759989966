(function () {
  'use strict';

  angular
  .module('architizer.app')
  .factory('ProductRequest',  ProductRequestResource);

  ProductRequestResource.$inject = [
    '$http',
    '$resource',
    '$rootScope',
    'AuthenticationService',
    'Config',
  ];

  function ProductRequestResource (
    $http,
    $resource,
    $rootScope,
    AuthenticationService,
    Config
  ) {

    let $scope = $rootScope.$new();
    let ProductRequest = resource();

    // Extend ProductRequest with Favorite/Clear Actions
    ProductRequest.prototype.$favorite = $favorite;
    ProductRequest.prototype.$clear = $clear;

    return ProductRequest;

    ////////////////////////////////////////////////////////////////////////////////////////
    // Functions
    ////////////////////////////////////////////////////////////////////////////////////////

    // Product Request $resource definition
    function resource () {
      return $resource(Config.apiUrlBase + 'product_requests/:id', { id: '@id' }, {
        createDraft: {
          method: 'POST',
          transformRequest: setDraftStatus,
        },
        updateDraft: {
          method: 'PATCH',
          transformRequest: setDraftStatus,
        },
        publishDraft: {
          method: 'PATCH',
          transformRequest: setPublishedStatus,
        },
        createPublished: {
          method: 'POST',
          transformRequest: setPublishedStatus,
        },
        updatePublished: {
          method: 'PATCH',
          transformRequest: setPublishedStatus,
        },
        query: {
          isArray: true,
          interceptor: addPaginationData(),
        },
        mine: {
          url: Config.apiUrlBase + 'product_requests/mine',
          isArray: true,
          interceptor: addPaginationData(),
        }
      }, {
        cancellable: true
      });
    }

    ////////////////////////////////////////////////////////////////////////////////
    // interceptor Functions
    ////////////////////////////////////////////////////////////////////////////////

    // Helper to add $pagination data to response
    function addPaginationData () {
      return {
        response: (response) => {
          response.resource.$pagination = response.data.$pagination;
          return response.resource;
        }
      };
    }

    ////////////////////////////////////////////////////////////////////////////////
    // transformRequest Functions
    ////////////////////////////////////////////////////////////////////////////////

    // transformRequest function to set draft status on Product Request
    function setDraftStatus (data) {
      data.workflow_status = 'R'; // "Draft" workflow_status
      return angular.toJson(data);
    }

    // transformRequest function to set published status on Product Request
    function setPublishedStatus (data) {
      data.workflow_status = 'O'; // "Published" workflow_status
      return angular.toJson(data);
    }

    ////////////////////////////////////////////////////////////////////////////////
    // Favorite/Clear Actions
    ////////////////////////////////////////////////////////////////////////////////

    function $favorite () {
      // Add this ID to the user's favorites (for the view)
      AuthenticationService.user.product_request_ids_favorites.push(this.id);

      // Update the API
      return $http.post(markingUrl(this), { status: 'F' });
    }

    function $clear () {
      // Remove this ID from the user's favorites (for the view)
      let i = AuthenticationService.user.product_request_ids_favorites.indexOf(this.id);
      AuthenticationService.user.product_request_ids_favorites.splice(i, 1);

      // Update the API
      return $http.post(markingUrl(this), { status: 'N' });
    }

    // Helper to get the API URL for setting marking on a Product Request instance
    function markingUrl (instance) {
      return Config.apiUrlBase + 'product_requests/' + instance.id + '/marking';
    }
  }
})();
