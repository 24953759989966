
'use strict';

angular
	.module('architizer.app')
	.filter('taxonomyIcon', [function () {

    /**
     * Get a string representing a material design icon for a taxonomy
     *
     * @param slug The slug of the fist-level taxonomy to return an icon for.
     */

		return function (slug) {

      if (!slug || !angular.isString(slug)) {

        return;
      }

			switch (slug) {

				case 'lighting':
					return 'lightbulb_outline';

				case 'building-products':
					return 'business';

				case 'finishes':
					return 'format_paint';

        case 'raw-materials':
          return 'widgets';

        case 'furniture':
          return 'event_seat';

        case 'plumbing':
          return 'hot_tub';

        case 'equipment':
          return 'local_laundry_service';

        case 'accessories':
          return 'flag';
			}
		};
	}]);
