'use strict';

angular
	.module('ui')
	.directive('uiFlash', [function () {

		return {
			replace: true,
			restrict: 'E',
			transclude: false,
			templateUrl: '/views/uikit/ui-flash.html',
			scope: {
				message: '=',				// The text to be displayed in the flash message
				type: '='						// The type of message (success/error)
			},
			link: function () {}
		};
	}]);