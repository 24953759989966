(function() {
  'use strict';

  angular
  .module('architizer.app')
  .controller('SourceBpmConversationContentController', SourceBpmConversationContentController);

  SourceBpmConversationContentController.$inject = [
    '$filter',
    '$modal',
    '$rootScope',
    '$scope',
    'conversation',
    'ConversationMessagesService',
    'CustomEventsService',
    'messages',
    'productRequest',
    'ProductResponse',
    'user',
  ];

  function SourceBpmConversationContentController (
    $filter,
    $modal,
    $rootScope,
    $scope,
    conversation,
    ConversationMessagesService,
    CustomEventsService,
    messages,
    productRequest,
    ProductResponse,
    user
  ) {

    var vm = this;

    const {
      SEND_PRODUCT_MODAL_SHOW,
      CONVERSATION_MESSAGE_SEND_COMPLETE
    } = CustomEventsService.events;

    // Data
    vm.messages = messages;
    vm.conversation = conversation;
    vm.mySide = 'B';
    vm.messageGroups = [];
    vm.noMoreMessages = false;
    vm.$filter = $filter;
    vm.systemGeneratedMessage = null;
    vm.productRequest = productRequest;
    vm.user = user;
    vm.rootScopeUnbindRefs = [];

    // Functions
    vm.getNextPage = getNextPage;
    vm.onViewProductClick = onViewProductClick;
    vm.hasRecommenderId = hasRecommenderId;
    vm.getStandardReasonsHelperText = getStandardReasonsHelperText;
    vm.getOtherReasonsHelperText = getOtherReasonsHelperText;
    vm.hasStandardReasons = hasStandardReasons;
    vm.hasOtherReasons = hasOtherReasons;
    
    // Initialize
    init();

    ////////////////////////////////////////////////////////////////////////////////
    // Functions
    ////////////////////////////////////////////////////////////////////////////////

    /**
     * Initialization
     */
    function init () {
      bindEventListeners()

      // Mark conversation as read
      vm.conversation.$markRead();
      vm.conversation.my_last_viewed = moment().format();

      // Make message groups
      processMessages();

      // Check if we should show system generated message
      if (!conversation.my_can_add_message) {
        vm.systemGeneratedMessage = {
          content: 'You\'ll be able to respond to an architect once they send you a direct message.',
          action: {
            label: 'Send Another Product',
            onClick: function onSendAnotherProductClick () { $rootScope.$broadcast(SEND_PRODUCT_MODAL_SHOW); },
          }
        };
      }
    }

    /**
     * Function that unbinds event listeners when controller $scope is destroyed.
     */
    function unbindEventListeners () {
      vm.rootScopeUnbindRefs.forEach(unbindFn => unbindFn());
    }

    /**
     * Function that encapsulate event listeners for this controller.
     */
    function bindEventListeners () {
      // Set up listener for when user sends a new message in the send-message UI
      const UNBIND_CONVERSATION_MESSAGE_SEND_COMPLETE = $rootScope.$on(CONVERSATION_MESSAGE_SEND_COMPLETE, function (event, data) {
        vm.messages.unshift(data.message);
        processMessages();
      });

      // Hold a reference to `unbind` functions for all $rootScope events
      vm.rootScopeUnbindRefs.push(UNBIND_CONVERSATION_MESSAGE_SEND_COMPLETE);

      // Unbind all $rootScope event listeners on controller $destroy event
      $scope.$on('$destroy', unbindEventListeners);
    }


    /**
     * Process Messages
     */
    function processMessages () {
      ConversationMessagesService.makeMessageGroups(vm.messages)
      .then(function (messageGroups) {
        vm.messageGroups = messageGroups;
      });
    }

    /**
     * Get next page of messages
     */
    function getNextPage () {
      // Don't try to get more messages if no more messages flag is set
      if (vm.noMoreMessages === true) { return; }

      ConversationMessagesService.getNextPage(vm.messages)
      .then(function (messages) {

        // Set "no more messages" flag and return if the message response is empty
        if (!messages.length) { vm.noMoreMessages = true; return; }

        // Add messages to array
        vm.messages = vm.messages.concat(messages);

        // Regenerate the message groups
        ConversationMessagesService.makeMessageGroups(vm.messages)
        .then(function (messageGroups) {
          vm.messageGroups = messageGroups;
        });
      });
    }

    /**
     * On View Product click
     */
    function onViewProductClick (message) {
      ProductResponse
      .get({
        id: message.structured_data.objects[0].id,
        force: 'brand,images,creator',
      })
      .$promise
      .then(function (productResponse) {
        showProductResponseContentModal(productResponse);
      });
    }

    /**
     * Show Product Reponse Content modal
     */
    function showProductResponseContentModal (productResponse) {

      // Create and open modal
      $modal.open({
        templateUrl: '/views/source/shared/product-response.content.modal.html',
        controller: 'SourceProductResponseModalController',
        controllerAs: 'ProductResponseModalCtrl',
        resolve: {
          project: () => null,
          productRequest: () => vm.productRequest,
          productResponse: () => productResponse,
          mySide: () => vm.mySide,
          user: () => vm.user,
        }
      });
    }

    /**
     * Function to check if the product response has a recommender_id
     */
    function hasRecommenderId (message) {
      var recommenderIdExists = message.structured_data.objects[0].recommender_id;
      if(recommenderIdExists){
        return true;
      }

      return false;
    }

    /**
     * Function to get Dismissed Product standard reasons helper text (based on number of standard reasons)
     * 
     * Example: 
     *  1 standard reason: "This product was too expensive."
     *  2 standard reasons: "This product was too expensive and didn't meet my specification requirements."
     *  3 standard reasons: "This product was too expensive, didn't meet my specification requirements, 
     *  and didn't match the specifications I was looking for."
     * 
     */
    function getStandardReasonsHelperText (message) {
      var standardReasons = message.structured_data.properties.reasons;

      if(standardReasons) {
        if(standardReasons.length === 1) {
          return `This product ${ standardReasons[0].toLowerCase().replace(/ i /g, ' I ') }.`;
        } else if (standardReasons.length === 2) {
          return `This product ${ standardReasons[0].toLowerCase().replace(/ i /g, ' I ') }
          and ${ standardReasons[1].toLowerCase().replace(/ i /g, ' I ') }.`;
        } else if(standardReasons.length === 3) {
          return `This product ${ standardReasons[0].toLowerCase().replace(/ i /g, ' I ') },
          ${ standardReasons[1].toLowerCase().replace(/ i /g, ' I ') }, and
          ${ standardReasons[2].toLowerCase().replace(/ i /g, ' I ') }.`;
        }
      }
    }

    /**
     * Function to get Dismissed Product Other / custom reasons and format them for display 
     */
     function getOtherReasonsHelperText (message) {
       var otherReasons = message.structured_data.properties.message.toLowerCase();
       // If there is more than one reason separated by a newline, split them into an array
       var otherReasonsColl = otherReasons.split('\n');
       var lastChar;
       var helperText;

       if(otherReasons) {
         // For custom response with just one reason
         if(otherReasonsColl.length === 1) {

           lastChar = otherReasons.slice(-1);
           // Capitalize first letter
           helperText = otherReasons.slice(0, 1).toUpperCase()  + otherReasons.slice(1);
           return (lastChar === '.') ? `<p class="mb-0">${ helperText }</p>` : `<p class="mb-0">${ helperText }.</p>`;

         // For custom response with more than one reason
         } else {
           var formattedReasons = [];
           // Remove empty strings and whitespaces from array
           otherReasonsColl = otherReasonsColl.filter(reason => reason.trim() !== '' );

           // Capitalize first letter, add fullstop and line break after each reason
           angular.forEach(otherReasonsColl, (reason) => {
               var formattedReason = reason.slice(0, 1).toUpperCase() + reason.slice(1);
               lastChar = formattedReason.slice(-1);
               
               if(lastChar !== '.') { formattedReason += '.'; }
               formattedReasons.push(formattedReason + '<br>');
           });

           helperText = formattedReasons.join('<br>');
           return `<p class="mb-0">${ helperText }</p>`;
         }
       }
     }
     
    /**
     * Function to check if a message has Dismissed Product standard reasons
     */
    function hasStandardReasons (message) {
      var standardReason = message.structured_data.properties.reasons.length;
      return standardReason ? true : false;
    }

    /**
     * Function to check if a message has Dismissed Product Other / custom reasons
     */
    function hasOtherReasons (message) {
       var otherReason = message.structured_data.properties.message.length;
       return otherReason ? true : false; 
    }
  }
})();
