(function() {
  'use strict';

  angular
  .module('architizer.app')
  .controller('SourceArchitectProjectConversationActionBarController', SourceArchitectProjectConversationActionBarController);

  SourceArchitectProjectConversationActionBarController.$inject = [
    '$modal',
    '$transitions',
    'conversation',
    'CustomDataService',
    'productRequest',
  ];

  function SourceArchitectProjectConversationActionBarController (
    $modal,
    $transitions,
    conversation,
    CustomDataService,
    productRequest
  ) {

    var vm = this;

    // Data
    vm.conversation = conversation;
    vm.productRequest = productRequest;

    // Functions
    vm.onMoreClick = onMoreClick;

    // Initialize
    init();

    ////////////////////////////////////////////////////////////////////////////////
    // Functions
    ////////////////////////////////////////////////////////////////////////////////

    /**
     * Initialization
     */
    function init () {

      // Prepare structured data points for view in request info modals
      CustomDataService.prepareForDisplay(angular.fromJson(vm.productRequest.structured_data));
    }

    /**
     * On "More" link click in action bar
     */
    function onMoreClick () {

      // Create and open modal
      $modal.open({
        templateUrl: '/views/source/shared/product-search.content.modal.html',
        controller: 'SourceProductSearchModalController',
        controllerAs: 'ProductSearchModalCtrl',
        resolve: {
          productRequest: () => vm.productRequest,
        }
      });
    }
  }
})();