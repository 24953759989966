
'use strict';

angular
	.module('architizer.app')
	.filter('priceRange', [function () {

		return function (input, full) {

			switch (input) {

				case '$':
					return full ? '<span class="adk priceRange"><span class="gray-200"><span class="black">$</span>$$</span>' : '$';

				case '$$':
					return full ? '<span class="adk priceRange"><span class="gray-200"><span class="black">$$</span>$</span>' :  '$$';

				case '$$$':
					return full ? '<span class="adk priceRange"><span class="gray-200"><span class="black">$$$</span></span>' :  '$$$';
			}
		};
	}]);
