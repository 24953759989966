(function() {
  'use strict';

  class ObjectActionTracker {
    constructor($rootScope, $transitions, $window, AuthenticationService) {
      this.$rootScope = $rootScope;
      this.$transitions = $transitions;
      this.analytics = $window.analytics;
      this.AuthenticationService = AuthenticationService;
    }

    start() {
      this.bindTransitions();
      this.bindListeners();
    }

    bindTransitions() {
      const transitions = [
        { entering: 'source.app.arc.projects.new', event: 'project_started' },
        { entering: 'source.app.arc.project.requests.new', event: 'product_request_started' },
        { entering: 'source.opportunities.opportunity', event: 'opportunity_viewed' },
      ];

      for (let transition of transitions) {
        this.$transitions.onEnter({ entering: transition.entering }, (trans, state) => {
          var stateParams = trans.injector().get('$stateParams');
          var props = this.transitionProperties(state, stateParams);
          this.analytics.track(this.eventText(transition.event), props);
        });
      }
    }

    bindListeners() {
      const objects = [
        'product_response',
        'sample_request',
        'project',
      ];

      const actions = [
        'created',
        'updated',
        'published',
        'schedule_requested',
        'opened',
        'sent',
        'product_url_clicked',
      ];

      // Get the current user and create event listeners
      this.AuthenticationService.getCurrentUser().then((user) => {

        // Set up the context/traits object from the current user
        const context = {
          traits: {
            firstName: user.first_name,
            lastName: user.last_name,
            email: user.email,
            id: user.id,
            username: user.email,
          }
        };

        // Create event listeners for each object/action combination
        for (let object of objects) {
          for (let action of actions) {
            let event = object + '_' + action;
            this.$rootScope.$on(event, (e, props) => this.analytics.track(this.eventText(event), props, { context: context } ));
          }
        }

      });
    }

    eventText(event) {
      return event.split('_').join(' ');
    }

    transitionProperties(state, stateParams) {
      var props;
      switch (state.name) {
        case 'source.opportunities.opportunity':
          props = { 'product_request_id': stateParams.request };
          break;
        case 'source.app.arc.project.requests.new':
          props = { 'project_id': stateParams.project };
          break;
        default:
          props = {};
      }
      return props;
    }
  }

  angular
    .module('architizer.app')
    .run([
      '$rootScope',
      '$transitions',
      '$window',
      'AuthenticationService',
      function ($rootScope, $transitions, $window, AuthenticationService) {
        const tracker = new ObjectActionTracker($rootScope, $transitions, $window, AuthenticationService);
        tracker.start();
      }
    ]);
}());
