'use strict';

angular
	.module('architizer.app')
	.directive('newRoleRequest', ['$q', 'RoleRequest', 'AuthenticationService', function ($q, RoleRequest, authentication) {

		return {
			restrict: 'A',
			require: '?ngModel',
			link: function ($scope, element, attrs, ngModel) {

				if (!ngModel) {

					return;
				}

				ngModel.$asyncValidators.newRoleRequest = function (value) {

					var deferred = $q.defer();

					if (!value || !authentication.user) {

						deferred.resolve();
						return deferred.promise;
					}

					// Support selecting a full firm/brand or just the id
					if (angular.isObject(value)) {

						value = value.id;
					}

					// Fetch the role requests
					RoleRequest.mine(

						function (roleRequests) {

							// Prepare the params for finding a role request
							var params = {};

							if (attrs['newRoleRequest'] == 'brand') {

								params.brand_id = value;
							}

							else {

								params.firm_id = value;
							}

							// Try find a role request with this id
							if (_.findWhere(roleRequests, params)) {

								deferred.reject();
							}

							else {

								deferred.resolve();
							}
						},
						function () {

							deferred.reject();
						}
					);

					return deferred.promise;
				};
			}
		};
	}]);
