(function () {
  'use strict';

  angular
  .module('architizer.app')
  .config(['$stateProvider', function ($stateProvider) {

    $stateProvider
    .state({
      name: 'settingsBrand',
      url: '/dashboard/brand/:slug/{path:.*}',
      views: {
        'root@': {
          controller: RedirectToDjangoBrandEditDetails
        }
      },
      resolve: {
        user: ResolveUser,
        brand: ResolveBrand,
      }
    });

    RedirectToDjangoBrandEditDetails.$inject = ['$state', '$transition$', '$window', 'AuthorizationService', 'brand', 'user',];
    function RedirectToDjangoBrandEditDetails ($state, $transition$, $window, AuthorizationService, brand, user) {
      let permission = AuthorizationService.userHasPermissionForBrand(
        user,
        brand.id,
        [
          AuthorizationService.BRAND_PERMISSIONS.CAN_CHANGE_BRAND_INFO,
          AuthorizationService.BRAND_PERMISSIONS.CAN_CHANGE_BRAND_USERS,
          AuthorizationService.BRAND_PERMISSIONS.CAN_VIEW_BRAND_USERS
        ]
      );
      console.log(permission);

      if (permission) {
        $window.location.href = '/brands/' + $transition$.params().slug + '/edit/details';
      } else {
        $state.go('404');
      }
    }

    ResolveUser.$inject = ['AuthenticationService'];
    function ResolveUser (AuthenticationService) {
      return AuthenticationService.getCurrentUser()
      .then(function (user) { return user; })
      .catch(function () { return null; });
    }

    ResolveBrand.$inject = ['$transition$', 'Brand'];
    function ResolveBrand ($transition$, Brand) {
      return Brand.get({
        id: $transition$.params().slug,
      }).$promise;
    }
  }]);
})();