(function() {
  'use strict';

  angular
  .module('architizer.app')
  .factory('ProjectService', ProjectService);

  ProjectService.$inject = [
  ];

  function ProjectService () {

    var service = this;

    // Field Options
    service.phases = phases();
    service.budgets = budgets();
    service.buildingSizes = buildingSizes();

    // Functions
    service.getPhaseByValue = getPhaseByValue;

    return service;

    ////////////////////////////////////////////////////////////////////////////////
    // Functions
    ////////////////////////////////////////////////////////////////////////////////

    /**
     * Get phase by value
     */
    function getPhaseByValue (value) {
      var phase;

      angular.forEach(service.phases, function (p) {
        if (p.value === value) {
          phase = p;
        }
      });

      return phase;
    }

    ////////////////////////////////////////////////////////////////////////////////
    // Field Options
    //
    // For ui-select fields, etc.
    ////////////////////////////////////////////////////////////////////////////////

    /**
     * Project Phases
     */
    function phases () {
      return [
        {
          label: 'Schematic Design ',
          value: 'schematic-design'
        },
        {
          label: 'Design Development',
          value: 'design-development'
        },
        {
          label: 'Construction Documents',
          value: 'construction-documents'
        },
        {
          label: 'Construction Administration ',
          value: 'construction-administration'
        },
        {
          label: 'Construction',
          value: 'under-construction'
        }
      ];
    }

    /**
     * Project Budgets
     */
    function budgets () {
      return [
        {
          'label': '0 - 10K',
          'value': 1,
        }, {
          'label': '10K - 50K',
          'value': 10000,
        }, {
          'label': '50K - 100K',
          'value': 50000,
        }, {
          'label': '100K - 500K',
          'value': 100000,
        }, {
          'label': '500K - 1M',
          'value': 500000,
        }, {
          'label': '1M - 5M',
          'value': 1000000,
        }, {
          'label': '5M - 10M',
          'value': 5000000,
        }, {
          'label': '10M - 50M',
          'value': 10000000,
        }, {
          'label': '50M - 100M',
          'value': 50000000,
        }, {
          'label': '100M +',
          'value': 100000000,
        }, {
          'label': 'Undisclosed',
          'value': 0,
        }, {
          'label': 'Unknown',
          'value': -2,
        }
      ];
    }

    /**
     * Project Building Sizes
     */
    function buildingSizes () {
      return [
        {
          'label': '0 sqft - 1000 sqft',
          'value': 'sqft_0_1',
        }, {
          'label': '1000 sqft - 3000 sqft',
          'value': 'sqft_1_3',
        }, {
          'label': '3000 sqft - 5000 sqft',
          'value': 'sqft_3_5',
        }, {
          'label': '5000 sqft - 10,000 sqft',
          'value': 'sqft_5_10',
        }, {
          'label': '10,000 sqft - 25,000 sqft',
          'value': 'sqft_10_25',
        }, {
          'label': '25,000 sqft - 100,000 sqft',
          'value': 'sqft_25_100',
        }, {
          'label': '100,000 sqft - 300,000 sqft',
          'value': 'sqft_100_300',
        }, {
          'label': '300,000 sqft - 500,000 sqft',
          'value': 'sqft_300_500',
        }, {
          'label': '500,000 sqft - 1,000,000 sqft',
          'value': 'sqft_500_1000',
        }, {
          'label': '1,000,000 +',
          'value': 'sqft_1000',
        }
      ]
    }
  }
})();
