'use strict';

angular
	.module('ui')
	.directive('uiTags', ['$parse', function ($parse) {

		return {
			replace: true,
			restrict: 'E',
			templateUrl: '/views/uikit/ui-tags.html',
			scope: {
				'tags': '=',
				'click': '=',
				'remove': '='
			},
			link: function ($scope, element, attrs) {

				$scope.hasClick = !!$scope.click;
				$scope.hasRemove = !!$scope.remove;
			}
		};
	}]);