'use strict';

angular
	.module('architizer.app')
	.filter('highlight', function () {

		return function (input, query) {
			if (!input) {
				return;
			}

			var output = input.replace(new RegExp(query, 'gi'), '<span class="highlight">$&</span>', 'g');
			return output;
		};
	});
