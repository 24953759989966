'use strict';

angular
	.module('ui')
	.directive('uiCheckbox', ['$timeout', function ($timeout) {

		return {
			replace: true,
			restrict: 'E',
			transclude: true,
			templateUrl: '/views/uikit/ui-checkbox.html',
			require: [
				'?ngModel',
				'?ngDisabled'
			],
			scope: {
				ngModel: '=',
				ngDisabled: '='
			},
			link: function ($scope, element, attrs, ngModel) {

				/*
				 * View params
				 */

				// Create a random id to link checkbox to label
				$scope.checkId = Math.random().toString(36).substring(5);

				/*
				 * UI interaction
				 */


				/*
				 * Data methods
				 */

				// end here if no ng-model
				if (!ngModel) {

					return;
				}


			}
		};
	}]);