(function() {
  'use strict';

  angular
  .module('architizer.app')
  .filter('BPMResponseMarkingLabel', [function () {

    /**
     * Turns the "marking" field in a response object into a UI-friendly string (BPM view)
     *
     * "F" --> "Shortlisted"
     * "D" --> "Feedback"
     * "N" --> "In Review"
     */

    return function (marking) {

      if (!marking) {
        return null;
      }

      switch (marking) {
        case 'F':
        return 'Shortlisted';

        case 'D':
        return 'Feedback';

        case 'N': 
        return 'In Review';

        default:
        return null;
      }
    };
  }])

  .filter('architectResponseMarkingLabel', [function () {
    /**
     * Turns the "marking" field in a response object into a UI-friendly string (Architect view)
     *
     * "F" --> "Shortlisted"
     * "D" --> "Dismissed"
     * "N" --> "In Review"
     */

    return function (marking) {

        if (!marking) {
          return null;
        }

        switch (marking) {
          case 'F':
          return 'Shortlisted';

          case 'D':
          return 'Dismissed';

          case 'N':
          return 'In Review';

          default:
          return null;
        }
      };
  }]);
})();
