(function() {
  'use strict';

  angular
  .module('architizer.app')
  .controller('LoggedProductModalController', LoggedProductModalController);

  LoggedProductModalController.$inject = [
    '$modalInstance',
    '$timeout',
    'Attribution',
    'CustomDataService',
    'FileService',
    'LudwigService',
    'mode',
    'productRequest',
    'productResponse',
    'ProductResponse',
  ];

  function LoggedProductModalController (
    $modalInstance,
    $timeout,
    Attribution,
    CustomDataService,
    FileService,
    LudwigService,
    mode,
    productRequest,
    productResponse,
    ProductResponse
  ) {

    var vm = this;

    // Data
    vm.mode = mode;
    vm.models = models();
    vm.productRequest = productRequest;
    vm.productResponse = angular.copy(productResponse);
    vm.form = {};
    vm.elementConfig = elementConfig();
    vm.showAdvancedControls = false;
    vm.saving = false;

    // Functions
    vm.FileService = FileService;
    vm.onDataPointClick = onDataPointClick;
    vm.onRemoveDataPointClick = onRemoveDataPointClick;
    vm.isDataPointFieldRequired = isDataPointFieldRequired;
    vm.onSubmitClick = onSubmitClick;
    vm.onCancelClick = onCancelClick;
    vm.hideDataPoint = hideDataPoint;
    vm.areBrandFieldsOpen = false;

    // Initialize
    init();

    ////////////////////////////////////////////////////////////////////////////////
    // Functions
    ////////////////////////////////////////////////////////////////////////////////

    /**
     * Initialization
     */
     function init () {
        if(vm.mode !== 'new'){
          initWithResponse(vm.productResponse);
        }
     }

    // Models for new product responses
    function models () {
        return {
        brandName: '',
        brandId: '',
        brandUrl: CustomDataService.dataPoints.brandUrl(),
        productName: CustomDataService.dataPoints.productName(),
        productUrl: CustomDataService.dataPoints.productUrl(),
        description: CustomDataService.dataPoints.description(),
        files: [],
        structuredData: [],
        selectedDataPoints: [],
        internalNotes: CustomDataService.dataPoints.internalNotes(),
        productType: CustomDataService.dataPoints.productType(),
      };
    }

    // Brand Config Information
    function elementConfig () {
      return {
        brandSuggestions: [],
        brandSuggestionsConfig: getBrandSuggestionsConfig(),
        loggedProductDataPoints: CustomDataService.getLoggedProductDataPoints(),
      };

      // Checks for mode ('edit'/'new') to return the correct brand suggestions config object
      function getBrandSuggestionsConfig () {
        if (vm.mode === 'new') {
          return newBrandSuggestionsConfig();
        } else {
          return existingBrandSuggestionsConfig();
        }
      }

      // Brand Suggestions Config (New Product Response)
      function newBrandSuggestionsConfig () {
        return {
          create: createNewBrand,
          createOnBlur: false,
          selectOnTab: false,
          maxItems: 1,
          highlight: false,
          placeholder: 'Enter brand name',
          load: getBrandSuggestions,
          loadThrottle: 300,
          loadingClass: 'loading',
          labelField: 'term',
          valueField: 'id',
          searchField: ['term'],
          openOnFocus: false,
          closeAfterSelect: true,
          render: {
            option: newBrandOptionTemplate,
            item: newBrandDisplayTemplate,
            option_create: brandCreateTemplate,
          },
        };
      }

      // Brand Suggestions Config (Existing Product Response)
      function existingBrandSuggestionsConfig () {
        return {
          create: false,
          createOnBlur: false,
          selectOnTab: false,
          maxItems: 1,
          highlight: false,
          placeholder: 'Enter brand name',
          load: getBrandSuggestions,
          loadThrottle: 300,
          loadingClass: 'loading',
          labelField: 'term',
          valueField: 'id',
          searchField: ['term'],
          openOnFocus: false,
          closeAfterSelect: false,
          render: {
            option: existingBrandTemplate,
            item: existingBrandTemplate,
          },
        };
      }

     // Function to create a new brand using user input
     function createNewBrand (input) {
        var newBrand = {
          term: input,
          id: 'newBrand',
        };
        vm.models.brandName = input;
        vm.areBrandFieldsOpen = true;
        return newBrand;
      }

      // Function to get brand name suggestions from Ludwig API
      function getBrandSuggestions (query, callback) {
        if (!query.length) {
          vm.elementConfig.brandSuggestions = [];
          callback();
          return;
        }

        LudwigService
        .getBrandSuggestions(query)
        .then((data) => {
          vm.elementConfig.brandSuggestions = data;
          callback(data);
        })
        .catch(() => {
          vm.elementConfig.brandSuggestions = [];
          callback();
        });
      }

      // Brand Option Dropdown Template (New Product Response)
      function newBrandOptionTemplate (item, escape) {
        var verified, brand_name, domain, responses, pseudotiers;
        brand_name = escape(item.term) + '';

        // For new brands
        if(!item.payload) {
         return '<div>' + brand_name + '<div>';
        }
        // For existing brands
        else {
          verified = (item.payload.source_verified ? '<span class="verified">+</span>' : '');
          domain = '&ensp;<span><a target="_blank" href="http://'+ escape(item.payload.domain) + '">' + escape(item.payload.domain) + '</a></span>';
          responses = (item.payload.product_response_count ? '<span class="shrink columns light-gray pr-0">' + escape(item.payload.product_response_count) + ' Responses</span>' : '');
          pseudotiers = (item.payload.pseudotiers ? '<span class="columns light-gray ellipsis">' + escape(item.payload.pseudotiers) + '</span>' : '');
          return '<div class="row"><div class="columns"><div class="row"><div class="columns">' + verified + brand_name + domain + '</div></div><div class="row meta">' + responses + pseudotiers + '</div></div></div>';
        }
      }

      // Brand Display Template (New Product Response)
      function newBrandDisplayTemplate (item, escape) {
        var verified, brand_name, domain;
        brand_name = escape(item.term) + '';

        // For new brands
        if(!item.payload) {
          vm.areBrandFieldsOpen = true;
          return '<div>' + brand_name + '<div>';
        }
        // For existing brands
          else {
          vm.areBrandFieldsOpen = false;
          setBrandUrl(item.payload.domain);
          verified = (item.payload.source_verified ? '<span class="row align-middle pl-s"><span class="verified pr-xxs">+</span>' : '');
          domain = '&ensp;<a target="_blank" href="http://'+ escape(item.payload.domain) + '">' + escape(item.payload.domain) + '</a></span>';
          return '<div>' + verified + brand_name + domain + '</div>';
        }

        // Function to set vm.models.brandUrl to the input URL
        function setBrandUrl (url) {
          vm.models.brandUrl.fields[0].value = url;
          return url;
        }
      }

      // Brand Create Template (New Product Response)
      function brandCreateTemplate (item, escape) {
        var brand_name = escape(item.input);
        return '<div class="create blue-500" style="font-weight: normal;">Create brand "' + brand_name + '"...</div>';
      }

      // Brand Display and Dropdown Template (Existing Product Response)
      function existingBrandTemplate (item, escape) {
        var verified, brand_name, domain;

        verified = (item.source_verified ? '<span class="row align-middle pl-s"><span class="verified pr-xxs">+</span>' : '');
        brand_name = escape(item.name) + '';
        domain = '&ensp;<a target="_blank" href="http://'+ escape(item.website) + '">' + escape(item.website) + '</a></span>';
        return '<div>' + verified + brand_name + domain + '</div>';
      }
    }

    /**
     * On click of Optional Criteria blue buttons
     */
    function onDataPointClick (dataPoint) {

      // Remove the data point from the clickable optional criteria list
      var index = vm.elementConfig.loggedProductDataPoints.indexOf(dataPoint);
      vm.elementConfig.loggedProductDataPoints.splice(index, 1);

      // Add it to the custom criteria list
      vm.models.selectedDataPoints.push(dataPoint);
    }

    /**
     * On click of Remove button on optional criteria datapoint fields
     */
    function onRemoveDataPointClick (dataPoint) {

      // Remove the data point from the custom criteria list
      var index = vm.models.selectedDataPoints.indexOf(dataPoint);
      vm.models.selectedDataPoints.splice(index, 1);

      // Add it to the blue buttons
      vm.elementConfig.loggedProductDataPoints.push(dataPoint);
    }

    /**
     * Check whether an optional criteria datapoint field should validate as "required"
     */
    function isDataPointFieldRequired (dataPoint) {
      var formSubmitted = vm.form.$submitted;
      var allFieldsAreRequired = dataPoint.requireAllFields;

      if (formSubmitted && allFieldsAreRequired) {
        var fieldsWithValues = dataPoint.fields.filter((field) => !!field.value);
        return fieldsWithValues.length;
      } else {
        return false;
      }
    }

    /**
     * On Submit button click
     */
    function onSubmitClick () {

      vm.form.$setSubmitted(true);

      // Check after a timeout so the form's validity will be properly updated
      $timeout(() => {

        // Invalid form
        if (!vm.form.$valid) {
          // Find the first element with an error
          var errorElement = angular.element(document.getElementById(vm.form.$error[Object.keys(vm.form.$error)[0]][0].$name));
          // Find the modal element
          var modalElement = angular.element('div.reveal-overlay');

          // Tell the modal element to scroll to the error element
          modalElement.scrollToElement(errorElement, 150, 250);
          return;
        }

        // Valid form
        if (vm.form.$valid) {
          var details = [];
          // Push each of the permanent form fields into details array
          details.push(vm.models.brandUrl);
          details.push(vm.models.productName);
          details.push(vm.models.productUrl);
          details.push(vm.models.internalNotes);
          details.push(vm.models.productType);
          details.push(vm.models.description);

          // Flatten the optional criteria data points into details array
          angular.forEach(vm.models.selectedDataPoints, (dataPoint) => {
            // Convert Lead Time from weeks to days (stored on API in days) before pushing it to the details array
            if(dataPoint.name === 'leadTime'){
              leadTimeInDays(dataPoint);
            }

            // Convert Price from string to number format
            if(dataPoint.name === 'price') {
              convertPriceToNumber(dataPoint);
            }

            // Don't push the fields we already pushed above (these are always displayed on the form)
            if (!['productUrl', 'internalNotes', 'productType'].includes(dataPoint.name)) {
              details.push(dataPoint);
            }
          });

          // Make a product response
          var productResponse = new ProductResponse();

          // Fields to exclude from structured_data/details
          var excluded = [];

          // For each field in each dataPoint on the form
          angular.forEach(details, (dataPoint) => {
            angular.forEach(dataPoint.fields, (field) => {

              // Push the value onto the appropriate productResponse model field
              if (field.apiField) {
                productResponse[field.apiField] = field.value;

                // Exclude the dataPoint from being added to the structured_data array
                if (excluded.indexOf(dataPoint) === -1) {
                  excluded.push(dataPoint);
                }
              }
            });
          });

          // Remove the excluded datapoints from the details array
          details = _.difference(details, excluded);

          // Set price_currency
          productResponse.price_currency = 'USD';

          productResponse.brandName = vm.models.brandName;
          productResponse.brandUrl = vm.models.brandUrl.fields[0].value;
          productResponse.brand_id = parseInt(vm.models.brandId);
          productResponse.product_request_id = vm.productRequest.id;
          productResponse.structured_data = CustomDataService.prepareForAPI(details);
          productResponse.media_data = vm.models.files;
          productResponse.media_data_includes_existing = true; // This flag tells the API that media_data will include existing MediaItemAttributions for this object (required)

          // Save the Product Response
          vm.saving = true;
          save(productResponse);
        }

        // Helper function to save product response on the API
        function save (response) {
          if(vm.mode === 'new') {
            response.$createLoggedProduct(() =>{
              vm.saving = false;
              $modalInstance.close();
            });
          } else {
            // Add data from existing product response
            response.id = vm.productResponse.id;
            response.creator_id = vm.productResponse.creator_id;

            response.$updateLoggedProduct(() => {
              vm.saving = false;
              $modalInstance.close();
            });
          }
        }

        // Helper function to translate Lead Time from weeks to days (stored on API in days)
         function leadTimeInDays (leadTimeDataPoint) {
          angular.forEach(leadTimeDataPoint.fields, (field) => field.value = (7 * field.value));
          return leadTimeDataPoint;
        }

        // Helper function to translate Price from string format to number
        function convertPriceToNumber (priceDataPoint) {
          angular.forEach(priceDataPoint.fields, (field) => {
            // We only want the first two price data point fields (price_low and price_high)
            if(field.name !== 'currency') {
              // If price field is empty, set it to null
              if(field.value === '') {
                field.value = null;
              } else {
                // if price field is not empty, convert string input to number format
                field.value = parseInt(field.value);
              }
            }
          });
          return priceDataPoint;
        }
      });
    }

     /**
     * Initialize the form for editing an existing product response and populate fields with existing data
     *
     */
    function initWithResponse (productResponse) {

      // Brand Name
      formatBrandForSelectize(productResponse);
      // Brand Url
      getBrandUrl(productResponse);
      // Product Name
      vm.models.productName.fields[0].value = productResponse.name;
      // Product Url
      getProductUrl(productResponse);
      // Description
      vm.models.description.fields[0].value = productResponse.description;
      // Attachments
      vm.models.files = productResponse.images.gallery;
      // Prepare Structured Data Points
      prepareStructuredData(productResponse);
      // Prepare Optional Criteria
      prepareOptionalCriteria(productResponse);
      // Internal Notes
      getInternalNotes(productResponse);
      // Product Type
      getProductType(productResponse);
    }

    function prepareStructuredData (response) {
      // Extend each structured data point with the template the data was created from
      CustomDataService.extendInstancesWithTemplates(response.structured_data);
      vm.models.selectedDataPoints = response.structured_data;
    }

    function prepareOptionalCriteria (response) {
      // Make a copy of the recommended data points and reassign it at the end of the function
      var blueButtons = angular.copy(vm.elementConfig.loggedProductDataPoints);

      /**
       * Lead Time
       */
      if (response.lead_time_high && response.lead_time_low) {
        // If response has an existing lead time, splice the "Lead Time" button
         var leadTimeButton = _.find(blueButtons, { name: 'leadTime' });

         if(leadTimeButton) {
          blueButtons.splice(blueButtons.indexOf(leadTimeButton), 1);
         }

         onAddOptionalCriteriaClick(CustomDataService.dataPoints.leadTime());

         // Find the optional criteria field that we just added above for lead time, and update the value with the product response's lead time
         angular.forEach(vm.models.selectedDataPoints, function (dataPoint) {
          if (dataPoint.name === 'leadTime') {
            var leadTimeLow = _.find(dataPoint.fields, { apiField: 'lead_time_low'});
            var leadTimeHigh = _.find(dataPoint.fields, { apiField: 'lead_time_high'});

            leadTimeLow.value = leadTimeInWeeks(response.lead_time_low);
            leadTimeHigh.value = leadTimeInWeeks(response.lead_time_high);
          }
        });
      }

      // Helper function to convert lead time to weeks for display
       function leadTimeInWeeks (dataPoint) {
        return Math.floor(dataPoint / 7);
      }

      /**
       * Price
       */
      if (response.price_high && response.price_low) {
        // If response has an existing price, splice the "Price" button
         var priceButton = _.find(blueButtons, { name: 'price' });

         if(priceButton) {
          blueButtons.splice(blueButtons.indexOf(priceButton), 1);
         }

         onAddOptionalCriteriaClick(CustomDataService.dataPoints.price());

         // Find the optional criteria field that we just added above for price and update the value with the product response's price
         angular.forEach(vm.models.selectedDataPoints, function (dataPoint) {
          if (dataPoint.name === 'price') {
            var priceLow = _.find(dataPoint.fields, { apiField: 'price_low'});
            var priceHigh = _.find(dataPoint.fields, { apiField: 'price_high'});

            priceLow.value = convertPrice(response.price_low);
            priceHigh.value = convertPrice(response.price_high);
          }
        });
      }

        // Helper function to translate Price from string to number format
       function convertPrice (price) {
        if(!price) {
          return null;
        } else {
          return parseInt(price);
        }
      }

      // Go through structured data points and delete existing field critieria buttons
      angular.forEach(response.structured_data, (dataPoint) => {
        var blueButtonFound = _.findWhere(blueButtons, { name: dataPoint.name });

        // If a criteria button is found for this dataPoint, splice it
        if (blueButtonFound) {
          blueButtons.splice(blueButtons.indexOf(blueButtonFound), 1);
        }
      });

      /**
       * Remove criteria options from elementConfig.optionalCriteriaOptions that have already been selected
       */
      angular.forEach(angular.fromJson(response.structured_data), function (point) {
        angular.forEach(blueButtons, function (option) {
          if (point.name === option.name) {
            var index = blueButtons.indexOf(option);
            blueButtons.splice(index, 1);
          }
        });
      });

      // Reassign it to the copy we made at the beginning of the function
      vm.elementConfig.loggedProductDataPoints = blueButtons;
    }

    /**
     * Click for optional criteria datapoint buttons
     */
    function onAddOptionalCriteriaClick (dataPoint) {

      // Remove the data point from the clickable optional criteria list
      var index = vm.elementConfig.loggedProductDataPoints.indexOf(dataPoint);
      vm.elementConfig.loggedProductDataPoints.splice(index, 1);

      // Add it to the custom criteria list
      vm.models.selectedDataPoints.push(angular.copy(dataPoint));
    }

    /**
     * Helper function to prepare stored Brand for Selectize element
     */
    function formatBrandForSelectize (response) {

     if (response.brand_id) {
        // Make a fake Selectize item with the value "existing" to denote this value was saved previously
        var existingBrand = {
          id: response.brand_id,
          name: response.brand.name,
          website: response.brand.website,
          source_verified: response.brand.verified,
          value: 'existing',
        };

        // Push the "existing" Selectize item into the array so it will be available as an option
        vm.elementConfig.brandSuggestions.push(existingBrand);
        vm.models.brandId = response.brand_id;

        // Return "existing" as a string to set the value of the Selectize to this
        return 'existing';

      } else {
        return null;
      }
    }

    /**
     * Helper function to prepare stored Product Url field
     */
    function getProductUrl (response) {
      angular.forEach(response.structured_data, (field) => {
        if(field.name === 'productUrl') {
          vm.models.productUrl.fields[0].value = _.findWhere(productResponse.structured_data, { name: 'productUrl' }).fields[0].value;
        }
      });
    }

    /**
     * Helper function to prepare stored Brand Url field
     */
    function getBrandUrl (response) {
      angular.forEach(response.structured_data, (field) => {
        if(field.name === 'brandUrl') {
          vm.models.productUrl.fields[0].value = _.findWhere(productResponse.structured_data, { name: 'brandUrl' }).fields[0].value;
        }
      });
    }

    /**
     * Helper function to prepare stored Internal Notes field
     */
    function getInternalNotes (response) {
      angular.forEach(response.structured_data, (field) => {
        if(field.name === 'internalNotes') {
          vm.models.internalNotes.fields[0].value = _.findWhere(productResponse.structured_data, { name: 'internalNotes' }).fields[0].value;
        }
      });
    }

    /**
     * Helper function to prepare stored Product Type field
     */
    function getProductType (response) {
      angular.forEach(response.structured_data, (field) => {
        if(field.name === 'productType') {
          vm.models.productType.fields[0].value = _.findWhere(productResponse.structured_data, { name: 'productType' }).fields[0].value;
        }
      });
    }

     /**
     * Function to check for hidden data points, which should not
     * be displayed on the Product Response content modal
     */
    function hideDataPoint (structuredDataPoint) {
      var hiddenDataPoints = ['searchTool','internalNotes', 'productType', 'productUrl'];
      return hiddenDataPoints.indexOf(structuredDataPoint.name) !== -1;
    }

    /**
     * On Cancel button click
     */
    function onCancelClick () {
      $modalInstance.dismiss();
    }
  }
})();
