(function() {
  'use strict';
  
  angular
    .module('architizer.app')
    .controller('ProjectSearchInterestModalController', ProjectSearchInterestModalController);

  ProjectSearchInterestModalController.$inject = [
    '$modalInstance',
    '$state',
    'project',
  ];

  function ProjectSearchInterestModalController (
    $modalInstance,
    $state,
    project
  ) {

    var vm = this;

    // Data
    vm.project = project;

    // Functions
    vm.onCancelClick = onCancelClick;
    vm.onBackToProjectClick = onBackToProjectClick;

    ////////////////////////////////////////////////////////////////////////////////
    // Functions
    ////////////////////////////////////////////////////////////////////////////////

    /**
     * On Cancel button click, dismiss the modal
     */
    function onCancelClick () {
      $modalInstance.dismiss();
    }

    /**
     * On 'Back to Projects' button click, dismiss modal and send user back to 'Projects' view
     */
    function onBackToProjectClick () {
      $state.go('source.app.arc.projects', { inherit: false });
      $modalInstance.dismiss();
    }
  }
}());