'use strict';

angular
	.module('architizer.app')
	.factory('UrlService', ['$state', function ($state) {

		var urlService = {};

		urlService.queryString = function (params) {

			var query = [];

			angular.forEach(params, function (value, key) {

				if (typeof value === 'object') {

					value = value.join(',');
				}

				query.push(key + '=' + value);
			});

			query = query.join('&');

			return query;
		};

		// create a product search url from key/value pairs
		// of search params
		urlService.productSearchUrl = function (params) {

			// create the query string
			var query = urlService.queryString(params);

			// create the url
			return decodeURIComponent($state.href('catalog') + '?' + query);
		};

		return urlService;
	}]);