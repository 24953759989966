'use strict';

angular
.module('architizer.app')
.config(['$stateProvider', function ($stateProvider) {

  $stateProvider

  // Internal Tools: Root
  .state({
    name: 'source.internal',
    url: 'i/',
    abstract: true,
    data: {
      hideFooter: true,
      hasActionBar: true,
    },
    resolve: {
      AdminUser: ResolveAdminUser,
    }
  })

  // Internal Tools: Request Tagger
  .state({
    name: 'source.internal.requestTagger',
    url: 'request-tagger/',
    abstract: true,
    resolve: {
      actionBar: () => ({ viewTitle: 'Request Tagger', rightLinks: [] }),
    }
  })

  // Internal Tools: Request Tagger - Request
  .state({
    name: 'source.internal.requestTagger.request',
    url: '{request}/',
    views: {
      'root@': {
        templateUrl: '/views/source/internal-tools/request-tagger.html',
        controller: 'RequestTaggerController',
        controllerAs: '$ctrl',
      }
    },
    resolve: {
      productRequest: ResolveProductRequest,
      productRequestSolrDoc: ResolveProductRequestSolrDoc,  // We resolve the Solr Document for this request so we can display entity-extracted data on the form (product_type_ent_ss, for example)
    }
  });

  ResolveProductRequest.$inject = ['$state', '$transition$', 'ProductRequest'];
  function ResolveProductRequest ($state, $transition$, ProductRequest) {
    return ProductRequest.get({
      id: $transition$.params().request,
      force: 'images,structured_data,firm,creator'
    })
    .$promise
    .then(function (request) { return (request.workflow_status === 'O') ? request : $state.go('404'); })
    .catch(function () { $state.go('404'); });
  }

  ResolveProductRequestSolrDoc.$inject = ['$transition$', 'LudwigService'];
  function ResolveProductRequestSolrDoc ($transition$, LudwigService) {
    const queryParameters = {
      q: '*:*',
      fq: 'django_id:' + $transition$.params().request,
    };
    return LudwigService.getProductRequests(queryParameters)
    .then(request => request.response.docs[0])
    .catch(error => console.error(error));
  }

  ResolveAdminUser.$inject= ['$state', 'AuthenticationService'];
  function ResolveAdminUser ($state, AuthenticationService) {
    return AuthenticationService.getCurrentUser()
    .then(function (user) { return (user.is_staff) ? user : $state.go('source'); })
    .catch(function () { $state.go('source'); });
  }
}]);
