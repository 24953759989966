'use strict';

angular
  .module('architizer.app')
  .factory('Firm', ['$resource', 'Config', function($resource, Config) {

    var Firm = $resource(Config.apiUrlBase + 'firms/:id', { id: '@id' }, {
			update: {
				method: 'PATCH'
			},
    });

    return Firm;
  }]);
