'use strict';

angular
  .module('architizer.app')
  .controller('SettingsFirmAddTeamMembersController', [
  'firm',
  'invitations',
  'team',
	'$scope',
	'$state',
  '$filter',
	'PopupManager',
	'Invitation',
	'AuthorizationService',
	'FlashManager',
	function (firm, invitations, team, $scope, $state, $filter, popupManager, Invitation, authorization, flashManager) {

		$scope.viewbag = {
			models: {
				teamMembers: [],
				permissions: {
					architizer_admin: false,
					specifier: false,
					member: true
				}
			},
			elementConfig: {
				permissions: {
					architizer_admin: {
						disabled: false,
					},
					specifier: {
						disabled: false,
					}
				}
			},
			firm: firm,
			working: false
		};

		var init = function () {

			if (firm.product_source_access != 'A') {

				$scope.viewbag.models.permissions.architizer_admin = true;
				$scope.viewbag.elementConfig.permissions.architizer_admin.disabled = true;
			}
		};

		$scope.userAutocompleteFormatter = function (value) {

      if (value && typeof value == 'object') {

        return {
          type: 'user',
          primary: value.first_name + ' ' + value.last_name,
          secondary: (value.geolocations && value.geolocations[0]) ? value.geolocations[0].locality : null,
          image: $filter('imgix')(value.images.avatar, 'thumbnail'),
          value: value
        };
      }

      else {
        return {};
      }
    };

    $scope.userAutocompleteResponseTransform = function (response) {

      return response;
    };

    $scope.onCancelClick = function () {

    	$state.go('settingsFirm.team.list');
    };

    var _onPostInviteSuccess = function (users, userIds, userEmails, permissions) {

    	return function () {

    		// Add the users to the dashboard list
    		angular.forEach(users, function (user) {

    			if (!user.permissions) { user.permissions = {}; }
    			user.permissions = permissions;

    			team.unshift(user);
    		});

    		// We don't have the ids of the new invitations, so we can't
    		// update the dashboard in the same way.
    		// Refetch all the invitations.
    		Invitation.query(
          { firm_id: firm.id },
					function (is) {

						// Add the new invitations. Can't just assign the returned
						// invites to the old var as this will break watch bindings.
						angular.forEach(is, function (i) {

							if (!_.findWhere(invitations, { id: i.id })) {

								invitations.unshift(i);
							};
						});

						// Present flash
		    		var message = userIds.length ? userIds.length + ' users were added to your team' : '';
		    		message += (userIds.length && userEmails.length) ? ', and ' : '';
		    		message += userEmails.length ? userEmails.length + ' invitations were sent out' : '';

		    		flashManager.push({
		    			message: message,
		    			type: 'success'
		    		});

		    		// Go to the list page
		    		$state.go('settingsFirm.team.list');
					},
					function (error, status) {

						// Update loading status
    				$scope.viewbag.working = false;

						// Go to the list page
		    		$state.go('settingsFirm.team.list');
					});
    	};
    };

    var _onPostInviteError = function () {

    	// Update loading status
    	$scope.viewbag.working = false;
    };

    $scope.onInviteClick = function () {

    	// Indicate loading status
    	$scope.viewbag.working = true;

    	// Break up team members into emails and ids
			var userEmails = [];
			var userIds = [];

			// Also maintain a list of the full user objects
			// This is so we can inject back into the list page
			// without doing an API request
			var users = [];

			angular.forEach($scope.viewbag.models.teamMembers, function (teamMember) {

				if (angular.isString(teamMember)) {

					userEmails.push(teamMember);
				}

				else {

					userIds.push(teamMember.id);
					users.push(teamMember);
				}
			});

			// Prepare permissions
			var permissions = [];
			angular.forEach($scope.viewbag.models.permissions, function (value, key) {

				if (value) {

					var rolePermissions = authorization.getPermissionsForRole('firm', key);
					permissions = _.union(permissions, rolePermissions);
				}
			});

			// Post the invites
		 	Invitation.save({
        firm_id: firm.id,
        user_emails: userEmails,
        user_ids: userIds,
        permissions: permissions
      },
	 		_onPostInviteSuccess(users, userIds, userEmails, permissions),
	 		_onPostInviteError);
    };

		// When permissions are changed
		$scope.$watch('viewbag.models.permissions.architizer_admin', function (value) {

			if (value) {

				$scope.viewbag.models.permissions.specifier = true;
				$scope.viewbag.elementConfig.permissions.specifier.disabled = true;
			}

			else {

				$scope.viewbag.elementConfig.permissions.specifier.disabled = false;
			}
		});

		init();
	}]);
