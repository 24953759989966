'use strict';

angular
    .module('architizer.app')
    .controller('TermsOfUseController', [
        '$scope',
        'GlobalView',
        function ($scope, globalView)
        {

            $scope.viewbag = {

            };

            var init = function () {

            };

            // kick things off
            init();
        }
    ]);