(function() {
  'use strict';

  angular
  .module('architizer.app')
  .factory('CurrencyService', CurrencyService);

  CurrencyService.$inject = [

  ];

  function CurrencyService (

  ) {

    var service = this;

    // Configuration
    service.currencies = {
      AUD: AUD,
      CAD: CAD,
      EUR: EUR,
      GBP: GBP,
      JPY: JPY,
      USD: USD,
    };

    // Functions
    service.getCurrenciesOptions = getCurrenciesOptions;
    service.getCurrenciesGroupedDropdown = getCurrenciesGroupedDropdown;

    return service;

    ////////////////////////////////////////////////////////////////////////////////
    // Functions
    ////////////////////////////////////////////////////////////////////////////////

    /**
     * Get currencies options
     *
     * Converts currencies to label/value options for a select element
     *
     * Takes an optional argument as an array of strings for the currencies desired.
     *
     * Ex: getCurrenciesOptions(['USD', 'GBP'])
     */
    function getCurrenciesOptions (currencies) {
      var options = [];

      // If an array is passed in, use it - otherwise, use service.currencies
      currencies = currencies || service.currencies;

      angular.forEach(currencies, function (currency) {
        if (Array.isArray(currencies)) {

          // Find the currency by key in service.currencies, then call it
          currency = service.currencies[currency]();

        } else {

          // currency is a factory function, so call it
          currency = currency();
        }

        options.push({
          label: currency.symbol_native + ' ' + currency.name,
          value: currency.code
        });
      });

      return options;
    }

    /**
     * Get currencies grouped dropdown
     *
     * (for use with uikit ui-select elements with grouped=true)
     */
    function getCurrenciesGroupedDropdown () {
      var currenciesGroup = [];
      var currenciesGroupedDropdown = [];

      angular.forEach(service.currencies, function (currency) {

        // currency is a factory function, so call it
        currency = currency();

        var value = {
          label: currency.symbol_native + ' ' + currency.name,
          value: currency.code
        };
        currenciesGroup.push(value);
      });

      currenciesGroupedDropdown.push({
        label: 'All',
        options: currenciesGroup 
      });

      return currenciesGroupedDropdown;
    }

    ////////////////////////////////////////////////////////////////////////////////
    // Currencies (factory functions)
    ////////////////////////////////////////////////////////////////////////////////

    function AUD () {
      return {
        'symbol': 'AU$',
        'name': 'Australian Dollar',
        'symbol_native': '$',
        'decimal_digits': 2,
        'rounding': 0.0,
        'code': 'AUD',
        'name_plural': 'Australian dollars'
      };
    }

    function CAD () {
      return {
        'symbol': 'CA$',
        'name': 'Canadian Dollar',
        'symbol_native': '$',
        'decimal_digits': 2,
        'rounding': 0.0,
        'code': 'CAD',
        'name_plural': 'Canadian dollars'
      };
    }

    function EUR () {
      return {
        'symbol': '\u20ac',
        'name': 'Euro',
        'symbol_native': '\u20ac',
        'decimal_digits': 2,
        'rounding': 0.0,
        'code': 'EUR',
        'name_plural': 'euros'
      };
    }

    function GBP () {
      return {
        'symbol': '\u00a3',
        'name': 'British Pound Sterling',
        'symbol_native': '\u00a3',
        'decimal_digits': 2,
        'rounding': 0.0,
        'code': 'GBP',
        'name_plural': 'British pounds sterling'
      };
    }

    function JPY () {
      return {
        'symbol': '\u00a5',
        'name': 'Japanese Yen',
        'symbol_native': '\uffe5',
        'decimal_digits': 0,
        'rounding': 0.0,
        'code': 'JPY',
        'name_plural': 'Japanese yen'
      };
    }

    function USD () {
      return {
        'symbol': '$',
        'name': 'US Dollar',
        'symbol_native': '$',
        'decimal_digits': 2,
        'rounding': 0.0,
        'code': 'USD',
        'name_plural': 'US dollars'
      };
    }
  }
})();


