'use strict';

angular
	.module('ui')
	.directive('greaterThan', ['$parse', function ($parse) {

		return {
			replace: false,
			restrict: 'A',
			require: '?ngModel',
			link: function ($scope, element, attrs, ngModel) {

				// end here if no ng-model
				if (!ngModel) {

					return;
				}

				// The referenced comparison value
				var _comparisonValue;

				// When the referenced comparison value changes,
				// save the value and validate
				$scope.$watch(function () {

					return $parse(attrs.greaterThan)($scope);
				}, function (value) {

					_comparisonValue = value;
					ngModel.$validate();
				});

				ngModel.$validators.greaterThan = function (value) {

					// Don't validate if this field is empty
					if (value == '' || value == null) {

						return true;
					}

					// Don't validate if the comparison value is empty
					if (_comparisonValue == '' || _comparisonValue == null) {

						return true;
					}

					return parseFloat(value) >= parseFloat(_comparisonValue);
				};
			}
		};
	}]);
