(function () {
  'use strict';

  var dependencies = [
    'duScroll',
    'mm.foundation.modal',
    'mm.foundation.tooltip',
    'mm.foundation.tabs',
    'mm.foundation.dropdownToggle',
    'ngAnimate',
    'ngCookies',
    'ngRaven',
    'ngResource',
    'ngSanitize',
    'ngTouch',
    'ui',
    'ui.router',
  ];

  // UIKit
  angular.module('ui', [
    'ngAnimate',
    'ngMessages',
    'ngSanitize',
  ]);

  angular.module('architizer.app', dependencies);
})();