'use strict';

angular
	.module('ui')
	.directive('uiScrollDot', ['$compile', '$timeout', '$document', function ($compile, $timeout, $document) {

		return {
			restrict: 'A',
			replace: false,
			transclude: false,
			link: function ($scope, element, attrs) {

				/*
				 * UI params
				 */

				var scrollTop = 0;
				var scrollHeight = 0;
				var offsetHeight = 0;
				var dotMargin = 3;
				var dotSize = 8;
				var dot = null;
				var barHeight = 0;

				// Stores scroll position
				// Used in addressing webkit bug
				var position;

				// Last mouse drag position
				var lastDragPosition;

				/*
				 * UI interation
				 */

				function onChildScroll(e) {

					// Capture the scroll height/position of scrolling element
					scrollTop = this.scrollTop;
					scrollHeight = this.scrollHeight;
					offsetHeight = this.offsetHeight;

					e.stopPropagation();
				}

				// Prevent scrolling window when end of list is reached
				function onChildMousewheel(e) {


					// Scrolling down
					if (e.originalEvent.deltaY > 0 && this.scrollTop + this.offsetHeight >= this.scrollHeight) {

						e.preventDefault();
					}

					// Scrolling up
					if (e.originalEvent.deltaY < 0 && this.scrollTop <= 0) {

						e.preventDefault();
					}
				}

				function onBodyMouseMove(e) {

					var child = element.children()[0];

					var delta = {
						x: e.clientX - lastDragPosition.x,
						y: e.clientY - lastDragPosition.y
					};

					var range = offsetHeight - 2*dotMargin - barHeight;

					child.scrollTop += (delta.y / range) * (child.scrollHeight - child.offsetHeight);

					// Update drag position for next movement
					lastDragPosition = {
						x: e.clientX,
						y: e.clientY
					};
				}

				function onBodyMouseUp(e) {

					endDrag();
				}

				function onDotMouseDown(e) {

					startDrag(e);

					e.preventDefault();
					e.stopPropagation();
				}

				function startDrag(e) {

					// Record starting position
					lastDragPosition = {
						x: e.clientX,
						y: e.clientY
					};

					// Attach drag handlers to the body element
					var body = $document.find('body');
					body.on('mousemove', onBodyMouseMove);
					body.on('mouseup', onBodyMouseUp);
				}

				function endDrag() {

					// Clear last drag position
					lastDragPosition = null;

					// Remove drag handlers from the body element
					var body = $document.find('body');
					body.off('mousemove');
					body.off('mouseup');
				}


				/*
				 * View methods
				 */

				var reposition = function () {

					var child = element.children()[0];
					scrollTop = child.scrollTop;
					scrollHeight = child.scrollHeight;
					offsetHeight = child.offsetHeight;

					// Update how big the bar should be
					barHeight = (offsetHeight / scrollHeight) * offsetHeight;
					dot.css('height', parseInt(barHeight) + 'px');

					// Hide the dot if there is no scrolling to be done
					var hasScrollClass = element.hasClass('hasScroll');
					if (scrollHeight <= offsetHeight && hasScrollClass) {

						element.removeClass('hasScroll');
					}

					else if (scrollHeight > offsetHeight && !hasScrollClass) {

						element.addClass('hasScroll');
					}

					// Position the dot
					var position = scrollTop / (scrollHeight - offsetHeight);
					dot.css('top', dotMargin + position*(offsetHeight - 2*dotMargin - barHeight) + 'px');
				};


				/*
				 * Handle webkit scrolling bug
				 * Scroll position jumps upon interacting with an
				 * overflow: scroll element inside a fixed position
				 * container
				 */
				element.on('click', function (e) {

					// Checkboxes fire the click event twice
					// Until this is fixed, store the scroll
					// position on the first event only
					if (!position) {

						position = element.children()[0].scrollTop;
					}

					// Reapply the position on the next digest
					$timeout(function () {

						element.children()[0].scrollTop = position;
					});

					// Clear position after a brief delay, in
					// preparation of next click interaction
					$timeout(function () {

						position = null;
					}, 200);
				});


				/*
				 * Initialise element
				 */

				element.addClass('uiScrollDot');

				// Create scroll background
				var bg = angular.element('<div />')
					.addClass('scrollBackground');
				element.append(bg);

				// Create scroll dot
				dot = angular.element('<div />')
					.addClass('scrollDot');
				element.append(dot);

				// Bind events
				element.children().bind('scroll', onChildScroll);
				element.children().bind('mousewheel', onChildMousewheel);
				dot.bind('mousedown', onDotMouseDown);

				// Create a continuous update loop utilising requestAnimFrame
				(function animLoop() {

					requestAnimFrame(animLoop);
					reposition();
				})();
			}
		};
	}]);
