(function() {
  'use strict';

  angular
  .module('architizer.app')
  .controller('SourcePublicOpportunityController', SourcePublicOpportunityController);

  SourcePublicOpportunityController.$inject = [
    '$document',
    '$filter',
    '$modal',
    '$state',
    '$timeout',
    'Attribution',
    'AuthorizationService',
    'CustomDataService',
    'FileClick',
    'FileService',
    'productRequest',
    'ProductResponse',
    'User',
    'user',
  ];

  function SourcePublicOpportunityController (
    $document,
    $filter,
    $modal,
    $state,
    $timeout,
    Attribution,
    AuthorizationService,
    CustomDataService,
    FileClick,
    FileService,
    productRequest,
    ProductResponse,
    User,
    user
  ) {

    var vm = this;

    // Data
    vm.user = user;
    vm.productRequest = productRequest;
    vm.productResponses = productResponses();
    vm.draftResponse = draftResponse();
    vm.form = {};
    vm.models = models();
    vm.elementConfig = elementConfig();
    vm.sendMode = '';
    vm.userCanSendProducts = userCanSendProducts();
    vm.heroImage = setHeroImage();

    // Functions
    vm.FileService = FileService;
    vm.onDataPointClick = onDataPointClick;
    vm.onRemoveDataPointClick = onRemoveDataPointClick;
    vm.isDataPointFieldRequired = isDataPointFieldRequired;
    vm.getBackgroundImage = getBackgroundImage;
    vm.onSubmitClick = onSubmitClick;
    vm.toggleFavorite = toggleFavorite;
    vm.setHeroImage = setHeroImage;
    vm.onFileDownloadClick = FileClick.onFileDownloadClick;

    // Initialize
    init();

    ////////////////////////////////////////////////////////////////////////////////
    // Functions
    ////////////////////////////////////////////////////////////////////////////////

    /**
     * Initialization
     */
    function init () {
      // Prepare product search structured data
      CustomDataService.prepareForDisplay(vm.productRequest.structured_data);

      // Preselect the brand if there's only one
      if (vm.elementConfig.brandOptions.length === 1) {
        vm.models.brandId = vm.elementConfig.brandOptions[0].value;
      }

      if (vm.draftResponse && vm.userCanSendProducts) {
        initWithResponse(vm.draftResponse);
      }
    }

    function userCanSendProducts () {
      // Just check if the user is a member of a brand for now (see FIXME below):
      if (vm.user) {
        return (!!Object.keys(vm.user.permissions.brands).length);
      } else {
        return false;
      }

      /**
       * FIXME: This function needs to use AuthorizationService
       *
       * AuthorizationService doesn't always have the user data right away, so it sometimes returns
       * an empty array of brands for which the user is allowed to make product responses.
       *
       * Refactor AuthorizationService.getBrandsForPermission() to return a promise, then chain on it like this:
       *
         return AuthorizationService
         .getBrandsForPermission(AuthorizationService.BRAND_PERMISSIONS.CAN_CHANGE_BRAND_PRODUCT_RESPONSES)
         .then((brands) => { return !!brands.length; });
       *
       * etc.
       */
    }

    function models () {
      if (userCanSendProducts()) {
        return {
          brandId: '',
          productName: CustomDataService.dataPoints.productName(),
          productUrl: CustomDataService.dataPoints.productUrl(),
          leadTime: CustomDataService.dataPoints.leadTime(),
          price: CustomDataService.dataPoints.price(),
          description: CustomDataService.dataPoints.description(),
          workPhone: getUserWorkPhone(),
          files: [],
          selectedDataPoints: [],
        };
      } else {
        return {};
      }
    }

    function elementConfig () {
      return {
        brandOptions: getBrands(),
        bpmSendProductDataPoints: CustomDataService.getBpmSendProductDataPoints(),
      };

      // Get the brands this user can create responses for
      function getBrands () {
        var brandOptions = [];
        var authorizedBrands = AuthorizationService.getBrandsForPermission(AuthorizationService.BRAND_PERMISSIONS.CAN_CHANGE_BRAND_PRODUCT_RESPONSES, vm.user);

        angular.forEach(authorizedBrands, (brand) => {
          brandOptions.push({
            label: brand.name,
            value: brand.id
          });
        });

        return brandOptions;
      }
    }

    function getUserWorkPhone () {
      // Find the user's saved work number if they have one
      var work = vm.user.phone_numbers.find((phoneNumber) => phoneNumber.type === 'work');
      if (work) {
        return work.number;
      } else {
        return '';
      }
    }

    function productResponses () {
      let nonPayingBrands = [];
      let payingBrands = [];

      // Get non-draft responses
      let openProductResponses = vm.productRequest.product_responses.filter((productResponse) => productResponse.workflow_status === 'O');

      // Sort product response by paying brands first to prioritize their responses
      angular.forEach(openProductResponses, (response) => $filter('opportunitySortOrder')(response));

      // Group product responses by paying/non-paying status
      openProductResponses.forEach((response) => {
        if (response.statusSort === 1) {
          payingBrands.push(response);
        } else {
          nonPayingBrands.push(response);
        }
      });

      // Sort responses within each group by date created
      payingBrands = $filter('orderBy')(payingBrands, '-created');
      nonPayingBrands = $filter('orderBy')(nonPayingBrands, '-created');
      let sortedProductResponses = [...payingBrands, ...nonPayingBrands];
      return sortedProductResponses;
    }

    function draftResponse () {
      /*
       * Find a draft response if it exists & vm.user exists (i.e. is logged in)
       * TODO: Have this fetch drafts from an authenticated endpoint, and
       * hide drafts from ProductRequest.product_responses
       */

      return vm.productRequest.product_responses.find((productResponse) => productResponse.workflow_status === 'R' && vm.user && productResponse.creator_id === vm.user.id);
    }

    function getBackgroundImage (product) {
      if (product.images.hero.base_imgix_url) {
        return $filter('imgix')(product.images.hero, 'medium');
      } else if (product.images.gallery.length) {
        return $filter('imgix')(product.images.gallery[0], 'medium');
      } else {
        return '/images/placeholder-product-4-3.png';
      }
    }

    function setHeroImage (image) {
      if (image) {
        vm.heroImage = image;
        return image;
      } else if (vm.productRequest.images.hero) {
        return vm.productRequest.images.hero;
      } else {
        return null;
      }
    }

    function onDataPointClick (dataPoint) {

      // Remove the data point from the blue buttons
      var index = vm.elementConfig.bpmSendProductDataPoints.indexOf(dataPoint);
      vm.elementConfig.bpmSendProductDataPoints.splice(index, 1);

      // Add it to the optional details fields
      vm.models.selectedDataPoints.push(dataPoint);
    }

    function onRemoveDataPointClick (dataPoint) {

      // Remove the data point from the optional details fields
      var index = vm.models.selectedDataPoints.indexOf(dataPoint);
      vm.models.selectedDataPoints.splice(index, 1);

      // Add it to the blue buttons
      vm.elementConfig.bpmSendProductDataPoints.push(dataPoint);
    }

    function isDataPointFieldRequired (dataPoint) {
      var formSubmitted = vm.form.$submitted;
      var tryingToPublish = vm.sendMode === 'publish';
      var allFieldsAreRequired = dataPoint.requireAllFields;

      if (formSubmitted && tryingToPublish && allFieldsAreRequired) {
        var fieldsWithValues = dataPoint.fields.filter((field) => !!field.value);
        return fieldsWithValues.length;
      } else {
        return false;
      }
    }

    // Helper function to translate Lead Time from weeks to days (stored on API in days)
     function leadTimeInDays (leadTimeDataPoint) {
      angular.forEach(leadTimeDataPoint.fields, (field) => field.value = (7 * field.value));
      return leadTimeDataPoint;
    }

    // Helper function to translate Price from string format to number
    function convertPriceToNumber (priceDataPoint) {
      angular.forEach(priceDataPoint.fields, (field) => {
        // We only want the first two price data point fields (price_low and price_high)
        if(field.name !== 'currency') {
          // If price field is empty, set it to null
          if(field.value === '') {
            field.value = null;
          } else {
            // if price field is not empty, convert string input to number format
            field.value = parseInt(field.value);
          }
        }
      });
      return priceDataPoint;
    }

    /**
     * Repopulate the form with data from a product response (used for drafts)
     */
    function initWithResponse (productResponse) {

      // Brand
      vm.models.brandId = productResponse.brand_id;
      // Product Name
      vm.models.productName.fields[0].value = productResponse.name;
      // Product Url
      prepareProductUrl(productResponse);
      // Description
      vm.models.description.fields[0].value = productResponse.description;
      // Lead Time
      prepareLeadTime(productResponse);
      // Price
      preparePrice(productResponse);
      // Attachments
      vm.models.files = productResponse.images.gallery;
      // Prepare Structured Data Points
      prepareStructuredData(productResponse);
      // Prepare Optional Criteria
      prepareOptionalCriteria(productResponse);
    }

    function prepareProductUrl (response) {
      var productUrlDataPoint = response.structured_data.find((dataPoint) => dataPoint.name === 'productUrl');

      if (productUrlDataPoint) {
        vm.models.productUrl.fields[0].value = productUrlDataPoint.fields[0].value;

        // Remove product URL data point from the structured data so it won't show up in the selectedDataPoints fields below
        var index = response.structured_data.indexOf(productUrlDataPoint);
        response.structured_data.splice(index, 1);
      }
    }

    function prepareStructuredData (response) {
      // Extend each structured data point with the template the data was created from
      CustomDataService.extendInstancesWithTemplates(response.structured_data);
      vm.models.selectedDataPoints = response.structured_data;
    }

    function prepareLeadTime (response) {
      if (response.lead_time_high && response.lead_time_low) {

        var leadTimeLow = vm.models.leadTime.fields.find((field) => field.apiField === 'lead_time_low');
        var leadTimeHigh = vm.models.leadTime.fields.find((field) => field.apiField === 'lead_time_high');

        leadTimeLow.value = leadTimeInWeeks(response.lead_time_low);
        leadTimeHigh.value = leadTimeInWeeks(response.lead_time_high);
      }

      // Helper function to convert lead time to weeks for display
      function leadTimeInWeeks (dataPoint) {
        return Math.floor(dataPoint / 7);
      }
    }

    function preparePrice (response) {
      if (response.price_high && response.price_low) {

        var priceLow = vm.models.price.fields.find((field) => field.apiField === 'price_low');
        var priceHigh = vm.models.price.fields.find((field) => field.apiField === 'price_high');

        priceLow.value = convertPrice(response.price_low);
        priceHigh.value = convertPrice(response.price_high);
      }

      // Helper function to translate Price from string to number format
      function convertPrice (price) {
        if(!price) {
          return null;
        } else {
          return parseInt(price);
        }
      }
    }

    function prepareOptionalCriteria (response) {
      // Make a copy of the recommended data points and reassign it at the end of the function
      var blueButtons = angular.copy(vm.elementConfig.bpmSendProductDataPoints);

      // Go through structured data points and delete existing field critieria buttons
      angular.forEach(response.structured_data, (dataPoint) => {
        var blueButtonFound = blueButtons.find((button) => button.name === dataPoint.name);

        // If a criteria button is found for this dataPoint, splice it
        if (blueButtonFound) {
          blueButtons.splice(blueButtons.indexOf(blueButtonFound), 1);
        }
      });

      /**
       * Remove criteria options from elementConfig.optionalCriteriaOptions that have already been selected
       */
      angular.forEach(angular.fromJson(response.structured_data), function (point) {
        angular.forEach(blueButtons, function (option) {
          if (point.name === option.name) {
            var index = blueButtons.indexOf(option);
            blueButtons.splice(index, 1);
          }
        });
      });

      // Reassign it to the copy we made at the beginning of the function
      vm.elementConfig.bpmSendProductDataPoints = blueButtons;
    }

    function prepareProductResponseForAPI () {
      var details = [];

      // Push each of the permanent form fields into details array
      details.push(vm.models.productName);
      details.push(vm.models.productUrl);
      details.push(leadTimeInDays(angular.copy(vm.models.leadTime))); // angular.copy() to prevent the brief flash of weeks-to-days converted values in the field on the form
      details.push(convertPriceToNumber(vm.models.price));
      details.push(vm.models.description);

      // Flatten the optional criteria data points into details array
      angular.forEach(vm.models.selectedDataPoints, (dataPoint) => {
        // Push it into the details array
        details.push(dataPoint);
      });

      // Make a product response via the ProductResponse endpoint
      var productResponse = new ProductResponse();

      // Fields to exclude from structured_data/details
      var excluded = [];

      // For each field in each dataPoint on the form
      angular.forEach(details, (dataPoint) => {
        angular.forEach(dataPoint.fields, (field) => {

          // Push the value onto the appropriate productResponse model field
          if (field.apiField) {
            productResponse[field.apiField] = field.value;

            // Exclude the dataPoint from being added to the structured_data array
            if (excluded.indexOf(dataPoint) === -1) {
              excluded.push(dataPoint);
            }
          }
        });
      });

      // Remove the excluded datapoints from the details array
      details = _.difference(details, excluded);

      // Add price_currency
      productResponse.price_currency = 'USD';

      productResponse.brand_id = parseInt(vm.models.brandId);
      productResponse.product_request_id = vm.productRequest.id;
      productResponse.structured_data = CustomDataService.prepareForAPI(details);
      productResponse.media_data = vm.models.files;
      productResponse.media_data_includes_existing = true; // This flag tells the API that media_data will include existing MediaItemAttributions for this object (required)

      return productResponse;
    }

    function toggleFavorite () {
      switch (vm.productRequest.marking) {
        case 'F':
          vm.productRequest.marking = 'N';
          vm.productRequest.$clear();
          break;
        default:
          vm.productRequest.marking = 'F';
          vm.productRequest.$favorite();
          break;
      }
    }

    function onSubmitClick (sendMode) {
      // sendMode can be 'draft' or 'publish' - this determines which $resource action we call
      vm.sendMode = sendMode;
      vm.form.$setSubmitted(true);

      $timeout(() => {

        // Invalid form
        if (!vm.form.$valid) {

          // Find the first element with an error
          let formError = vm.form.$error[Object.keys(vm.form.$error)[0]][0].$name;
          let errorElement = angular.element(document.getElementById(formError));

          // Find <div> with the the id of '#opportunityViewContent' on the page
          let pageElement = angular.element('#opportunityViewContent');

          // Tell the page element to scroll to the first error element
          pageElement.scrollToElement(errorElement, 150, 250);
          return;
        }

        // Valid form
        if (vm.form.$valid) {
          vm.saving = true;

          let productResponse = prepareProductResponseForAPI();

          let action = '';
          // Set the correct action to call with the API
          if (!vm.draftResponse && sendMode === 'draft')    { action = 'creatingDraft'; }     // There is NO existing draft, and the user clicked "Save Draft"
          if (!vm.draftResponse && sendMode === 'publish')  { action = 'creatingPublished'; } // There is NO existing draft, and the user clicked "Publish"
          if ( vm.draftResponse && sendMode === 'draft')    { action = 'updatingDraft'; }     // There IS an existing draft, and the user clicked "Save Draft"
          if ( vm.draftResponse && sendMode === 'publish')  { action = 'publishingDraft'; }   // There IS an existing draft, and the user clicked "Publish"

          // Set product response ID if we're updating or publishing a draft
          if (action === 'updatingDraft' || action === 'publishingDraft') {
            productResponse.id = vm.draftResponse.id;
          }

          // Call the right action with the API
          switch (action) {
            case 'creatingDraft':
              productResponse.$createDraft(success, error);
              break;

            case 'creatingPublished':
              productResponse.$createPublished(success, error);
              break;

            case 'updatingDraft':
              productResponse.$updateDraft(success, error);
              break;

            case 'publishingDraft':
              productResponse.$publishDraft(success, error);
              break;
          }

          // Update user's work phone number from vm.models.workPhone field
          updateWorkPhone(vm.models.workPhone);
        }

        ////////////////////////////////////////////////////////////////////////////////
        // Helpers
        ////////////////////////////////////////////////////////////////////////////////

        function success () {
          vm.saving = false;
          $state.reload();
        }

        function error (data) {
          vm.saving = false;
          captureError(data);

          // Show an error modal if the user fails to submit the response
          $modal.open({
            template: errorModalTemplate(),
            controller: ErrorModalController,
            controllerAs: 'mc',
            resolve: {
              error: () => data,
            }
          });

          // Error Modal Template
          function errorModalTemplate () {
            return `
            <p>We're having trouble submitting your product! 😥</p>
            <p><a ng-click="mc.showIntercom()">Click here to contact Architizer Support</a>.</p>
            <!-- Footer (Buttons) -->
            <div class="footer">
              <div class="row align-center">
                <!-- Close  -->
                <div class="small-6 medium-4 columns">
                  <button class="small expanded button"
                          ng-click="mc.onCloseClick()"
                          type="button">Close</button>
                </div>
              </div>
            </div>
            `;
          }

          // Error Modal Controller
          ErrorModalController.$inject = ['$modalInstance', 'error'];
          function ErrorModalController ($modalInstance, error) {
            this.error = error;
            this.showIntercom = () => window.Intercom('showNewMessage', 'I\'m having trouble submitting a product!');
            this.onCloseClick = () => $modalInstance.close();
          }
        }

        function captureError (error) {
          Raven.captureMessage('Error Submitting Product Response', {
            level: 'error',
            extra: {
              'View': 'Send Product form on Opportunity view',
              'Error': error,
            }
          });
        }
      });

      function updateWorkPhone (workPhone) {
        // Get existing phone numbers and find work phone number
        var phoneNumbers = vm.user.phone_numbers;
        var userWorkPhone = phoneNumbers.find((phoneNumber) => phoneNumber.type === 'work');

        // Update the value if it exists, otherwise add it
        if (userWorkPhone) {
          userWorkPhone.number = workPhone;
        } else {
          phoneNumbers.push({
            number: workPhone,
            type: 'work',
          });
        }

        // PATCH to the API then update the stored user data
        User
        .updateMe({phone_numbers: phoneNumbers})
        .$promise
        .then(() => Object.assign(vm.user, phoneNumbers));
      }
    }
  }
})();
