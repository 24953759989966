(function() {
  'use strict';

  angular
  .module('architizer.app')
  .controller('SourceArchitectProjectConversationSendMessageController', SourceArchitectProjectConversationSendMessageController);

  SourceArchitectProjectConversationSendMessageController.$inject = [
    '$filter',
    '$modal',
    '$rootScope',
    'AuthenticationService',
    'conversation',
    'Conversation',
    'CustomEventsService',
    'productRequest',
    'ProductResponse',
    'user',
  ];

  function SourceArchitectProjectConversationSendMessageController (
    $filter,
    $modal,
    $rootScope,
    AuthenticationService,
    conversation,
    Conversation,
    CustomEventsService,
    productRequest,
    ProductResponse,
    user
    ) {

    var vm = this;

    const {
      CONVERSATION_MESSAGE_SEND_COMPLETE
    } = CustomEventsService.events;

    // Data
    vm.conversation = conversation;
    vm.message = '';
    vm.avatar = AuthenticationService.user.images.avatar;
    vm.sendButtonLoading = false;
    vm.productRequest = productRequest;
    vm.user = user;

    // Functions
    vm.onSendMessageClick = onSendMessageClick;
    vm.onRequestSamplesClick = onRequestSamplesClick;

    // Initialize
    init();

    ////////////////////////////////////////////////////////////////////////////////
    // Functions
    ////////////////////////////////////////////////////////////////////////////////

    /**
     * Initialization
     */
    function init () {
    }

    /**
     * Send conversation message
     */
    function onSendMessageClick () {
      // Show loader in send button
      vm.sendButtonLoading = true;

      // Send the message with the API
      Conversation.sendMessage({
        id: vm.conversation.id,
        content: vm.message,
      })
      .$promise
      .then(SendMessageSuccess)
      .catch(SendMessageError);
    }

    function SendMessageSuccess (data) {

      // Broadcast the sent message so we can update the conversation
      $rootScope.$broadcast(CONVERSATION_MESSAGE_SEND_COMPLETE, { message: data });

      // Reset Message Form
      vm.sendButtonLoading = false;
      vm.message = '';

      return data;
    }

    function SendMessageError (error) {
      console.error('Error Sending Message:', error);

      // Reset Send Button
      vm.sendButtonLoading = false;
    }

    /**
     * On Request Samples click
     */
    function onRequestSamplesClick () {

      // Get other responses for this conversation to populate Product dropdown
      ProductResponse.query({
        conversation_id: vm.conversation.id,
        force: 'brand,creator',
      })
      .$promise
      .then((productResponses) => {

        $modal.open({
          templateUrl: '/views/source/architect/product-response.request-samples.modal.html',
          controller: 'RequestSamplesController',
          controllerAs: 'RequestSamplesCtrl',
          resolve: {
            conversation: () => conversation,
            productResponses: () => productResponses,
            productRequest: () => productRequest,
            currentlySelectedProductResponseId: () => null,
            user: () => vm.user,
          }
        });
      });
    }
  }
})();
