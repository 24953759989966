/* global Selectize */
(function () {
  'use strict';
  /**
   * selectize.js wrapper directive
   *
   * Derived from https://github.com/kbanman/selectize-ng/
   */

  angular
  .module('architizer.app')
  .directive('selectize', selectize);

  selectize.$inject = [
    '$timeout',
  ];

  function selectize (
    $timeout
  ) {

    return {
      restrict: 'EA',
      require: '^ngModel',
      scope: {
        config: '=?',
        ngDisabled: '=',
        ngModel: '=',
        ngRequired: '&',
        options: '=?',
        placeholder: '@?',
      },
      link: link,
    };

    function link (scope, element, attrs, modelCtrl) {
      let settings = angular.extend({}, Selectize.defaults, scope.config);
      settings.loadThrottle = 0; // (!) Turn off loadThrottle because it seems to be incompatible with the Angular digest cycle
      settings.onInitialize = onInitialize;
      settings.placeholder = scope.placeholder || '';

      // Initialize the Selectize element and grab the initialized instance
      let selectize = element.selectize(settings)[0].selectize;
      let wrapper = angular.element(selectize.$wrapper);

      // Listeners
      selectize.on('change', () => onSelectizeChange());                  // On Selectize value change: set ng-model
      scope.$parent.$watch(attrs.ngModel, setSelectizeValue, true);       // On ng-model change: set Selectize value
      selectize.on('focus', () => onSelectizeFocus());
      selectize.on('blur', () => onSelectizeBlur());
      scope.$parent.$watchCollection(attrs.options, setSelectizeOptions); // On options change: set Selectize options
      scope.$on('$destroy', () => selectize.destroy());                   // On destroy: clean up

      ////////////////////////////////////////////////////////////////////////////////
      // Directive Functions
      ////////////////////////////////////////////////////////////////////////////////

      // Initialization
      function onInitialize () {
        selectize = element[0].selectize;
        wrapper = angular.element(selectize.$wrapper);
        // Call the callback with the Selectize instance (to expose it to controllers, etc)
        if (scope.config.onInitialize) {
          scope.config.onInitialize(selectize);
        }
      }

      // Set Selectize element value
      function setSelectizeValue (value) {
        let wrapper = angular.element(selectize.$wrapper);
        let values = parseModelValue(value);
        // let valuesUnchanged = (values === parseModelValue(modelCtrl.$viewValue));

        // if (valuesUnchanged) { return; }

        // FIXME: valuesUnchanged was breaking the ability to update the ng-model via controller methods
        // "return if valuesUnchanged" is now disabled, but it's unclear what purpose it was serving in the first place

        if (!values.length) { wrapper.addClass('ng-empty'); return; }

        $timeout(() => selectize.setValue(values));
      }

      // Set Selectize element options
      function setSelectizeOptions (newOptions) {
        if (!newOptions) { return; }

        // Check if newOptions is a promise, and re-call this function after resolving
        const isPromise = typeof newOptions.then === 'function'; // We will know if it's a promise because it's "thenable"
        if (isPromise) {
          newOptions.then(data => setSelectizeOptions(data));
          return;
        }

        // Get current values of this Selectize (these have to be added manually, below)
        let values = parseModelValue(angular.copy(modelCtrl.$viewValue)); // Use angular.copy so we don't modify the original

        // Add options on the next digest
        $timeout(() => {
          selectize.addOption(newOptions);

          // If the user entered custom values into the Selectize previously, add "options" for the custom values so they will render
          values.forEach((value) => selectize.addOption({label: value, value: value}));

          setSelectizeValue(values);
        });
      }

      // Set $touched when changed and update model
      function onSelectizeChange () {
        modelCtrl.$setTouched();
        setModelValue(selectize.items);
      }

      function onSelectizeFocus () {
        wrapper.addClass('focus');
      }

      function onSelectizeBlur () {
        wrapper.removeClass('focus');
      }

      // Set Angular ng-model value
      function setModelValue (value) {
        // Handle single-item select values
        if (settings.maxItems === 1) {
          value = value[0];
        }

        validate();
        scope.$apply(() => modelCtrl.$setViewValue(value));

        // Set validity classes on Selectize element
        wrapper.toggleClass('ng-valid', modelCtrl.$valid);
        wrapper.toggleClass('ng-invalid', modelCtrl.$invalid);
        wrapper.toggleClass('ng-dirty', modelCtrl.$dirty);
        wrapper.toggleClass('ng-pristine', modelCtrl.$pristine);
        wrapper.toggleClass('ng-touched', modelCtrl.$touched);
        wrapper.toggleClass('ng-untouched', modelCtrl.$untouched);
        wrapper.toggleClass('ng-empty', isEmpty(modelCtrl.$viewValue));


        // Helper for determining whether model value is valid
        function validate () {
          let isInvalid = (scope.ngRequired() || attrs.required) && isEmpty(modelCtrl.$viewValue);
          modelCtrl.$setValidity('required', !isInvalid);
        }

        function isEmpty (val) {
          return val === undefined || val === null || !val.length;
        }
      }

      // Helper for parsing values from ng-model
      function parseModelValue (value) {
        if (!value) {
          return [];
        }
        if (angular.isArray(value)) {
          return value;
        }
        return String(value).split(settings.delimiter);
      }
    }
  }

})();