'use strict';

/*
 * Configures routing settings
 */
angular
  .module('architizer.app')
  .config([
    '$locationProvider', 
    '$urlRouterProvider', 
    function ($locationProvider, $urlRouterProvider) {

      // Use html5 routing
      $locationProvider.html5Mode({ enabled: true }).hashPrefix('!');

      // Allow missing trailing slash for all routes
      $urlRouterProvider.rule(function($injector, $location) {

        var path = $location.path(), search = $location.search();

        if (path[path.length-1] !== '/') {

          if (search === {}) {

            return path + '/';
          } else {

            var params = [];
            angular.forEach(search, function(v, k){

                params.push(k + '=' + v);
            });
            return path + '/?' + params.join('&');
          }
        }
      });
    }]);