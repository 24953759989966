'use strict';

angular
	.module('ui')
	.directive('uiTooltip', ['$parse', '$compile', '$window', '$document', '$rootScope', function ($parse, $compile, $window, $document, $rootScope) {

		return {
			restrict: 'A',
			link: function ($scope, element, attrs) {

				var _template = '<div ng-show="!!config.text" class="uiTooltip {{ config.direction }}">' +
					'<div class="content caption longform" ng-bind-html="config.text"></div>' +
				'</div>';

				var tooltipConfig = {},
					tooltipElement,
					tooltipScope;

				// Return if no attribute value. This is the text
				// that will be displayed in the tooltip
				if (!attrs['uiTooltip']) {

					return;
				}

				// Update the text whenever it changes
				$scope.$watch($parse(attrs['uiTooltip']), function (value) {

					tooltipConfig.text = value;
				});

				// Initialisation
				var init = function () {

					_attachEvents();
				};

				// Attach element interaction handlers
				var _attachEvents = function () {

					// Show tooltip when element is hovered
					element.on('mouseenter', function () {

						$scope.$apply(showTooltip);
					});

					// Hide tooltip when element mouse leaves
					element.on('mouseleave', function () {

						$scope.$apply(hideTooltip);
					});

					// Ensure tooltip is removed when target element
					// is removed
					$scope.$on('$destroy', hideTooltip);
				};


				/*
				 * View methods
				 */

				var showTooltip = function () {

					if (!tooltipElement) {

						// Create the tooltip
						_createTooltip();
					}
				};

				var hideTooltip = function () {

					if (tooltipElement) {

						tooltipElement.remove();
						tooltipElement = null;
					}


					if (tooltipScope) {

						tooltipScope.$destroy();
						tooltipScope = null;
					}
				};

				var _createTooltip = function () {

					// Create a fresh scope for the tooltip
					tooltipScope = $rootScope.$new();

					// Add the tooltip config
					tooltipScope.config = tooltipConfig;

					// Default config
					tooltipScope.config.direction = 'down';

					// Compile the markup into a linking function
          var linkFn = $compile(_template);

          // Inject the scope, and link
          var el = angular.element(linkFn(tooltipScope));

          // Append to the document
					angular.element($document[0].body).append(el);

					// Save reference
					tooltipElement = el;

					// Begin positioning the tooltip
					_beginPositioning();
				};

				var reposition = function () {

					// Get window scroll position
					var scrollX = $window.scrollX || $window.pageXOffset;
					var scrollY = $window.scrollY || $window.pageYOffset;

					// Get rectangle of anchor element
					var anchorRect = element[0].getBoundingClientRect();

					// Get rectangle of tooltip element
					var tooltipRect = tooltipElement[0].getBoundingClientRect();

					// Calculate the tooltip direction
					var direction = 'down';
					if ((anchorRect.bottom + tooltipElement[0].offsetHeight) >= ($window.innerHeight-100)) {

						direction = 'up';
					}

					if (tooltipConfig.direction != direction) {

						tooltipConfig.direction = direction;
					}

					// Position based on direction
					switch (tooltipConfig.direction) {

						case 'down':
							tooltipElement
								.css('top', anchorRect.bottom + scrollY + 'px')
								.css('left', anchorRect.left + (anchorRect.width - tooltipRect.width)/2 + scrollX + 'px')

							break;

						case 'up':
							tooltipElement
								.css('top', anchorRect.top + scrollY - element[0].offsetHeight + 'px')
								.css('left', anchorRect.left + (anchorRect.width - tooltipRect.width)/2 + scrollX + 'px')

							break;
					}
				};

				// Begin positioning loop
				var _beginPositioning = function () {

					// Request end loop flag
					var _end = false;

					// Animation loop
					(function animLoop() {

						if (!_end) {

							requestAnimFrame(animLoop);
							reposition();
						}
					})();

					// Clean up loop on destroy
					tooltipScope.$on('$destroy', function () {

						_end = true;
					});
				};

				// Kick things off
				init();
			}
		};
	}]);
