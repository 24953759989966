(function() {
  'use strict';

  angular
  .module('architizer.app')
  .controller('SourceBpmConversationsSidebarConversationsListController', SourceBpmConversationsSidebarConversationsListController);

  SourceBpmConversationsSidebarConversationsListController.$inject = [
    'conversations',
    'selectedConversation',
    '$state',
  ];

  function SourceBpmConversationsSidebarConversationsListController (conversations, selectedConversation, $state) {
    var vm = this;

    // Data
    vm.conversations = conversations;
    vm.filter = {
      value: '',
    };
    vm.sort = {
      value: 'updated',
      reverse: true,
    };

    // Functions
    vm.onConversationClick = onConversationClick;
    vm.sortPredicate = sortPredicate;
    vm.filterPredicate = filterPredicate;
    vm.boldIfUnread = boldIfUnread;

    // Initialize
    init();

    ////////////////////////////////////////////////////////////////////////////////
    // Functions
    ////////////////////////////////////////////////////////////////////////////////

    /**
     * Initialization
     */
    function init () {

    }

    /**
     * Used with ng-style for emboldening conversations that are unread
     */
    function boldIfUnread (conversation) {
      var conversationHasNeverBeenRead = !conversation.my_last_viewed;
      var conversationHasNewMessages = conversation.my_last_viewed < conversation.modified;

      if (conversationHasNeverBeenRead || conversationHasNewMessages) {
        // Embolden
        return { 'font-weight': '800' };
      } else {
        // Don't embolden
        return {};
      }
    }

    /**
     * Sort predicate function for Conversation list
     */
    function sortPredicate (item) {
      return item.modified;
    }

    /**
     * Filter predicate function for Conversation list
     *
     * Allows user to search by product request name
     */
    function filterPredicate (item) {
      var r = new RegExp(vm.filter.value, 'i');
      return r.test((item.name));
    }

    /**
     * On conversation click in sidebar
     */
    function onConversationClick ($event, conversation) {
      $event.stopPropagation();

      selectedConversation = conversation;

      // Make the conversation appear "read"
      conversation.my_last_viewed = moment().format();

      // Go to this conversation
      $state.go('source.app.bpm.conversations.conversation', { conversation: conversation.id }, { reload: true, inherit: false });
    }
  }
})();