'use strict';

angular
	.module('ui')
	.directive('requiredWhen', ['$parse', function ($parse) {

		return {
			restrict: 'A',
			require: '?ngModel',
			link: function ($scope, element, attrs, ngModel) {

				if (!ngModel) {

					return;
				}

				ngModel.$validators.requiredWhen = function (value) {

          var doValidation = $parse(attrs.requiredWhen);

          if (!doValidation) {

            return true;
          }

          if (angular.isUndefined(value) || value === null || value === '') {

            return false;
          }

          else {

            return true;
          }
				};

        // Perform validation when the validation condition changes
        $scope.$watch(function () {

          return $parse(attrs.requiredWhen);
        }, function () {

          ngModel.$validate();
        });
			}
		};
	}]);


angular
  .module('ui')
  .directive('requiredWhenViewbag', ['$parse', function ($parse) {

    return {
      restrict: 'A',
      require: '?ngModel',
      link: function ($scope, element, attrs, ngModel) {

        if (!ngModel) {

          return;
        }

        ngModel.$validators.requiredWhenViewbag = function (value) {

          var doValidation = $parse(attrs.requiredWhenViewbag)($scope);

          if (!doValidation) {

            return true;
          }

          if (angular.isUndefined(value) || value === null || value === '') {

            return false;
          }

          else {

            return true;
          }
        };

        // Perform validation when the validation condition changes
        $scope.$watch(function () {

          return $parse(attrs.requiredWhenViewbag)($scope);
        }, function () {

          ngModel.$validate();
        });
      }
    };
  }]);
