'use strict';

angular
	.module('architizer.app')
  .filter('hasResponse', [function () {

    /**
     * Returns true if a product request is a BPM response, which:
     *  - has a response
     *  - does not have a recommender_id attribute
     */

		return function (request) {

			if (!request.product_responses) {

        return false;
      }

      var response = _.find(request.product_responses, function(response) {

        return response.workflow_status == 'O' && !response.recommender_id;
      });

			return !!response;
		};
	}])

	.filter('hasDraft', [function () {

    /**
     * Returns true if a product request has a draft response`
     */

		return function (request) {

			if (!request.product_responses) {

        return false;
      }

      var draft = _.find(request.product_responses, function(response) {

        return response.workflow_status == 'R';
      });

			return !!draft;
		};
	}])
  
  .filter('requestStatus', [function () {

    /**
     * Color-codes the product request according to status
     *
     * "R" --> "Draft"
     * "P" --> "Pending"
     * "C" --> "Closed"
     */

    return function (status) {

      if (!status) {
        return null;
      }

      switch (status) {
        case 'R':
        return '<span class="quicksilver-500">Draft</span>';

        case 'P':
        return '<span class="blue-500">Pending</span>';

        case 'C':
        return '<span class="red-400">Closed</span>';

        default:
        return null;
      }
    };
  }]);