'use strict';

angular
	.module('ui')
	.directive('uiScrollMask', ['$animate', function ($animate) {

		return {
			restrict: 'A',
			replace: false,
			transclude: false,
			link: function ($scope, element, attrs) {

				/*
				 * UI params
				 */

				$scope.direction = 'vertical';
				$scope.contentBefore = false;
				$scope.contentAfter = false;


				/*
				 * UI interation
				 */

				// Calculate whether there is content before/after on scroll
				var onChildScroll = function (e) {

					var that = this;
					var startPosition = $scope.direction == 'horizontal' ? this.scrollLeft : this.scrollTop;
					var endPosition = $scope.direction == 'horizontal' ? this.scrollLeft + this.offsetWidth : this.scrollTop  + this.offsetHeight;

					$scope.$apply(function () {

						if (startPosition <= 0) {

							$scope.contentBefore = false;
						}

						else {

							$scope.contentBefore = true;
						}

						if (endPosition >= that.scrollHeight) {

							$scope.contentAfter = false;
						}

						else {

							$scope.contentAfter = true;
						}
					});
				};

				// Update the view according to calculated params
				$scope.$watch('contentBefore', function () {

					if ($scope.contentBefore) {

						$animate.addClass(element, 'contentBefore');
					}

					else {

						$animate.removeClass(element, 'contentBefore');
					}
				});

				$scope.$watch('contentAfter', function () {

					if ($scope.contentAfter) {

						$animate.addClass(element, 'contentAfter');
					}

					else {

						$animate.removeClass(element, 'contentAfter');
					}
				});


				/*
				 * Initialise element
				 */

				element.addClass('uiScrollMask');
				element.addClass($scope.direction == 'horizontal' ? 'directionX' : 'directionY');

				element.children().bind('scroll', onChildScroll);
			}
		};
	}]);
