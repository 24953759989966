(function() {
  'use strict';

  angular
    .module('architizer.app')
    .factory('CustomDataService', CustomDataService);

  CustomDataService.$inject = [
    'LudwigService',
    'UnitsService',
  ];

  function CustomDataService (
    LudwigService,
    UnitsService
  ) {

    var service = this;

    // Configuration
    service.dataPoints = {
      width: width,
      length: length,
      height: height,
      mass: mass,
      volume: volume,
      unitPrice: unitPrice,
      unit_price: unitPrice, // this exists because there is an issue displaying old requests with unit_price defined as field name
      color: color,
      material: material,
      finish: finish,
      application: application,
      brandUrl: brandUrl,
      format: format,
      description: description,
      performance: performance,
      productType: productType,
      product_type: productType,   // NOTE: This is snake_case because our Entity Extraction pipeline merges this data into an existing "product_type" list
      colorName: colorName,
      modelNumber: modelNumber,
      productUrl: productUrl,
      productName: productName,
      referenceBrands: referenceBrands,
      internalNotes: internalNotes,
      leadTime: leadTime,
      price: price,
    };

    // Optional Criteria Data Points that are shown on the Request form
    service.optionalCriteriaDataPoints = [
      color,
      material,
      finish,
      application,
      format,
      length,
      width,
      height,
      mass,
      volume,
      referenceBrands,
    ];

    // Data Points for RequestTagger (/source/i/request-tagger)
    service.requestTaggerDataPoints = [
      productType,
      color,
      material,
      finish,
      application,
      format,
      performance,
      length,
      width,
      height,
      mass,
      volume,
      referenceBrands,
    ];

    // Optional Criteria Data Points that are shown on the "Add Product" modal to add a logged product in the Products view
    service.loggedProductDataPoints = [
      modelNumber,
      colorName,
      color,
      length,
      width,
      height,
      leadTime,
      mass,
      volume,
      material,
      finish,
      application,
      format,
      performance,
      price,
    ];

    // Optional Criteria Data Points for BPM "Send Product" form
    service.bpmSendProductDataPoints = [
      modelNumber,
      colorName,
      color,
      length,
      width,
      height,
      mass,
      volume,
      material,
      finish,
      application,
      format,
      performance,
    ];

    // Functions
    service.getAllDataPoints = getAllDataPoints;
    service.getOptionalCriteriaDataPoints = getOptionalCriteriaDataPoints;
    service.getRequestTaggerDataPoints = getRequestTaggerDataPoints;
    service.getLoggedProductDataPoints = getLoggedProductDataPoints;
    service.getBpmSendProductDataPoints = getBpmSendProductDataPoints;
    service.getDataPointForName = getDataPointForName;
    service.extendInstancesWithTemplates = extendInstancesWithTemplates;
    service.prepareForDisplay = prepareForDisplay;
    service.prepareForAPI = prepareForAPI;

    ////////////////////////////////////////////////////////////////////////////////
    // Functions
    ////////////////////////////////////////////////////////////////////////////////

    /**
     * Get All Data Points
     *
     * Returns all possible datapoints (these can be shown in both Product Requests and Product Responses)
     */
    function getAllDataPoints () {
      var dataPoints = [];

      angular.forEach(service.dataPoints, function (dataPoint) {
        dataPoints.push(dataPoint());
      });

      return dataPoints;
    }

    /**
     * Get Optional Criteria Data Points
     *
     * Returns all datapoints for use with optional criteria section on new Product Request form
     */
    function getOptionalCriteriaDataPoints () {
      var dataPoints = [];

      angular.forEach(service.optionalCriteriaDataPoints, function(dataPoint) {
        dataPoints.push(dataPoint());
      });

      return dataPoints;
    }

    /**
     * Get RequestTagger Data Points
     *
     * Returns all datapoints for use with RequestTagger form
     */
    function getRequestTaggerDataPoints () {
      var dataPoints = [];

      angular.forEach(service.requestTaggerDataPoints, function(dataPoint) {
        dataPoints.push(dataPoint());
      });

      return dataPoints;
    }

    /**
     * Get Data Points for Recommended Products "Add Product" modal
     *
     * Returns all datapoints for use with the "Add Product" modal in Products view
     */
    function getLoggedProductDataPoints () {
      var dataPoints = [];

      angular.forEach(service.loggedProductDataPoints, function(dataPoint) {
        dataPoints.push(dataPoint());
      });

      return dataPoints;
    }

    /**
     * Get Data Points for BPM "Send Product" form
     *
     * Returns all datapoints for use with the "Send Product" form on Opportunity view and in Conversation "Send Product" modal
     */
    function getBpmSendProductDataPoints () {
      var dataPoints = [];

      angular.forEach(service.bpmSendProductDataPoints, function(dataPoint) {
        dataPoints.push(dataPoint());
      });

      return dataPoints;
    }

    /**
     * Extend instances of structured data points (e.g.: the values serialized
     * in the structured_data field of a product request) with their data point
     * definitions (the "template").
     *
     * Static information (e.g.: the decorators) about a data point should be
     * stripped out during serialization of a data point; this function adds
     * that data back in.
     *
     * @param instances An array of structured data points
     */
    function extendInstancesWithTemplates (instances) {
      angular.forEach(instances, function(instance) {
        var template;

        // Legacy format
        if (instance.key) {

          template = getDataPointForName(instance.key);

          instance.fields = [];
          instance.fields.push({
            name: 'value',
            value: instance.value,
          });
          instance.fields.push({
            name: 'unit',
            value: instance.unit
          });
        }

        // New format
        else {
          template = getDataPointForName(instance.name);
        }

        // Don't break if there is an error with finding a template for this structured datapoint
        if (!template) {

          // Just politely tell Raven and we can look into it
          Raven.captureMessage('Structured Data Formatting Error', {
            level: 'info',
            extra: {
              'Structured Data Instance': instance,
            }
          });

          // Carry on
          return;
        }

        // Copy template attributes onto instance
        angular.forEach(instance.fields, function(field, i) {
          // Save the value if it exists
          var value;
          if (instance.fields[i].value) {
            value = angular.copy(instance.fields[i].value);
          }

          instance.fields[i] = angular.extend(field, template.fields[i]);

          if (value) {
            instance.fields[i].value = value;
          }

        });

        // Set up the instance with the template info
        instance.name = template.name;
        instance.label = template.label;
        instance.decorator = template.decorator;
        instance.unit = template.unit;
      });
    }

    /**
     * Prepare structured data for display on static locations
     *
     * Extends instances of structured data points with everything but the options
     */
    function prepareForDisplay (structured_data) {
      angular.forEach(structured_data, function (dataPoint) {
        var template;

        template = getDataPointForName(dataPoint.name);

        // Don't stop the show if no template is found
        if (!template) {
          return;
        }

        // Copy template attributes onto dataPoint
        angular.forEach(dataPoint.fields, function(field, i) {

          // Save the value if it exists
          var value;

          if (dataPoint.fields[i].value) {
            value = angular.copy(dataPoint.fields[i].value);
          }

          // Don't extend with all the other data about this field
          // dataPoint.fields[i] = angular.extend(field, template.fields[i]);

          // Put the value back into the dataPoint
          if (value) {
            dataPoint.fields[i].value = value;
          }

        });

        // Set up the dataPoint with the template info
        dataPoint.name = template.name;
        dataPoint.label = template.label;
        dataPoint.decorator = template.decorator;
        dataPoint.unit = template.unit;

      });
    }

    /**
     * Prepare structured data for send to API
     *
     * Takes in the Optional Criteria fields array and processes it into an
     * array to send as the structured_data object on a product request.
     */
    function prepareForAPI (optionalCriteria) {
      var structuredData = [];

      angular.forEach(angular.fromJson(optionalCriteria), (field) => {
         // Don't push "Description" fields to structuredData
        if (field.name === 'description') {
          return;
        } else {
          pushDataPoint(field);
        }
      });

      /**
       * Helper function to make the individual dataPoints that get saved in productRequest.structured_data
       */
      function pushDataPoint (field) {
        var emptyFieldFound = false;

        var dataPoint = {
          name: field.name,
          fields: []
        };

        angular.forEach(field.fields, function (f) {
          // Set empty flag if the field has no value (or no length, in the case of arrays), otherwise push to dataPoint fields
          if (!f.value || !f.value.length) {
            emptyFieldFound = true;
          } else {
            dataPoint.fields.push({
              name: f.name,
              value: f.value
            });
          }
        });

        // If there were no empty fields found for this criterion, push the criterion to structured data
        if (!emptyFieldFound) {
          structuredData.push(dataPoint);
        }
      }

      // Return the array of structured data points
      return structuredData;
    }

    /**
     * Get the data point for a given name
     *
     * @param name The name of the data point to retrieve
     */
    function getDataPointForName (name) {

      if (service.dataPoints[name]) {
        return service.dataPoints[name]();
      }
    }

    /**
     * Selectize Configuration for text-tags elements
     */
    function selectizeConfig () {
      return {
        create: true,
        createOnBlur: true,
        selectOnTab: false,
        hideSelected: true,
        searchField: ['label', 'value'],
        highlight: true,
        labelField: 'label',
        valueField: 'value',
        delimiter: ',',
        sortField: [{ field: 'count', direction: 'desc' }],
      };
    }

    ////////////////////////////////////////////////////////////////////////////////
    // Data Points
    ////////////////////////////////////////////////////////////////////////////////

    /**
     * Color
     */
    function color () {
      return {
        label: 'Color',
        name: 'color',
        decorator: 'array',
        fields: [
          {
            label: 'Color',
            name: 'value',
            ui: 'text-tags',
            value: [],
            config: selectizeConfig(),
            options: options('color'),
            placeholder: 'Choose from the dropdown or type anything'
          }
        ]
      };
    }

    /**
     * Material
     */
    function material () {
      return {
        label: 'Material',
        name: 'material',
        decorator: 'array',
        fields: [
          {
            label: 'Material',
            name: 'value',
            ui: 'text-tags',
            value: [],
            config: selectizeConfig(),
            options: options('material'),
            placeholder: 'Choose from the dropdown or type anything'
          }
        ]
      };
    }

    /**
     * Finish
     */
    function finish () {
      return {
        label: 'Finish',
        name: 'finish',
        decorator: 'array',
        fields: [
          {
            label: 'Finish',
            name: 'value',
            ui: 'text-tags',
            value: [],
            config: selectizeConfig(),
            options: options('finish'),
            placeholder: 'Choose from the dropdown or type anything'
          }
        ]
      };
    }

    function application () {
      return {
        label: 'Application',
        name: 'application',
        decorator: 'array',
        fields: [
          {
            label: 'Application',
            name: 'value',
            ui: 'text-tags',
            value: [],
            config: selectizeConfig(),
            options: options('application'),
            placeholder: 'Choose from the dropdown or type anything'
          }
        ]
      };
    }

    /**
     * Format
     */
    function format () {
      return {
        label: 'Format',
        name: 'format',
        decorator: 'array',
        fields: [
          {
            label: 'Format',
            name: 'value',
            ui: 'text-tags',
            value: [],
            config: selectizeConfig(),
            options: options('format'),
            placeholder: 'Choose from the dropdown or type anything'
          }
        ]
      };
    }

    /**
     * Description
     */
    function description () {
      return {
        label: 'Description',
        name: 'description',
        decorator: 'text',
        fields: [
          {
            label: 'Description',
            name: 'value',
            ui: 'textarea',
            apiField: 'description',
            placeholder: 'Anything else we should know? Performance criteria, logistical information, etc.'
          }
        ]
      };
    }

    /**
     * Reference Brands
     */
    function referenceBrands () {
      return {
        label: 'Reference Brands',
        name: 'referenceBrands',
        decorator: 'text',
        fields: [
          {
            label: 'Reference Brands or Products',
            name: 'value',
            ui: 'text',
            type: 'text',
            placeholder: 'Are there any brands or products that you have already considered for this search?'
          }
        ]
      };
    }

    /**
     * Width
     */
    function width () {
      return {
        label: 'Width',
        name: 'width',
        decorator: 'unit',
        requireAllFields: true,
        fields: [
          {
            label: 'Width',
            name: 'value',
            ui: 'text',
            type: 'text',
            placeholder: 'Enter a value'
          },
          {
            label: 'Unit Type',
            name: 'unit',
            ui: 'select',
            options: UnitsService.linear.options,
            placeholder: 'Please choose…'
          }
        ]
      };
    }

    /**
     * Length
     */
    function length () {
      return {
        label: 'Length',
        name: 'length',
        decorator: 'unit',
        requireAllFields: true,
        fields: [
          {
            label: 'Length',
            name: 'value',
            ui: 'text',
            type: 'text',
            placeholder: 'Enter a value'
          },
          {
            label: 'Unit Type',
            name: 'unit',
            ui: 'select',
            options: UnitsService.linear.options,
            placeholder: 'Please choose…'
          }
        ]
      };
    }

    /**
     * Height
     */
    function height () {
      return {
        label: 'Height',
        name: 'height',
        decorator: 'unit',
        requireAllFields: true,
        fields: [
          {
            label: 'Height',
            name: 'value',
            ui: 'text',
            type: 'text',
            placeholder: 'Enter a value'
          },
          {
            label: 'Unit Type',
            name: 'unit',
            ui: 'select',
            options: UnitsService.linear.options,
            placeholder: 'Please choose…'
          }
        ]
      };
    }

    /**
     * Mass
     */
    function mass () {
      return {
        label: 'Mass',
        name: 'mass',
        decorator: 'unit',
        requireAllFields: true,
        fields: [
          {
            label: 'Mass',
            name: 'value',
            ui: 'text',
            type: 'text',
            placeholder: 'Enter a value'
          },
          {
            label: 'Unit Type',
            name: 'unit',
            ui: 'select',
            options: UnitsService.mass.options,
            placeholder: 'Please choose…'
          }
        ]
      };
    }

    /**
     * Volume
     */
    function volume () {
      return {
        label: 'Volume',
        name: 'volume',
        decorator: 'unit',
        requireAllFields: true,
        fields: [
          {
            label: 'Volume',
            name: 'value',
            ui: 'text',
            type: 'text',
            placeholder: 'Enter a value'
          },
          {
            label: 'Unit Type',
            name: 'unit',
            ui: 'select',
            options: UnitsService.volume.options,
            placeholder: 'Please choose…'
          }
        ]
      };
    }

    /**
     * Unit Price
     */
    function unitPrice () {
      return {
        label: 'Unit Price',
        name: 'unit_price',
        decorator: 'range',
        requireAllFields: true,
        unit: '$',
        fields: [
          {
            label: 'Price low',
            name: 'price_low',
            ui: 'text',
            type: 'number',
            placeholder: 'Enter a value'
          },
          {
            label: 'Price high',
            name: 'price_high',
            ui: 'text',
            type: 'number',
            placeholder: 'Enter a value'
          }
        ]
      };
    }

    /**
     * Estimated Price Range
     */
    function price () {
      return {
        label: 'Price',
        name: 'price',
        decorator: 'range',
        requireAllFields: true,
        fields: [
          {
            label: 'Min Price',
            name: 'value',
            ui: 'text',
            type: 'number',
            apiField: 'price_low',
            placeholder: '',
            preLabel: '$',
          },
          {
            label: 'Max Price',
            name: 'value',
            ui: 'text',
            type: 'number',
            apiField: 'price_high',
            placeholder: '',
            preLabel: '$',
          },
        ]
      };
    }

    /**
     * Lead Time
     */
    function leadTime () {
      return {
        label: 'Lead Time',
        name: 'leadTime',
        decorator: 'range',
        requireAllFields: true,
        unit: '',
        fields: [
          {
            label: 'Min Lead Time',
            name: 'value',
            ui: 'text',
            type: 'number',
            apiField: 'lead_time_low',
            placeholder: '',
            postLabel: 'weeks',
          },
          {
            label: 'Max Lead Time',
            name: 'value',
            ui: 'text',
            type: 'number',
            apiField: 'lead_time_high',
            placeholder: '',
            postLabel: 'weeks',
          },
        ]
      };
    }

    /**
     * Performance
     */
    function performance () {
      return {
        label: 'Performance',
        name: 'performance',
        decorator: 'text',
        fields: [
          {
            label: 'Performance Criteria',
            name: 'value',
            ui: 'text-tags',
            value: [],
            options: options('performance'),
            config: selectizeConfig(),
            placeholder: 'Enter performance criteria'
          }
        ]
      };
    }

    /**
     * Product Type
     */
    function productType () {
      return {
        label: 'Product Type',
        name: 'product_type',  // NOTE: This is snake_case because our Entity Extraction pipeline merges this data into an existing "product_type" list
        decorator: 'array',
        fields: [
          {
            label: 'Product Type',
            name: 'value',
            ui: 'text-tags',
            value: [],
            options: options('product_type'),
            config: selectizeConfig(),
            placeholder: 'Enter product type'
          }
        ]
      };
    }

    /**
     * Model Number
     */
    function modelNumber () {
      return {
        label: 'Model Number',
        name: 'modelNumber',
        decorator: 'text',
        fields: [
          {
            label: 'Model Number',
            name: 'value',
            ui: 'text',
            type: 'text',
            placeholder: 'Enter model number'
          }
        ]
      };
    }

    /**
     * Color Name
     */
    function colorName () {
      return {
        label: 'Color Name',
        name: 'colorName',
        decorator: 'text',
        fields: [
          {
            label: 'Color Name',
            name: 'value',
            ui: 'text',
            type: 'text',
            placeholder: 'Enter color name'
          }
        ]
      };
    }

    /**
     * Product URL
     */
    function productUrl () {
      return {
        label: 'Product URL',
        name: 'productUrl',
        decorator: 'link',
        fields: [
          {
            label: 'Product URL',
            name: 'value',
            ui: 'text',
            type: 'text',
            placeholder: ''
          }
        ]
      };
    }

   /**
    * Brand URL
    */
    function brandUrl () {
      return {
        label: 'Brand URL',
        name: 'brandUrl',
        decorator: 'link',
        fields: [
          {
            label: 'Brand URL',
            name: 'value',
            ui: 'text',
            type: 'text',
            apiField: 'brandUrl',
            placeholder: ''
          }
        ]
      };
    }

    /**
     * Product Name
     */
    function productName () {
      return {
        label: 'Product Name',
        name: 'productName',
        decorator: 'text',
        fields: [
          {
            label: 'Product Name',
            name: 'value',
            ui: 'text',
            type: 'text',
            apiField: 'name',
            placeholder: ''
          }
        ]
      };
    }

    /**
     * Internal Notes
     */
    function internalNotes () {
      return {
        label: 'Internal Notes',
        name: 'internalNotes',
        decorator: 'longform',
        fields: [
          {
            label: 'Notes',
            name: 'value',
            ui: 'textarea',
            placeholder: 'Leave feedback for our internal team'
          }
        ]
      };
    }

    /**
     * Function to populate options inside of text-tags (Selectize) data point fields
     */
    function options (field) {
      if (!field) { return []; }

      return LudwigService.getProductTags(field)  // Try to get product tags
      .then(mapApiResponseToSelectizeValues)      // Translate API response into Selectize-friendly options
      .catch(() => fallbackOptions()[field]);     // Use fallback options if not available
    }

    /**
     * The API returns "options" in the format { name: 'wall-mounted', count: 12 }
     *
     * Selectize needs "options" in the format { label: 'wall-mounted', value: 'wall-mounted' }
     *
     * This function translates the API response into an array of Selectize-friendly options
     */
    function mapApiResponseToSelectizeValues (options) {
      return options.map((option) => {
        let realValue = '';

        // Find the true value of the option
        if (option.value) {
          realValue = option.value;
        } else if (option.label) {
          realValue = option.label;
        } else if (option.name) {
          realValue = option.name;
        }

        // Give Selectize-friendly properties to the options
        option.value = realValue;
        option.label = realValue;

        return option;
      });
    }

    function fallbackOptions () {
      return {
        color: [
          { label: 'beige', value: 'beige' },
          { label: 'black', value: 'black' },
          { label: 'blue', value: 'blue' },
          { label: 'bronze', value: 'bronze' },
          { label: 'brown', value: 'brown' },
          { label: 'chrome', value: 'chrome' },
          { label: 'copper', value: 'copper' },
          { label: 'cream', value: 'cream' },
          { label: 'gold', value: 'gold' },
          { label: 'green', value: 'green' },
          { label: 'grey - light', value: 'grey - light' },
          { label: 'grey - medium', value: 'grey - medium' },
          { label: 'grey - dark', value: 'grey - dark' },
          { label: 'multicolor', value: 'multicolor' },
          { label: 'orange', value: 'orange' },
          { label: 'pink', value: 'pink' },
          { label: 'purple', value: 'purple' },
          { label: 'red', value: 'red' },
          { label: 'tan', value: 'tan' },
          { label: 'white', value: 'white' },
          { label: 'yellow', value: 'yellow' },
          { label: 'wood - light', value: 'wood - light' },
          { label: 'wood - medium', value: 'wood - medium' },
          { label: 'wood - dark', value: 'wood - dark' },
          { label: 'lighting - warm', value: 'lighting - warm' },
          { label: 'lighting - cool', value: 'lighting - cool' },
          { label: 'lighting - multicolor', value: 'lighting - multicolor' },
        ],
        format: [
          { label: 'large format', value: 'large format' },
          { label: 'tile', value: 'tile' },
          { label: 'plank', value: 'plank' },
          { label: 'panel', value: 'panel' },
          { label: 'applied', value: 'applied' },
          { label: 'poured', value: 'poured' },
          { label: 'blocks', value: 'blocks' },
          { label: 'bricks', value: 'bricks' },
          { label: 'field', value: 'field' },
          { label: 'freestanding', value: 'freestanding' },
          { label: 'large', value: 'large' },
          { label: 'oval', value: 'oval' },
          { label: 'sheet', value: 'sheet' },
          { label: 'pendant', value: 'pendant' },
          { label: 'slatted', value: 'slatted' },
          { label: 'round', value: 'round' },
          { label: 'cylindrical', value: 'cylindrical' },
          { label: 'square', value: 'square' },
          { label: 'rectangle', value: 'rectangle' },
          { label: 'hexagon', value: 'hexagon' },
          { label: 'slab', value: 'slab' },
          { label: 'wide', value: 'wide' },
          { label: 'straight laid', value: 'straight laid' },
          { label: 'herringbone', value: 'herringbone' },
        ],
        material: [
          { label: 'acrylic', value: 'acrylic' },
          { label: 'aluminum', value: 'aluminum' },
          { label: 'brass', value: 'brass' },
          { label: 'cement', value: 'cement' },
          { label: 'ceramic', value: 'ceramic' },
          { label: 'chrome', value: 'chrome' },
          { label: 'clay', value: 'clay' },
          { label: 'concrete', value: 'concrete' },
          { label: 'copper', value: 'copper' },
          { label: 'epoxy', value: 'epoxy' },
          { label: 'fabric', value: 'fabric' },
          { label: 'felt', value: 'felt' },
          { label: 'fiberglass', value: 'fiberglass' },
          { label: 'foam', value: 'foam' },
          { label: 'glass', value: 'glass' },
          { label: 'gypsum', value: 'gypsum' },
          { label: 'granite', value: 'granite' },
          { label: 'iron', value: 'iron' },
          { label: 'laminate', value: 'laminate' },
          { label: 'limestone', value: 'limestone' },
          { label: 'marble', value: 'marble' },
          { label: 'metal', value: 'metal' },
          { label: 'nylon', value: 'nylon' },
          { label: 'plaster', value: 'plaster' },
          { label: 'plastic', value: 'plastic' },
          { label: 'polyester', value: 'polyester' },
          { label: 'porcelain', value: 'porcelain' },
          { label: 'quartz', value: 'quartz' },
          { label: 'resin', value: 'resin' },
          { label: 'rubber', value: 'rubber' },
          { label: 'slate', value: 'slate' },
          { label: 'solid surface', value: 'solid surface' },
          { label: 'stainless steel', value: 'stainless steel' },
          { label: 'steel', value: 'steel' },
          { label: 'stone', value: 'stone' },
          { label: 'travertine', value: 'travertine' },
          { label: 'urethane', value: 'urethane' },
          { label: 'velvet', value: 'velvet' },
          { label: 'veneer', value: 'veneer' },
          { label: 'wood', value: 'wood' },
          { label: 'wood - natural', value: 'wood - natural' },
          { label: 'wood - engineered', value: 'wood - engineered' },
          { label: 'terrazzo', value: 'terrazzo' },
        ],
        finish: [
          { label: 'anodized', value: 'anodized' },
          { label: 'brushed', value: 'brushed' },
          { label: 'clear-coated', value: 'clear-coated' },
          { label: 'frosted', value: 'frosted' },
          { label: 'glazed', value: 'glazed' },
          { label: 'gloss', value: 'gloss' },
          { label: 'high-gloss', value: 'high-gloss' },
          { label: 'honed', value: 'honed' },
          { label: 'lapped', value: 'lapped' },
          { label: 'matte', value: 'matte' },
          { label: 'metallic', value: 'metallic' },
          { label: 'mirror', value: 'mirror' },
          { label: 'natural', value: 'natural' },
          { label: 'non-slip', value: 'non-slip' },
          { label: 'oiled', value: 'oiled' },
          { label: 'painted', value: 'painted' },
          { label: 'polished', value: 'polished' },
          { label: 'powder coated', value: 'powder-coated' },
          { label: 'satin', value: 'satin' },
          { label: 'semi-gloss', value: 'semi-gloss' },
          { label: 'stain resistant', value: 'stain resistant' },
          { label: 'stained', value: 'stained' },
          { label: 'unfinished', value: 'unfinished' },
          { label: 'varnished', value: 'varnished' },
        ],
        application: [
          { label: 'exterior', value: 'exterior' },
          { label: 'interior', value: 'interior' },
          { label: 'wet', value: 'wet' },
          { label: 'dry', value: 'dry' },
          { label: 'wall', value: 'wall' },
          { label: 'ceiling', value: 'ceiling' },
          { label: 'floor', value: 'floor' },
          { label: 'roof', value: 'roof' },
          { label: 'stairs', value: 'stairs' },
          { label: 'stair treads', value: 'stair treads' },
          { label: 'facade', value: 'facade' },
          { label: 'cabinets', value: 'cabinets' },
          { label: 'millwork', value: 'millwork' },
          { label: 'countertops', value: 'countertops' },
          { label: 'paneling', value: 'paneling' },
          { label: 'partition', value: 'partition' },
          { label: 'balustrade', value: 'balustrade' },
          { label: 'guardrail infill', value: 'guardrail infill' },
        ]
      };
    }

    return service;
  }
})();
