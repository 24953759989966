'use strict';

angular
	.module('ui')
	.directive('uiFloatLabel', [function () {

		return {
			replace: true,
			restrict: 'E',
			transclude: true,
			templateUrl: '/views/uikit/ui-float-label.html',
			scope: {
				'for': '=',
				'placeholder': '@'
			},
			link: function () {}
		};
	}]);