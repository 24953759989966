'use strict';

angular
	.module('ui')
	.directive('uiMask', [function () {

		var _masks = {
			integer: {
				parser: function (value) {

					// Remove any non-numeric characters
					return value.replace(/[^0-9]/g, '');
				},
				formatter: function (value) {

					var number = parseInt(value);

					if (isNaN(number)) {

						return '';
					}

					return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
				}
			},
			float: {
				parser: function (value) {

					return value.replace(/[^0-9\.]/g, '');
				},
				formatter: function (value) {

					var integral = '';
					var fractional = '';

					if (value == '' || value == null) {

						return '';
					}

					value = value.toString().replace(/[^0-9\.]/g, '');
					var dot_index = value.indexOf('.');
					if (dot_index != -1) {

						integral = value.substr(0, dot_index);
						fractional = value.substr(dot_index + 1);
						fractional = fractional.replace(/\./g, '');
					} else {

						integral = value;
					}

					integral = integral.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

					return dot_index != -1 ? integral + '.' + fractional : integral;
				}
			}
		};

		return {
			replace: false,
			restrict: 'A',
			require: 'ngModel',
			link: function ($scope, element, attrs, ngModel) {

				// Parse the masks requested
				var maskNames = attrs['uiMask'].split(',');

				if (!maskNames) {

					maskNames = [];
				}

				else if (!angular.isArray(maskNames)) {

					maskNames = [maskNames];
				}

				angular.forEach(maskNames, function (maskName) {

					var mask = _masks[maskName];

					if (!mask) {

						return;
					}

					ngModel.$parsers.push(function (value) {

						var sanitised = mask.parser(value);
						var formatted = mask.formatter(sanitised);

						// Update control value with formatted
						ngModel.$setViewValue(formatted);
						element.val(ngModel.$viewValue);

						return sanitised;
					});

					ngModel.$formatters.push(mask.formatter);
				});
			}
		}
	}]);
