'use strict';

angular
	.module('ui')
	.directive('uiAttribution', [function () {

		return {
			replace: true,
			restrict: 'E',
			transclude: false,
			templateUrl: '/views/uikit/ui-attribution.html',
			scope: {
				primary: '=',
				secondary: '=',
				image: '=',
				size: '@',
				emphasis: '@',
				background: '@'
			},
			link: function ($scope, element, attrs, ngModel) {

				/*
				 * View params
				 */

				$scope.size = $scope.size || 'medium';
				$scope.background = $scope.background || 'light';


				/*
				 * UI interaction
				 */


				/*
				 * Data methods
				 */

				// end here if no ng-model
				if (!ngModel) {

					return;
				}
			}
		};
	}]);