'use strict';

angular
	.module('architizer.app')
	.factory('SegmentioService', [
		'$rootScope',
		'$window',
		'$state',
		'GlobalView',
		'AuthenticationService',
		'User',
		function ($rootScope, $window, $state, globalView, authentication, User) {

		var segmentioService = {
			api: $window.analytics,
			traits: {}
		};

		var options = {};

		segmentioService.setOptions = function(opts) {

			options = angular.extend(options, opts);
		}

		segmentioService.identify = function () {

			var onUserSuccess = function (user) {
				var traits = user.traits || {};

				angular.extend(segmentioService.traits, traits);

				// Segment Limitation: only allow god and professional users to be identified
				if (user.id_god || user.user_progress === 'PROFESSIONAL' || user.user_progress === 'PENDING_PROFESSIONAL') {
					segmentioService.api.identify(
						segmentioService.traits.pk,
						segmentioService.traits,
						angular.extend(authentication.segmentGlobalOptions, options),
					);
				}
			}

			var onUserError = function () {};

			// Get authenticated user
			User
				.me({ force: 'last_company_join_date' })
				.$promise
				.then(onUserSuccess)
				.catch(onUserError);
		}

		var addAppProperties = function(data) {

			data = data || {};

			return angular.extend(data, {
				/* Add variation flags here */
			});
		}

		segmentioService.track = function(name, data) {

			segmentioService.api.track(
				name,
				addAppProperties(data),
				angular.extend(authentication.segmentGlobalOptions, options),
			);
		}

		segmentioService.page = function(name, data) {
			segmentioService.api.page(
				name,
				addAppProperties(data),
				angular.extend(authentication.segmentGlobalOptions, options),
			);
		}

		// identify when user changes
		$rootScope.$watch(function () {

			return authentication.user;
		}, function () {
			segmentioService.identify();
		});

		// return the new service
		return segmentioService;
	}]);
