'use strict';

angular
  .module('architizer.app')
  .controller('SettingsFirmController', [
    'firm',
    'user',
    '$scope',
    '$state',
    '$window',
    'AuthorizationService',
    'AuthenticationService',
    'GlobalView',
    function (firm, user, $scope, $state, $window, authorization, authentication, globalView) {

    	globalView.title = 'Architizer - Settings';
    	globalView.currentSecondaryItem = '';
      
			$scope.$state = $state;
    	$scope.viewbag = {
        firm: firm
      };

      var init = function () {
        // Default to the correct substate
        var parentState = 'settingsFirm';

        if($state.current.name.substr(-parentState.length) === parentState) {

          // Select the correct state based on the user permissions
          if (authorization.userHasPermissionForFirm(
            user,
            firm.id,
            authorization.FIRM_PERMISSIONS.CAN_CHANGE_FIRM_INFO)
          ) {

            $state.go('.company');
          }

          else {

            $state.go('.team.list');
          }
        }

        // Get current user
        authentication.getCurrentUser();

        // Watch for changes to user (logout)
        $scope.$watchCollection(function () { return authentication.user }, onUserChange);
      };


      var onUserChange = function (user) {

        $scope.viewbag.user = user;

        // If user logs out
        if ($scope.viewbag.user === null) {
          // send user to root and force refresh to get out of Angular
          $window.location.assign('/');
        }
      };

      // Update firm displayed in the header
      var _updateFirm = function () {

        // Set header info
        $scope.viewbag.firm = firm;
      };

      $scope.$watch(function () {

        return firm;
      }, _updateFirm, true);

      // Kick things off
      init();
    }]);