(function() {
  'use strict';

  angular
  .module('architizer.app')
  .controller('SourceArchitectProductResponseDismissModalController', SourceArchitectProductResponseDismissModalController);

  SourceArchitectProductResponseDismissModalController.$inject = [
    '$modal',
    '$modalInstance',
    '$state',
    '$timeout',
    'Communication',
    'productResponse',
  ];

  function SourceArchitectProductResponseDismissModalController (
    $modal,
    $modalInstance,
    $state,
    $timeout,
    Communication,
    productResponse
    ) {

    var vm = this;

    // Data
    vm.form = {};
    vm.productResponse = productResponse;
    vm.bpmName = `${ vm.productResponse.creator.first_name } ${ vm.productResponse.creator.last_name }`;
    vm.bpmAvatar = vm.productResponse.creator.images.avatar;

    vm.brandName = vm.productResponse.brand.name;
    vm.productName = vm.productResponse.name;
    vm.models = models();
    vm.elementConfig = elementConfig();

    // Functions
    vm.onDismissClick = onDismissClick;
    vm.onCancelClick = onCancelClick;
    vm.noCheckboxesSelected = noCheckboxesSelected;

    ////////////////////////////////////////////////////////////////////////////////
    // Functions
    ////////////////////////////////////////////////////////////////////////////////

    /**
     * Models
     */
    function models () {
      return {
        reasons: [],
        otherReason: '',
        checkboxes: {
          expense: false,
          aesthetic: false,
          requirements: false,
          other: false,
        },
      };
    }

    /**
     * Element Configuration
     */
    function elementConfig () {
      return {
        checkboxMessages: {
          expense: 'Was too expensive',
          aesthetic: 'Didn\'t match the aesthetic that I was looking for',
          requirements: 'Didn\'t meet my specification requirements',
        },
      };
    }

    /**
     * Function for ng-required to determine whether fields are required
     */
    function noCheckboxesSelected () {
      const checkboxChecked = (vm.models.checkboxes.expense || vm.models.checkboxes.aesthetic || vm.models.checkboxes.requirements || vm.models.checkboxes.other);
      return (!checkboxChecked && vm.form.$submitted);
    }

    /**
     * On Cancel button click, dismiss the modal
     */
    function onCancelClick () {
      $modalInstance.dismiss();
    }

    /**
     * On Dismiss button click, construct the dismiss reasons object
     */
    function onDismissClick () {
      vm.form.$setSubmitted(true);
      vm.form.$valid = false;

      let message = '';

      // Checkbox: Expense
      if (vm.models.checkboxes.expense) {
        vm.models.reasons.push(vm.elementConfig.checkboxMessages.expense);
      }

      // Checkbox: Aesthetic
      if (vm.models.checkboxes.aesthetic) {
        vm.models.reasons.push(vm.elementConfig.checkboxMessages.aesthetic);
      }

      // Checkbox: Requirements
      if (vm.models.checkboxes.requirements) {
        vm.models.reasons.push(vm.elementConfig.checkboxMessages.requirements);
      }

      // Checkbox: Other (Set dismiss message if this is checked)
      if (vm.models.checkboxes.other) {
        message = vm.models.otherReason;
      }

      vm.form.$valid = noCheckboxesSelected() ? false : true;

      // Make sure the user has filled out the form
      if (vm.form.$valid) {
        // Create a Communication $resource
        const communication = new Communication({
          action_requested: 'product_response_dismiss',
          product_response_id: vm.productResponse.id,
          message: message,
          reasons: vm.models.reasons,
        });

        // Post the feedback to the API
        communication
        .$save()
        .then(() => {
          // Close modal when user hits 'Dismiss Product' button
          $modalInstance.close(vm.productResponse);
        });
      }
    }
  }
})();