'use strict';

/**
 * @ngdoc service
 * @name architizerWebsiteApp.api
 * @description
 * # api
 * Service in the architizerWebsiteApp.
 */
angular
	.module('architizer.app')
  .service('ApiService', [
		'$http',
		'$q',
		'$cookies',
		'Config',
    function ($http, $q, $cookies, config)
  {

      var apiService = {};

      /**
       * Make an API GET request
       *
       * @param resource The API resource
       * @param params The query string parameters
       * @param options Angular $http options
			 * @return promise
       */

			apiService.get = function (resource, params = {}, options = {}) {
				var paramsString = '';
				var isCanary = options.canary;

				delete options.canary;

				// add canary param to both the oauth token request and the resource
				// request if required
				if (isCanary) {

					params.canary = true;
				}

      	// params must be an object
      	if (params && typeof params === 'object') {

      		// create params string
	      	var paramsArray = [];
	      	angular.forEach(params, function(value, key) {

	      		paramsArray.push(key + '=' + value);
	      	});

					if (paramsArray.length) {

						paramsString = '?' + paramsArray.join('&');
					}
      	}

				return $http.get(config.apiUrlBase + resource + paramsString, options);
			};


			/**
       * Make an API POST request
       *
       * @param resource The API resource
       * @param payload The JSON payload
       * @param options Angular $http options
			 * @return promise
       */

			apiService.post = function (resource, payload, options = {}) {
				return $http.post(config.apiUrlBase + resource, payload, options);
			};


			/**
       * Make an API DELETE request
       *
       * @param resource The API resource
       * @param options Angular $http options
			 * @return promise
       */

			apiService.delete = function (resource, options = {}) {
				return $http.delete(config.apiUrlBase + resource, options);
			};


			/**
       * Make an API POST request
       *
       * @param resource The API resource
       * @param payload The JSON payload
       * @param options Angular $http options
			 * @return promise
       */

			apiService.patch = function (resource, payload, options = {}) {
				return $http.patch(config.apiUrlBase + resource, payload, options);
			};


			/**
       * Make an API PUT request
       *
       * @param resource The API resource
       * @param payload The JSON payload
       * @param options Angular $http options
			 * @return promise
       */

			apiService.put = function (resource, payload, options = {}) {
				return $http.put(config.apiUrlBase + resource, payload, options);
			};

      return apiService;
    }]);
