'use strict';

/*
 * Configures response to state change events
 */
angular
  .module('architizer.app')
  .run([
    '$location',
    '$state',
    '$timeout',
    '$trace',
    '$transitions',
    '$anchorScroll',
    'GlobalView',
    '$modalStack',
    function ($location, $state, $timeout, $trace, $transitions, $anchorScroll, globalView, $modalStack)
  {

    /**
     * Show uiLoader when page is loading
     */

    $transitions.onStart( { }, function(transition) {
      let toState = transition.to();

      // Dismiss any open Modal.
      $modalStack.dismissAll();

      // If we need to show the uiLoader in a specific ui-view, don't set globalView.loading
      if (toState.data && toState.data.showUiLoaderIn) { return; }

      // Otherwise, set globalView.loading to show the global uiLoader
      globalView.loading = true;

    });

    /**
     * Un-set globalView attributes when state change finishes
     */
    $transitions.onSuccess( { }, function(transition) {
      globalView.currentPrimaryItem = '';
      globalView.currentSecondaryItem = '';

      globalView.loading = false;

      // Create and dispatch new event to send new page URL to the Django app
      var event = new CustomEvent('change_location', {detail: window.location.href});
      window.dispatchEvent(event);
    });

    /**
     * Handle any resolve or data errors on transition errors
     */

    $state.defaultErrorHandler((error) => {
      // Let $transitions handle all internal errors; only handle RejectType = ERROR
      if (error.type === 6) {
        switch (error.detail.status) {

          case 404:
            $state.go('404');
            break;

          case 401:
          case 403:
            $state.go('403');
            break;

          default:
            console.error(error);
            $state.go('500');
            break;
        }
      }
    });
  }]);
