'use strict';

angular
  .module('architizer.app')
  .factory('RoleRequest', ['$resource', 'Config', function($resource, Config) {

    var RoleRequest = $resource(Config.apiUrlBase + 'users/role_requests/:id', { id: '@id' }, {
			mine: {
        isArray: true,
				url: Config.apiUrlBase + 'users/role_requests/mine'
			},
      approve: {
        method: 'PATCH',
        params: { force: 'user' },
        url: Config.apiUrlBase + 'users/role_requests/:id/approve',
        transformRequest: setApprovedFlag,
      },
      decline: {
        method: 'PATCH',
        params: { force: 'user' },
        url: Config.apiUrlBase + 'users/role_requests/:id/decline',
        transformRequest: setDeclineFlag,
      }
		});

    // transformRequest function to set "approve" property on RoleRequest
    function setApprovedFlag (data) {
      data.approve = true;
      return angular.toJson(data);
    }

    // transformRequest function to set "approve" property on RoleRequest
    function setDeclineFlag (data) {
      data.decline = true;
      return angular.toJson(data);
    }

    return RoleRequest;
  }]);
