'use strict';

angular
  .module('architizer.app')
  .config(['$stateProvider', function ($stateProvider) {
    $stateProvider
      .state({
        name: 'careersSingle',
        url: '/careers/job/?gh_jid',
        redirectTo: (transition) => {
          // We redirect all job posting URLs to the canonical Greenhouse URL because we don't want to control this page on our site anymore
          const $window = transition.injector().get('$window');
          const greenhouseBaseUrl = 'https://boards.greenhouse.io/architizer/jobs/';
          $window.location.href = greenhouseBaseUrl + transition.params().gh_jid;
        },
      })
      .state({
        name: 'careers',
        url: '/careers/',
        views: {
          'root@': {
            controller: 'CareersListController',
            controllerAs: '$ctrl',
            templateUrl: '/views/static/careers.html',
          },
        },
        data: {
          showIntercom: false,
          fixedHeader: true,
          showFooter: true,
        },
      });
  }]);
