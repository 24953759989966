(function() {
  'use strict';

  angular
  .module('architizer.app')
  .controller('SourceArchitectProjectsController', SourceArchitectProjectsController);

  SourceArchitectProjectsController.$inject = [
    '$filter',
    '$modal',
    '$state',
    'FlashManager',
    'Project',
    'projects',
  ];

  function SourceArchitectProjectsController (
    $filter,
    $modal,
    $state,
    FlashManager,
    Project,
    projects
  ) {

    var vm = this;

    // Data
    vm.projects = getProjects(projects.response.docs);
    vm.sourceProjects = _.where(vm.projects, { use_context_s: 'S' });
    vm.nonSourceProjects = _.difference(vm.projects, vm.sourceProjects);
    vm.MAX_TEAM_MEMBERS_VISIBLE = 5;

    // Functions
    vm.getBackgroundImage = getBackgroundImage;
    vm.getMembersCount = getMembersCount;

    // Initialize
    init();

    ////////////////////////////////////////////////////////////////////////////////
    // Functions
    ////////////////////////////////////////////////////////////////////////////////

    /**
     * Initialization
     */
    function init () {
    }

    /**
     * Get member information for each project
     */
    function getProjects(projects){
      return angular.forEach(projects, (project) => {
        if(project.project_members_info_ss){
          project.project_members_info_ss = project.project_members_info_ss;
        }
      });
    }

    /**
     * Get Background Image for Project Card
     */
    function getBackgroundImage (project) {
      var heroImage = project.hero_image_url_s;
      if (heroImage) {
        return $filter('imgix')(project, 'medium');
      } else {
        return '/images/placeholder-product-4-3.png';
      }
    }

    /**
     * Get number of members for each project
     */
    function getMembersCount (project) {
      if(project.project_members_info_ss) {
        var membersCount = project.project_members_info_ss.length;
        return membersCount;
      }
    }
  }
})();
