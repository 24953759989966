'use strict';

angular
	.module('ui')
	.directive('uiUserAutocomplete', ['$parse', '$compile', '$document', '$window', '$timeout', '$http', '$q', function ($parse, $compile, $document, $window, $timeout, $http, $q) {

		var _dropdownTemplate = '<div class="uiSelectDropdown uiAutocompleteDropdown" ng-class="{ hasValue: (multi ? current.length : current.value), hover: isHover, focus: isFocussed, invalid: invalid, showImages: true, loading: loading }">' +
				'<div ui-scroll-dot ui-scroll-mask class="dropdown">' +
					'<div class="options scroll">' +
						'<div ng-show="loading" class="loader"><ui-loader width="20" height="20" fore-color="#0cb7fa" back-color="rgba(189,199,205, 0.2)"></ui-loader></div>' +
						'<div ng-hide="loading" class="option {{ option.type }}" ng-click="onOptionClicked(option)" ng-repeat="option in options">' +
							'<ui-placeholder class="image" src="option.image" type="user"></ui-placeholder>' +
							'<div class="text">' +
								'<span class="primary subhead strong">{{ option.primary }}</span>' +
								'<span class="secondary caption baseline weakColor">{{ option.secondary }}</span>' +
							'</div>' +
						'</div>' +
					'</div>' +
				'</div>' +
			'</div>';

		var _emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		return {
			replace: true,
			restrict: 'E',
			templateUrl: '/views/uikit/ui-user-autocomplete.html',
			require: [
				'?ngModel',
				'?ngDisabled'
			],
			scope: {
				ngModel: '=',
				ngDisabled: '=',
				form: '=',
				name: '@',
				label: '@',
				placeholder: '@',
				flasher: '=',
				formatter: '=',
				responseTransform: '=',
				url: '@',
				multi: '=',
				textChanged: '='
			},
			link: function ($scope, element, attrs, controllers) {

				/*
				 * View params
				 */

				$scope.isHover = false;
				$scope.isFocussed = false;
				$scope.dropdownOpen = false;
				$scope.text = '';
				$scope.hasText = false;
				$scope.debounce = 100;
				$scope.options = [];
				$scope.loading = false;

				// Multi mode parameters
				$scope.selectedOptions = []
				$scope.selectedOptionsTags = [];

				var _minQueryLength = 3;
				var _touchedFlag = false;

				// The dropdown element
				var _dropdown;

				// Canceller promise
				var _canceller;


				/*
				 * UI interaction
				 */

				// Used to cancel the blur event
				var _cancelClose = false;

				$scope.onMouseEnter = function () {

					$scope.isHover = true;
				};

				$scope.onMouseOut = function () {

					$scope.isHover = false;
				};

				$scope.onFocus = function () {

					$scope.isFocussed = true;
				};

				$scope.onBlur = function () {

					// Allow a short delay before closing.
					// This is important in the case that a dropdown
					// option is clicked - click must occur before the
					// dropdown closes as a result of the blur()
					// Also, allow the close to be cancelled outright,
					// so that multiple options can be selected in multis
					$timeout(function () {

						// Tokenise an entered string if multi
						if ($scope.multi) {

							_handleTabOrComma();

							// Clear the text, in the case the input
							// wasn't valid
							$scope.text = '';
						}

						// Close the dropdown
						if (_cancelClose) {

							// Refocus on the control
							element.children()[1].focus();

							// Reset the flag
							_cancelClose = false;
						}

						else {

							// Blur and close
							$scope.isFocussed = false;
							closeDropdown();
						}

						// If the control was touched during this interaction,
						// set the touched state on the control
						if (_touchedFlag) {

							setTouched();
						}
					}, 200);
				};

				$scope.onElementClicked = function () {

					toggleDropdown();
				};

				$scope.onOptionClicked = function (option) {

					// Select the option
					selectOption(option);
					closeDropdown();

					if ($scope.multi) {

						// Clear the text
						$scope.text = '';

						// Clear the suggestions so they don't show up
						// next time a search is performed
						$scope.options = [];
					}

					// Set control touched
					_touchedFlag = true;
				};

				$scope.onKeydown = function ($event) {

					switch($event.keyCode) {

						case 8: 	// backspace
							_handleBackspace($event);
							break;

						case 9: 	// tab
						case 188:	// comma
							_handleTabOrComma($event);
							break;
					}

					// Set control touched
					_touchedFlag = true;
				};

				$scope.onKeyup = function($event) {

					_onTextChanged();
				};

				$scope.onRemoveClick = function (tag) {

					if (deselectOption) {

						deselectOption(tag.value);
					}
				};

				// An internal function called when the input text
				// changes. This is when the value of the element
				// changes, not the model, and therefore is called
				// before the debounce is resolved.
				var _onTextChanged = function () {

					// update placeholder when text is changed
					// cannot rely on $modelValue because of debounce
					// so we perform the check when typing occurs
					updatePlaceholder();

					// Call a handler
					if ($scope.textChanged) {

						$scope.textChanged(_getInputUndebouncedValue());
					}
				};

				var _handleBackspace = function ($event) {

					// Multi-select
					if ($scope.multi) {

						// If no text and there are selected tags, remove
						// the last one.
						// Make sure the debounce doesn't interefere with
						// reading this value
						if (!_getInputUndebouncedValue() && $scope.selectedOptionsTags.length) {

							// Get the option for the last tag
							var option = $scope
								.selectedOptionsTags[$scope.selectedOptionsTags.length-1]
								.value;

							// Remove the option
							if (deselectOption) {

								deselectOption(option);
							}
						}
					}

					// Single-select
					else {

						// There may still be text in the box, but the
						// autocompleted value is no longer valid.
						if (clear) {

							clear();
						}
					}
				};

				var _handleTabOrComma = function ($event) {

					// If there is text, create an option
					// Make sure the debounce doesn't interfere with
					// reading this value
					var text = _getInputUndebouncedValue();

					if (text) {

						// The text accepts email addresses only.
						// Validate before proceeding
						if (!_emailRegex.test(text)) {

							// Cancel moving focus to the next element
							if ($event) {

								$event.preventDefault();
							}

							return;
						}

						var option = {
							primary: text,
							value: text
						};

						if (selectOption) {

							selectOption(option);
						}

						// Clear the text input
						$scope.text = '';

						// Cancel moving focus to the next element
						if ($event) {

							$event.preventDefault();
						}
					}
				};


				/*
				 * UI methods
				 */

				var openDropdown = function () {

					$scope.dropdownOpen = true;

					// Compile the dropdown template and link to scope
					// The dropdown will share the same scope as the
					// main select element
					if (!_dropdown) {

						var linkFn = $compile(_dropdownTemplate);
						_dropdown = angular.element(linkFn($scope));

						// We use the error state if the invalid class is on
						// on the element. However since the dropdown is out
						// of document flow, we cannot target using CSS. Set
						// a flag on the scope instead.
						$scope.invalid = element.hasClass('invalid');

						// Append to the document
						angular.element($document[0].body).append(_dropdown);
					}

					// Begin the position loop
					(function positionLoop () {

						// Only position as long as dropdown is open
						if ($scope.dropdownOpen) {

							requestAnimFrame(positionLoop);
							reposition();
						}
					})();
				};

				var closeDropdown = function () {

					$scope.dropdownOpen = false;

					// Remove the dropdown
					if (_dropdown) {

						_dropdown.remove();
						_dropdown = null;
					}

					// Remove options from dropdown
					$scope.options = [];
				};

				var toggleDropdown = function () {

					if ($scope.dropdownOpen) {

						closeDropdown();
					}

					else {

						openDropdown();
					}
				};

				var reposition = function () {

					// Reposition the dropdown if one exists
					if (_dropdown) {

						// Get the location of the select element
						var rect = element.children()[1].getBoundingClientRect();

						var scrollX = $window.scrollX || $window.pageXOffset;
						var scrollY = $window.scrollY || $window.pageYOffset;

						// Position the dropdown
						_dropdown
							.css('left', rect.left + scrollX + 'px')
							.css('top', rect.bottom + scrollY + 'px')
							.css('width', rect.width + 'px');
					}
				};

				// Start the autocomplete
				var doAutocomplete = function (query) {

					if (!query) {

						return;
					}

					// Cancel autocomplete if not minimum length
					if (query.length < _minQueryLength) {

						// Clear and close the dropdown
						$scope.options = [];
						closeDropdown();

						return;
					}

					// Replace the %query% token with what's in the text box
					var url = $scope.url.replace(/%query%/g, query);

					// Set loading state
					$scope.loading = true;

					// Show the dropdown
					openDropdown();

					// Cancel a previous request
					if (_canceller) {

						_canceller.resolve();
					}

					// Set up canceller
					_canceller = $q.defer();

					// Perform the autocomplete
					$http.get(url, { timeout: _canceller.promise })
						.success(onAutocompleteSuccess)
						.error(onAutocompleteError);
				};

				// When the autocomplete is successful
				var onAutocompleteSuccess = function (data) {

					// Transform the response
					var results = $scope.responseTransform ? $scope.responseTransform(data) : data;

					// Format the options
					var options = [];

					angular.forEach(results, function (result) {

						var option = $scope.formatter ? $scope.formatter(result) : result;
						options.push(option);
					});

					// Set the control options
					$scope.options = options;

					// Hide the dropdown if no results
					if (!options.length) {

						closeDropdown();
					}

					// Set loading state
					$scope.loading = false;
				};

				// When the autocomplete was unsuccessful
				var onAutocompleteError = function (error) {

					// Error handler is called not just on errors
					// but also when cancelled. Don't clear the
					// results in this case
					// A real error would return what the error is -
					// a cancelled request does not
					if (!error) {

						return;
					}

					$scope.options = [];
					closeDropdown();

					// Set loading state
					$scope.loading = false;
				};

				// Watch for when the query changes
				// Text may be set on init. Only show if control
				// has been touched
        var debounceTimer;
        $scope.$watch('text', function (query) {

          if (_touchedFlag) {

            $timeout.cancel(debounceTimer);
            debounceTimer = $timeout(function() {
                doAutocomplete(query);
            }, 1500);
					}
				});

				// Conditionally show or hide the placeholder
				var updatePlaceholder = function () {

					$scope.hasText = !!_getInputUndebouncedValue() || $scope.selectedOptionsTags.length;
				};

				var _getInputUndebouncedValue = function () {

					var inputElement = element.children().eq(1).children().eq(0).children().eq(1).children()[1];

					if (inputElement) {

						return inputElement.value;
					}

					return null;
				};

				// Clean up
				$scope.$on('$destroy', function () {

					// Positioning loop looks at dropdownOpen param
					// to see if it should continue.
					// Make closed on destroy to make sure loop
					// doesn't continue
					$scope.dropdownOpen = false;
				});


				/*
				 * Data methods
				 */

				// end here if no ng-model
				if (!controllers[0]) {

					return;
				}

				var ngModel = controllers[0];

				// Sets touched state on model
				var setTouched = function () {

					ngModel.$setTouched();
				};

				var selectOption = function (option) {

					if ($scope.multi) {

						// Add the value to the model list
						ngModel.$viewValue.push(option.value);

						// Trigger validation. Since the model is an
						// array, we need to do this manually as the
						// $watch won't pick the change up.
						ngModel.$validate();

						// Add the option to the selected options list
						$scope.selectedOptions.push(option);
					}

					else {

						// Set the model to the value of the option
						ngModel.$setViewValue(option.value);
					}

					// Update the view
					ngModel.$render();
				};

				var deselectOption = function (option) {

					// Remove the value from the model list
					var index = ngModel.$viewValue.indexOf(option.value);
					ngModel.$viewValue.splice(index, 1);

					// Remove the option from the selected options
					index = $scope.selectedOptions.indexOf(option);
					$scope.selectedOptions.splice(index, 1);

					// Update the view
					ngModel.$render();
				};

				var clear = function () {

					ngModel.$setViewValue(null);
				};

				// Find the option hash that has the given value
				var getOptionByValue = function (value) {

					if ($scope.multi) {

						return _.findWhere($scope.selectedOptions, { value:value });
					}

					else {

						return _.findWhere($scope.options, { value:value });
					}
				};

				var cancelDebounce = function () {

					ngModel.$rollbackViewValue();
				};

				// Find an array of option hashes corresponding to the
				// provided array of values
				var getOptionsByValues = function (values) {

					var options = [];
					_.forEach(values, function (value) {

						var option = getOptionByValue(value);

						if (option) {

							options.push(option);
						}
					});

					return options;
				};

				ngModel.$render = function () {

					if ($scope.multi) {

						// Update tags
						$scope.selectedOptionsTags = [];

						// Format the options
						var options = getOptionsByValues(ngModel.$viewValue);

						angular.forEach(options, function (option) {

							$scope.selectedOptionsTags.push({
								label: option.primary,
								value: option
							});
						});

						// Position the text input after the tags. Wait for the next
						// digest cycle since a new tag might need to be rendered first
						$timeout(function () {

							var inputElement = element.children().eq(1).children().eq(0).children();
							var tagsElement = element.children().eq(1).children()[1];

							if (tagsElement) {

								var tagsWidth = tagsElement.clientWidth;
								inputElement.css('paddingLeft', tagsWidth + 'px');
							}
						});
					}

					else {

						// Pass the current option to the view
						var option = getOptionByValue(ngModel.$viewValue);

						// Set the control text
						if (option) {

							$scope.text = option.primary;
						}
					}

					// Update placeholder
					updatePlaceholder();
				};
			}
		};
	}]);
