(function () {
  'use strict';

  angular
  .module('architizer.app')
  .controller('CareersListController', CareersListController);

  CareersListController.$inject = ['$http'];

  function CareersListController ($http) {
    let vm = this;

    vm.departments = [];
    vm.notEmpty = (department) => department.jobs.length;

    init();

    function init () {
      // Get Departments Data from Greenhouse
      $http
      .get('https://api.greenhouse.io/v1/boards/architizer/embed/departments', {content: true})
      .then((response) => vm.departments = response.data.departments);
    }
  }
})();
