'use strict';

angular
	.module('ui')
	.directive('uiPill', [function () {

		return {
			replace: true,
			restrict: 'E',
			transclude: false,
			templateUrl: '/views/uikit/ui-pill.html',
			require: '?ngModel',
			scope: {
				ngModel: '=',
				options: '='
			},
			link: function ($scope, element, attrs, ngModel) {

				/*
				 * View params
				 */


				/*
				 * UI interaction
				 */

				$scope.onOptionClick = function (option) {

					ngModel.$setViewValue(option.value);
				};

				/*
				 * Data methods
				 */

				// end here if no ng-model
				if (!ngModel) {

					return;
				}
			}
		};
	}]);