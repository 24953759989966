'use strict';

angular
	.module('architizer.app')

	/*
	 * `date` filter
	 *
	 * Renders a timestamp as a date string, using a provided
	 * MomentJS format string.
	 *
	 * Default format string outputs Jan 1, 2015.
	 *
	 */
	
	.filter('date', [function () {

		return function (value, formatString) {

			if (!value) { return };

			formatString = formatString || 'MMM D, YYYY'

			var formatted = moment(value).format(formatString);
			return formatted;
		};
	}])
	
	
	/*
	 * `isDateWithin` filter
	 *
	 * Renders a timestamp as a date string, using a provided
	 * MomentJS format string.
	 *
	 * Default format string outputs Jan 1, 2015.
	 *
	 */
	
	.filter('isDateWithin', [function () {

		return function (value, amount, unit, forward) {

			if (!value || !amount) { return false };
			
			unit = unit || 'days';

			// Look backward
			if (!forward) {
			
				var adjusted = moment(value).add(amount, unit);
				return adjusted.isAfter();	
			}
			
			// Look forward
			else {
			
				var adjusted = moment(value).subtract(amount, unit);
				return adjusted.isBefore();	
			}
		};
	}])


	/*
	 * `days` filter
	 *
	 * Renders a number of days in weeks or months if
	 * required (used in lead time display)
	 *
	 */
	
	.filter('days', [function () {

		return function (values, includeUnit, separator) {

			if (values == null || (angular.isArray(values) && !values.length)) {

				return null;
			}

			// Default to showing the unit
			if (includeUnit == null) {

				includeUnit = true;
			}

			// Default to showing a separator
			if (separator == null) {

				separator = ' – ';
			}

			// Allow for single value or list of values
			if (!(values instanceof Array)) {

				values = [values];
			}

			// Find the correct unit
			var unit = 'days',
				radix = 1;

			// Months
			if (values[0] % 30 == 0) {

				if (!values[1] || (values[1] % 30 == 0)) {

					unit = 'months';
					radix = 30;
				}
			}

			// Weeks
			else if (values[0] % 7 == 0) {

				if (!values[1] || (values[1] % 7 == 0)) {

					unit = 'weeks';
					radix = 7
				}
			}

			// Format the result as "%one[[%separator]%two] [%units]"
			// e.g.: 7 - 10 days
			return (values[0] / radix) +
				(values[1] ? separator + (values[1] / radix) : '') +
				(includeUnit ? ' ' + unit : '');
		};
	}])

	/**
	 * `weeks` filter
	 *
	 * Renders a number of weeks from a given number of days
	 * Rounds up to the next week if a remainder exists
	 */
	.filter('weeks', [function () {
		return function (input) {

			var remainder = (input % 7);

			if (remainder) {
				input = input - remainder;
			}

			var weeks = (input / 7);
			if (remainder) {
				return weeks + 1;
			} else {
				return weeks;
			}
		};
	}])

	/*
	 * `from-now` filter
	 *
	 * Renders a timestamp from the past as a string.
	 * The formatting style is applied according to how
	 * long ago the date was.
	 *
	 * Wrapper around MomentJS.
	 *
	 */
	
	.filter('fromNow', [function () {

		return function (value) {

			var created = moment(value).fromNow();
			return created;
		};
	}])


	/*
	 * `time-remaining` filter
	 *
	 * Renders a timestamp in the future as a string.
	 * The formatting style is applied according to how
	 * far from now the date is. e.g.:
	 *
	 * '2 minutes'
	 * '68 hours'
	 * '4 days'
	 *
	 * Input must be an ISO 8601 timestamp (e.g.:
	 * '2014-11-24T02:17:26Z')
	 *
	 * The output can be compacted according to the length
	 * parameter. e.g.:
	 *
	 * 'short' -> '< 1 min'
	 * 'long' -> 'Less than a minute'
	 *
	 * 'noValueString' argument can be passed in if you want the UI to
	 *  display a certain string when the value is null
	 */
	
	.filter('timeRemaining', [function () {

		return function (value, suffix, expired, noValueString='') {

			var date = moment(value);

			// Is the date in the past
			if (date.isBefore()) {

				return expired;
			}

			// If the value is null
			if(!value){
				return noValueString;
			}

			return date.fromNow(true) + ' ' + (suffix ? suffix : '');
		};
	}])


	/*
	 * `time-remaining-or-open` filter
	 *
	 * Renders a timestamp in the future as a string.
	 * The formatting style is applied according to how
	 * long from now the date is. e.g.:
	 *
	 * '2 minutes'
	 * '68 hours'
	 * '4 days'
	 *
	 * Returns 'open' if the date is over 48 hours in
	 * the future.
	 *
	 * Input must be an ISO 8601 timestamp (e.g.:
	 * '2014-11-24T02:17:26Z')
	 *
	 */
	
	.filter('timeRemainingOrOpen', [function () {

		return function (value, suffix, expired) {

			var date = moment(value);

			// Is the date in the past
			if (date.isBefore()) {

				return expired;
			}

			var ahead48hours = moment().add(48,'h');

			if (date.isAfter(ahead48hours)) {
				return 'open';
			}
			else {
				return date.fromNow(true) + ' ' + (suffix ? suffix : '');
			}
		};
	}])


	/*
	 * Filters an array of objects to those which are in the future
	 *
	 * @param array<object> input A list of objects to filter
	 * @param mixed key The object key which contains an ISO timestamp
	 * @return array The objects that are in the future
	 */
	
	.filter('objsInFuture', function () {

		return function (input, key) {

			var filtered = [];
			var now = moment();

			angular.forEach(input, function (obj) {

				// If the moment is in the future
				if (moment().diff(obj[key]) <= 0) {

					filtered.push(obj);
				}
			});

			return filtered;
		};
	})


	/*
	 * Filters an array of objects to those which are in the past
	 *
	 * @param array<object> input A list of objects to filter
	 * @param mixed key The object key which contains an ISO timestamp
	 * @return array The objects that are in the future
	 */
	.filter('objsInPast', function () {

		return function (input, key) {

			var filtered = [];
			var now = moment();

			angular.forEach(input, function (obj) {

				// If the moment is in the past
				if (moment().diff(obj[key]) > 0) {

					filtered.push(obj);
				}
			});

			return filtered;
		};
	})


	/*
	 * Filters an array of objects to those which are in the past
	 *
	 * @param array<object> input A list of objects to filter
	 * @param mixed key The object key which contains an ISO timestamp
	 * @return bool
	 */
	
	.filter('inPast', function () {

		return function (input) {

			// If the moment is in the past
			return moment().diff(input) > 0;
		};
	});
