'use strict';

angular
  .module('architizer.app')
  .filter('getBrandName', [function () {

    /**
     * Returns the brand name for a conversation
     */

    return function (conversation) {

      if (!conversation || !conversation.sides) {

        return null;
      }

      var brandSide = _.find(conversation.sides, function(side) {
        return side.content_object_type === 'brand';
      });

      if (brandSide) {
        return brandSide.content_object.name;
      }

      return 'Unknown Brand';
    };
  }])

  .filter('getOtherUser', [function () {

    /**
     * Returns the conversation user who is on the other "side"
     */

    return function (conversation) {

      if (!conversation || !conversation.sides) {

        return null;
      }

      var mySide = conversation.my_side;

      var otherSide = _.find(conversation.sides, function(side) {
        return side.id !== mySide;
      });

      if (otherSide) {
        return otherSide;
      }

      return otherSide;
    };
  }])

  .filter('messageGroupNeedsDatebar', [function() {

    /**
     * Returns boolean for whether this messageGroup needs to show the date above in the conversation content view
     */

    return function(inputGroup, lastGroup) {

      // Return true if this is the first group
      if (!lastGroup) {
        return true;
      }

      // Check if the previous messageGroup is for the same day as this messageGroup
      var lastDate = moment(lastGroup.date);
      var inputDate = moment(inputGroup.date);

      return !lastDate.isSame(inputDate, 'day');
    };
  }]);
