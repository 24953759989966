'use strict';

angular
  .module('architizer.app')
  .factory('AuthenticationService', [
    '$rootScope',
    '$window',
    '$http',
    '$timeout',
    '$cookies',
    '$q',
    'GlobalView',
    'Config',
    'ApiService',
    'Firm',
    'Brand',
    'User',
    'PopupManager',
    function ($rootScope, $window, $http, $timeout, $cookies, $q, globalView, config, apiService, Firm, Brand, User, popupManager) {

      var authenticationService = {
        hasUser: false,
        userPromise: null,
        user: null,
        singularToken: null,
        segmentGlobalOptions: {},
      };

      authenticationService.isProfessionalUser = function (user = authenticationService.user) {
        if (user && (user.is_god || user.user_progress === 'PROFESSIONAL' || user.user_progress === 'PENDING_PROFESSIONAL')) {
          return true;
        }
        return false;
      }

      /**
       * Get Current User
       *
       * This function returns a promise AND sets service.user to the user that is returned
       */
      authenticationService.getCurrentUser = function () {
        // If we already have a stored user, don't re-request - just resolve it
        var deferred = $q.defer();

        if (!authenticationService.userPromise) {

          // Get user from /users/me
          User.me()
          .$promise
          .then(getUserSuccess)
          .catch(getUserError);

          authenticationService.userPromise = deferred.promise;
        }

        // User Success
        function getUserSuccess (httpData) {
          var user = new User(httpData);

          // Segment Limitation: only allow god and professional users to be identified
          let callback = handleSingularUserToken;
          if (authenticationService.isProfessionalUser(user)) {
            callback = handleProfessionalUserToken;
          }
          constructSingularToken(user.user_progress, callback);

          // Get firms and brands for this user
          var firmsForUser = authenticationService.getFirmsForUser(user);
          var brandsForUser = authenticationService.getBrandsForUser(user);

          // Initialize firms and brands associated with the user as empty arrays
          user.firms = [];
          user.brands = [];

          // Store the user in the service
          authenticationService.user = user;

          // Set up Sentry's user-specific error reporting
          Raven.setUserContext({ id: user.id });

          Promise
            .all([firmsForUser, brandsForUser])
            .then(([firms, brands]) => {
              user.firms = firms;
              user.brands = brands;
            })
            .then(() => {
              deferred.resolve(user);
            })
            .catch((e) => {
              console.error('Failed to get Firms and Brands associated with the user');
            });
        }

        // User Error
        function getUserError (error) {
          constructSingularToken('VISITOR', handleSingularUserToken);

          // Reset Sentry's user-specific error reporting
          Raven.setUserContext();

          deferred.reject({ data: error, code: error.status });
        }

        // Set global options for Segment as a singular visitor
        function handleSingularUserToken () {
          authenticationService.segmentGlobalOptions = {
            anonymousId: authenticationService.singularToken,
            userId: null,
            context: {
              ip: '0.0.0.0',
            },
            integrations: {}
          };

          localStorage.setItem('ajs_user_id', null);
          $cookies.put('ajs_user_id', null, {
            expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
            path: '/',
            domain: (location.host[0] === '.' ? '' : '.') + location.host,
          });
        }

        // Set global options for Segment as a user
        function handleProfessionalUserToken () {
          authenticationService.segmentGlobalOptions = {
            anonymousId: authenticationService.singularToken
          };
        }

        // Segment Limitation: only allow god and professional users to be identified
        // Construct Segment Singular Token
        // (mm)(dd)(YYYY)-(SINGULAR_TOKEN_TYPE)438-(4)07f-817d-3c9030178bee
        function constructSingularToken (type = 'VISITOR', callback = () => {}) {
          const SINGULAR_TOKEN_TYPE = {
            PROFESSIONAL: 6,
            PENDING_PROFESSIONAL: 5,
            NON_PROFESSIONAL: 4,
            GENERIC: 3,
            NOT_VERIFIED: 2,
            VISITOR: 1
          }
          const token = ['00000000', '1438', '407f', '817d', '3c9030178bee'];
          try {
            const date = new Date();
            token[0] = ('0' + (date.getUTCMonth() + 1)).slice(-2) + ('0' + date.getUTCDate()).slice(-2) + date.getUTCFullYear();
            token[1] = SINGULAR_TOKEN_TYPE[type] + '438';
          } catch (_) {}
          authenticationService.singularToken = token.join('-');
          callback();
        }

        return $q.when(authenticationService.userPromise);
      };

      /**
       * Redirect to Django app for sign-in
       */

      authenticationService.presentSigninModal = function () {
        var pathname = $window.location.pathname.slice(1); // Slice off the front slash of the path
        var thisPage = encodeURIComponent(pathname + $window.location.search + $window.location.hash);

        $window.location = ('/login/?next=' + thisPage);
      };

      /**
       * Redirect to Django app to register
       */

      authenticationService.presentRegisterModal = function () {
        var pathname = $window.location.pathname.slice(1); // Slice off the front slash of the path
        var thisPage = encodeURIComponent(pathname + $window.location.search + $window.location.hash);

        $window.location = ('/register/?next=' + thisPage);
      };

      /**
       * Helper methods
       */

      authenticationService.getFirmsForUser = function (user) {
        // Get a list of firm ids the user is associated with
        var ids = [],
            deferred = $q.defer();

        angular.forEach(user.permissions.firms, function (permissions, id) {

          ids.push(id);
        });

        // Process the array into a string for "ids"
        ids = ids.join(',');

        // Fetch the firms
        Firm.query({ ids: ids })
          .$promise
          .then((data) => deferred.resolve(data))
          .catch((error) => deferred.reject(error))

        return deferred.promise;
      };

      authenticationService.getBrandsForUser = function (user) {

        // Get a list of brand ids the user is associated with
        var ids = [],
            deferred = $q.defer();
        angular.forEach(user.permissions.brands, function (permissions, id) {

          ids.push(id);
        });

        // Process the array into a string for "ids"
        ids = ids.join(',');

        // Fetch the brands
        Brand.query({ ids: ids })
          .$promise
          .then((data) => deferred.resolve(data))
          .catch((error) => deferred.reject(error))

        return deferred.promise;
      };

      authenticationService.logout = function (success) {

        // Clear server cookie
        apiService
          .post('authentication/logout', {})
          .then(function() {

            authenticationService.user = null;

            // clear Sentry's user-specific error reporting
            Raven.setUserContext();

            // TEMP: Clear session info
            $cookies.remove('arc-auth');
            $cookies.remove('architizer-session');

            if (success) {
              success();
            }
          });
      };

      // update the hasUser property when user changes
      $rootScope.$watch(function () {

        return authenticationService.user;
      }, function (user) {

        authenticationService.hasUser = !!user;
      });

      return authenticationService;
    }]);
