'use strict';

/*
 * Bootstraps firm settings routes
 * FIXME: Replace usage of RouteHelperService below to send a resolved
 * user from a parent state. See ../../source/public.routes.js for a precedent.
 */
angular
  .module('architizer.app')
  .config(['$stateProvider', function ($stateProvider) {

    $stateProvider
    .state({
      name: 'settingsFirm',
      url: '/dashboard/firm/:slug/',
      views: {
        'root@': {
          controller: 'SettingsFirmController',
          templateUrl: '/views/settings/firm.html',
        },
      },
      data: {
        showIntercom: true,
        fixedHeader: true,
        showFooter: true,
      },
      resolve: {
        user: ['firm', 'RouteHelperService', 'AuthorizationService', function (firm, routeHelper, authorization) {

          var fnAuth = function (user) {

            return authorization.userHasPermissionForFirm(
              user,
              firm.id,
              [
                authorization.FIRM_PERMISSIONS.CAN_CHANGE_FIRM_INFO,
                authorization.FIRM_PERMISSIONS.CAN_CHANGE_FIRM_USERS,
                authorization.FIRM_PERMISSIONS.CAN_VIEW_FIRM_USERS
              ]
            );
          };

          return routeHelper.checkAuth(fnAuth);
        }],
        firm: ['$transition$', '$q', 'Firm', function ($transition$, $q, Firm) {

          return Firm.get({
            id: $transition$.params().slug,
            force: 'images'
          }).$promise;
        }]
      }
    })
    .state({
      name: 'settingsFirm.company',
      url: 'company/',
      views: {
        'dashboard': {
          controller: 'SettingsFirmCompanyController',
          templateUrl: '/views/settings/firm-company.html',
        },
      },
      data: {
        showIntercom: true,
        fixedHeader: true,
        showFooter: true,
      },
      resolve: {
        user: ['firm', 'RouteHelperService', 'AuthorizationService', function (firm, routeHelper, authorization) {

          var fnAuth = function (user) {

            return authorization.userHasPermissionForFirm(
              user,
              firm.id,
              authorization.FIRM_PERMISSIONS.CAN_CHANGE_FIRM_INFO
            );
          };

          return routeHelper.checkAuth(fnAuth);
        }]
      }
    })
    .state({
      name: 'settingsFirm.team',
      url: 'team/',
      views: {
        'dashboard': {
          templateUrl: '/views/settings/firm-team.html',
        },
      },
      data: {
        showIntercom: true,
        fixedHeader: true,
        showFooter: true,
      },
      abstract: true,
      resolve: {
        user: ['firm', 'RouteHelperService', 'AuthorizationService', function (firm, routeHelper, authorization) {

          var fnAuth = function (user) {

            return authorization.userHasPermissionForFirm(
              user,
              firm.id,
              authorization.FIRM_PERMISSIONS.CAN_VIEW_FIRM_USERS
            );
          };

          return routeHelper.checkAuth(fnAuth);
        }],
        roleRequests: ['user', 'firm', 'RoleRequest', 'AuthorizationService', function (user, firm, RoleRequest, authorization) {

          // Don't display role requests if user doesn't have
          // change_user permissions
          if (!authorization.userHasPermissionForFirm(
            user,
            firm.id,
            [authorization.FIRM_PERMISSIONS.CAN_CHANGE_FIRM_USERS]
          )) {

            return [];
          }

          return RoleRequest.query({ firm_id: firm.id, force: 'user,images' }).$promise;
        }],
        invitations: ['user', 'firm', 'Invitation', 'AuthorizationService', function (user, firm, Invitation, authorization) {

          // Don't display invitations if user doesn't have
          // change_user permissions
          if (!authorization.userHasPermissionForFirm(
            user,
            firm.id,
            [authorization.FIRM_PERMISSIONS.CAN_CHANGE_FIRM_USERS]
          )) {

            return [];
          }

          return Invitation.query({ firm_id: firm.id }).$promise;
        }],
        team: ['user', 'firm', 'User', function (user, firm, User) {

          return User.query({ firm_id: firm.id, force: 'images' }).$promise;
        }]
      }
    })
    .state({
      name: 'settingsFirm.team.list',
      url: '',
      views: {
        'team': {
          controller: 'SettingsFirmTeamListController',
          templateUrl: '/views/settings/firm-team-list.html',
        },
      },
      data: {
        showIntercom: true,
        fixedHeader: true,
        showFooter: true,
      },
    })
    .state({
      name: 'settingsFirm.team.add',
      url: 'add/',
      views: {
        'team': {
          controller: 'SettingsFirmAddTeamMembersController',
          templateUrl: '/views/settings/firm-add-team-members.html',
        },
      },
      data: {
        showIntercom: true,
        fixedHeader: true,
        showFooter: true,
      },
      resolve: {
        user: ['firm', 'RouteHelperService', 'AuthorizationService', function (firm, routeHelper, authorization) {

          var fnAuth = function (user) {

            return authorization.userHasPermissionForFirm(
              user,
              firm.id,
              [authorization.FIRM_PERMISSIONS.CAN_CHANGE_FIRM_USERS]
            );
          };

          return routeHelper.checkAuth(fnAuth);
        }]
      }
    })
    .state({
      name: 'settingsFirm.team.updateInvitation',
      url: 'invitation/:invitationId/',
      views: {
        'team': {
          controller: 'SettingsFirmUpdateInvitationController',
          templateUrl: '/views/settings/firm-update-team-settings.html',
        },
      },
      data: {
        showFooter: true,
      },
      resolve: {
        user: ['firm', 'RouteHelperService', 'AuthorizationService', function (firm, routeHelper, authorization) {

          var fnAuth = function (user) {

            return authorization.userHasPermissionForFirm(
              user,
              firm.id,
              [authorization.FIRM_PERMISSIONS.CAN_CHANGE_FIRM_USERS]
            );
          };

          return routeHelper.checkAuth(fnAuth);
        }],

        invitation: ['user', '$transition$', 'Invitation', function (user, $transition$, Invitation) {

          return Invitation.get({ id: $transition$.params().invitationId }).$promise;
        }]
      }
    })
    .state({
      name: 'settingsFirm.team.update',
      url: 'user/:userId/',
      views: {
        'team': {
          controller: 'SettingsFirmUpdateUserSettingsController',
          templateUrl: '/views/settings/firm-update-team-settings.html',
        },
      },
      data: {
        showIntercom: true,
        fixedHeader: true,
        showFooter: true,
      },
      resolve: {
        user: ['firm', 'RouteHelperService', 'AuthorizationService', function (firm, routeHelper, authorization) {

          var fnAuth = function (user) {

            return authorization.userHasPermissionForFirm(
              user,
              firm.id,
              [authorization.FIRM_PERMISSIONS.CAN_CHANGE_FIRM_USERS]
            );
          };

          return routeHelper.checkAuth(fnAuth);
        }],

        subject: ['firm', 'user', '$transition$', 'User', function (firm, user, $transition$, User) {
          return User.publicUser({ id: $transition$.params().userId, firmId: firm.id }).$promise;
        }],

        userSettings: ['$transition$', 'UserSettings', 'firm', function ($transition$, UserSettings, firm) {

          return UserSettings.get({
            userId: $transition$.params().userId,
            organizationType: 'firm',
            organizationId: firm.id
          }).$promise;
        }]
      }
    });
  }]);
