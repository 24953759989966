'use strict';

angular
	.module('architizer.app')
	.filter('phase', ['ProjectService', function (project) {
	
			return function (value) {

				if (!value) {

					return;
				}

				var phase = project.getPhaseByValue(value);

				return phase ? phase.label : '';
			};
	}]);