'use strict';

angular
    .module('architizer.app')
    .controller('TeamController', [
        '$scope',
        function ($scope)
        {

              $scope.getFirstName = function(member) {
                  var firstName =  member.name.split(' ')[0];
                  return firstName;
              };

              $scope.viewbag = {
                  team: [
                    {
                      'name' :  'Marc Kushner',
                      'headshot_url' :  '//s3.amazonaws.com/contenthub.architizer.com/images/team_page/headshots/Marc.jpg',
                      'title' :  'Chief Executive Officer',
                      'tier' : '0'
                    },
                    {
                      'name' :  'David Weber',
                      'headshot_url' :  '//s3.amazonaws.com/contenthub.architizer.com/images/team_page/headshots/David_Weber.jpg',
                      'title' :  'Chief Financial Officer',
                      'tier' : '0'
                    },
                    {
                      'name' :  'Thomas Tachibana',
                      'headshot_url' :  '//s3.amazonaws.com/contenthub.architizer.com/images/team_page/headshots/Thomas.jpg',
                      'title' :  'Director of Product Management',
                      'tier' : '3'
                    },
                    {
                      'name' :  'Paul Keskeys',
                      'headshot_url' :  '//s3.amazonaws.com/contenthub.architizer.com/images/team_page/headshots/Paul.jpg',
                      'title' :  'Managing Editor',
                      'tier' : '3'
                    },
                    {
                      'name' :  'Tamara Yaeger',
                      'headshot_url' :  '//s3.amazonaws.com/contenthub.architizer.com/images/team_page/headshots/Tamara.jpg',
                      'title' :  'Accounting Operations Manager',
                      'tier' : '3'
                    },
                    {
                      'name' :  'Sophia Yoo',
                      'headshot_url' :  '//s3.amazonaws.com/contenthub.architizer.com/images/team_page/headshots/Sophia_Yoo.jpg',
                      'title' :  'Product Manager',
                      'tier' : '3'
                    },
                    {
                      'name' :  'Caroline Lau',
                      'headshot_url' :  '//s3.amazonaws.com/contenthub.architizer.com/images/team_page/headshots/Caroline_Lau_NEW.jpg',
                      'title' :  'VP of People Operations',
                      'tier' : '1'
                    },
                    {
                      'name' :  'Alyzeh Rizvi',
                      'headshot_url' :  '//s3.amazonaws.com/contenthub.architizer.com/images/team_page/headshots/Alyzeh.jpg',
                      'title' :  'Account Manager',
                      'tier' : '3'
                    },
                    {
                      'name' :  'Rae Oakley',
                      'headshot_url' :  '//s3.amazonaws.com/contenthub.architizer.com/images/team_page/headshots/Rae.jpg',
                      'title' :  'Account Manager',
                      'tier' : '3'
                    },
                    {
                      'name' :  'Nicole Doyle',
                      'headshot_url' :  '//s3.amazonaws.com/contenthub.architizer.com/images/team_page/headshots/NicoleDoyle.jpg',
                      'title' :  'Strategic Partnerships Manager',
                      'tier' : '3'
                    },
                    {
                      'name' :  'Jordan Doane',
                      'headshot_url' :  '//s3.amazonaws.com/contenthub.architizer.com/images/team_page/headshots/Jordan_Doane.jpg',
                      'title' :  'Business Development Manager',
                      'tier' : '3'
                    },
                    {
                      'name' :  'Mani Nilchiani',
                      'headshot_url' :  '//s3.amazonaws.com/contenthub.architizer.com/images/team_page/headshots/Mani_Nilchiani.jpg',
                      'title' :  'Software Engineer',
                      'tier' : '3'
                    },
                    {
                      'name' :  'Alex Meltzer',
                      'headshot_url' :  '//s3.amazonaws.com/contenthub.architizer.com/images/team_page/headshots/Alex_Meltzer.jpg',
                      'title' :  'Account Manager',
                      'tier' : '3'
                    },
                    {
                      'name' :  'Brian Maiorana',
                      'headshot_url' :  '//s3.amazonaws.com/contenthub.architizer.com/images/team_page/headshots/Brian_Maiorana.jpg',
                      'title' :  'VP of Sales',
                      'tier' : '3'
                    },
                    {
                      'name' :  'Connie Chang',
                      'headshot_url' :  '//s3.amazonaws.com/contenthub.architizer.com/images/team_page/headshots/Connie_Chang.jpg',
                      'title' :  'Search Operations Intern',
                      'tier' : '3'
                    },
                    {
                      'name' :  'Hannah Van',
                      'headshot_url' :  '//s3.amazonaws.com/contenthub.architizer.com/images/team_page/headshots/Hannah_Van.jpg',
                      'title' :  'Search Operations Intern',
                      'tier' : '3'
                    },
                    {
                      'name' :  'Bryan Corn',
                      'headshot_url' :  '//s3.amazonaws.com/contenthub.architizer.com/images/team_page/headshots/bryan_corn.jpg',
                      'title' :  'Business Development Intern',
                      'tier' : '3'
                    },
                    {
                      'name' :  'Lucy Wen',
                      'headshot_url' :  '//s3.amazonaws.com/contenthub.architizer.com/images/team_page/headshots/lucy_wen.jpg',
                      'title' :  'Sales Representative',
                      'tier' : '3'
                    },
                    {
                      'name' :  'Kenneth Ottey',
                      'headshot_url' :  '//s3.amazonaws.com/contenthub.architizer.com/images/team_page/headshots/kenneth_ottey.jpg',
                      'title' :  'Sales Representative',
                      'tier' : '3'
                    },
                  ]
              };

            var init = function () {

            };

            // kick things off
            init();
        }
    ]);
