(function() {
  'use strict';

  angular
  .module('architizer.app')
  .directive('reverseInfiniteScroll', reverseInfiniteScroll);

  reverseInfiniteScroll.$inject = [
    '$parse',
  ];

  function reverseInfiniteScroll ($parse) {
    return {
      restrict: 'A',
      replace: false,
      link: function($scope, element, attrs) {

        // Attach debounced handler on scroll
        element.on('scroll', _.debounce(handleScroll, 100));

        /**
         * Handle the element being scrolled 
         */
        function handleScroll(e) {
          var scrollTop = element.scrollTop();

          if (scrollTop <= 0) {

            // Parse a handler function from the directive attribute value
            var handler = $parse(attrs['reverseInfiniteScroll']);

            $scope.$apply(function() {
              handler($scope, { $event: e });
            });
          }
        }
      }
    };
  }
})();