'use strict';

angular
  .module('architizer.app')
  .controller('SettingsFirmUpdateInvitationController', [
  'firm',
  'invitations',
  'invitation',
	'$scope',
	'$state',
	'PopupManager',
	'Invitation',
	'AuthorizationService',
	'FlashManager',
	function (firm, invitations, invitation, $scope, $state, popupManager, Invitation, authorization, flashManager) {

		$scope.viewbag = {
			settings: invitation,
			models: {
				permissions: {
					architizer_admin: false,
					specifier: false,
					member: true
				}
			},
			elementConfig: {
				permissions: {
					architizer_admin: {
						disabled: false
					},
					specifier: {
						disabled: false
					}
				}
			},
			firm: firm,
			working: false
		};

		var init = function () {

			_populate();

			if (firm.product_source_access != 'A') {

				$scope.viewbag.elementConfig.permissions.architizer_admin.disabled = true;
			}
		};

		var _populate = function () {

			var roles = authorization.getRolesForPermissions('firm', $scope.viewbag.settings.permissions);
			angular.forEach(roles, function (role) {

				$scope.viewbag.models.permissions[role] = true;
			});
		};

		$scope.onRevokeClick = function () {

			var _cancelButton = {
				label: 'Cancel',
				onClick: function () {

					popupManager.pop();
				}
			};

			var _revokeButton = {
				label: 'Revoke',
				color: 'danger'
			};

			_revokeButton.onClick = function () {

				// Indicate loading status
				_revokeButton.loading = true;

				// Clear permissions
			 	invitation.$delete(function () {

	 	 			// Update the invitation list
	 	 			var index;
	 	 			_.find(invitations, function (x, i) {

	 	 				if (x.id == invitation.id) { index = i; return true; }
	 	 			});
	 	 			invitations.splice(index, 1);

	 	 			// Present flash
					flashManager.push({
						message: 'The invitation for ' + invitation.email + ' was revoked',
						type: 'warning'
					});

					popupManager.pop();

		 	 		$state.go('settingsFirm.team.list');
		 	 	}, function () {

		 	 		// Update loading status
					_revokeButton.loading = false;
		 	 	});
			};

			popupManager.push({
				heading: 'Revoke the invitation for ' + invitation.email,
				size: 'small',
				content: '<span class="body">Do you want to remove the invitation for ' + invitation.email + ' to join your team?</span>',
				buttons: [_cancelButton, _revokeButton]
			});
		};

		$scope.onCancelClick = function () {

			$state.go('settingsFirm.team.list');
		};

		$scope.onSaveClick = function () {

			// Indicate loading status
    	$scope.viewbag.working = true;

			// Prepare permissions
			var permissions = [];
			angular.forEach($scope.viewbag.models.permissions, function (value, key) {

				if (value) {

					var rolePermissions = authorization.getPermissionsForRole('firm', key);
					permissions = _.union(permissions, rolePermissions);
				}
			});

			// Update the invite
      invitation.permissions = permissions;
      invitation.location_codes = [];
		 	invitation.$update(function (invitation) {

 	 			// Update the invitation list
 	 			var index;
 	 			_.find(invitations, function (x, i) {

 	 				if (x.id == invitation.id) { index = i; return true; }
 	 			});
 	 			invitations[index] = invitation;

 	 			// Present flash
				flashManager.push({
					message: 'The invitation for ' + invitation.email + ' was updated',
					type: 'success'
				});

	 	 		$state.go('settingsFirm.team.list');
	 	 	}, function () {

	 	 		// Update loading status
  			$scope.viewbag.working = false;
	 	 	});
		};

		// When permissions are changed
		$scope.$watch('viewbag.models.permissions.architizer_admin', function (value) {

			if (value) {

				$scope.viewbag.models.permissions.specifier = true;
				$scope.viewbag.elementConfig.permissions.specifier.disabled = true;
			}

			else {

				$scope.viewbag.elementConfig.permissions.specifier.disabled = false;
			}
		});

		init();
	}]);
