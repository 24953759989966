'use strict';

angular
	.module('ui')
	.directive('uiFormMessage', [function () {

		return {
			replace: true,
			restrict: 'E',
			transclude: true,
			templateUrl: '/views/uikit/ui-form-message.html',
			scope: {
				message: '@',
				color: '@'
			},
			link: function ($scope) {

				$scope.color = $scope.color || 'default';
			}
		};
	}]);
