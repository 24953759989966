'use strict';

angular
	.module('ui')
	.directive('maxChars', ['$timeout', function ($timeout) {

		var COUNTER_SUFFIX = ' characters left';

		return {
			restrict: 'A',
			replace: false,
			require: 'ngModel',
			link: function($scope, element, attributes, controller) {

				/*

				The max-chars attribute in the ui-text element accepts a number or an object.
				
				You can specify the following properties in the object:

				- limit: number (this is the character limit on the ui-text element for which max-chars is an attribute)
				- hideCounter: boolean (hides the character counter element when true); defaults to false
				- cutOff: boolean (prevents the user from typing more than the specified limit); defaults to false

				Construct the object in the view like this:
				{limit: 100, hideCounter: false, cutOff: true}

				*/

				// default properties
				var limit,
					hideCounter = false,
					cutOff = false;

				// ensure max-chars is greater than zero
				if (!attributes.maxChars > 0) {

					return;
				}

				// if an object is passed into the max-chars attribute, assign properties from the object
				if (typeof angular.fromJson(attributes.maxChars) !== 'number') {
					attributes.maxChars = angular.fromJson(attributes.maxChars);

					limit = angular.fromJson(attributes.maxChars["limit"]);
					hideCounter = attributes.maxChars["hideCounter"] || false;
					cutOff = attributes.maxChars.cutOff || false;
				}

				else {

					limit = attributes.maxChars;
				};

				// create the chars counter
				var counter = angular.element('<span />')
					.addClass('compact')
					.addClass('loose')
					.addClass('heavy')
					.addClass('gunmetalWeakColor')
					.addClass('rightLabel');

				// add the chars counter unless hideCounter is true
				if (!hideCounter) {
					var formGroup = element
						.append(counter);
				}

				// set the number of chars remaining
				counter.text(limit + COUNTER_SUFFIX);

				controller.$validators.maxChars = function (value) {

					// prevent user from typing more than limit if cutOff is true
					if (cutOff) {

						if (value && (value.length > limit)) {

							var transformedInput = value.substring(0, limit);
							controller.$setViewValue(transformedInput);
							controller.$render();

							return true;
						}
					}

					// calculate char count remaining
					if (value) {

						var remaining = limit - value.length;
					}

					else {

						remaining = limit;
					}

					// test and set the validity after update.
					var valid = (remaining >= 0);

					// update the number of chars remaining
					counter.text(remaining + COUNTER_SUFFIX);

					// style
					counter
						.removeClass('warning')
						.removeClass('red');

					if (remaining < 10 && remaining >= 0) {

						counter.addClass('warning');
					}

					else if (remaining < 0) {

						counter.addClass('red');
					}

					return valid;
				};
			}
		};
	}]);