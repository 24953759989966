/* global Dropzone */
(function () {
  'use strict';
  /**
   * Dropzone.js wrapper directive
   *
   * Derived from http://stackoverflow.com/a/31931621
   */

  angular
  .module('architizer.app')
  .directive('dropzone', dropzone);

  dropzone.$inject = [
    'FileService',
  ];

  function dropzone (
    FileService
  ) {

    return {
      restrict: 'A',
      require: ['ngModel'],
      scope: {
        ngModel: '=',
        autoProcess: '@?',
        callback: '&?',
        maxFilesize: '@?',
        maxThumbnailFilesize: '@?',
        mimetypes: '@?',
        message: '@?',
      },
      link: link,
    };

    function link (scope, element, attrs, modelCtrl) {
      var ngModel = modelCtrl[0];

      if (!ngModel) { return; }

      // Autoprocess
      if (scope.autoProcess !== null && scope.autoProcess === 'false') {
        scope.autoProcess = false;
      } else {
        scope.autoProcess = true;
      }

      // Max file size
      if (scope.maxFilesize === null) {
        scope.maxFilesize = Dropzone.prototype.defaultOptions.maxFilesize;
      } else {
        scope.maxFilesize = parseInt(scope.maxFilesize, 10);
      }

      // Max thumbnail file size
      if (scope.maxThumbnailFilesize === null) {
        scope.maxThumbnailFilesize = Dropzone.prototype.defaultOptions.maxThumbnailFilesize;
      } else {
        scope.maxThumbnailFilesize = parseInt(scope.maxThumbnailFilesize, 10);
      }

      // Message for the uploading
      if (scope.message === null) {
        scope.message = Dropzone.prototype.defaultOptions.dictDefaultMessage;
      }

      // Initialize Dropzone
      let thisDropzone; // Used in callbacks - gets assigned once the Dropzone is initialized

      element.dropzone({
        url: '/', // Initialize Dropzone with url initially set to '/', which will throw a 404 on POST if setUploadPolicy fails
        maxFilesize: scope.maxFilesize,
        paramName: 'file',
        acceptedFiles: scope.mimetypes,
        maxThumbnailFilesize: scope.maxThumbnailFilesize,
        dictDefaultMessage: scope.message,
        autoProcessQueue: scope.autoProcess,
        thumbnailWidth: 230,
        thumbnailHeight: 230,
        addRemoveLinks: true,
        renameFile: FileService.makeFilename,
        accept: accept,
        init: function () {
          thisDropzone = this;

          // Upload Policy
          setUploadPolicy(thisDropzone);
          thisDropzone.on('sending', applyUploadPolicy);

          // Callbacks
          thisDropzone.on('thumbnail', FileService.prepareThumbnail);
          thisDropzone.on('success', success);
          thisDropzone.on('removedfile', removedfile);
        },
      });

      ////////////////////////////////////////////////////////////////////////////////
      // Upload Policy Helpers
      ////////////////////////////////////////////////////////////////////////////////

      function setUploadPolicy (dropzone) {
        FileService
        .getUploadPolicy()
        .then((response) => {
          dropzone.uploadPolicy = response.fields;
          dropzone.options.url = response.action;
        });
      }

      function applyUploadPolicy (file, xhr, formData){
        thisDropzone.uploadPolicy.forEach((field) => {
          formData.append(field.name, field.value);
        });
      }

      ////////////////////////////////////////////////////////////////////////////////
      // Callbacks
      ////////////////////////////////////////////////////////////////////////////////

      function accept (file, done) {
        if (!thisDropzone.uploadPolicy) {
          done('Connection error. Try to refresh the page.');
        } else {
          done();
        }
      }

      function success (dropzoneFile, response) {
        // Call success function
        if (scope.callback) {
          scope.callback({file: dropzoneFile, response: response});
        }

        // Add the upload URL to the dropzoneFile because FileService needs it to construct the s3_key
        dropzoneFile.upload.url = thisDropzone.options.url;

        // Format the file for the API with FileService
        let file = FileService.format(dropzoneFile, response);

        // Add the file to the model
        let files = FileService.add(file, angular.copy(ngModel.$viewValue));

        // Update the model
        ngModel.$setViewValue(files);

        // Remove successful file from the Dropzone uploader
        thisDropzone.removeFile(dropzoneFile);
      }

      function removedfile (file) {
        // Remove the file from ngModel
        let files = FileService.remove(file, angular.copy(ngModel.$viewValue));

        // Update the model
        ngModel.$setViewValue(files);
      }
    }
  }
})();
