(function() {
  'use strict';

  angular
  .module('architizer.app')
  .controller('SourceArchitectConversationsController', SourceArchitectConversationsController);

  SourceArchitectConversationsController.$inject = [
    '$scope',
    '$state',
    '$timeout',
    '$transition$',
    'conversations',
    'project',
    'selectedConversation',
    'unreadConversationsCount',
  ];

  function SourceArchitectConversationsController (
    $scope,
    $state,
    $timeout,
    $transition$,
    conversations,
    project,
    selectedConversation,
    unreadConversationsCount
  ) {

    var vm = this;

    // Data
    vm.$transition$ = $transition$;
    vm.activateMainPane = false;
    vm.conversations = conversations;
    vm.project = project;
    vm.selectedConversation = selectedConversation;
    vm.unreadConversationsCount = unreadConversationsCount; // FIXME: Use this data to display unread conversations count

    // Functions

    // Watches
    /**
     * Watch for changes to selection in sidebar
     *
     * Used for responsive display of .splitPaneMain
     *
     * When viewing a request on a small screen, the request should take up the whole view and hide the sidebar when it is shown.
     * See the style rules for .activateMainPane in split-pane.scss
     */
    $scope.$watch(
      function WatchSidebarSelection () { return $state.includes('**.request') || $state.includes('**.conversation'); },
      function SetActivateMainPane (result) { vm.activateMainPane = result; }
    );

    // Initialize
    init();

    ////////////////////////////////////////////////////////////////////////////////
    // Functions
    ////////////////////////////////////////////////////////////////////////////////

    /**
     * Initialization
     */
    function init () {
      // Don't select the most recent conversation if we've clicked the "Back" button from a "conversation" view
      const comingBackFromConversation = (vm.$transition$.from().name === 'source.app.arc.project.conversations.conversation');
      if (comingBackFromConversation) { return; }

      $timeout(() => {
       if (!vm.selectedConversation.id && vm.conversations.length) {
         // Find most recent conversation
         $state.go('source.app.arc.project.conversations.conversation', { project: vm.project.id, conversation: vm.conversations[0].id });
       }
     });
    }
  }
})();
