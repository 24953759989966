(function () {
  'use strict';

  angular
  .module('architizer.app')
  .run(RunDelightedHelper);

  RunDelightedHelper.$inject = [
    '$rootScope',
    '$transitions',
    '$window',
    'AuthenticationService'
  ];

  function RunDelightedHelper (
    $rootScope,
    $transitions,
    $window,
    AuthenticationService
  ) {

    let helper = {};

    ////////////////////////////////////////////////////////////////////////////////
    // Watchers
    ////////////////////////////////////////////////////////////////////////////////

    // Set user options when we have a user
    $rootScope.$watch(() => AuthenticationService.user, setDelightedUser);

    // Call Delighted when $state data has `showDelighted = true`
    $transitions.onSuccess({}, showDelighted);

    ////////////////////////////////////////////////////////////////////////////////
    // Functions
    ////////////////////////////////////////////////////////////////////////////////

    function setDelightedUser (user) {
      if (!user) {
        // Clear user options
        helper.userOptions = null;
      } else {
        // Set user options from user
        helper.userOptions = {};
        helper.userOptions.name = user.first_name + ' ' + user.last_name;
        helper.userOptions.email = user.email;
        helper.userOptions.createdAt = user.created;
      }
    }

    // Determine whether to initialize Delighted
    function showDelighted (transition) {
      const toState = transition.to();
      const shouldShowSurvey = (toState.data && toState.data.showDelighted);

      if (shouldShowSurvey) {
        callSurvey(helper.userOptions);
      }
    }

    // Call the Delighted survey
    function callSurvey (user) {
      // Handle errors for no snippet or no user
      if (!$window.delighted || !user) { return; }

      // Show the survey
      return $window.delighted.survey(user);
    }
  }
})();