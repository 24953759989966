'use strict';

angular
  .module('architizer.app')
  .controller('SettingsFirmTeamListController', [
    'user',
    'firm',
    'roleRequests',
    'invitations',
    'team',
    '$scope',
    '$http',
    '$state',
    'GlobalView',
    'AuthorizationService',
    'FlashManager',
    'PopupManager',
    'UserSettings',
    function (user, firm, roleRequests, invitations, team, $scope, $http, $state, globalView, authorization, FlashManager, popupManager, UserSettings) {

      globalView.title = 'Architizer - Settings';
      globalView.currentSecondaryItem = '';

      $scope.viewbag = {
        firm: firm,
        roleRequests: roleRequests,
        invitations: invitations,
        team: team,
        hasChangeUsersPermission: authorization.userHasPermissionForBrand(
          user,
          firm.id,
          [authorization.FIRM_PERMISSIONS.CAN_CHANGE_FIRM_USERS]
        )
      };

      // When the Add Team Members button is clicked
      $scope.onNewTeamMembersClick = function() {

        $state.go('settingsFirm.team.add');
      };

      // When the update pending invite button is clicked
      $scope.onEditPendingInvitationClick = function (invite) {

        $state.go('settingsFirm.team.updateInvitation', { invitationId: invite.id });
      };

      $scope.onResendInvitationClick = function(invite) {

        // Indicate loading status
        invite.resending = true;

        invite
          .$resend()
          .then(_onResendInvitationSuccess(invite))
          .then(_onResendInvitationError(invite));
      };

      var _onResendInvitationSuccess = function (invite) {

        return function () {

          // Update loading status
          invite.resending = false;

          popupManager.push({
            heading: 'Invitation sent',
            size: 'small',
            content: '<p class="body longform">Your invitation was resent successfully!',
            buttons: [{
              label: 'Great!',
              color: 'success',
              onClick: function () {

                popupManager.pop();
              }
            }]
          });
        };
      };

      var _onResendInvitationError = function (invite) {

        return function () {

          // Update loading status
          invite.resending = false;
        };
      };

      // When the update team member button is clicked
      $scope.onUpdateTeamMemberClick = function(user) {

        $state.go('settingsFirm.team.update', { userId: user.id });
      };

      $scope.onApproveRoleRequestClick = onApproveRoleRequestClick;
      function onApproveRoleRequestClick (roleRequest) {
        roleRequest.loading = true;

        roleRequest
        .$approve()
        .then(success)
        .catch(error);

        function success (data) {
          team.unshift(data.user); // Add the user to the Team section

          // Show flash message
          FlashManager.push({
            message: data.user.first_name + ' ' + data.user.last_name + ' was added to your team.',
            type: 'success',
          });

          deleteRoleRequest(roleRequest);
        }

        function error (data) {
          roleRequest.loading = false;
          Raven.captureException(new Error('Error Approving RoleRequest'), { extra: { response: data }});
        }
      }

      $scope.onRejectRoleRequestClick = onRejectRoleRequestClick;
      function onRejectRoleRequestClick (roleRequest) {
        roleRequest
        .$decline()
        .then(success)
        .catch(error => {
          Raven.captureException(new Error('Error declining RoleRequest'), { extra: { response: error }});
        });
        
        function success (data) {
          deleteRoleRequest(roleRequest);

          // Show flash message
          FlashManager.push({
            message: data.user.first_name + ' ' + data.user.last_name + ' role request was declined.',
            type: 'error', // if didnt work try danger or warning
          });
        }
      }

      // Helper to delete a role request and remove from the list
      function deleteRoleRequest (roleRequest) {
        roleRequest.loading = false;
        // Find the Role Request and remove it from the list
        var index = roleRequests.indexOf(roleRequest);
        roleRequests.splice(index, 1);
      }
    }]);
