(function () {
  'use strict';

  angular
  .module('architizer.app')
  .factory('AuthorizationService', AuthorizationService);

  AuthorizationService.$inject = [
    'AuthenticationService'
  ];

  function AuthorizationService (
    AuthenticationService
  ) {

    var service = this;

    // Data
    service.USER_TYPES = userTypes();
    service.BRAND_PERMISSIONS = brandPermissions();
    service.FIRM_PERMISSIONS = firmPermissions();
    service.BRAND_ROLES = brandRoles();
    service.FIRM_ROLES = firmRoles();
    service.ROLES = roles();

    // Functions
    service.userType = userType;
    service.getPermissionsForRole = getPermissionsForRole;
    service.getRolesForPermissions = getRolesForPermissions;
    service.hasPermission = hasPermission;
    service.userHasPermissionForBrand = userHasPermissionForBrand;
    service.userHasPermissionForFirm = userHasPermissionForFirm;
    service.getFirmsForPermission = getFirmsForPermission;
    service.getBrandsForPermission = getBrandsForPermission;

    return service;

    ////////////////////////////////////////////////////////////////////////////////
    // Functions
    ////////////////////////////////////////////////////////////////////////////////

    function userTypes () {
      return {
        FIRM: 'arc',
        BRAND: 'bpm',
        NO_SOURCE_ACCESS: 'none',
      };
    }

    function brandPermissions () {
      return {
        CAN_MANAGE_BRAND: 'manage_brand',
        CAN_CHANGE_BRAND_USERS: 'change_brand_users',
        CAN_VIEW_BRAND_USERS: 'view_brand_users',
        CAN_CHANGE_BRAND_INFO: 'change_brand_info',
        CAN_CHANGE_BRAND_SALES_TEAM: 'change_brand_sales_team',
        CAN_CHANGE_BRAND_PRODUCT_RESPONSES: 'change_productresponse',
        CAN_SHARE_PRODUCT_REQUEST: 'allocate_productrequest',
        CAN_RECEIVE_BRAND_SALES_ALERTS: 'receive_brand_sales_alerts',
        HAS_MEMBERSHIP: 'has_brand_membership'
      };
    }

    function firmPermissions () {
      return {
        CAN_MANAGE_FIRM: 'manage_firm',
        CAN_CHANGE_FIRM_USERS: 'change_firm_users',
        CAN_VIEW_FIRM_USERS: 'view_firm_users',
        CAN_CHANGE_FIRM_INFO: 'change_firm_info',
        CAN_CHANGE_FIRM_PRODUCT_REQUESTS: 'change_productrequest',
        HAS_MEMBERSHIP: 'has_firm_membership'
      };
    }

    function brandRoles () {
      return {
        architizer_admin: {
          key: 'architizer_admin',
          name: 'Architizer Admin'
        },
        sales_admin: {
          key: 'sales_admin',
          name: 'Sales Administrator'
        },
        sales_person: {
          key: 'sales_person',
          name: 'Sales Person'
        },
        member: {
          key: 'member',
          name: 'Member'
        }
      };
    }

    function firmRoles () {
      return {
        architizer_admin: {
          key: 'architizer_admin',
          name: 'Architizer Admin'
        },
        specifier: {
          key: 'specifier',
          name: 'Specifier'
        },
        member: {
          key: 'member',
          name: 'Member'
        }
      };
    }

    // Define roles and associated permissions
    function roles () {
      return {
        brand: {
          architizer_admin: [
            service.BRAND_PERMISSIONS.CAN_MANAGE_BRAND,
            service.BRAND_PERMISSIONS.CAN_CHANGE_BRAND_USERS,
            service.BRAND_PERMISSIONS.CAN_CHANGE_BRAND_INFO,
            service.BRAND_PERMISSIONS.CAN_CHANGE_BRAND_SALES_TEAM,
            service.BRAND_PERMISSIONS.CAN_CHANGE_BRAND_PRODUCT_RESPONSES,
            service.BRAND_PERMISSIONS.CAN_SHARE_PRODUCT_REQUEST,
            service.BRAND_PERMISSIONS.CAN_RECEIVE_BRAND_SALES_ALERTS,
            service.BRAND_PERMISSIONS.CAN_VIEW_BRAND_USERS,
            service.BRAND_PERMISSIONS.HAS_MEMBERSHIP
          ],
          sales_admin: [
            service.BRAND_PERMISSIONS.CAN_CHANGE_BRAND_SALES_TEAM,
            service.BRAND_PERMISSIONS.CAN_CHANGE_BRAND_PRODUCT_RESPONSES,
            service.BRAND_PERMISSIONS.CAN_SHARE_PRODUCT_REQUEST,
            service.BRAND_PERMISSIONS.CAN_RECEIVE_BRAND_SALES_ALERTS,
            service.BRAND_PERMISSIONS.CAN_VIEW_BRAND_USERS,
            service.BRAND_PERMISSIONS.HAS_MEMBERSHIP
          ],
          sales_person: [
            service.BRAND_PERMISSIONS.CAN_CHANGE_BRAND_PRODUCT_RESPONSES,
            service.BRAND_PERMISSIONS.CAN_VIEW_BRAND_USERS,
            service.BRAND_PERMISSIONS.HAS_MEMBERSHIP
          ],
          member: [
            service.BRAND_PERMISSIONS.CAN_VIEW_BRAND_USERS,
            service.BRAND_PERMISSIONS.HAS_MEMBERSHIP
          ]
        },
        firm: {
          architizer_admin: [
            service.FIRM_PERMISSIONS.CAN_MANAGE_FIRM,
            service.FIRM_PERMISSIONS.CAN_CHANGE_FIRM_USERS,
            service.FIRM_PERMISSIONS.CAN_CHANGE_FIRM_INFO,
            service.FIRM_PERMISSIONS.CAN_VIEW_FIRM_USERS,
            service.FIRM_PERMISSIONS.HAS_MEMBERSHIP
          ],
          specifier: [
            service.FIRM_PERMISSIONS.CAN_CHANGE_FIRM_PRODUCT_REQUESTS,
            service.FIRM_PERMISSIONS.CAN_VIEW_FIRM_USERS,
            service.FIRM_PERMISSIONS.HAS_MEMBERSHIP
          ],
          member: [
            service.FIRM_PERMISSIONS.CAN_VIEW_FIRM_USERS,
            service.FIRM_PERMISSIONS.HAS_MEMBERSHIP
          ]
        }
      };
    }

    // Check user type (firm or brand)
    function userType (user) {
      if (!user || !user.permissions) {
        return;
      }

      if (user.hasOwnProperty('is_source_user') && !user.is_source_user) {
        return service.USER_TYPES.NO_SOURCE_ACCESS;
      }
      if (_.isEmpty(user.permissions.firms) && _.isEmpty(user.permissions.brands)) {
        return service.USER_TYPES.NO_SOURCE_ACCESS;
      }
      if (_.isEmpty(user.permissions.firms)) {
        return service.USER_TYPES.BRAND;
      }
      if (_.isEmpty(user.permissions.brands)) {
        return service.USER_TYPES.FIRM;
      }
    }

    function getPermissionsForRole (type, role) {
      return service.ROLES[type][role];
    }

    function getRolesForPermissions (type, permissions) {
      var roles = [];
      angular.forEach(service.ROLES[type], function (rolePermissions, role) {

        var flag = false;
        angular.forEach(rolePermissions, function (rolePermission) {
          flag = flag || (permissions.indexOf(rolePermission) === -1);
        });

        if (!flag) {
          roles.push(role);
        }
      });

      return roles;
    }

    function hasPermission (permissions, permission) {
      return !!permissions[permission];
    }

    /*
     * Checks if a user has a brand permission(s) using OR
     *
     * @param object user The user object
     * @param mixed brandId The id of the brand to check against
     * @param string|array permissions The permissions to look for
     */
    function userHasPermissionForBrand (user, brandId, permissions) {
      if (!angular.isArray(permissions)) {
        permissions = [permissions];
      }

      var brandPermissions = user.permissions.brands[brandId.toString()];

      if (!brandPermissions) {
        return false;
      }

      var hasPermission = false;
      angular.forEach(permissions, function (permission) {
        hasPermission = hasPermission || service.hasPermission(brandPermissions, permission);
      });

      return hasPermission;
    }

    /*
     * Checks if a user has a firm permission(s) using OR
     *
     * @param object user The user object
     * @param mixed firmId The id of the firm to check against
     * @param string|array permissions The permissions to look for
     */
    function userHasPermissionForFirm (user, firmId, permissions) {
      if (!angular.isArray(permissions)) {
        permissions = [permissions];
      }

      var firmPermissions = user.permissions.firms[firmId.toString()];

      if (!firmPermissions) {
        return false;
      }

      var hasPermission = false;
      angular.forEach(permissions, function (permission) {
        hasPermission = hasPermission || service.hasPermission(firmPermissions, permission);
      });

      return hasPermission;
    }

    /*
     * Fetches all firms which the current user has a permission for
     *
     * @param string|array permission The permission or array of
     *    permissions to filter the firms by
     * @return array<object> The firms
     */
    function getFirmsForPermission (permission) {
      var user = AuthenticationService.user;

      if (!user) {
        return [];
      }

      if (!angular.isArray(permission)) {
        permission = [permission];
      }

      var firms = [];
      var firmIds = _.keys(user.permissions.firms);
      
      angular.forEach(firmIds, function (id) {
        if (userHasPermissionForFirm(user, id, permission)) {
          var firm = _.findWhere(user.firms, { id: parseInt(id) });

          if (firm) { 
            firms.push(firm);
          }
        }
      });

      return firms;
    }

    /*
     * Fetches all brands which the current user has a permission for
     *
     * @param string|array permission The permission or array of
     *    permissions to filter the brands by
     * @param object user The User object - defaults to AuthenticationService.user if none is passed
     * @return array<object> The brands
     */

    function getBrandsForPermission (permission, user=AuthenticationService.user) {
      if (!user) {
        return [];
      }

      if (!angular.isArray(permission)) {
        permission = [permission];
      }

      var brands = [];
      var brandIds = _.keys(user.permissions.brands);

      angular.forEach(brandIds, function (id) {
        if (userHasPermissionForBrand(user, id, permission)) {
          var brand = _.findWhere(user.brands, { id: parseInt(id) });

          if (brand) {
            brands.push(brand);
          }
        }
      });

      return brands;
    }
  }
})();
