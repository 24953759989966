'use strict';

angular
	.module('architizer.app')
	.directive('uniqueEmail', ['$q', 'User', function ($q, User) {

		return {
			restrict: 'A',
			require: '?ngModel',
			link: function ($scope, element, attrs, ngModel) {

				if (!ngModel) {

					return;
				}

				ngModel.$asyncValidators.uniqueEmail = function (value) {

					var deferred = $q.defer();

					User.query(
						{ q: value, exact_match: true },
						function (users) {

							if (users.length) {

								deferred.reject();
							}

							else {

								deferred.resolve();
							}
						},
						function () {

							deferred.reject();
						}
					);

					return deferred.promise;
				};
			}
		};
	}]);
