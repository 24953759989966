(function() {
  'use strict';

  angular
  .module('architizer.app')
  .controller('SourceProductResponseModalController', SourceProductResponseModalController);

  SourceProductResponseModalController.$inject = [
    '$filter',
    '$modal',
    '$modalInstance',
    '$rootScope',
    '$scope',
    '$state',
    'CustomDataService',
    'CustomEventsService',
    'FileClick',
    'mySide',
    'productRequest',
    'productResponse',
    'ProductResponse',
    'project',
    'SearchstaxService',
    'user',
  ];

  function SourceProductResponseModalController (
    $filter,
    $modal,
    $modalInstance,
    $rootScope,
    $scope,
    $state,
    CustomDataService,
    CustomEventsService,
    FileClick,
    mySide,
    productRequest,
    productResponse,
    ProductResponse,
    project,
    SearchstaxService,
    user
    ) {

    var vm = this;

    // Data
    vm.productRequest = productRequest;
    vm.productResponse = productResponse;
    vm.project = project;
    vm.showFooterButtons = showFooterButtons();
    vm.productUrl = getProductUrl();
    vm.mySide = mySide;
    vm.heroImage = setHeroImage();
    vm.user = user;
    vm.saving = false;
    vm.isProductRecommendation = !(vm.productResponse.product_request_id === vm.productRequest.id)
    vm.rootScopeUnbindRefs = [];

    // Functions
    vm.onFileDownloadClick = FileClick.onFileDownloadClick;
    vm.onSendMessageClick = onSendMessageClick;
    vm.onRequestSamplesClick = onRequestSamplesClick;
    vm.onCancelClick = onCancelClick;
    vm.showEditProductModal = showEditProductModal;
    vm.onAddToProducts = onAddToProducts;
    vm.onDismissClick = onDismissClick;
    vm.onRestoreClick = onRestoreClick;
    vm.hideDataPoint = hideDataPoint;
    vm.onProductUrlClick = onProductUrlClick;
    vm.setHeroImage = setHeroImage;

    // Custom event names
    const {
      PRODUCT_RESPONSE_DISMISS_START,
      PRODUCT_RESPONSE_DISMISS_COMPLETE,
      PRODUCT_RECOMMENDATION_ADD_TO_PRODUCTS_START,
      PRODUCT_RECOMMENDATION_ADD_TO_PRODUCTS_COMPLETE
    } = CustomEventsService.events;

    // Initialize
    init();

    ////////////////////////////////////////////////////////////////////////////////
    // Functions
    ////////////////////////////////////////////////////////////////////////////////

    /**
     * Initialization
     */
    function init () {
      // Prepare the structured datapoints for display in modal
      CustomDataService.prepareForDisplay(angular.fromJson(vm.productResponse.structured_data));
      // Track the initialization of this controller (Product Response Modal opens)
      if (vm.isProductRecommendation) {
        trackAction('product_recommendation_opened', trackingProperties());
        trackClickInSearchstax();
      } else {
        trackAction('product_response_opened', trackingProperties());
      }

      bindEventListeners();
    }

    /**
     * Prepare & return baseline tracking properties for this controller
     */
    function trackingProperties () {

      let { id, name, product_request_id, brand_id } = vm.productResponse;

      return {
        id,
        name,
        product_request_id,
        brand_id
      };
    }

    /**
     * Emit tracking event for `ObjectActionTracker`
     */
    function trackAction (trackingEventName, props) {
      $rootScope.$emit(trackingEventName, props);
    }

    /**
     * Function that unbinds event listeners when controller $scope is destroyed.
     */
    function unbindEventListeners () {
      vm.rootScopeUnbindRefs.forEach(unbindFn => unbindFn());
    }

    /**
     * Function that encapsulate event listeners for this controller.
     */
    function bindEventListeners () {
      const UNBIND_PRODUCT_RECOMMENDATION_ADD_TO_PRODUCTS_COMPLETE = $rootScope.$on(PRODUCT_RECOMMENDATION_ADD_TO_PRODUCTS_COMPLETE, () => {
        vm.saving = false;
        $modalInstance.dismiss();
      });

      // Hold a reference to `unbind` functions for all $rootScope events
      vm.rootScopeUnbindRefs.push(UNBIND_PRODUCT_RECOMMENDATION_ADD_TO_PRODUCTS_COMPLETE);

      // Unbind all $rootScope event listeners on controller $destroy event
      $scope.$on('$destroy', unbindEventListeners);
    }

    /**
     * Call Searchstax TrackClick (wrapped in a function to improve readability in init())
     */
    function trackClickInSearchstax () {
      // For Recommended Products, set up a trackClickData object for SearchStax analytics
      const ssAppName = 'productRecommendations';
      // Pass product request name as query since our /search/product_recommendations API transforms Product Request ID into Product Request Name when we query
      const query = vm.productRequest.name;

      const trackClickData = {
        key: SearchstaxService.getApiKey(ssAppName),
        session: SearchstaxService.getSession(),
        query: query,
        position: vm.productResponse.searchResultPosition, // searchResultPosition is added in ResolveProductRecommendations function in routes
        cDocId: vm.productResponse.id,
        cDocTitle: vm.productResponse.name,
      };

      // Send Searchstax TrackClick event
      SearchstaxService.trackClick(trackClickData);
    }

    function getProductUrl () {
      return vm.productResponse.structured_data.find((dataPoint) => dataPoint.name === 'productUrl');
    }

    /**
     * On Cancel button click
     */
    function onCancelClick () {
      $modalInstance.dismiss();
    }

    /**
     * On Send Message button click
     */
    function onSendMessageClick (productResponse) {

      ProductResponse.conversation({
        id: productResponse.id,
        force: 'project',
      })
      .$promise
      .then(function (conversation) {
        $state.go('source.app.arc.project.conversations.conversation', { project: vm.project.id, conversation: conversation.id }, { inherit: false, reload: true });
        $modalInstance.dismiss();
      });
    }

    /**
     * Set Hero Image
    */
    function setHeroImage (image) {
      if (image) {
        vm.heroImage = image;
        return image;
      } else if (vm.productResponse.images.hero) {
        return vm.productResponse.images.hero;
      } else {
        return null;
      }
    }

    /**
     * On Request Samples button click
     */
    function onRequestSamplesClick () {

      // Get Conversation ID for this product response
      ProductResponse.conversation({
        id: productResponse.id,
        force: 'project,images',
      })
      .$promise
      .then((conversation) => {

        // Get other responses for this conversation
        ProductResponse.query({
          conversation_id: conversation.id,
          force: 'brand,creator',
        })
        .$promise
        .then((productResponses) => {

          var requestSamplesModal = $modal.open({
            templateUrl: '/views/source/architect/product-response.request-samples.modal.html',
            controller: 'RequestSamplesController',
            controllerAs: 'RequestSamplesCtrl',
            resolve: {
              conversation: () => conversation,
              productRequest: () => vm.productRequest,
              productResponse: () => productResponse,
              productResponses: () => productResponses,
              currentlySelectedProductResponseId: () => vm.productResponse.id,
              user: () => vm.user,
            }
          });

          requestSamplesModal
          .result
          .then( () => $modalInstance.dismiss());
        });
      });
      // Close product response modal as soon as the "Dismiss" button is hit
      $modalInstance.dismiss();
    }

     /**
     * Function to check for hidden data points, which should not
     * be displayed on the Product Response content modal
     */
    function hideDataPoint (structuredDataPoint) {

      var hiddenDataPoints = ['searchTool', 'internalNotes', 'productType', 'productUrl'];
      return hiddenDataPoints.indexOf(structuredDataPoint.name) !== -1;
    }

    /**
     * Determine whether footer buttons should be shown
     *
     * - Don't show to BPMs
     * - Show to Architects on the Products View only
     */
    function showFooterButtons () {

      // Only architect users will have a project
      var architectUser = !!project;

      var onConversationView = $state.includes('**.conversation.**');

      if(architectUser) {
        // Hide buttons for ARC users on the Conversations view
        if(onConversationView) {
          return false;
        // Show buttons for ARC users on all other views
        } else {
          return true;
        }
      }
      // Hide buttons for BPM users
      return false;
    }

    /**
     * Show Edit Product modal
     */
    function showEditProductModal () {

      // Close product response content modal if the "Edit" icon is clicked
      $modalInstance.dismiss();

      // Open 'Edit Product' modal
      var editProductModal = $modal.open({
        templateUrl: '/views/source/architect/logged-product.modal.html',
        controller: 'LoggedProductModalController',
        controllerAs: '$ctrl',
        resolve: {
          mode: () => 'edit',
          productRequest: () => {
            // Fake fetch a product request to get the product_request_id of the product response
            return {
              id: vm.productResponse.product_request_id,
            };
          },
          productResponse: () => vm.productResponse,
        }
      });

      editProductModal
      .result
      .then((productResponse) => {
        $state.reload();
      });
    }

    /**
     * On Dismiss button click in product response content modal
     */
     function onDismissClick (response, $event) {
      // Stop propagation - the parent element has an ng-click on it too
      $event.stopPropagation();

      // Close product response modal as soon as the "Dismiss" button is hit
      $modalInstance.dismiss();

      // Don't dismiss if this response is already dismissed
      if (response.marking === 'D') { return; }

      // Create and open modal
      var productResponseDismissModal = $modal.open({
        templateUrl: '/views/source/architect/product-response.dismiss.modal.html',
        controller: 'SourceArchitectProductResponseDismissModalController',
        controllerAs: 'ProductResponseDismissModalCtrl',
        resolve: {
          productResponse: () => response,
        }
      });

      // Take the result from the modal and [...]
      productResponseDismissModal
      .result
      .then((communication) => {

        /**
         * FIXME: Make this consistent with other API calls
         * This is inconsistent because this modal creates a Communication object
         */

        // Emit a `start` event with the `id` of the dismissed Product
        $rootScope.$emit(PRODUCT_RESPONSE_DISMISS_START, { id: response.id });

        // Dismiss the response with the communication data
        response
        .$dismiss(communication.json_data)
        .then(() => {
          response.marking = 'D';
          $modalInstance.close(response);
        })
        .catch((error) => console.error(error))
        .finally(() => {
          // Emit a `complete` event with the `id` of the dismissed Product
          $rootScope.$emit(PRODUCT_RESPONSE_DISMISS_COMPLETE, { id: response.id });
        });
      });
    }

    /**
     * On "Add to My Products" click
     */
    function onAddToProducts (productRecommendation) {
      // Don't allow product to be added multiple times
      if (vm.saving === true) { return; }

      vm.saving = true;
      $rootScope.$emit(PRODUCT_RECOMMENDATION_ADD_TO_PRODUCTS_START, productRecommendation);
    }

    /**
     * On Restore button click in product response content modal
     */
    function onRestoreClick (response, $event) {
      // Stop propagation - the parent element has an ng-click on it too
      $event.stopPropagation();

      // Don't shortlist if this is already 'In Review'
      if (response.marking === 'N') { return; }

      vm.saving = true;

      // Shortlist the response through the API
      response
      .$clear()
      .then(() => {
        response.marking = 'N';
        $modalInstance.close(response);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        vm.saving = false;
      });
    }

    /**
     * On Product URL click in product response content modal
     */
    function onProductUrlClick () {
      // Use autolinkHref $filter so the links will be normalized to contain `http://` if they don't already
      let responseUrl = $filter('autolinkHref')($filter('find')(vm.productUrl.fields, 'name', 'value').value);
      // Prepare tracking properties for this event.
      let trackingProps = Object.assign(
        {},
        trackingProperties(),
        { url: responseUrl }
      );
      trackAction('product_response_product_url_clicked', trackingProps);
    }
  }
})();
