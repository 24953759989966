'use strict';

angular
	.module('ui')
	.directive('email', [function () {

		return {
			replace: false,
			restrict: 'A',
			require: '?ngModel',
			link: function ($scope, element, attrs, ngModel) {

				var PATTERN = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

				/*
				 * Data methods
				 */

				// end here if no ng-model
				if (!ngModel) {

					return;
				}

				ngModel.$validators.email = function (value) {

					return PATTERN.test(value);
				};
			}
		};
	}]);
