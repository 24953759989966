
(function() {
  'use strict';

  angular
  .module('architizer.app')
  .controller('SourceArchitectProjectProductsController', SourceArchitectProjectProductsController);

  SourceArchitectProjectProductsController.$inject = [
    '$filter',
    '$log',
    '$state',
    '$modal',
    'projectData',
    'project',
    'ProductRequest',
    'user'
  ];

  function SourceArchitectProjectProductsController (
    $filter,
    $log,
    $state,
    $modal,
    { products, categories },
    project,
    ProductRequest,
    user
  ) {

    var vm = this;

    // Data
    vm.products = products
      .map(assignSortOrder)
      .map(assignAddedBy)
      .map(assignAddedByDisplayName);
    vm.project = project;
    vm.categories = categories;
    vm.user = user;
    vm.mySide = 'A';
    vm.markingFilter = '*';
    vm.addedByFilter = '*';
    vm.sortCategory = 'sortOrder';
    vm.sortReverse = false;
    vm.getTooltip = getTooltip;
    vm.allCategoriesSelected = true;

    // Functions
    vm.showProductResponseContentModal = showProductResponseContentModal;
    vm.toggleShortlist = toggleShortlist;
    vm.assignMarkingOrder = assignMarkingOrder;
    vm.toggleAllCategories = toggleAllCategories;
    vm.getTotalProductsCount = getTotalProductsCount;
    vm.getBackgroundImage = getBackgroundImage;
    vm.updateProductFilters = updateProductFilters;
    vm.getTotalVisibleProductsCount = getTotalVisibleProductsCount;

    // Initialize
    init();

    ////////////////////////////////////////////////////////////////////////////////
    // Functions
    ////////////////////////////////////////////////////////////////////////////////

    /**
     * Initialization
     */
    function init () {
      updateVisibleProducts();
    }

    /**
     * Add an `addedBy` property to each product
     */
    function assignAddedBy (product) {
      let addedBy = '*';
      if (product.creator.id === user.id) {
        addedBy = 'me';
      } else if (product.recommender_type === 'arc_user') {
        addedBy = 'team-member';
      }

      product.addedBy = addedBy;
      return product;
    }

    /**
     * Define what type of use has logged this product
     */
    function assignAddedByDisplayName (product) {
      let displayValue = '';
      switch (product.recommender_type) {
        case 'internal':
          displayValue = 'Architizer';
          break;
        case 'arc_user':
        case 'bpm_user':
          displayValue = `${product.creator.first_name} ${product.creator.last_name}`;
          break;
        default:
          displayValue = `${product.creator.first_name} ${product.creator.last_name}`;
      }

      product.addedByDisplayName = displayValue;
      return product;
    }
    

    /**
     * Show Product Reponse Content modal
     */
    function showProductResponseContentModal (productResponse) {
      var productResponseContentModal = $modal.open({
        templateUrl: '/views/source/shared/product-response.content.modal.html',
        controller: 'SourceProductResponseModalController',
        controllerAs: 'ProductResponseModalCtrl',
        resolve: {
          project: () => vm.project,
          productRequest: () => new ProductRequest({
            id: productResponse.product_request_id,
            project_id: productResponse.project_id,
          }),
          productResponse: () => productResponse,
          mySide: () => vm.mySide,
          user: () => vm.user,
        }
      });
    }

    /**
     * Toggle function for Shortlist / In Review button click
     */
    function toggleShortlist(product, $event) {
      // Stop propagation - the parent element has an ng-click on it too
      $event.stopPropagation();

      product[product.marking === 'F' ? '$clear' : '$shortlist']()
        .then(({ data: { status } }) => {
          product.marking = status;
          assignSortOrder(product);
        })
        .catch((error) => console.log(error));
    }

    /**
     * Utility function to get all products that are visible,
     * based on user filtering
     */
    function getTotalVisibleProductsCount () {
      return vm.products.filter(({ visible }) => visible).length;
    }

    /**
     * Based on filtering criteria, flag each product as visible or not visible
     */
    function updateVisibleProducts () {
      vm.products = vm.products.map(assignVisible);
    }

    /**
     * Determine if a product should be visible, based on filtering criteria
     */
    function assignVisible (product) {
      let visible = (getSelectedCategoryLabels().indexOf(product.category) > -1) &&
          (vm.markingFilter === '*' ? true : product.marking === vm.markingFilter) &&
          (vm.addedByFilter === '*' ? true : product.addedBy === vm.addedByFilter);
      product.visible = visible;
      return product;
    }

    /**
     * Function to get product card tooltips
     */
    function getTooltip (product) {
      if(product.marking === 'F') { return 'Shortlisted'; }
      if(product.marking !== 'F') { return 'Shortlist'; }
    }

    /**
     * Based on user filtering combination, update each product object
     */
    function updateProductFilters () {
      updateVisibleProducts();
      const selectedCategoryFilterLabels = getSelectedCategoryLabels();
      vm.allCategoriesSelected = vm.categories.length  === selectedCategoryFilterLabels.length;

      const category = this.getAttribute('data-category');
      const marking = this.getAttribute('data-marking') || 'N';
      const addedBy = this.getAttribute('data-addedby') || '*';
      return (selectedCategoryFilterLabels.indexOf(category) > -1) &&
        (vm.markingFilter === '*' ? true : marking === vm.markingFilter) &&
        (vm.addedByFilter === '*' ? true : addedBy === vm.addedByFilter);
    }
    
    /**
     * Utility function to get total products count
     */
    function getTotalProductsCount () {
      return categories.reduce((totalCount, { count }) => {
        return totalCount + count;
      }, 0)
    }

    /**
     * Utility function that returns an array of selected category labels 
     */
    function getSelectedCategoryLabels () {
      return categories
        .filter(({ selected }) => selected )
        .map(({ label }) => label );
    }
    
    /**
     * Utility function that toggles all cateogries 
     */
    function toggleAllCategories () {
      vm.categories = vm.categories.map((category) => {
        category.selected = vm.allCategoriesSelected;
        return category;
      });

      updateProductFilters();
    }

    /**
     * Get Background Image for Product Card
     */
    function getBackgroundImage (product) {
      if (product.images.hero.base_imgix_url) {
        return $filter('imgix')(product.images.hero, 'medium');
      } else if (product.images.gallery.length) {
        return $filter('imgix')(product.images.gallery[0], 'medium');
      } else {
        return '/images/placeholder-product-4-3.png';
      }
    }

    /**
     * Based on `marking`, add a sorting number to each product, used for sorting
     */
    function assignSortOrder (product) {
      const marking = product.marking;
      let sortOrder;
      if (marking) {
        if (marking === 'N') {
          sortOrder = 1;
        } else if (marking === 'F') {
          sortOrder = 2;
        } else {
          sortOrder = 3;
        }
      } else {
        sortOrder = 1;
      }

      product.sortOrder = sortOrder;
      return product;
    }

    /**
     * Function to get assign card order based on its marking
     */
    function assignMarkingOrder(productMarking){
      if(productMarking === 'N') {
        return 0;
      } else if (productMarking === 'F') {
        return 1;
      } else {
        return 2;
      }
    }

  }

}());
