(function() {
  'use strict';

  angular
  .module('architizer.app')
  .controller('ArchitectureGenomeController', [
    '$http',
    'Config',
    ArchitectureGenomeController
  ]);

  function ArchitectureGenomeController ($http, config) {
    var vm = this;

    vm.config = config;

    vm.formData = {};

    // Hidden Fields
    vm.formData.elqFormName = 'GenomeForm-1487091509936';
    vm.formData.elqSiteID = '840291656';
    vm.formData.elqCookieWrite = '0';

    // Email
    vm.formData.Email = '';

    // Validation Flags
    vm.formValid = true;

    // Functions
    vm.validateEmail = validateEmail;

    var eloquaUrl = 'https://s840291656.t.eloqua.com/e/f2';

    /**
     * Validate user's email address
     */
    function validateEmail () {

      var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      var emailValid = emailRegex.test(vm.formData.Email);

      // Do your validation steps
      if (emailValid) {
        submitEmail();
      }
      else {
        // Show your error state;
        $('.signupForm .infotext').fadeIn('250').html('Please enter a valid email address!');
      }
    }

    /**
     * Submit email address to Eloqua Form endpoint
     */
    function submitEmail () {

      $http({
         method: 'POST',
         url: eloquaUrl,
         data: serialize(vm.formData),
         headers: {'Content-Type': 'application/x-www-form-urlencoded'},
      })
      .then(submitEmailSuccess)
      .catch(submitEmailError);

      /**
       * Turns form data into a query string for submitting to a HTTP POST endpoint
       */
      function serialize (obj) {
        var str = [];
        for (var p in obj) {
          if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
          }
        }
        return str.join('&');
      }

      function submitEmailSuccess (data) {
        // Fade out and show 'Thank you'
        console.error(data);
        $('.signupForm .control, .signupForm .infotext').fadeOut('450')
          .promise().done(function () {
            $('.signupForm .thanks').fadeIn('450');
          });
      }

      function submitEmailError (error) {
        console.error(error);
        $('.signupForm .infotext').fadeIn('250').html('Looks like something went wrong...try again soon.');
        // Show an error to the user if there was an issue submitting to Eloqua (not a validation error)
      }
    }
  }
})();
