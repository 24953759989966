'use strict';

angular
	.module('ui')
	.directive('uiHero', ['$parse', function ($parse) {

		return {
			replace: true,
			restrict: 'E',
			transclude: true,
			templateUrl: '/views/uikit/ui-hero.html',
			link: function ($scope, element, attrs, ngModel) {

				/*
				 * View params
				 */

				$scope.$watch(attrs.image, function () {

					$scope.image = $parse(attrs['image'])($scope);
				});

				$scope.$watch(attrs.attribution, function () {

					$scope.attribution = $parse(attrs['attribution'])($scope);
				});


				/*
				 * UI interaction
				 */


				/*
				 * Data methods
				 */

				// end here if no ng-model
				if (!ngModel) {

					return;
				}
			}
		};
	}]);