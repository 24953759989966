(function () {
  'use strict';

  angular
  .module('architizer.app')
  .controller('BaseController', BaseController);

  BaseController.$inject = [
    '$location',
    '$log',
    '$rootScope',
    '$scope',
    '$state',
    '$timeout',
    '$transitions',
    '$window',
    'AuthenticationService',
    'AuthorizationService',
    'Config',
    'CustomEventsService',
    'GlobalView',
    'PopupManager',
  ];

  function BaseController (
    $location,
    $log,
    $rootScope,
    $scope,
    $state,
    $timeout,
    $transitions,
    $window,
    AuthenticationService,
    AuthorizationService,
    Config,
    CustomEventsService,
    GlobalView,
    PopupManager
  ) {

    $scope.config = Config;
    $scope.globalView = GlobalView;
    $scope.authorization = AuthorizationService;
    $scope.authentication = AuthenticationService;
    $scope.popupManager = PopupManager;
    $scope.$state = $state;

    // Use Design Kit
    $scope.useAdk = false;

    const {
      ACTION_BAR_CLEAR,
      ACTION_BAR_UPDATE
    } = CustomEventsService.events;

    // listen for when a new state is loaded

    $transitions.onSuccess({ }, function(transition) {
      
      let toState = transition.to(),
          fromState = transition.from();
      
      // Clear ActionBar for non-ActionBar pages
      if (toState.data && !toState.data.hasActionBar) {
        $rootScope.$broadcast(ACTION_BAR_CLEAR)
      }

      // Broadcast updates to the ActionBar data
      if (toState.data && toState.data.hasActionBar) {
        let actionBarData = transition.injector().get('actionBar') || {};
        $rootScope.$broadcast(ACTION_BAR_UPDATE, actionBarData)
      }

      // Determine whether this $state should use Design Kit
      if (toState.data && toState.data.useAdk) {
        $timeout(function() {
          $(document).foundation();
        }, 0);
        $scope.useAdk = true;
      } else {
        $scope.useAdk = false;
      }

      // Check for Design Kit development build hash
      adkDevelopmentBuild();

      // scroll to top of page
      $window.scrollTo(0,0);

      // update the navigation
      var module = $location.path().split('/')[1];
      switch (module) {

        case 'source':
          GlobalView.currentPrimaryItem = 'source';
          break;

        default:
          GlobalView.currentPrimaryItem = '';
          break;
      }

      /**
       * Architizer Design Kit Development Builds
       *
       * Look for ?adk= parameter in the URL and append a link to the
       * corresponding development build of Design Kit.
       *
       * Example:
       *
       * `?adk=abcd123` will append
       *
       * <link rel="stylesheet" href="//design-kit.arc.ht/css/dev/abcd123.css">
       *
       * to the head of the page.
       */
      function adkDevelopmentBuild () {

        // If we have the ?adk= parameter and we haven't already appended a tag
        if ($location.search().adk && !document.querySelector('#adk-dev-version')) {
          var adkHash = $location.search().adk;

          // Construct the stylesheet <link> tag
          var link = document.createElement('link');
          link.id = 'adk-dev-version';
          link.rel = 'stylesheet';
          link.href = '//design-kit.arc.ht/css/dev/' + adkHash + '.css';

          // Append to <head>
          $log.info('Using Architizer Design Kit Development Build:', adkHash);
          document.head.appendChild(link);
        }
      }
    });
  }
})();
