'use strict';

angular
  .module('architizer.app')
  .config(['$stateProvider', function ($stateProvider) {

    $stateProvider
    .state({
      name: 'architecturegenome',
      url: '/architecturegenome/',
      views: {
        'root@': {
          templateUrl: '/views/static/architecturegenome.html',
          controller: 'ArchitectureGenomeController',
          controllerAs: 'ArchitectureGenomeCtrl',
        },
      },
      data: {
        showIntercom: false,
        fixedHeader: true,
        showFooter: true,
      },
    });
  }]);
