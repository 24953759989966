(function() {
  'use strict';

  angular
  .module('architizer.app')
  .factory('UnitsService', UnitsService);

  UnitsService.$inject = [

  ];

  function UnitsService (

  ) {

    var service = this;

    // Configuration
    service.units = {
      units: units,
      in: inches,
      ft: feet,
      yd: yards,
      mm: millimeters,
      cm: centimeters,
      m: meters,
      sqft: squareFeet,
      m2: squareMeters,
      yd2: squareYards,
      lb: pounds,
      oz: ounces,
      g: grams,
      kg: kilograms,
      gal: gallons,
      l: liters,
      day: days,
      wk: weeks,
      mo: months,
    };

    // Groupings
    service.dimensionless = {
      label: 'Dimensionless',
      value: 'dimensionless',
      options: [
        units(),
      ],
    };
    service.linear = {
      label: 'Linear',
      value: 'linear',
      options: [
        inches(),
        feet(),
        yards(),
        millimeters(),
        centimeters(),
        meters(),
      ]
    };
    service.area = {
      label: 'Area',
      value: 'area',
      options: [
        squareFeet(),
        squareMeters(),
        squareYards(),
      ],
    };
    service.mass = {
      label: 'Mass',
      value: 'mass',
      options: [
        pounds(),
        ounces(),
        grams(),
        kilograms(),
      ],
    };
    service.volume = {
      label: 'Volume',
      value: 'volume',
      options: [
        gallons(),
        liters(),
      ],
    };
    service.time = {
      label: 'Time',
      value: 'time',
      options: [
        days(),
        weeks(),
        months(),
      ],
    };

    service.allUnits = getAllUnits();

    service.quantityUnits = [
      units(),
      feet(),
      yards(),
      meters(),
      squareFeet(),
      squareYards(),
      squareMeters(),
    ];

    // Functions
    service.getAllUnits = getAllUnits;
    service.getUnitForCode = getUnitForCode;

    return service;

    ////////////////////////////////////////////////////////////////////////////////
    // Functions
    ////////////////////////////////////////////////////////////////////////////////

    /**
     * All Units
     *
     * Returns an array of all possible unit options
     */
    function getAllUnits () {
      var units = [];

      angular.forEach(service.units, function (unit) {
        units.push(unit());
      });

      return units;
    }

    /**
     * Get Unit for Code
     *
     * Used by $filter methods on templates to display units correctly in UI copy
     */
    function getUnitForCode (code) {
      if (service.units[code]) {
        return service.units[code]();
      }
    }

    ////////////////////////////////////////////////////////////////////////////////
    // Units
    ////////////////////////////////////////////////////////////////////////////////

    function units () {
      return {
        label: 'Units',
        singular: 'unit',
        plural: 'units',
        symbol: '',
        value: 'units'
      };
    }

    function inches () {
      return {
        label: 'Inches',
        singular: 'inch',
        plural: 'inches',
        symbol: 'in',
        value: 'in'
      };
    }

    function feet () {
      return {
        label: 'Feet',
        singular: 'foot',
        plural: 'feet',
        symbol: 'ft',
        value: 'ft'
      };
    }

    function yards () {
      return {
        label: 'Yards',
        singular: 'yard',
        plural: 'yards',
        symbol: 'yd',
        value: 'yd'
      };
    }

    function millimeters () {
      return {
        label: 'Millimeters',
        singular: 'millimeter',
        plural: 'millimeters',
        symbol: 'mm',
        value: 'mm'
      };
    }

    function centimeters () {
      return {
        label: 'Centimeters',
        singular: 'centimeter',
        plural: 'centimeters',
        symbol: 'cm',
        value: 'cm'
      };
    }

    function meters () {
      return {
        label: 'Meters',
        singular: 'meter',
        plural: 'meters',
        symbol: 'm',
        value: 'm'
      };
    }

    function squareFeet () {
      return {
        label: 'Square feet',
        singular: 'square foot',
        plural: 'square feet',
        symbol: 'ft²',
        value: 'sqft'
      };
    }

    function squareMeters () {
      return {
        label: 'Square meters',
        singular: 'square meter',
        plural: 'square meters',
        symbol: 'm²',
        value: 'm2'
      };
    }

    function squareYards () {
      return {
        label: 'Square yards',
        singular: 'square yard',
        plural: 'square yards',
        symbol: 'yd²',
        value: 'yd2'
      };
    }

    function pounds () {
      return {
        label: 'Pounds',
        singular: 'pound',
        plural: 'pounds',
        symbol: 'lb',
        value: 'lb'
      };
    }

    function ounces () {
      return {
        label: 'Ounces',
        singular: 'ounce',
        plural: 'ounces',
        symbol: 'oz',
        value: 'oz'
      };
    }

    function grams () {
      return {
        label: 'Grams',
        singular: 'gram',
        plural: 'grams',
        symbol: 'g',
        value: 'g'
      };
    }

    function kilograms () {
      return {
        label: 'Kilograms',
        singular: 'kilogram',
        plural: 'kilograms',
        symbol: 'kg',
        value: 'kg',
      };
    }

    function gallons () {
      return {
        label: 'Gallons',
        singular: 'gallon',
        plural: 'gallons',
        symbol: 'gal',
        value: 'gal'
      };
    }

    function liters () {
      return {
        label: 'Liters',
        singular: 'liter',
        plural: 'liters',
        symbol: 'l',
        value: 'l'
      };
    }

    function days () {
      return {
        label: 'Days',
        singular: 'day',
        plural: 'days',
        symbol: 'day',
        value: 'day'
      }
    }

    function weeks () {
      return {
        label: 'Weeks',
        singular: 'week',
        plural: 'weeks',
        symbol: 'wk',
        value: 'wk'
      }
    }

    function months () {
      return {
        label: 'Months',
        singular: 'month',
        plural: 'months',
        symbol: 'mo',
        value: 'mo'
      }
    }

  }
})();