'use strict';

angular
  .module('ui')
  .directive('uiMenuDropdown', ['$parse', '$compile', '$window', '$document', '$rootScope', '$timeout', function ($parse, $compile, $window, $document, $rootScope, $timeout) {

    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      template: '<div class="uiMenuDropdown" ng-class="{ open: menuOpen }"><span class="title glyph block">1</span></div>',
      link: {
        pre: function($scope, element, attrs, ctrls, transclude) {

          var items,
            menuConfig = {},
            menuOffsetHorizontal = 14,
						menuDefaultOrientation = attrs.defaultOrientation || 'se',
            menuElement,
            menuScope;

          $scope.menuOpen = false;

          var _template = '<div ng-show="!!config.items.length" class="uiMenuDropdownTray {{ config.direction }}" ng-class="{ ready: ready }"></div>';


          /**
           * Initialisation
           */

  				var init = function () {

            _readItems();
  					_attachEvents();
  				};


          function _readItems() {

            // Take the nested html of the element and store the items
            // that have been provided
            transclude($scope, function(clone) {

              items = clone.filter('.uiMenuDropdownItem');
            });
          }

          // Attach element interaction handlers
  				function _attachEvents() {

  					// Show tooltip when element is hovered
  					element.on('click', function () {

              if (!$scope.menuOpen) {

                $scope.$apply(showMenu);
              }
  					});

  					// Ensure tooltip is removed when target element
  					// is removed
  					$scope.$on('$destroy', hideMenu);
  				}


          /*
  				 * View methods
  				 */

  				var showMenu = function () {

  					if (!menuElement) {

  						// Create the tooltip
  						_createMenu();
  					}

            $scope.menuOpen = true;
  				};

  				var hideMenu = function () {

  					if (menuElement) {

  						menuElement.remove();
  						menuElement = null;
  					}


  					if (menuScope) {

  						menuScope.$destroy();
  						menuScope = null;
  					}

            $scope.menuOpen = false;

            // Remove the click handler on the body
				    $document.find('body').eq(0).off('click');
  				};

  				var _createMenu = function () {

  					// Create a fresh scope for the tooltip
  					menuScope = $rootScope.$new();

  					// Add the tooltip config
  					menuScope.config = {};
            menuScope.config.items = items;
						menuScope.ready = false;

  					// Default config
  					menuScope.config.direction = menuDefaultOrientation;

  					// Compile the markup into a linking function
            var linkFn = $compile(_template);

            // Inject the scope, and link
            var el = angular.element(linkFn(menuScope));

            // Add the items
            angular.forEach(items, function(item) {

              var itemLinkFn = $compile(item.outerHTML);
              var item = itemLinkFn($scope);
              el.append(item);
            });

            // Append to the document
  					angular.element($document[0].body).append(el);

  					// Save reference
  					menuElement = el;

  					// Begin positioning the tooltip
  					_beginPositioning();

            // Attach a click handler on the document.
    				// When the body is clicked, close the menu
    				// Allow a short delay before doing this so that a click event that
    				// may have triggered this function call completes first.
    				$timeout(function () {

    					$document.find('body').eq(0).on('click', function (event) {

    						$scope.$apply(hideMenu);
    					});
    				}, 100);
  				};

  				var reposition = function () {

  					// Get window scroll position
  					var scrollX = $window.scrollX || $window.pageXOffset;
  					var scrollY = $window.scrollY || $window.pageYOffset;

  					// Get rectangle of anchor element
  					var anchorRect = element[0].getBoundingClientRect();

  					// Get rectangle of menu element
  					var menuRect = menuElement[0].getBoundingClientRect();

  					// Calculate the menu direction
  					var direction = menuDefaultOrientation;
  					if ((anchorRect.right + menuElement[0].offsetWidth) >= ($window.innerWidth-50)) {

  						direction = 'sw';
  					}

  					if (menuScope.config.direction != direction) {

  						menuScope.config.direction = direction;
  					}

  					// Position based on direction
  					switch (menuScope.config.direction) {

  						case 'se':
  							menuElement
  								.css('top', anchorRect.bottom + scrollY + 'px')
  								.css('left', anchorRect.left + (anchorRect.width)/2 + scrollX - menuOffsetHorizontal + 'px')

  							break;

  						case 'sw':
  							menuElement
                  .css('top', anchorRect.bottom + scrollY + 'px')
  								.css('left', anchorRect.left + (anchorRect.width)/2 - menuRect.width + scrollX + menuOffsetHorizontal + 'px')

  							break;
								
							case 's':
  							menuElement
  								.css('top', anchorRect.bottom + scrollY + 'px')
  								.css('left', anchorRect.left + (anchorRect.width)/2 + scrollX + 'px')

  							break;
  					}
						
						menuScope.ready = true;
  				};

  				// Begin positioning loop
  				var _beginPositioning = function () {

  					// Request end loop flag
  					var _end = false;

  					// Animation loop
  					setTimeout(function animLoop() {

  						if (!_end) {

  							requestAnimFrame(animLoop);
  							menuScope.$apply(reposition);
  						}
  					});

  					// Clean up loop on destroy
  					menuScope.$on('$destroy', function () {

  						_end = true;
  					});
  				};

          // Kick things off
  				init();
        }
      }
    }
  }]);
