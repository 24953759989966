'use strict';

angular
	.module('ui')
	.directive('uiHeroPreview', [function () {
		
		return {
			restrict: 'E',
			replace: true,
			templateUrl: '/views/uikit/ui-hero-preview.html',
			scope: {
				src: '='	
			},
			link: function($scope, element) {
				
				$scope.x = 0.5;
				$scope.y = 0.5;
				
				$scope.onImageClick = function (e) {
					
					var target = e.target || e.srcElement,
				        rect = target.getBoundingClientRect(),
				        offsetX = e.clientX - rect.left,
				        offsetY = e.clientY - rect.top;
						
					// Container dimensions
					var container = angular.element(e.target),
						cx = container.width(),
						cy = container.height();
						
					// Focus point relative to container
					var xdash = offsetX / cx,
						ydash = offsetY / cy;
						
					// Image dimensions
					var image = new Image();
					image.src = $scope.src;
					
					var ixNat = image.width,
						iyNat = image.height,
						aspectRatio = ixNat / iyNat;
						
					var ix, iy,
						parentRatioX = ixNat / cx,
						parentRatioY = iyNat / cy;
					
					if (parentRatioX <= parentRatioY) {
						
						ix = cx;
						iy = ix / aspectRatio;	
					}
					
					else {
						
						iy = cy;
						ix = iy * aspectRatio;
					}
						
					// The distance between the edge of the background
					// image to the center of the container
					// Note this is limited by the bounding dimension
					var edx, edy,
					 	xold = $scope.x,
						yold = $scope.y;
						
					if (parentRatioX <= parentRatioY) {
					
						edx = 0.5 * cx;
						edy = Math.max(0.5 * cy, iy * yold);	
					}
					
					else {
						
						edx = Math.max(0.5 * cx, ix * xold);
						edy = 0.5 * cy;
					}
					
					// Calculate new focus point
					var x = (edx - cx * (0.5 - xdash)) / ix,
						y = (edy - cy * (0.5 - ydash)) / iy;
					
					$scope.x = x;
					$scope.y = y;
				};
			}
		};
	}]);