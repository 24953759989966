'use strict';

angular
	.module('architizer.app')
	.filter('countries', ['LocationService', function (location) {

			return function (codes, max) {

				if (!codes || !codes.length) {

					return [];
				}

				if (max) {

					codes = codes.slice(0, max);
				}

				return location.getCountriesForCodes(codes);
			};
	}])

	.filter('states', ['LocationService', function (location) {

			return function (codes, max) {

				if (!codes || !codes.length) {

					return [];
				}

				if (max) {

					codes = codes.slice(0, max);
				}

				return location.getLocationsForCodes(codes).states;
			};
	}])

	.filter('location', function () {

			return function (arcLocation) {

				if (!arcLocation) {

					return '';
				}

				// United States
				if (arcLocation.country === 'United States') {

					// Don't show country (Portland, Oregon)
					return arcLocation.locality + ', ' + arcLocation.administrative_area_level_1;
				}

				// International
				else {

					// If no administrative_area_level_1, omit it (Belgrade, Serbia)
					if (arcLocation.administrative_area_level_1 === '[no name]' ||
						arcLocation.administrative_area_level_1 === '' ||
						arcLocation.administrative_area_level_1 === null) {

						return arcLocation.locality + ', ' + arcLocation.country;
					}

					// Otherwise, show administrative_area_level_1 (Collingwood, Victoria, Australia)
					else {

						return arcLocation.locality + ', ' + arcLocation.administrative_area_level_1 + ', ' + arcLocation.country;
					}

				}
			};
	})

	.filter('mailingAddress', function () {

			return function (arcLocation) {

				if (!arcLocation) {

					return '';
				}

				return '<span class="block">' + arcLocation.street_number + ' ' + arcLocation.route + '</span>' +
					'<span class="block">' + arcLocation.locality + ', ' + arcLocation.administrative_area_level_1 + ' ' + arcLocation.postal_code + '</span>';
			};
	})

	.filter('cityStateZip', function () {

			return function (arcLocation) {

				if (!arcLocation) {

					return '';
				}

				return arcLocation.locality + ', ' + arcLocation.administrative_area_level_1 + ' ' + arcLocation.postal_code;
			};
	});
