(function() {
  'use strict';

  angular
  .module('architizer.app')
  .controller('SourceBpmConversationSendMessageController', SourceBpmConversationSendMessageController);

  SourceBpmConversationSendMessageController.$inject = [
    '$filter',
    '$modal',
    '$rootScope',
    '$scope',
    '$state',
    'AuthenticationService',
    'conversation',
    'Conversation',
    'CustomEventsService',
    'productRequest',
    'user',
  ];

  function SourceBpmConversationSendMessageController (
    $filter,
    $modal,
    $rootScope,
    $scope,
    $state,
    AuthenticationService,
    conversation,
    Conversation,
    CustomEventsService,
    productRequest,
    user
    ) {

    var vm = this;

    const {
      SEND_PRODUCT_MODAL_SHOW,
      CONVERSATION_MESSAGE_SEND_COMPLETE
    } = CustomEventsService.events;

    // Data
    vm.user = user;
    vm.message = '';
    vm.conversation = conversation;
    vm.productRequest = productRequest;
    vm.avatar = AuthenticationService.user.images.avatar;
    vm.rootScopeUnbindRefs = [];

    // Functions
    vm.sendMessage = sendMessage;
    vm.onSendProductClick = onSendProductClick;

    // Initialize
    init();

    ////////////////////////////////////////////////////////////////////////////////
    // Functions
    ////////////////////////////////////////////////////////////////////////////////

    /**
     * Initialization
     */
    function init () {
      bindEventListeners();
    }

    /**
     * Function that unbinds event listeners when controller $scope is destroyed.
     */
    function unbindEventListeners () {
      vm.rootScopeUnbindRefs.forEach(unbindFn => unbindFn());
    }

    /**
     * Function that encapsulate event listeners for this controller.
     */
    function bindEventListeners () {
      // Set up listener for when user sends a new message in the send-message UI
      const UNBIND_SEND_PRODUCT_MODAL_SHOW = $rootScope.$on(SEND_PRODUCT_MODAL_SHOW, function () {
        onSendProductClick();
      });

      // Hold a reference to `unbind` functions for all $rootScope events
      vm.rootScopeUnbindRefs.push(UNBIND_SEND_PRODUCT_MODAL_SHOW);

      // Unbind all $rootScope event listeners on controller $destroy event
      $scope.$on('$destroy', unbindEventListeners);
    }

    /**
     * Send conversation message
     */
    function sendMessage () {

      // Show loader in send button
      vm.sendButtonLoading = true;

      // Send the message with the API
      Conversation.sendMessage({
        id: vm.conversation.id,
        content: vm.message,
      })
      .$promise
      .then(SendMessageSuccess)
      .catch(SendMessageError);
    }

    function SendMessageSuccess (data) {

      // Broadcast the sent message so we can update the conversation
      $rootScope.$broadcast(CONVERSATION_MESSAGE_SEND_COMPLETE, { message: data });

      // Reset Message Form
      vm.sendButtonLoading = false;
      vm.message = '';

      return data;
    }

    function SendMessageError (error) {
      console.error('Error Sending Message:', error);

      // Reset Send Button
      vm.sendButtonLoading = false;
    }

    /**
     * Send product (show send product modal)
     */
    function onSendProductClick () {
      var sendProductModal = $modal.open({
        templateUrl: '/views/source/bpm/conversation.send-product.modal.html',
        controller: 'SendProductModalController',
        controllerAs: 'OpportunityCtrl', // FIXME: This name is non-standard because this form is shared with the Opportunity view
        resolve: {
          user: () => vm.user,
          productRequest: () => vm.productRequest,
        }
      });

      sendProductModal
      .result
      .then(() => $state.reload());
    }
  }
})();
