'use strict';

angular
	.module('ui')
	.directive('minValue', [function () {

		return {
			replace: false,
			restrict: 'A',
			require: '?ngModel',
			link: function ($scope, element, attrs, ngModel) {

				var min = attrs['minValue'] || 0;

				// if no ngModel, end here
				if (!ngModel) {

					return;
				}

				ngModel.$validators.minValue = function (value) {

					// Don't validate if this field is empty
					if (value == '' || value == null) {

						return true;
					}

					return parseFloat(value) >= parseFloat(min);
				};
			}
		};
	}]);