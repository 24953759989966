(function() {
  'use strict';

  angular
  .module('architizer.app')
  .controller('SourceBpmConversationActionBarController', SourceBpmConversationActionBarController);

  SourceBpmConversationActionBarController.$inject = [
    '$modal',
    '$state',
    '$transitions',
    'conversation',
    'CustomDataService',
    'PopupManager',
    'productRequest',
  ];

  function SourceBpmConversationActionBarController (
    $modal,
    $state,
    $transitions,
    conversation,
    CustomDataService,
    PopupManager,
    productRequest
    ) {

    var vm = this;

    // Data
    vm.conversation = conversation;
    vm.productRequest = productRequest;
    vm.$state = $state;

    // Functions
    vm.showSearchDetails = showSearchDetails;
    vm.onSentProductsClick = onSentProductsClick;
    vm.onMessagesClick = onMessagesClick;

    // Watches
    /**
     * Clear vm.productRequest on $transitions.onStart
     *
     * The Action Bar data persists while loading a different product request.
     * This clears the data when changing states.
     */
    $transitions.onStart( { }, () => { vm.conversation = null; } );

    // Initialize
    init();

    ////////////////////////////////////////////////////////////////////////////////
    // Functions
    ////////////////////////////////////////////////////////////////////////////////

    /**
     * Initialization
     */
    function init () {

      // Prepare structured data points for view in request info modals
      CustomDataService.prepareForDisplay(angular.fromJson(vm.productRequest.structured_data));
    }

    /**
     * Show Product Search details modal
     */
    function showSearchDetails () {
      $modal.open({
        templateUrl: '/views/source/shared/product-search.content.modal.html',
        controller: 'SourceProductSearchModalController',
        controllerAs: 'ProductSearchModalCtrl',
        resolve: {
          productRequest: () => vm.productRequest,
        }
      });
    }

    /**
     * On Sent Products link click in action bar
     */
    function onSentProductsClick () {
      $state.go('source.app.bpm.conversations.conversation.products');
    }

    /**
     * On Messages link click in action bar
     */
    function onMessagesClick () {
      $state.go('source.app.bpm.conversations.conversation');
    }
  }
})();