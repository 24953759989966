(function() {
  'use strict';

  angular
  .module('architizer.app')
  .run(RunIntercomHelper);

  RunIntercomHelper.$inject = [
    'AuthenticationService',
    'Config',
    'SegmentioService',
    '$location',
    '$rootScope',
    '$state',
    '$timeout',
    '$transitions',
    '$window',
  ];

  function RunIntercomHelper (
    AuthenticationService,
    Config,
    SegmentioService,
    $location,
    $rootScope,
    $state,
    $timeout,
    $transitions,
    $window) {

    var intercomAppId = Config.intercom.app_id;
    var isIntercomQueued = null;
    var isIntercomActive = false;
    var hash = null;

    function setSegmentioOptions() {
      var options = {
        integrations: {
          All: true
        }
      };

      SegmentioService.setOptions(options);
    }

    // Function to set Intercom User Traits
    function createUserTraits() {

      var traits = { app_id: intercomAppId };
      var user = AuthenticationService.user;
      var userBrands;
      var userFirms;
      var company;

      // Add user-specific traits if we have a user
      if (AuthenticationService.isProfessionalUser()) {
        company = {};

        // Get user's firms or brands
        userBrands = (Object.keys(user.roles.brands).length ? user.brands : null);
        userFirms = (Object.keys(user.roles.firms).length ? user.firms : null);

        // Gather company information
        if (userBrands && userBrands.length) {
          // Company ID is appended with "B" to denote this is a brand ID
          company.id = 'B' + userBrands[0].id;
          company.name = userBrands[0].name;
          company['Company Type'] = 'Brand';
          company['Source Access'] = userBrands[0].product_source_access === 'A' ? true : false;
        }

        if (userFirms && userFirms.length) {
          // Company ID is appended with "F" to denote this is a firm ID
          company.id = 'F' + userFirms[0].id;
          company.name = userFirms[0].name;
          company['Company Type'] = 'Firm';
          company['Source Access'] = userFirms[0].product_source_access === 'A' ? true : false;
        }

        traits = angular.extend(traits, {
          createdAt: user.created,
          email: user.email,
          firstName: user.first_name,
          lastName: user.last_name,
          user_id: user.id,
          username: user.email,
          user_hash: user.intercom_hmac,
          company: company
        });
      }

      return traits;
    }

    // Function to call Intercom (accepts strings 'boot' or 'shutdown' as parameters)
    function callIntercom(command) {
      var traits = createUserTraits();
      switch (command) {
        case 'shutdown':
          if ($window.Intercom) {
            $window.Intercom('shutdown');
            isIntercomActive = false;
          } else {
            isIntercomQueued = false; // Set queue if no window.Intercom
          }
          break;

        case 'boot':
          if ($window.Intercom) {
            $window.Intercom('shutdown');
            $window.Intercom('boot', traits);
            isIntercomActive = true;
          } else {
            isIntercomQueued = true; // Set queue if no window.Intercom
          }
          break;
      }
    }

    // Watch for window.Intercom
    $rootScope.$watch(function() {
      return $window.Intercom;
    }, function (Intercom) {
      if (typeof Intercom === 'function') {

        // If Intercom is queued to boot/shutdown
        if (isIntercomQueued !== null) {
          if (isIntercomQueued) {
            callIntercom('boot');   // Call Boot
          } else {
            callIntercom('shutdown'); // Call Shutdown
          }
        }
      }

    });

    // Update Intercom show/hide on $state change
    $transitions.onSuccess({}, () => {
      setSegmentioOptions();
      if (!isIntercomActive) {
        callIntercom('boot');
      }
    });

    // Update Intercom user when user changes
    $rootScope.$watch(function() {
      return AuthenticationService.user;
    }, function (user) {

      // Set intercom hash from returned user, if we have a user
      if (user) {
        hash = user.intercom_hmac;
      } else {
        hash = null;
      }

      setSegmentioOptions();
      callIntercom('boot');
    });
  }
})();
