'use strict';

angular
  .module('ui')
  .directive('uiMenuSelect', ['$parse', '$compile', '$window', '$document', '$rootScope', '$timeout', function ($parse, $compile, $window, $document, $rootScope, $timeout) {

    return {
      restrict: 'E',
      replace: true,
      transclude: false,
      template: '<div class="uiMenuSelect uiMenuDropdown" ng-class="{ open: menuOpen, hasValue: hasValue }"><span class="body block ellipsis">{{ label }}</span></div>',
      scope: {
        options: '='
      },
      require: 'ngModel',
      link: {
        pre: function($scope, element, attrs, ngModel) {

          var items,
            menuOffsetHorizontal = 14,
						menuDefaultOrientation = attrs.defaultOrientation || 'se',
            menuDefaultLabel = attrs.defaultLabel || 'Select',
            menuElement,
            menuScope;

          $scope.menuOpen = false;
          $scope.hasValue = false;
          $scope.label = menuDefaultLabel;

          var _template = '<div ng-show="!!options.length" class="uiMenuDropdownTray {{ direction }}" ng-class="{ ready: ready }">' +
            '<div ng-repeat="option in options" ng-class="{ active: option.value == value }" ng-click="onOptionClick(option)" class="uiMenuDropdownItem uiMenuDropdownToggle">' +
              '<span class="check material-icons">check</span>' +
              '{{ option.label }}' +
            '</div>' +
          '</div>';


          /**
           * Initialisation
           */

  				function init() {

  					_attachEvents();
            _initWatches();
  				};

          // Attach element interaction handlers
  				function _attachEvents() {

  					// Show tooltip when element is hovered
  					element.on('click', function () {

              if (!$scope.menuOpen) {

                $scope.$apply(showMenu);
              }
  					});

  					// Ensure tooltip is removed when target element
  					// is removed
  					$scope.$on('$destroy', hideMenu);
  				}
          
          // Watch for changes in the control value
          function _initWatches() { 

            $scope.$watch(
              function() { return ngModel.$modelValue; },
              onValueChanged
            );
          }


          /*
  				 * View methods
  				 */

  				function showMenu() {

  					if (!menuElement) {

  						// Create the tooltip
  						_createMenu();
  					}

            $scope.menuOpen = true;
  				};

  				function hideMenu() {

  					if (menuElement) {

  						menuElement.remove();
  						menuElement = null;
  					}


  					if (menuScope) {

  						menuScope.$destroy();
  						menuScope = null;
  					}

            $scope.menuOpen = false;

            // Remove the click handler on the body
				    $document.find('body').eq(0).off('click');
  				};

  				function _createMenu() {
            
            // Create a fresh scope for the dropdown
            menuScope = $rootScope.$new();

  					// Add the dropdown config
  					menuScope.direction = menuDefaultOrientation;
            menuScope.options = $scope.options;
            menuScope.onOptionClick = onOptionClick;
            menuScope.value = ngModel.$modelValue;
            menuScope.ready = false;

  					// Compile the markup into a linking function
            var linkFn = $compile(_template);

            // Inject the scope, and link
            var el = angular.element(linkFn(menuScope));

            // Append to the document
  					angular.element($document[0].body).append(el);

  					// Save reference
  					menuElement = el;

  					// Begin positioning the tooltip
  					_beginPositioning();

            // Attach a click handler on the document.
    				// When the body is clicked, close the menu
    				// Allow a short delay before doing this so that a click event that
    				// may have triggered this function call completes first.
    				$timeout(function () {

    					$document.find('body').eq(0).on('click', function (event) {

    						$scope.$apply(hideMenu);
    					});
    				}, 100);
  				};

  				function reposition() {

  					// Get window scroll position
  					var scrollX = $window.scrollX || $window.pageXOffset;
  					var scrollY = $window.scrollY || $window.pageYOffset;

  					// Get rectangle of anchor element
  					var anchorRect = element[0].getBoundingClientRect();

  					// Get rectangle of menu element
  					var menuRect = menuElement[0].getBoundingClientRect();

  					// Calculate the menu direction
  					var direction = menuDefaultOrientation;
  					if ((anchorRect.right + menuElement[0].offsetWidth) >= ($window.innerWidth-50)) {

  						direction = 'se';
  					}

  					if (menuScope.direction != direction) {

  						menuScope.direction = direction;
  					}

  					// Position based on direction
  					switch (menuScope.direction) {

  						case 'se':
  							menuElement
  								.css('top', anchorRect.bottom + scrollY + 'px')
  								.css('left', anchorRect.left + (anchorRect.width)/2 + scrollX - menuOffsetHorizontal + 'px')

  							break;

  						case 'sw':
  							menuElement
                  .css('top', anchorRect.bottom + scrollY + 'px')
  								.css('left', anchorRect.left + (anchorRect.width)/2 - menuRect.width + scrollX + menuOffsetHorizontal + 'px')

  							break;
								
							case 's':
  							menuElement
  								.css('top', anchorRect.bottom + scrollY + 'px')
  								.css('left', anchorRect.left + (anchorRect.width)/2 + scrollX + 'px')

  							break;
  					}
            
            menuScope.ready = true;
  				};
          
          // When the control value changes
          function onValueChanged(value) {

            $scope.hasValue = !!value;
            
            // Show the default label if the value is falsey
            if (!value) {
              
              $scope.label = menuDefaultLabel;
              return;
            }
            
            // Find the corresponding option
            var option = _.find($scope.options, function(o) {
              
              return o.value == value;
            });
            
            if (!option) {
              
              return;
            }
            
            $scope.label = option.controlLabel || option.label;
          }
          
          // When an option is clicked
          function onOptionClick(option) {
            
            ngModel.$setViewValue(option.value);
          }

  				// Begin positioning loop
  				function _beginPositioning() {

  					// Request end loop flag
  					var _end = false;

  					// Animation loop
  					setTimeout(function animLoop() {

  						if (!_end) {

  							requestAnimFrame(animLoop);
  							menuScope.$apply(reposition);
  						}
  					});

  					// Clean up loop on destroy
  					menuScope.$on('$destroy', function () {

  						_end = true;
  					});
  				};

          // Kick things off
  				init();
        }
      }
    }
  }]);
