(function() {
  'use strict';

  angular
    .module('architizer.app')
    .factory('UtilsService', UtilsService);

  function UtilsService() {

    var service = this;

    service.extendSuggestionsArrayWithRawTermField = extendSuggestionsArrayWithRawTermField;
    service.dedupeSuggesterResults = dedupeSuggesterResults;
    service.unwrapFacets = unwrapFacets;

    return service;

    ////////////////////////////////////////////////////////////////////////////////
    // Functions
    ////////////////////////////////////////////////////////////////////////////////

    /**
     * Extend any given object with a `raw` field that strips HTML tags from `term` 
     * field. This is used for Solr-backed typeahead clients. and assumes `suggestions`
     * to be an Array of [{term: <b>keyword</b> etc}]
     * The `raw` field will be used for sorting in `orderBy` filter.
    **/
    function extendSuggestionsArrayWithRawTermField (suggestions) {
      return suggestions.map(function(suggestion) {
        return Object.assign({}, suggestion, {
          raw: suggestion.term.replace(/(<([^>]+)>)/ig,'').toLowerCase()
        });
      });
    }

    /**
     * Dedupes the suggestions returned from Solr suggester.  
     * dedup-ing is based on `raw` object field and assumes the following shape:
     * [{term: '<b>sea</b>rch term', raw: 'search term'}]
    **/
    function dedupeSuggesterResults (suggestions) {
      var rawTerms = suggestions.map(function(suggestion) {
        return suggestion.raw;
      });
      var uniqueRawTerms = Array.from(new Set(rawTerms));
      return uniqueRawTerms.map(function(term) {
        return suggestions.find(function(suggestion) {
          return suggestion.raw === term;
        });
      });
    }

    /**
     * Unwrap Facets
     *
     * This separates Solr's facet field data into objects that are consumed by our UI.
     *
     * facetFields is an array of alternating category names and document counts, like this:
     * [ "Accessories", 100, "Building Products", 200, "Equipment", 300 ]
     *
     * We have to turn that into an array of objects more like this:
     * [{ label: "Accessories", count: 100 }, { label: "Building Products", count: 200 }, ... ]
     *
     * Additionally, pass a selectedCheckFn function to be evaluated to determine whether 
     * this category should be displayed as "selected" (useful for ng-model on checkboxes, etc)
     */
    function unwrapFacets (facetFields = [], selectedCheckFn = () => false) {
      if (!facetFields.length) { return []; }

      let labels = facetFields.filter((o, i) => !(i % 2));  // Odd indexes are facet labels
      let counts = facetFields.filter((o, i) => i % 2);     // Even indexes are facet counts

      return labels.map((label, i) => {
        let categoryObject = {
          label: label,
          value: label, // Just reuse the label as the value - "value" is what we send to Solr
          slug: label.replace(' ', '_'),
          count: counts[i],
          selected: selectedCheckFn(label),
        };
        return categoryObject;
      });
    }
  }
}());
