(function () {
  'use strict';

  angular
  .module('architizer.app')
  .factory('CustomEventsService', CustomEventsService);

  function CustomEventsService () {
    const service = this;

    service.events = events();

    return service;

    ////////////////////////////////////////////////////////////////////////////////

    function events () {
      return {
        PRODUCT_RESPONSE_DISMISS_START: 'productResponse:dismiss:start',
        PRODUCT_RESPONSE_DISMISS_COMPLETE: 'productResponse:dismiss:complete',
        PRODUCT_RECOMMENDATION_ADD_TO_PRODUCTS_START: 'productRecommendation:addToProducts:start',
        PRODUCT_RECOMMENDATION_ADD_TO_PRODUCTS_COMPLETE: 'productRecommendation:addToProducts:complete',
        CONVERSATION_MESSAGE_SEND_COMPLETE: 'conversationSentMessage',
        CONVERSATION_MESSAGE_READ_COMPLETE: 'conversationReadMessage',
        PROJECT_SCHEDULE_REQUEST_COMPLETE: 'project_schedule_requested',
        SEND_PRODUCT_MODAL_SHOW: 'showSendProductModal',
        ACTION_BAR_CLEAR: 'actionbar:clear',
        ACTION_BAR_UPDATE: 'actionbar:update',
        MODAL_BUTTON_CLICK: 'modalButtonWasClicked',
      };
    }
  }

})();
