'use strict';

angular
	.module('ui')
	.directive('uiButton', ['$parse', function ($parse) {

		return {
			replace: true,
			restrict: 'E',
			transclude: true,
			templateUrl: '/views/uikit/ui-button.html',
			require: [
				'?ngDisabled'
			],
			scope: {
				icon: '@',
				materialIcon: '@',
				label: '@',
				size: '@',
				color: '@',
				variant: '@',
				type: '@',
				ngDisabled: '=',
				loading: '=',
				regularTooltip: '@',
				disabledTooltip: '@'
			},
			link: function ($scope, element, attrs, ngModel, transclude) {

				// Default to button type
				$scope.button = $scope.button || 'button';
				$scope.styles = [];
				// Default to medium size
				$scope.styles.push($scope.size || 'medium');
				$scope.size = $scope.size || 'medium';

				// Default to normal variant
				$scope.styles.push($scope.variant || 'normal');
				$scope.variant = $scope.variant || 'normal';
				
				$scope.styles.push($scope.color || 'default');
				$scope.styles.push({'hasIcon': $scope.icon || $scope.materialIcon});
				$scope.styles.push({'loading': $scope.loading});
				/*
				 * View params
				 */

				// Indicate to the view if there's a label
				transclude(function (clone) {
					$scope.styles.push({'hasLabel': !!clone[0]});
					$scope.hasLabel = !!clone[0];
				});

				/*
				 * UI interaction
				 */

				// Stop click event from bubbling on disabled if button is disabled
				// Disabled buttons don't receive click events so this is applied to
				// the dummy container element.
				$scope.onClick = function ($event) {

					if ($scope.ngDisabled) {

						$event.stopPropagation();
					}
				};

				// Pipe provided events on directive to inner button
				var events = ['click'];
				angular.forEach(events, function (event) {

					// Parse a handler out of attribute
					var ngEvent = 'ng' + event.charAt(0).toUpperCase() + event.slice(1);
					var attrHandler = $parse(attrs[ngEvent]);

					// Raw JS event handler
          var handler = function (e) {

            $scope.$apply(function () {

              attrHandler($scope, { $event: e });
            });
          };

          // Attach handler to js event
          element.children()[0].addEventListener(event, handler, false);
				});

				/*
				 * Data methods
				 */

				// end here if no ng-model
				if (!ngModel) {

					return;
				}
			}
		};
	}]);
