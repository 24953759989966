(function() {
  'use strict';

  angular
  .module('architizer.app')
  .directive('lockAtBottom', lockAtBottom);

  lockAtBottom.$inject = [
    '$parse',
  ];

  function lockAtBottom ($parse) {
    return {
      restrict: 'A',
      replace: false,
      link: function($scope, element, attr) {

        // If the lock-at-bottom="" value is falsy, don't continue
        if (!$parse(attr.lockAtBottom)($scope)) {
          return;
        }

        /**
         * Is the element in the locked state
         */
        var isLocked = true;

        // Attach handler on scroll
        element.on('scroll', handleScroll);

        // Watch for changes in the scroll height
        var unregisterHeightWatcher = $scope.$watch(function() {
          return element.prop('scrollHeight');
        }, handleHeightChange);

        // Cleanup
        $scope.$on('$destroy', function () {
          unregisterHeightWatcher();
          element.off('scroll', handleScroll);
        });

        /**
         * Handle the element being scrolled 
         */
        function handleScroll() {

          var bottom = element.prop('offsetHeight') + element.scrollTop();
          var scrollHeight = element.prop('scrollHeight');

          isLocked = (bottom >= scrollHeight);
        }

        /**
         * If the element is in the locked state, scroll to the bottom
         * of the element when the height changes 
         */
        function handleHeightChange() {

          if (isLocked) {
            scrollToBottom();
          }
        }

        /**
         * Scroll to the bottom of the element
         */
        function scrollToBottom() {

          var offsetHeight = element.prop('offsetHeight');
          var scrollHeight = element.prop('scrollHeight');
          element.scrollTop(scrollHeight - offsetHeight);
        }
      }
    };
  }
})();