/* global Raven */
(function () {
  'use strict';

  angular
  .module('architizer.app')
  .factory('SearchstaxService', SearchstaxService);

  /**
   * SearchStax Service
   *
   * This service is for sending event data to SearchStax analytics on Measured Search.
   *
   * A sample call is shown here:

   const ssAppName = 'mySearchstaxAppNameInConfig'; // This needs to be defined in server/config.js in [env].measuredSearch[appName].key
   const trackData = {
     key: SearchstaxService.getApiKey(ssAppName),   // Use getApiKey in this service to get the API key
     session: SearchstaxService.getSession(),       // Use getSession in this service to get the session
     query: solrData.responseHeader.params.q,       // Pass the query from the Solr response
     shownHits: vm.things.length,                   // Pass the current number of "search results" shown
     totalHits: solrData.response.numFound,         // Pass the total results found from the Solr response
     latency: solrData.responseHeader.QTime,        // Pass the query time from the Solr response
   };

   SearchstaxService.track(trackData);              // Use the method in this service to send the call
   */
  SearchstaxService.$inject = [
    '$q',
    '$window',
    'Config',
  ];

  function SearchstaxService (
    $q,
    $window,
    Config
  ) {

    var service = this;

    // Data
    service.analyticsSnippet = $window._msq;
    service.apiKey = '';
    service.apiKeys = Config.measuredSearch;
    service.session = '';

    // Functions
    service.track = track;
    service.trackClick = trackClick;
    service.addToCart = addToCart;
    service.getApiKey = getApiKey;
    service.getSession = getSession;

    return service;

    ////////////////////////////////////////////////////////////////////////////////
    // Functions
    ////////////////////////////////////////////////////////////////////////////////

    // Check if we have everything we need to send data to SearchStax Analytics
    function validate (data) {
      let deferred = $q.defer();

      if (service.analyticsSnippet && data.key) {
        deferred.resolve(data);
      } else {
        deferred.reject(data);
      }
      return deferred.promise;
    }

    // Get API Key for a given SearchStax app name
    function getApiKey (ssAppName = '') {
      if (!service.apiKeys[ssAppName]) { return; } // Stop if there is no entry for ssAppName in Config.measuredSearch

      if (!service.apiKey) {
        service.apiKey = service.apiKeys[ssAppName].key || '';
      }
      return service.apiKey;
    }

    // Get session identifier for session tracking
    function getSession () {
      if (!service.session) {
        service.session = (new Date()).getTime();
      }
      return service.session;
    }

    // "Track" events (called when a search is performed)
    function track (trackData = {}) {
      validate(trackData)
      .then((validatedData) => { service.analyticsSnippet.push(['track', validatedData]); })
      .catch(logError);
    }

    // "Track Click" events (called when a search result is clicked)
    function trackClick (trackClickData = {}) {
      validate(trackClickData)
      .then((validatedData) => { service.analyticsSnippet.push(['trackClick', validatedData]); })
      .catch(logError);
    }

    // "Add to Cart" events (called when a product recommendation is added to a search)
    function addToCart (addToCartData = {}) {
      validate(addToCartData)
      .then((validatedData) => { service.analyticsSnippet.push(['addToCart', validatedData]); })
      .catch(logError);
    }

    // Send an error to Sentry if we fail to send these events to SearchStax
    function logError (error) {
      Raven.captureException(new Error('Error sending event to SearchStax analytics'), { extra: { data: error }});
    }
  }
})();