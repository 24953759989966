'use strict';

angular
  .module('ui')
  .directive('uiActionBar', ['$parse', '$window', function ($parse, $window) {

    return {
      restrict: 'E',
      transclude: true,
      replace: true,
      templateUrl: '/views/uikit/ui-action-bar.html',

      link: function($scope, element, attrs) {

        // Attributes
        var
          bar = element.children().eq(0),
          dock = attrs.uiActionBarDock,
          dockOffset = 0;

        // Bind to changes in the dock offset attribute
        $scope.$watch($parse(attrs.uiActionBarDockOffset), function (value) {

          dockOffset = value || 0;
          bar.css('top', dockOffset + 'px');
        });

        // Create a continuous update loop utilising requestAnimFrame
				(function animLoop() {

					requestAnimFrame(animLoop);
					update();
				})();


        /**
         *
         */

        function update() {

          var wndScroll = angular.element($window).scrollTop();
          var elRect = element[0].getBoundingClientRect();
          var barRect = bar[0].getBoundingClientRect();

          // Make the container match the height of the action bar
          // This preserves the document flow even though the action bar
          // is absolutely positioned
          element.css('height', barRect.height + 'px');

          // Dock the bar if sufficiently scrolled
          if (dock == 'top') {

            if (elRect.top <= dockOffset) {

              bar.addClass('uiActionBarDocked');
            }

            if (elRect.top > dockOffset) {

              bar.removeClass('uiActionBarDocked');
            }
          }
        }
      }
    }
  }]);
