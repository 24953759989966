'use strict';

angular
	.module('ui')
	.directive('uiImage', ['$filter', '$http', '$parse', function ($filter, $http, $parse) {

		return {
			restrict: 'E',
			replace: true,
			templateUrl: '/views/uikit/ui-image.html',
			scope: {
				defaultAttribution: '=attribution',
				options: '='
			},
			link: function ($scope, element, attrs) {

				$scope.hasClickHandler = false;
				$scope.size = '';


				/**
				 * Options
				 */

				var defaults = {
					natural: true,
					overlay: true,
					imageKey: 'large',
					showCaption: true,
					showCredit: true,
					buttons: [],
					enforceIcons: false,
					apiResource: 'media/attributions/%id%'
				};

				$scope.settings = angular.extend(defaults, $scope.options);
                                $scope.$filter = $filter;


				/**
				 * Default attribution
				 */

				$scope.attribution = {};
				$scope.$watchCollection('defaultAttribution', function () {

					$scope.attribution = angular.extend({}, $scope.defaultAttribution);
				});


				/**
				 * Fetch image
				 */

				var _onGetAttributionSuccess = function (response) {

					var attribution = response.data;
					$scope.attribution = angular.extend($scope.attribution, attribution);
				};

				var _onGetAttributionError = function (error) {

				};

				/**
				 * Positioning
				 */

				function reposition() {

					var width = element.width();
					var size = '';

					if ($scope.settings.enforceIcons) {

						size = 'small';
					}

					else if (width < 115) {

						size = 'thumbnail';
					}

					else if (width < 360) {

						size = 'small';
					}

					else if (width < 550) {

						size = 'medium';
					}

					else {

						size = 'large';
					}

					if ($scope.size != size) {
						
						$scope.$apply(function() {
							
							$scope.size = size;
						});
					}
				}

				// Request end loop flag
				var _end = false;

				// Animation loop
				function animLoop() {

					if (!_end) {

						requestAnimFrame(animLoop);
						reposition();
					}
				};

				// Prevent digest error on first call
				setTimeout(animLoop);

				// Clean up loop on destroy
				$scope.$on('$destroy', function () {

					_end = true;
				});


				/**
				 * Event handlers
				 */

				$scope.onOverlayClick = function($event) {
					
					// Parse a handler out of attribute
					var attrHandler = $parse(attrs['click']);
					
					// If a click has propagated from an overlaid button,
					// don't call the click handler
					if (angular.element($event.originalEvent.target).closest('.uiButton').length) {

						return;
					}

					attrHandler($scope.$parent, { $event: $event, image: $scope.attribution });
				};

				// Indicate to the view there is a click handler
				$scope.$watch(function() {
					
					return attrs.hasOwnProperty('click');
				}, function() {
					
					$scope.hasClickHandler = true;
				});
			}
		};
	}]);
