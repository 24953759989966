'use strict';

angular
	.module('ui')
	.directive('uiErrors', [function () {

		return {
			replace: true,
			restrict: 'E',
			transclude: false,
			templateUrl: '/views/uikit/ui-errors.html',
			scope: {
				'for': '=',
				'customMessagesTemplate': '@'
			},
			link: function ($scope) {}
		};
	}]);