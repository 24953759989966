'use strict';

/**
 * Service to manage popup stack
 */

angular
  .module('ui')
  .factory('PopupManager', [
    '$rootScope',
    '$compile',
    '$timeout',
    '$document',
    function($rootScope, $compile, $timeout, $document) {

        var popupManager = {};

        /*
         * Member variables
         */

        // The stack of popups
        var stack = [];

        // The current mask element
        var mask;

        // The z-index on which the stack will be based
        var baseZ = 10000;

        /*
         * Returns the current number of popups
         */
        popupManager.count = function () {

            return stack.length;
        };

        /*
         * Creates and pushes a popup onto the stack
         */
        popupManager.push = function (config) {

            // Default to modal type
            if (!config.type) {

                config.type = 'modal';
            }

            // Default to having a mask
            if (typeof config.mask === 'undefined') {

                config.mask = true;
            }

            // Default to medium size
            if (!config.size) {

                config.size = 'medium';
            }

            // Create a scope for the modal
            var scope = $rootScope.$new();

            // Get the controller name if provided
            if (config.controller) {

                var controller = config.controller;
                delete config.controller;
            }

            // Copy config into scope whenever it changes
            $rootScope.$watchCollection(function () {

              return config;
            }, function () {

              angular.extend(scope, config);
            });
            angular.extend(scope, config);

            // The z-index to insert at
            var z = baseZ + (stack.length+1);

            // Create a wrapper for positioning
            var markup = angular.element('<div class="uiPopupWrapper"></div>');
            markup.addClass(config.type);

            // Pop modal when "mask" is clicked
            // It's actually the invisible wrapper
            // receiving the click
            markup.bind('click', function (e) {

                if (angular.element(e.target).hasClass('uiPopupWrapper')) {

                    scope.$apply(function () {

                        popupManager.pop();
                    });
                }
            });

            // Modal type
            if (config.type == 'modal') {

                // Create the popup markup
                var popupMarkup = angular.element('<ui-popup type="modal" />')
                    .addClass('uiModal')
                    .attr('heading', 'heading')
                    .attr('note', 'note')
                    .attr('content', 'content')
                    .attr('content-url', 'contentUrl')
                    .attr('buttons', 'buttons')
                    .attr('size', '{{ size }}')
                    .attr('action', 'action')
                    .attr('externals', 'externals')
                    .attr('use-adk', 'useAdk');
                // Add the controller if provided
                if (controller) {

                    popupMarkup.attr('controller', controller);
                }

                // Append the popup to the wrapper
                markup.append(popupMarkup);
            }

            // Compile the markup into a linking function
            var linkFn = $compile(markup);

            // Inject the scope, link, and append
            var element = angular.element(linkFn(scope));
            angular.element($document[0].body).append(element);

            // Prepare to push onto stack
            var popup = {
                config: config,
                element: element,
                scope: scope
            };

            // Push onto stack
            stack.push(popup);
        };

        /*
         * Pops a popup off the stack
         */
        popupManager.pop = function () {

            // Remove the popup from the stack
            var popup = stack.pop();

            // Start exit animation
            popup.element.addClass('exiting');

            $timeout(function () {

                // Remove the popup from the document
                popup.element.remove();

                // Destroy the scope
                popup.scope.$destroy();
            }, 300);
        };

        /*
         * Update the view
         */
        popupManager.updateView = function () {

            // Determine if there are any popups requesting
            // a mask in the stack
            var needsMask = false;
            angular.forEach(stack, function (popup) {

                needsMask = needsMask || popup.config.mask;
            });

            // Add mask if required
            if (needsMask && !mask) {

                addMask();
            }

            // Remove mask if required
            else if (!needsMask && mask) {

                removeMask();
            }

            // Apply z-index to stack
            var z = baseZ;
            angular.forEach(stack, function(popup, i) {

                // Put the mask under the top popup
                if (i == (stack.length-1) && mask) {

                    mask.css('z-index', z);
                    z++;
                }

                // Apply z-index to the popup
                popup.element.css('z-index', z);
                z++;
            });
        };

        /*
         * Remove the background mask
         */
        var removeMask = function () {

            // Start exit animation
            mask.addClass('exiting');

            $timeout(function () {

                mask.remove();
                mask = null;
            }, 360);
        };

        /*
         * Add the background mask
         */
        var addMask = function () {

            mask = angular.element('<div class="uiMask"></div>');
            angular.element($document[0].body).append(mask);
        };

        /*
         * Whenever the stack changes
         */
        var onStackModified = function (stack) {

            popupManager.updateView();
        };

        // Hook up handler to stack watch
        $rootScope.$watchCollection(function () {

            return stack;
        }, onStackModified);

        return popupManager;
    }]);
