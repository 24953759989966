(function () {
  'use strict';
  /**
   * initialize-foundation Directive
   *
   * This re-initializes Foundation on the element from which it is called
   *
   * This is needed when Foundation components are appended to the DOM after $transitions.onSuccess
   *
   * Usage: Add 'initialize-foundation' to an element on which Foundation needs to be re-initialized:
   * (Example: an orbit carousel inside of a reveal modal (which is appended to DOM when opened))
   *
   * <div class="orbit" data-orbit initialize-foundation>
   *   ...
   * </div>
   */

  angular
  .module('architizer.app')
  .directive('initializeFoundation', initializeFoundation);

  initializeFoundation.$inject = [
    '$timeout'
  ];

  function initializeFoundation ($timeout) {
    return {
      restrict: 'A',
      link: (scope, element) => $timeout(() => $(element).foundation()),
    };
  }
})();