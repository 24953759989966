'use strict';

angular
	.module('ui')
	.directive('uiPlaceholder', [function () {

		return {
			replace: true,
			restrict: 'E',
			templateUrl: '/views/uikit/ui-placeholder.html',
			scope: {
				type: '@',
				src: '='
			},
			link: function ($scope, element, attrs) {


			}
		};
	}]);