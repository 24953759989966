'use strict';

angular
  .module('architizer.app')
  .factory('GlobalView', [function () {

    var globalViewService = {
      title: 'Architizer',
      metaDefaults: {
        'name': [
          {
            name: 'keywords',
            content: 'architecture,architecture news,interiors,interior design,portfolio,spec,brands,marketplace,products'
          },
          {
            name: 'description',
            content: 'Architizer is the largest database for architecture and sourcing building products. Home of the A+Awards - the global awards program for today’s best architects.'
          }
        ],
        'property': []
      },
      meta: {
        'name': [],
        'property': []
      },
      notificationVisible: false,
      trayOpen: false,
      sidebarOpen: false,
      hideHeader: false,
      hideFooter: false,
      currentPrimaryItem: '',
      currentSecondaryItem: '',
      loading: false
    };

    var init = function () {
      globalViewService.meta['name'] = globalViewService.metaDefaults['name'];
      globalViewService.meta['property'] = globalViewService.metaDefaults['property'];
    };

    // kick things off
    init();

    return globalViewService;
  }]);
