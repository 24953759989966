'use strict';

angular
  .module('architizer.app')
  .filter('capitalize', function () {

    return function(input, all) {
      return (!!input) ? input.replace(/([^\W_]+[^\s-]*) */g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();}) : '';
    };
  })

  .filter('capitalizeFirst', function () {
    return function(input) {
      if (!input) {
        return '';
      } else if (Array.isArray(input)) {
        let capitalized = [];
        angular.forEach(input, (item) => capitalized.push(item.charAt(0).toUpperCase() + item.slice(1)));
        return capitalized;
      } else {
        return input.charAt(0).toUpperCase() + input.slice(1);
      }
    };
  })

  .filter('titleCase', function () {
    return function(input) {
      if (!input) {
        return '';
      } else {
        return input.replace(/[A-Za-z0-9]+[^\s-]*/g, toTitleCase);
      }
    };

    /* Helper function to create new substrings to replace matched words in the regexp
       See 'Specifying function as a parameter' section for more info:
       https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/replace
       Example:
         Input: "assembly and testing equipment"
         Words: "assembly" (startIdx: 0), "and" (startIdx: 9), "testing" (startIdx: 13),
         "equipment" (startIdx: 21)
         Output: "Assembly and Testing Equipment"
    */
    function toTitleCase (word, startIndex, fullTitle) {
      const nonTitleCaseWords = /^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|via)$/i;

      // If word is a nonTitleCaseWord, lower case it
      if (startIndex > 0 && (startIndex + word.length !== fullTitle.length) && word.search(nonTitleCaseWords) > -1) {
        return word.toLowerCase();
      // If word is not a nonTitleCaseWord, capitalize first letter only
      } else {
        return word[0].toUpperCase() + word.substr(1);
      }
    }
  });
