(function() {
  'use strict';

  angular
  .module('architizer.app')
  .controller('SourceArchitectProjectsDeleteController', SourceArchitectProjectsDeleteController);

  SourceArchitectProjectsDeleteController.$inject = [
    '$modalInstance',
    'project',
    'Project',
  ];

  function SourceArchitectProjectsDeleteController (
    $modalInstance,
    project,
    Project) {

    var vm = this;

    // Data
    vm.project = project;

    // Functions
    vm.onDeleteClick = onDeleteClick;
    vm.onCancelClick = onCancelClick;

    ////////////////////////////////////////////////////////////////////////////////
    // Functions
    ////////////////////////////////////////////////////////////////////////////////

    /**
     * On Delete button click
     */
    function onDeleteClick () {

      // Perform Delete with API
      Project.update({
        id: vm.project.id,
        self_status: 'closed',
      })
      .$promise
      .then( (deletedProject) => $modalInstance.close(deletedProject) )
      .catch( (error) => console.error(error) );
    }

    /**
     * On Cancel button click
     */
    function onCancelClick () {
      $modalInstance.dismiss();
    }
  }
})();