(function() {
  'use strict';

  angular
  .module('architizer.app')
  .controller('SourceArchitectProjectsEditController', SourceArchitectProjectsEditController);

  SourceArchitectProjectsEditController.$inject = [
    '$filter',
    '$http',
    '$modal',
    '$q',
    '$state',
    '$timeout',
    'Attribution',
    'AuthorizationService',
    'FileService',
    'FlashManager',
    'LocationService',
    'Project',
    'ProjectService',
    'mode',
    'project',
    'AuthenticationService',
    'User',
  ];

  function SourceArchitectProjectsEditController (
    $filter,
    $http,
    $modal,
    $q,
    $state,
    $timeout,
    Attribution,
    AuthorizationService,
    FileService,
    FlashManager,
    LocationService,
    Project,
    ProjectService,
    mode,
    project,
    AuthenticationService,
    User
  ) {

    var vm = this;

    // Data
    vm.form = {};
    vm.rawLocations = {};
    vm.models = {
      building_size: '',
      budget: '',
      completion_date: formatDateForForm(),
      construction_start_date: formatDateForForm(),
      description: '',
      files: [],
      firm: '',
      location: '',
      name: '',
      phase: '',
      types: '',
    };
    vm.project = project;
    vm.mode = mode;
    vm.elementConfig = elementConfig();
    vm.projectMembers = [];
    vm.firmMembers = [];
    vm.saving = false;

    // Functions
    vm.FileService = FileService;
    vm.onSaveClick = onSaveClick;
    vm.onDeleteProjectClick = onDeleteProjectClick;
    vm.onProjectMembersClick = onProjectMembersClick;

    // Watches

    // Initialize
    init();

    ////////////////////////////////////////////////////////////////////////////////
    // Functions
    ////////////////////////////////////////////////////////////////////////////////

    /**
     * Initial data for vm.elementConfig
     */
     function elementConfig () {
       return {
         budgetOptions: ProjectService.budgets,
         buildingSizeOptions: ProjectService.buildingSizes,
         firmOptions: getFirmOptions(),
         typeOptions: getTypeOptions(),
         monthOptions: getMonthOptions(),
         phaseOptions: ProjectService.phases,
         locationSuggestions: [],
         getLocationSuggestions: getLocationSuggestions,
         locationSuggestionsConfig: locationSuggestionsConfig(),
       };
    }

    /**
     * Initialization
     */

    function init () {
      switch(vm.mode) {
        case 'new':
          // Set models.firm to the first firm in the list
          vm.models.firm = vm.elementConfig.firmOptions[0].value;
          vm.projectMembers = [AuthenticationService.user];
          break;

        case 'edit':
          // Don't show firm selector
          vm.elementConfig.firmOptions = [_.findWhere(vm.elementConfig.firmOptions, {value: vm.models.firm})];

          // Build array of project type IDs
          var projectTypes = _.map($filter('findLeaves')(vm.project.types, 'id', 'children'), function (id) { return id.toString(); });
          // Populate Models
          vm.models.firm = vm.project.firm_ids[0];
          vm.models.name = vm.project.name;
          vm.models.files = vm.project.images.gallery;
          vm.models.construction_start_date = formatDateForForm(vm.project.construction_start_date);
          vm.models.completion_date = formatDateForForm(vm.project.completion_date);
          vm.models.description = vm.project.description;
          vm.models.budget = getBudget();
          vm.models.building_size = vm.project.building_size;
          vm.models.location = formatGeolocationForSelectize();
          vm.models.types = projectTypes[0];
          vm.models.phase = vm.project.constr_status;
          vm.projectMembers = !vm.project.users.length ? [AuthenticationService.user] : vm.project.users;
          break;
      }

      vm.firmMembers = User.query({ firm_id: vm.models.firm, force: 'images'});
    }

     /**
     * On Save button click
     */
     function getBudget () {
      let budgets = ProjectService.budgets;
      let budget_range_display = vm.project.budget_range_display;

      let budget = budgets.find(findBudget);

      return budget.value;

      // Helper function to check if the budget_range_display for a project matches any of the budget options
      function findBudget (budget) {
        return budget.label === budget_range_display;
      }
     }

    function validateForm () {
      var deferred = $q.defer();

      vm.form.$setSubmitted(true);

      // Check after a timeout so the form's validity will be properly updated
      $timeout(() => {
        // Invalid form
        if (!vm.form.$valid) {
          // Find the first element with an error
          let formError = vm.form.$error[Object.keys(vm.form.$error)[0]][0].$name;
          let errorElement = angular.element(document.getElementsByName(formError));

          // Find the <main> element on the page
          let mainElement = angular.element('#content');

          // Tell the main element to scroll to the first error element
          mainElement.scrollToElement(errorElement, 150, 250);
          deferred.reject();
        }

        // Valid form
        if (vm.form.$valid) {
          deferred.resolve();
        }
      });
      return deferred.promise;
    }

    /**
     * On Save button click
     */
    function onSaveClick () {
      validateForm()
      .then(() => {
        // Set `saving` flag to true
        vm.saving = true;

        // Create Project $resource to send
        var project = new Project({
          name: vm.models.name,
          type_ids: [vm.models.types],
          geolocations: makeGeolocation(),
          constr_status: vm.models.phase,
          media_data: vm.models.files,
          media_data_includes_existing: true, // This flag tells the API that media_data will include existing MediaItemAttributions for this object (required)
          use_context: 'S'
        });

        var optionalFields = ['budget', 'building_size', 'construction_start_date', 'completion_date', 'description'];

        // Append optional fields to Project $resource if nonempty
        optionalFields.forEach( (key) => {
          let value = vm.models[key];
          switch (key) {
            case 'construction_start_date':
              value = prepareDateForAPI(vm.models[key]);
              if (value) { project[key] = value.substring(0,10); }
              break;
            case 'completion_date':
              value = prepareDateForAPI(vm.models[key]);
              if (value) { project[key] = value; }
              break;
            default:
              if (value) { project[key] = value; }
          }
        });

        project.user_attributions = vm.projectMembers.map((user) => {
            return { user_id: user.id };
        });

        // Save to the API
        switch (vm.mode) {
          case 'edit':
            project.id = vm.project.id;
            project.$update(onPostProjectSuccess, onPostProjectError);
            break;

          case 'new':
            project.firm_attributions = [{ firm_id: vm.models.firm }];
            project.$save(onPostProjectSuccess, onPostProjectError);
            break;
        }

        /**
         * When the project is successfully created
         */
        function onPostProjectSuccess (project) {
          // Saved successfully. Set the `saving` flag to false.
          vm.saving = false;
          var verb = (vm.mode === 'new' ? 'created' : 'updated');

          // Present flash
          FlashManager.push({
            message: project.name + ' was ' + verb,
            type: 'success'
          });

          // Go to Searches view
          if (verb == 'created') {
            $state.go('source.app.arc.project.requests', { project: project.id, view: 'searches' });
          }
        }

        /**
         * When an error occurs while creating the project
         */
        function onPostProjectError (error) {
          // Save failed. Set the `saving` flag to false.
          vm.saving = false;
          console.error(error);
        }
      });
    }

    /**
     * On "Delete Project" button click in project edit modal
     */
    function onDeleteProjectClick () {

      // Create the Delete Project modal
      var deleteProjectModal = $modal.open({
        templateUrl: '/views/source/architect/projects.project-delete.modal.html',
        controller: 'SourceArchitectProjectsDeleteController',
        controllerAs: 'DeleteProjectCtrl',
        resolve: {
          project: () => vm.project,
        }
      });

      // Take the result of the modal and go forward accordingly
      deleteProjectModal
      .result
      .then( (deletedProject) => {

        /**
         * FIXME: Use deletedProject to find the project in the Projects list and splice it
         */

        // Reload the Projects list to make the project disappear from the list
        $state.go('source.app.arc.projects', {}, { reload: true });
      });
    }

    /**
     * Helper function to create Architizer Geolocations from Google location results
     */
    function makeGeolocation () {
      if ((vm.rawLocations && vm.rawLocations.existing) && (vm.models.location === 'existing')) {
        // If there is already a geolocation saved, and the user hasn't changed it, use the existing one
        return vm.rawLocations.existing;
      } else if (vm.rawLocations && vm.rawLocations[vm.models.location]) {
        // Find the selected location in the rawLocations and convert it to an Architizer Geolocation

        // Get this value's raw location from the saved values and convert it to Architizer Geolocation
        var rawLocation = vm.rawLocations[vm.models.location];
        var geolocation = LocationService.convertGoogleLocationToArchitizer(rawLocation);

        // Return the Architizer-formatted Geolocation
        return geolocation;
      } else {
        return null;
      }
    }

    /**
     * Helper function to convert vm.model date objects [construction_start_, completion_]
     * into JavaScript Date objects
     */
    function prepareDateForAPI(date) {
      if (date && date.month && date.year) {
        // We can safely assume we have both date.month and date.year by frontend validation
        var parsedDate = new Date(Date.UTC(date.year, date.month));
        return parsedDate.toISOString()
      }
    }
    /**
     * Helper function to prepare stored Datetime ISO strings for dropdown elements
     */
    function formatDateForForm(date) {
      if (date) {
        var parsedDate = new Date(date);
        return { 'month': parsedDate.getUTCMonth().toString(), 'year': parsedDate.getUTCFullYear().toString() }
      } else {
        return { 'month': '', 'year': '' }
      }
    }
    /**
     * Helper function to prepare stored Architizer Geolocation for Selectize element
     */
    function formatGeolocationForSelectize () {
      if (vm.project.geolocations) {

        // Get the first geolocation
        vm.rawLocations.existing = vm.project.geolocations[0];

        // Make a fake Selectize item with the value "existing" to denote this value was saved previously
        var existingSuggestion = {
          name: vm.rawLocations.existing.name,
          value: 'existing',
        };

        // Push the "existing" Selectize item into the array so it will be available as an option
        vm.elementConfig.locationSuggestions.push(existingSuggestion);

        // Return "existing" as a string to set the value of the Selectize to this
        return 'existing';

      } else {
        return null;
      }
    }

    /**
     * Location Suggestions: Selectize.js options for Location field
     */
    function locationSuggestionsConfig () {
      return {
        create: false,
        createOnBlur: false,
        selectOnTab: false,
        maxItems: 1,
        highlight: false,
        load: getLocationSuggestions,
        loadThrottle: 200,
        loadingClass: 'loading',
        labelField: 'name',
        valueField: 'value',
        searchField: ['name'],
        openOnFocus: false,
        closeAfterSelect: false,
        render: {
          option: locationOptionTemplate,
          item: locationOptionDisplayTemplate,
        },
      };
    }

    // Template for Brand Options
    function locationOptionTemplate (item) {
      return '<div class="row"><div class="columns"><span>' + item.name + '</span></div></div>';
    }

    function locationOptionDisplayTemplate (item) {
      return '<span>' + item.name + '</span>';
    }

    function getLocationSuggestions (query, callback) {
      // Google Maps API URL
      var url = 'https://maps.googleapis.com/maps/api/geocode/json?address=' + query + '&key=AIzaSyD1raEndw8Qu_7tblXtcC5eLGHLjgRj9EY';

      // Return if no query string
      if (!query.length) {
        vm.elementConfig.locationSuggestions = [];
        callback();
      }

      // Call the Google Maps API and set location suggestions
      $http({
        method: 'GET',
        url: url
      })
      .then(function getLocationSuggestionsSuccess (data) {
        var formattedResults = [];

        if (data.data.results && data.data.results.length) {
          angular.forEach(data.data.results, function (result) {

            /**
             * Store the raw result in vm.rawLocations so it can be processed into an
             * Architizer Geolocation using LocationService when we save the project
             */
            vm.rawLocations[result.place_id] = angular.copy(result);

            // Push into the results for Selectize
            formattedResults.push({
              name: result.formatted_address,
              value: result.place_id,
            });
          });
        }

        // Populate the suggestions
        vm.elementConfig.locationSuggestions = formattedResults;
        callback(data);
      })
      .catch(function getLocationSuggestionsError () {
        vm.elementConfig.locationSuggestions = [];
        callback();
      });
    }

    /**
     * Get month options for date fields
     */
    function getMonthOptions () {
      return ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    }

    /**
     * Get firm options for the firm dropdown
     *
     * (These only show if the user has multiple firms)
     */
    function getFirmOptions () {
      var firmOptions = [];

      // Get the firms this user can create projects for
      var firms = AuthorizationService.getFirmsForPermission(
        AuthorizationService.FIRM_PERMISSIONS.CAN_CHANGE_FIRM_PRODUCT_REQUESTS
      );

      angular.forEach(firms, function (firm) {
        var option = { label: firm.name, value: firm.id };
        firmOptions.push(option);
      });

      return firmOptions;
    }

    /**
     * Get type options for the Project Type ui-select
     */
    function getTypeOptions () {

      // Fetch the project categories
      Project.categories()
      .$promise
      .then(function (categories) {

        // Set up categories for ui-select
        var options = [];

        angular.forEach(categories, function (category) {

          // The first level items are group headers
          var option = { label: category.text };

          // The second level items are selectable options
          var suboptions = [];
          angular.forEach(category.children, function (subcategory) {

            suboptions.push({
              label: subcategory.text,
              value: subcategory.id.toString()
            });
          });

          option.options = suboptions;
          options.push(option);
        });

        /**
         * FIXME: Don't set the vm.elementConfig.typeOptions from inside the function
         *
         * Instead, return an object that becomes the value of vm.elementConfig.typeOptions
         * through assignment:
         *
         * See getFirmOptions and the vm.elementConfig.firmOptions assignment for example
         */

        // Set Type Options inside the function (bad)
        vm.elementConfig.typeOptions = options;
      });
    }

    /**
     * On click of "+" button for Project Members (open modal)
     */
    function onProjectMembersClick () {
      var projectMembersModal = $modal.open({
        templateUrl: '/views/source/architect/projects.project-members.html',
        controller: 'SourceArchitectProjectMembersModalController',
        controllerAs: 'ProjectMembersCtrl',
        resolve: {
          firmMembers: () => vm.firmMembers,
          projectMembers: () => vm.projectMembers,
        }
      });

      // Get the result from the modal and set vm.projectMembers
      projectMembersModal
      .result
      .then((projectMembers) => vm.projectMembers = projectMembers);
    }
  }
})();
