'use strict';

angular
  .module('architizer.app')
  .config(['$stateProvider', function ($stateProvider) {

    /**
     * Define placeholder template snippets for empty ui-views in the uiSplitPane layout
     */
    var emptyPlaceholders = {
      //- If user has no conversations, display the ARC Messages empty state
      //- If user has conversations but no conversation is selected, ask user to select a conversation to view messages
      mainConversationsAdk: `
      <div class="adk"
           ng-if="!ConversationsCtrl.conversations.length">
        <adk-empty-state icon-url="/images/source/messages-empty-state-icon.svg"
                         title="No Messages"
                         description="We\'ll notify you when a manufacturer or expert sends you a message.">
        </adk-empty-state>
      </div>
      <div class="row align-middle"
           style="height: 100%"
           ng-if="ConversationsCtrl.conversations.length">
        <div class="columns">
          <p class="text-center quicksilver">Please select a conversation to view messages</p>
        </div>
      </div>
      `,
    };

    /**
     * Define States
     */
    $stateProvider

    ////////////////////////////////////////////////////////////////////////////////
    // App: Architect
    ////////////////////////////////////////////////////////////////////////////////

     // App: Architect Root
    .state({
      name: 'source.app.arc',
      abstract: true,
      data: {
        isFullscreen: true,
        hideFooter: true,
        showIntercom: true,
        useAdk: true,
        hasActionBar: true,
      },
    })

    // App: Architect Projects List
    .state({
      name: 'source.app.arc.projects',
      params: {
        q: '*',
        rows: 100,
        /* Without these forced properties, these parameters travel from state to state */
        project: {
          inherit: false,
          value: null,
          squash: true,
        },
      },
      resolve: {
        projects: ResolveProjectsMini,
        actionBar: ['$state', 'user', ($state, user) => {
          const firms = user.firms.map(({ name, slug }) => ({name, slug}));

          return {
            breadcrumbs: [],
            viewIcon: 'apps',
            viewTitle: viewTitle(),
            dropdownLinks: dropdownLinks(),
            rightLinks: [],
          };

          function viewTitle () {
            let title = '';
            if (firms.length === 1) {
              title = firms[0].name;
            } else {
              title = 'My Projects';
            };
            return title;
          }

          function dropdownLinks () {
            let links = [];
            const viewLink = { label: 'View Firm' };
            const editLink = { label: 'Edit Firm' };

            // Only show this menu to users with one firm
            if (firms.length === 1) {

              // Set link destinations
              viewLink.ngClick = () => window.location.href = `/firms/${firms[0].slug}`;
              editLink.ngClick = () => $state.go('settingsFirm', { slug: firms[0].slug });

              links.push(viewLink);
              links.push(editLink);
            }

            return links;
          }
        }]
      },
      views: {
        'root@': {
          templateUrl: '/views/source/architect/projects.html',
          controller: 'SourceArchitectProjectsController',
          controllerAs: 'ProjectsCtrl',
        },
      },
      data: {
        showDelighted: true,
      }
    })

    // App: Architect New Project
    .state({
      name: 'source.app.arc.projects.new',
      resolve: {
        mode: () => 'new',
        project: () => null,
        actionBar: () => ({
          viewTitle: 'New Project',
          breadcrumbs: [
            { icon: 'apps', state: 'source.app.arc.projects' },
          ],
          rightLinks: []
        }),
      },
      params: {
        view: 'newProject',
      },
      views: {
        'root@': {
          templateUrl: '/views/source/architect/project.edit.html',
          controller: 'SourceArchitectProjectsEditController',
          controllerAs: 'EditProjectCtrl',
        },
      },
    })

    // App: Architect Project
    .state({
      name: 'source.app.arc.project',
      abstract: true,
      resolve: {
        project: ResolveProject,
        actionBar: ['$state', 'project', ($state, project) => {
          let rightLinks = [];

          if (project.use_context === 'S') {
            rightLinks = [
              {
                label: 'Searches',
                state: 'source.app.arc.project.requests',
              },
              {
                label: 'Products',
                state: 'source.app.arc.project.responses',
              },
              {
                label: 'Messages',
                state: 'source.app.arc.project.conversations',
              },
            ];
          }

          return {
            breadcrumbs: [
              { icon: 'apps', state: 'source.app.arc.projects' }
            ],
            viewIcon: 'business',
            viewTitle: project.name,
            dropdownLinks: [
              { label: 'Edit Project', ngClick: editProject }
            ],
            rightLinks: rightLinks,
          };

          function editProject () {
            return $state.go('source.app.arc.project.edit');
          }
        }]
      },
      params: {
        /* Without these forced properties, these parameters travel from state to state */
        conversation: {
          inherit: false,
          value: null,
          squash: true,
        },
        request: {
          inherit: false,
          value: null,
          squash: true,
        },
      },
    })

    // App: Architect Edit/Update Project
    .state({
      name: 'source.app.arc.project.edit',
      params: {
        view: 'editProject'
      },
      resolve: {
        mode: () => 'edit',
        actionBar: ['$state', 'project', ($state, project) => {
          return {
            breadcrumbs: [
              { icon: 'apps', state: 'source.app.arc.projects' },
              { icon: 'business', state: 'source.app.arc.project.requests' }
            ],
            viewTitle: 'Edit Project',
          };
        }],
      },
      views: {
        'root@': {
          templateUrl: '/views/source/architect/project.edit.html',
          controller: 'SourceArchitectProjectsEditController',
          controllerAs: 'EditProjectCtrl',
        },
      },
    })

    // App: Architect Project Request Searches View
    .state({
      name: 'source.app.arc.project.requests',
      resolve: {
        productRequests: ResolveProductRequests,
      },
      params: {
        view: 'searches',
      },
      views: {
        'root@': {
          templateUrl: '/views/source/architect/project.searches.html',
          controller: 'SourceArchitectProjectSearchesController',
          controllerAs: 'SearchesCtrl',
        },
      },
    })

    // App: Architect Project Response Grid
    .state({
      name: 'source.app.arc.project.responses',
      resolve: {
        projectData: ResolveProjectData,
      },
      params: {
        view: 'responses'
      },
      views: {
        'root@': {
          templateUrl: '/views/source/architect/project.products.html',
          controller: 'SourceArchitectProjectProductsController',
          controllerAs: 'ProjectProductsCtrl',
        },
      },
    })

    // App: Architect Project New Request
    .state({
      name: 'source.app.arc.project.requests.new',
      params: {
        view: 'newSearch',
      },
      onEnter: OpenNewSearchModal
    })

    // App: Architect Project Request
    .state({
      name: 'source.app.arc.project.request',
      abstract: true,
      resolve: {
        productRequest: ResolveProductRequest,
        productResponses: ResolveProductResponses,
        productRecommendations: ResolveProductRecommendations,
        actionBar: ['$state', 'productRequest', ($state, productRequest) => {
          return {
            breadcrumbs: [
              { icon: 'apps', state: 'source.app.arc.projects' },
              { icon: 'business', state: 'source.app.arc.project.requests' }
            ],
            viewTitle: productRequest.name,
            dropdownLinks: [
              { label: 'Edit Search', ngClick: editSearch }
            ],
          };

          function editSearch () {
            return $state.go('source.app.arc.project.request.edit', { request: productRequest.id });
          }
        }]
      },
    })

    // App: Architect Project Request Products
    .state({
      name: 'source.app.arc.project.request.products',
      views: {
        'root@': {
          templateUrl: '/views/source/architect/project.request.products.html',
          controller: 'SourceArchitectProjectRequestProductsController',
          controllerAs: 'ProductsCtrl',
        }
      },
      resolve: {
        project: ResolveProject,
      },
      params: {
        view: 'products',
      },
    })

    // App: Architect Project Request Products - Individual Response Modal
    .state({
      name: 'source.app.arc.project.request.product',
      resolve: {
        productResponse: ResolveProductResponse,
      },
      params: {
        response: {
          inherit: false,
          value: null,
          squash: true,
        },
      },
      views: {
        'root@': {
          templateUrl: '/views/source/architect/project.request.products.html',
          controller: 'SourceArchitectProjectRequestProductsController',
          controllerAs: 'ProductsCtrl',
        }
      },
      onEnter: OpenProductDetailModal,
    })

    // App: Architect Project Edit/Update Request
    .state({
      name: 'source.app.arc.project.request.edit',
      resolve: {
        mode: ResolveProductRequestMode,
      },
      params: {
        view: 'editSearch',
      },
      views: {
        'root@': {
          templateUrl: '/views/source/architect/project.request.products.html',
          controller: 'SourceArchitectProjectRequestProductsController',
          controllerAs: 'ProductsCtrl',
        }
      },
      onEnter: OpenEditSearchModal,
    })

    // App: Architect Project Conversations List
    .state({
      name: 'source.app.arc.project.conversations',
      resolve: {
        conversations: ResolveConversations,
        conversation: () => null,
        selectedConversation: ResolveSelectedConversation,
        unreadConversationsCount: ResolveUnreadConversationsCount,
        $transition$: ['$transition$', ($transition$) => $transition$ ],
      },
      params: {
        view: 'messages',
      },
      views: {
        'root@': {
          templateUrl: '/views/source/architect/project.conversations.html',
          controller: 'SourceArchitectConversationsController',
          controllerAs: 'ConversationsCtrl',
        },
        'content@source.app.arc.project.conversations': {
          template: emptyPlaceholders.mainConversationsAdk,
        },
        'sidebar@source.app.arc.project.conversations': {
          templateUrl: '/views/source/architect/project.sidebar.conversations-list.html',
          controller: 'SourceArchitectProjectSidebarConversationsListController',
          controllerAs: 'ListCtrl',
        },
      },
      data: {
        showUiLoaderIn: 'root',
      },
    })

    // App: Architect Project Conversation Single
    .state({
      name: 'source.app.arc.project.conversations.conversation',
      resolve: {
        conversation: ResolveConversation,
        messages: ResolveMessages,
        productRequest: ResolveProductRequestForConversation,
      },
      views: {
        'content@source.app.arc.project.conversations': {
          templateUrl: '/views/source/shared/conversation.content.html',
          controller: 'SourceArchitectProjectConversationContentController',
          controllerAs: 'ConversationCtrl',
        },
        'actionBar@source.app.arc.project.conversations': {
          templateUrl: '/views/source/architect/project.conversation.action-bar.html',
          controller: 'SourceArchitectProjectConversationActionBarController',
          controllerAs: 'ActionBarCtrl',
        },
        'sendMessage@source.app.arc.project.conversations': {
          templateUrl: '/views/source/architect/project.conversation.send-message.html',
          controller: 'SourceArchitectProjectConversationSendMessageController',
          controllerAs: 'SendMessageCtrl',
        },
      },
      data: {
        showUiLoaderIn: 'content',
      },
    })

    ;

    ////////////////////////////////////////////////////////////////////////////////
    // onEnter Functions
    ////////////////////////////////////////////////////////////////////////////////

    // Open "New Search" modal on "New Search" state
    OpenNewSearchModal.$inject = [
      '$modal',
      '$state',
      '$transition$',
      'productRequests',
      'project',
      'user',
    ];
    function OpenNewSearchModal (
      $modal,
      $state,
      $transition$,
      productRequests,
      project,
      user
    ) {
      var newSearchModal = $modal.open({
        templateUrl: '/views/source/architect/project.search.create.modal.html',
        controller: 'ProjectSearchCreateModalController',
        controllerAs: 'CreateModalCtrl',
        resolve: {
          user: () => user,
          project: () => project,
          productRequests: () => productRequests,
        },
      });

      newSearchModal
      .result
      .then(modalSuccess, modalDismissed);

      function modalSuccess (modalData) {
        if (modalData && modalData.goToNewSearch) {
          // "Save and Continue" was clicked: Send to the Edit page for this Search
          $state.go('source.app.arc.project.request.edit', { project: project.id, request: modalData.newSearch.id });
        } else {
          // The modal stays open, and the controller handles the logic
          return;
        }
      }

      function modalDismissed () {
        $state.go('^');
      }
    }

    // Open "Product Details" modal on "Request Products" state
    OpenProductDetailModal.$inject = [
      '$modal',
      '$state',
      'productRequest',
      'productResponse',
      'project',
      'user'
    ];

    function OpenProductDetailModal (
      $modal,
      $state,
      productRequest,
      productResponse,
      project,
      user
    ) {
      let modal = $modal.open({
        templateUrl: '/views/source/shared/product-response.content.modal.html',
        controller: 'SourceProductResponseModalController',
        controllerAs: 'ProductResponseModalCtrl',
        resolve: {
          mySide: () => 'A',
          productRequest: () => productRequest,
          productResponse: () => productResponse,
          project: () => project,
          user: () => user,
        },
        closeOnClick: true,
        keyboard: true,
      });

      modal
      .result
      .then(() => {}, modalDismissed);

      function modalDismissed (modalData) {
        $state.go('^.products', { request: productRequest.id, view: 'products' });
      }
    }

    // Open "Edit Search" modal on "Edit Search" state
    OpenEditSearchModal.$inject = [
      '$modal',
      '$state',
      'mode',
      'productRequest',
      'project',
      'user',
    ];

    function OpenEditSearchModal (
      $modal,
      $state,
      mode,
      productRequest,
      project,
      user
    ) {

      let editSearchModal = $modal.open({
        templateUrl: '/views/source/architect/project.search.edit.modal.html',
        controller: 'ProjectSearchEditModalController',
        controllerAs: 'EditModalCtrl',
        resolve: {
          mode: () => mode,
          productRequest: () => productRequest,
          project: () => project,
          user: () => user,
        },
        closeOnClick: false,
        size: 'small',
        keyboard: false,
      });

      editSearchModal
      .result
      .then(modalSuccess, modalDismissed);

      function modalSuccess (modalData) {

        if (modalData.isDraft || modalData.isDeleted) {
          // Go to Searches view
          $state.go('source.app.arc.project.requests', { project: project.id, view: 'searches' }, { inherit: false, reload: true });
        } else {
          // Go to Products view
          $state.go('source.app.arc.project.request.products', { project: project.id, request: modalData.search.id, view: 'products' }, { inherit: false, reload: true });
        }
      }

      function modalDismissed (modalData) {
        if (modalData.isDraft) {
          $state.go('source.app.arc.project.requests', { project: project.id, view: 'searches' }, { inherit: false, reload: true });
        } else {
          $state.go('source.app.arc.project.request.products', { request: productRequest.id, view: 'products' });
        }
      }
    }

    ////////////////////////////////////////////////////////////////////////////////
    // Resolve Functions
    ////////////////////////////////////////////////////////////////////////////////

    // Product Request
    ResolveProductRequest.$inject = ['ProductRequest', '$transition$'];
    function ResolveProductRequest (ProductRequest, $transition$) {
      return ProductRequest.get({
        id: $transition$.params().request,
        force: 'project,firm,creator,product_responses,brand,images,types,structured_data'
      }).$promise;
    }

    // Product Request for Conversation
    ResolveProductRequestForConversation.$inject = ['ProductRequest', '$transition$'];
    function ResolveProductRequestForConversation (ProductRequest, $transition$) {
      return ProductRequest.query({
        conversation_id: $transition$.params().conversation,
        force: 'images',
        include_closed: 'true',
      })
      .$promise
      .then(function (productRequests) {
        /**
         * Return just the first item in the array
         *
         * This resource normally returns a list, not a single item,
         * so we are overriding the regular behavior of $resource query() calls
         * with this logic.
         */
        if (productRequests.length) {
          return productRequests[0];
        } else {
          return [];
        }
      });
    }

    // Project
    ResolveProject.$inject = ['Project', '$transition$'];
    function ResolveProject (Project, $transition$) {
      return Project.get({
        id: $transition$.params().project,
        force: 'firms,types,description,geolocations,images,users'}
      ).$promise;
    }

    // Project Product Responses (All Products for a Project and a hash of categories)
    ResolveProjectData.$inject = ['ProductResponse', 'ProductResponseDataTransformer', 'Project', 'UtilsService', '$transition$'];
    function ResolveProjectData (ProductResponse, ProductResponseDataTransformer, Project, UtilsService, $transition$) {
      return Project.products({
        id: $transition$.params().project
      }).$promise
        .then(({ response: { docs }, facet_counts: { facet_fields: { tier1_tags_ss } } }) => {
          return {
            products: docs
              .map(ProductResponseDataTransformer)
              .map(responseObject => new ProductResponse(responseObject)),
            categories: UtilsService.unwrapFacets(tier1_tags_ss, () => true),
          };
        });
    }

    // Projects Mini
    ResolveProjectsMini.$inject = ['Project', '$transition$'];
    function ResolveProjectsMini (Project, $transition$) {
      return Project.fromSearchEndpoint().$promise;
    }

    // Product Requests
    ResolveProductRequests.$inject = ['ProductRequest', '$transition$'];
    function ResolveProductRequests (ProductRequest, $transition$) {
      return ProductRequest.mine({
        project_id: $transition$.params().project,
        force: 'product_responses,shortlisted_product_response_ids,new_product_response_ids,types,geolocations,images,structured_data',
        include_closed: true,
        per_page: 100 // TODO: implement "Show More" button on UI for Project view and remove this parameter from the call
      }).$promise;
    }

    // Product Responses
    ResolveProductResponses.$inject = ['ProductResponse', '$transition$'];
    function ResolveProductResponses (ProductResponse, $transition$) {
      return ProductResponse.forRequest({
        id: $transition$.params().request,
        force: 'brand,images,creator,geolocations'
      }).$promise
        .then((productResponses) => {
        return productResponses.map((productResponse) => {
          return Object.assign(productResponse, { saving: false }); // Flag used for UI state
        });
      }).catch(error => {
        console.error('Failed to fetch Product Responses ', error);
      });
    }

    // Recommended Product Responses
    ResolveProductRecommendations.$inject = ['LudwigService', 'productRequest'];
    function ResolveProductRecommendations (LudwigService, productRequest) {
      // Don't resolve Product Recommendations if the search is a draft
      const isDraftSearch = productRequest.workflow_status === 'R';
      if (isDraftSearch) { return []; }
      // Only show the first 40
      const number = 40;
      return LudwigService.getProductRecommendations(productRequest.id, number)
      .catch(checkIfStillProcessing);

      function checkIfStillProcessing (error) {
        /**
         * If Product Recommendations is still processing, the API returns a 424
         * so we catch this error here and return an object {processing: true} to the controller.
         */
        if (error.metadata.status === 424) {
          return { processing: true };
        }
      }
    }

    // Find an individual product response from injected [...productResponses, ...productRecommendations]
    ResolveProductResponse.$inject = ['productResponses', 'productRecommendations', 'productRequest', '$state', '$transition$'];
    function ResolveProductResponse (productResponses, productRecommendations, productRequest, $state, $transition$) {
      const responseId = $transition$.params().response;
      const allResponses = [...productResponses, ...productRecommendations];
      let response = allResponses.find(rec => rec.id === parseInt(responseId));
      if (!response) {
        // If no response is found, return to the request products view
        $state.go('source.app.arc.project.request.products', { project: productRequest.project_id, request: productRequest.id, view: 'products' });
        return;
      }
      return response;
    }

    // Product Request Mode
    ResolveProductRequestMode.$inject = ['$filter', 'productRequest'];
    function ResolveProductRequestMode ($filter, productRequest) {
      if (productRequest.workflow_status === 'R') {
        return 'draft';
      } else {
        return 'published';
      }
    }

    // Conversations
    ResolveConversations.$inject = ['Conversation', '$transition$'];
    function ResolveConversations (Conversation, $transition$) {
      return Conversation.query({
        project_id: $transition$.params().project,
        force:'images',
      }).$promise;
    }

    // Conversation
    ResolveConversation.$inject = ['Conversation', '$transition$'];
    function ResolveConversation (Conversation, $transition$) {
      return Conversation.get({
        id: $transition$.params().conversation,
        force: 'images',
      }).$promise;
    }

    // Selected Conversation
    ResolveSelectedConversation.$inject = ['$transition$'];
    function ResolveSelectedConversation ($transition$) {
      if ($transition$.params().conversation) {
        return {id: parseInt($transition$.params().conversation)};
      } else {
        return {};
      }
    }

    // Messages
    ResolveMessages.$inject = ['Message', '$transition$'];
    function ResolveMessages (Message, $transition$) {
      return Message.query({
        conversationId: $transition$.params().conversation,
        force: 'images',
        per_page: 20,
      }).$promise;
    }

    // Unread Conversations Count (used in badges)
    ResolveUnreadConversationsCount.$inject = ['Conversation', '$q', '$transition$'];
    function ResolveUnreadConversationsCount (Conversation, $q, $transition$) {
      var deferred = $q.defer();

      Conversation.query({
        project_id: $transition$.params().project,
        unread_only: true,
      }, function (conversations) {
        deferred.resolve(conversations.$pagination.count);
      });

      return deferred.promise;
    }

  }]);
