(function() {
  'use strict';

  angular
    .module('architizer.app')
    .service('ConversationMessagesService', ConversationMessagesService);

  ConversationMessagesService.$inject = [
    'Message',
    '$q',
    '$window',
  ];
  function ConversationMessagesService (Message, $q, $window) {

    var service = this;

    // Configuration
    service.pageSize = 20;
    service.MESSAGE_CARD_TYPES_MAP = {
      'product_response_active': 'action',
      'product_request_updated': 'medium',
      'product_response_shortlisted': 'medium',
      'product_response_dismissed': 'medium',
      'product_response_samples_requested': 'medium',
      'joined_conversation': 'small',

      // FOR DEVELOPMENT:
      'small': 'small',
      'medium': 'medium',
      'large': 'large',
      'action': 'action',
    };

    // Functions
    service.makeMessageGroups = makeMessageGroups;
    service.mapMessageCardTypes = mapMessageCardTypes;
    service.getNextPage = getNextPage;
    service.getPage = getPage;
    service.autoLink = autoLink;
    service.breakNewLines = breakNewLines;

    ////////////////////////////////////////////////////////////////////////////////
    // Functions
    ////////////////////////////////////////////////////////////////////////////////

    /**
     * Process messages into messageGroups
     *
     * Group the messages into lists of messages sent by the same author
     * within a set period of time
     *
     * @param messages The vm.messages object of the conversation content controller
     *
     * Returns a promise for chaining this result into other functions
     */
    function makeMessageGroups (messages) {

      var deferred = $q.defer();
      var messageGroups = [];

      // Map message types
      messages = mapMessageCardTypes(messages);

      // Process messages
      angular.forEach(messages, function (message) {
        var lastGroup = messageGroups[messageGroups.length-1];

        // If no groups exist yet
        if (!lastGroup) {
          lastGroup = createGroup(message);
        } else {

          // Check if the previous group is for the same day as this message
          var lastDate = moment(lastGroup.date);
          var thisDate = moment(message.created);

          if (!lastDate.isSame(thisDate, 'day')) {
            lastGroup = createGroup(message);
          } else if (lastGroup.conversation_user_id !== message.conversation_user_id) {
            lastGroup = createGroup (message);
          }
        }

        lastGroup.messages.push(message);
      });

      // Create a group
      function createGroup (message) {
        var group = {
          date: message.created,
          side: message.side,
          conversation_user_id: message.conversation_user_id,
          creator: message.creator,
          messages: [],
        };

        messageGroups.push(group);

        return group;
      }

      // Resolve and return promise
      deferred.resolve(messageGroups);
      return deferred.promise;
    }

    /**
     * Map message types
     *
     * Maps message types to their corresponding "card type" for the view
     *
     * Uses the service.MESSAGE_CARD_TYPES_MAP object above
     */
    function mapMessageCardTypes (messages) {
      angular.forEach(messages, function(message) {
        if (service.MESSAGE_CARD_TYPES_MAP.hasOwnProperty(message.message_type)) {
          message.messageCardType = service.MESSAGE_CARD_TYPES_MAP[message.message_type];
        } else {
          message.messageCardType = null;
        }
      });

      return messages;
    }

    /**
     * Get next page
     *
     * Get the next page of messages
     *
     * @param messages The vm.messages object of the conversation content controller
     */
    function getNextPage (messages) {

      var deferred = $q.defer();

      // Get the timestamp of the oldest message we have
      var timestamp = _.last(messages).created;
      var conversationId = _.last(messages).conversation_id;

      getPage(timestamp, conversationId)
      .then(function (messages) {
        deferred.resolve(messages);
      });

      return deferred.promise;
    }

    /**
     * Get page
     *
     * Get a page of messages from a given timestamp
     *
     * @param timestamp The latest date from which to get results
     */
    function getPage (timestamp, conversationId) {

      var deferred = $q.defer();

      Message.query({
        conversationId: conversationId,
        force: 'images',
        before: timestamp,
        per_page: service.pageSize
      }, function(messages) {
        deferred.resolve(messages);
      });

      return deferred.promise;
    }

    /**
     * Auto link
     *
     * Make links into clickable <a href=""></a> tags using Autolinker
     *
     * Used by the formatted-message directive
     *
     * Returns a string as the message
     */
    function autoLink (message) {
      if (!$window.Autolinker) { return message; }

      return $window.Autolinker.link(message, {
        newWindow: true,
      });
    }

    /**
     * Break new lines
     *
     * Turns \r and \n characters into <br> tags
     *
     * Used by the formatted-message directive
     *
     * Returns a string as the message
     */
    function breakNewLines (message) {
      return message.replace(/(\r|\n)/g, '<br>');
    }

    return service;
  }
})();
