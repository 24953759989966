'use strict';

/**
 * Adds a 'scrollfix' class to the element when the page scrolls past its position.
 *
 * @param [offset] {int} optional Y-offset to override the detected offset.
 *   Takes 300 (absolute) or -300 or +300 (relative to detected)
 */
angular
  .module('architizer.app')
  .directive('scrollfix', ['$window', function ($window) {

    function getWindowScrollTop() {

      if (angular.isDefined($window.pageYOffset)) {

        return $window.pageYOffset;
      } else {

        var iebody = (document.compatMode && document.compatMode !== 'BackCompat') ? document.documentElement : document.body;
        return iebody.scrollTop;
      }
    };

    return {
      require: '^?scrollfixTarget',
      link: function (scope, elm, attrs, scrollfixTarget) {

        var top = elm[0].offsetTop,
          initialOffset = elm.offset().top,
          $target = scrollfixTarget && scrollfixTarget.$element || angular.element($window);

        if (!attrs.scrollfix) {

          attrs.scrollfix = top;
        } else if (typeof (attrs.scrollfix) === 'string') {

          // charAt is generally faster than indexOf: http://jsperf.com/indexof-vs-charat
          if (attrs.scrollfix.charAt(0) === '-') {

            attrs.scrollfix = top - parseFloat(attrs.scrollfix.substr(1));
          } else if (attrs.scrollfix.charAt(0) === '+') {

            attrs.scrollfix = top + parseFloat(attrs.scrollfix.substr(1));
          }
        }

        var offset = 0,
          ticking = false;

        function onScroll() {

          // if pageYOffset is defined use it, otherwise use other crap for IE
          offset = scrollfixTarget ? $target[0].scrollTop : getWindowScrollTop();
          requestTick();
        }

        function requestTick() {

          if (!ticking) {

            requestAnimationFrame(update);
          }

          ticking = true;
        }

        function update() {

          // reset the tick so we can
          // capture the next onScroll
          ticking = false;

          // dock to top
          if (attrs.scrollfix == 'top') {

            if (!elm.hasClass('scrollfix') && offset >= initialOffset) {

              elm.addClass('scrollfix');
            }

            else if (elm.hasClass('scrollfix') && offset < initialOffset) {

              elm.removeClass('scrollfix');
            }
          }

          // dock to provided offset
          else if (!elm.hasClass('scrollfix') && offset >= attrs.scrollfix) {

            elm.addClass('scrollfix');
          }

          else if (elm.hasClass('scrollfix') && offset < attrs.scrollfix) {

            elm.removeClass('scrollfix');
          }
        }

        $target.on('scroll', onScroll);

        // Unbind scroll event handler when directive is removed
        scope.$on('$destroy', function() {

          $target.off('scroll', onScroll);
        });

        onScroll();
      }
    };
  }])

  .directive('scrollfixTarget', [function () {

    return {
      controller: ['$element', function($element) {

        this.$element = $element;
      }]
    };
  }]);
