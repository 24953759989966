(function () {
  'use strict';

  angular
  .module('architizer.app')
  .factory('ProductResponse', ProductResponseResource);

  ProductResponseResource.$inject = [
    '$http',
    '$resource',
    '$rootScope',
    'AuthenticationService',
    'Config'
  ];

  function ProductResponseResource (
    $http,
    $resource,
    $rootScope,
    AuthenticationService,
    Config
  ) {

    let $scope = $rootScope.$new();
    let ProductResponse = resource();

    // Extend ProductResponse with Shortlist/Dismiss/Clear Actions
    ProductResponse.prototype.$shortlist = $shortlist;
    ProductResponse.prototype.$dismiss = $dismiss;
    ProductResponse.prototype.$clear = $clear;

    return ProductResponse;

    ////////////////////////////////////////////////////////////////////////////////
    // Functions
    ////////////////////////////////////////////////////////////////////////////////

    // Product Response $resource definition
    function resource () {
      return $resource(Config.apiUrlBase + 'product_responses/:id', {
        id: '@id',
        force: 'brand,creator',
      }, {
        createDraft: {
          method: 'POST',
          transformRequest: setDraftStatus,
        },
        updateDraft: {
          method: 'PATCH',
          transformRequest: setDraftStatus,
        },
        publishDraft: {
          method: 'PATCH',
          transformRequest: setPublishedStatus,
        },
        createPublished: {
          method: 'POST',
          transformRequest: setPublishedStatus,
        },
        updatePublished: {
          method: 'PATCH',
          transformRequest: setPublishedStatus,
        },
        createLoggedProduct: {
          url: Config.apiUrlBase + 'offerings',
          method: 'POST',
          transformRequest: setPublishedStatus,
        },
        updateLoggedProduct: {
          method: 'PATCH',
          transformRequest: setPublishedStatus,
        },
        get: {
          method: 'GET',
        },
        forRequest: {
          url: Config.apiUrlBase + 'product_requests/:id/product_responses',
          isArray: true
        },
        conversation: {
          url: Config.apiUrlBase + 'product_responses/:id/conversations',
        },
      });
    }

    ////////////////////////////////////////////////////////////////////////////////
    // interceptor Functions
    ////////////////////////////////////////////////////////////////////////////////

    // Helper to make properties for Segment event
    function makeProps (thisProductResponse, propsToExtend={}) {
      let props = Object.assign(
        {},
        propsToExtend,
        {
          brand_id: thisProductResponse.brand.id,
          brand_name: thisProductResponse.brand.name,
          creator_email: thisProductResponse.creator.email,
          creator_first_name: thisProductResponse.creator.first_name,
          creator_id: thisProductResponse.creator.id,
          creator_last_name: thisProductResponse.creator.last_name,
          id: thisProductResponse.id,
          name: thisProductResponse.name,
          product_request_id: thisProductResponse.product_request_id,
          recommender_id: thisProductResponse.recommender_id,
        }
      );

      return props;
    }

    ////////////////////////////////////////////////////////////////////////////////
    // transformRequest Functions
    ////////////////////////////////////////////////////////////////////////////////

    // transformRequest function to set draft status on Product Response
    function setDraftStatus (data) {
      data.workflow_status = 'R'; // "Draft" workflow_status
      return angular.toJson(data);
    }

    // transformRequest function to set published status on Product Response
    function setPublishedStatus (data) {
      data.workflow_status = 'O'; // "Published" workflow_status
      return angular.toJson(data);
    }

    ////////////////////////////////////////////////////////////////////////////////
    // Shortlist/Dismiss/Clear Actions
    ////////////////////////////////////////////////////////////////////////////////

    function $shortlist () {
      let thisProductResponse = this;
      return $http.post(markingUrl(thisProductResponse), { status: 'F' })
    }

    function $dismiss (reasons) {
      let thisProductResponse = this;
      return $http.post(markingUrl(thisProductResponse), { status: 'D', reasons: reasons })
    }

    function $clear () {
      let thisProductResponse = this;
      return $http.post(markingUrl(thisProductResponse), { status: 'N' });
    }

    // Helper to get the API URL for setting marking on a Product Response instance
    function markingUrl (instance) {
      return Config.apiUrlBase + 'product_responses/' + instance.id + '/marking';
    }
  }
})();
