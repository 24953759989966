(function() {
  'use strict';

  angular
  .module('architizer.app')
  .directive('formattedMessage', formattedMessage);

  formattedMessage.$inject = [
    'ConversationMessagesService',
    '$sanitize',
  ];

  /**
   * Format conversation message
   *
   * Uses ConversationMessagesService functions to auto-link and break new lines in messages
   */
  function formattedMessage (ConversationMessagesService, $sanitize) {
    return {
      restrict: 'A',
      scope: {
        'formattedMessage' : '='
      },
      link: function($scope, element) {
        var unwatch = $scope.$watch('formattedMessage', function (message) {
           if (message) {

            // Format the message
            message = ConversationMessagesService.autoLink(message);
            message = ConversationMessagesService.breakNewLines(message);
            message = $sanitize(message);

            // Display the message 
            element.html(message);

            // Stop the watcher after formatting the message
            unwatch();
          }
        });
      }
    };
  }
})();