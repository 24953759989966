(function() {
  'use strict';

  angular
  .module('architizer.app')
  .controller('SendProductModalController', SendProductModalController);

  SendProductModalController.$inject = [
    '$modalInstance',
    '$state',
    '$timeout',
    'AuthorizationService',
    'CustomDataService',
    'FileService',
    'productRequest',
    'ProductResponse',
    'User',
    'user',
  ];

  function SendProductModalController (
    $modalInstance,
    $state,
    $timeout,
    AuthorizationService,
    CustomDataService,
    FileService,
    productRequest,
    ProductResponse,
    User,
    user
  ) {

    var vm = this;

    // Data
    vm.user = user;
    vm.productRequest = productRequest;
    vm.form = {};
    vm.models = models();
    vm.elementConfig = elementConfig();
    vm.sendMode = '';

    // Functions
    vm.FileService = FileService;
    vm.onDataPointClick = onDataPointClick;
    vm.onSubmitClick = onSubmitClick;
    vm.onCancelClick = onCancelClick;

    // Initialize
    init();

    ////////////////////////////////////////////////////////////////////////////////
    // Functions
    ////////////////////////////////////////////////////////////////////////////////

    /**
     * Initialization
     */
    function init () {
      // Preselect the brand if there's only one
      if (vm.elementConfig.brandOptions.length === 1) {
        vm.models.brandId = vm.elementConfig.brandOptions[0].value;
      }
    }

    function models () {
      return {
        brandId: '',
        productName: CustomDataService.dataPoints.productName(),
        productUrl: CustomDataService.dataPoints.productUrl(),
        leadTime: CustomDataService.dataPoints.leadTime(),
        price: CustomDataService.dataPoints.price(),
        description: CustomDataService.dataPoints.description(),
        files: [],
        selectedDataPoints: [],
      };
    }

    function elementConfig () {
      return {
        brandOptions: getBrands(),
        bpmSendProductDataPoints: CustomDataService.getBpmSendProductDataPoints(),
      };

      // Get the brands this user can create responses for
      function getBrands () {
        var brandOptions = [];
        var authorizedBrands = AuthorizationService.getBrandsForPermission(AuthorizationService.BRAND_PERMISSIONS.CAN_CHANGE_BRAND_PRODUCT_RESPONSES);

        angular.forEach(authorizedBrands, (brand) => {
          brandOptions.push({
            label: brand.name,
            value: brand.id
          });
        });

        return brandOptions;
      }
    }

    function onDataPointClick (dataPoint) {

      // Remove the data point from the clickable optional criteria list
      var index = vm.elementConfig.bpmSendProductDataPoints.indexOf(dataPoint);
      vm.elementConfig.bpmSendProductDataPoints.splice(index, 1);

      // Add it to the custom criteria list
      vm.models.selectedDataPoints.push(dataPoint);
    }

    /**
     * On Submit button click
     */
    function onSubmitClick () {
      vm.sendMode = 'publish';
      vm.form.$setSubmitted(true);

      // Check after a timeout so the form's validity will be properly updated
      $timeout(() => {

        // Invalid form
        if (!vm.form.$valid) {
          // Find the first element with an error
          var errorElement = angular.element(document.getElementById(vm.form.$error[Object.keys(vm.form.$error)[0]][0].$name));
          // Find the modal element
          var modalElement = angular.element('div.reveal-overlay');

          // Tell the modal element to scroll to the error element
          modalElement.scrollToElement(errorElement, 150, 250);
          return;
        }

        // Valid form
        if (vm.form.$valid) {
          // Save the product response
          var details = [];

          // Push each of the permanent form fields into details array
          details.push(vm.models.productName);
          details.push(vm.models.productUrl);
          details.push(leadTimeInDays(angular.copy(vm.models.leadTime))); // angular.copy() to prevent the brief flash of weeks-to-days converted values in the field on the form
          details.push(convertPriceToNumber(vm.models.price));
          details.push(vm.models.description);

          // Flatten the optional criteria data points into details array
          angular.forEach(vm.models.selectedDataPoints, (dataPoint) => {
            // Push it into the details array
            details.push(dataPoint);
          });

          // Make a product response
          var productResponse = new ProductResponse();

          // Fields to exclude from structured_data/details
          var excluded = [];

          // For each field in each dataPoint on the form
          angular.forEach(details, (dataPoint) => {
            angular.forEach(dataPoint.fields, (field) => {

              // Push the value onto the appropriate productResponse model field
              if (field.apiField) {
                productResponse[field.apiField] = field.value;

                // Exclude the dataPoint from being added to the structured_data array
                if (excluded.indexOf(dataPoint) === -1) {
                  excluded.push(dataPoint);
                }
              }
            });
          });

          // Remove the excluded datapoints from the details array
          details = _.difference(details, excluded);

          // Add price_currency
          productResponse.price_currency = 'USD';

          productResponse.brand_id = parseInt(vm.models.brandId);
          productResponse.product_request_id = vm.productRequest.id;
          productResponse.structured_data = CustomDataService.prepareForAPI(details);
          productResponse.media_data = vm.models.files;
          productResponse.media_data_includes_existing = true; // This flag tells the API that media_data will include existing MediaItemAttributions for this object (required)

          // Save the Product Response
          productResponse.$createPublished(() => {
            $modalInstance.close();
          });
        }

        // Helper function to translate Lead Time from weeks to days (stored on API in days)
         function leadTimeInDays (leadTimeDataPoint) {
          angular.forEach(leadTimeDataPoint.fields, (field) => field.value = (7 * field.value));
          return leadTimeDataPoint;
        }

        // Helper function to translate Price from string format to number
        function convertPriceToNumber (priceDataPoint) {
          angular.forEach(priceDataPoint.fields, (field) => {
            // We only want the first two price data point fields (price_low and price_high)
            if(field.name !== 'currency') {
              // If price field is empty, set it to null
              if(field.value === '') {
                field.value = null;
              } else {
                // if price field is not empty, convert string input to number format
                field.value = parseInt(field.value);
              }
            }
          });
          return priceDataPoint;
        }
      });
    }

    /**
     * On Cancel button click
     */
    function onCancelClick () {
      $modalInstance.dismiss();
    }
  }
})();
