'use strict';

angular
	.module('architizer.app')
	.factory('SearchService', ['$http', '$timeout', 'Config', function ($http, $timeout, config) {

		var searchService = {
			searchInProgress: false
		};

		searchService.search = function (query, success, error) {

			if (!query) {

				// error callback if no query provided
				error('Please provide a search query');
				return;
			}

			searchService.searchInProgress = true;

			// perform the search request
			$http
				.get(config.apiUrlBase + 'search/autocomplete?query=' + query)
				.success(function (data) {

					searchService.searchInProgress = false;

					// success callback
					console.log(data);
					success(data.data);
				})
				.error(function () {

					searchService.searchInProgress = false;

					// error callback
					error('An error occurred');
				});
		};

		return searchService;
	}]);