'use strict';

/**
 * Service to manage flash message stack
 */

angular
  .module('ui')
  .factory('FlashManager', [
    '$rootScope',
    '$compile',
    '$timeout',
    '$document',
    function($rootScope, $compile, $timeout, $document) {

        var flashManager = {};

        /*
         * Member variables
         */

        // The stack of messages
        var stack = [];

        // The element that holds the flash messages
        var container;

        // The default time that messages stick around for
        var lifespan = 5000;

        // Animation params
        var entryAnimationLength = 250;
        var exitAnimationLength = 250;


        /*
         * Returns the current number of messages
         */
        flashManager.count = function () {

            return stack.length;
        };

        /*
         * Creates and pushes a message onto the stack
         */
        flashManager.push = function (config) {

            // Defaults
            config.type = config.type || 'neutral';
            config.lifespan = config.lifespan || lifespan;

            // Create a scope for the message
            var scope = $rootScope.$new();

            // Get the controller name if provided
            if (config.controller) {

                var controller = config.controller;
                delete config.controller;
            }

            // Copy config into scope
            angular.extend(scope, config);

            // Create the message markup
            var flashMarkup = angular.element('<ui-flash />')
                .attr('type', 'type')
                .attr('message', 'message');

            // Compile the markup into a linking function
            var linkFn = $compile(flashMarkup);

            // Inject the scope, and link
            var element = angular.element(linkFn(scope));

            // Prepare to push onto stack
            var message = {
                config: config,
                element: element,
                scope: scope
            };

            // Flash message is still a ui-flash element
            // Reference to element is lost on the next digest
            // so wait until this cycle is complete then update
            // the reference
            $timeout(function () {

                var elements = container.children();
                message.element = angular.element(elements[elements.length-1]);
            }, 50);

            // Push onto stack
            stack.push(message);

            // Ensure the container is created
            flashManager.updateView();

            // Add message to the view
            flashManager.add(message);

            // Remove after lifespan
            $timeout(function () {

                flashManager.remove(message);
            }, message.config.lifespan);
        };

        /*
         * Removes a message from the stack
         */
        flashManager.remove = function (message) {

            // Remove the message from the stack
            var index = stack.indexOf(message);
            stack.splice(index, 1);

            // Start exit animation
            message.element.addClass('exiting');

            $timeout(function () {

                // Remove the popup from the document
                message.element.remove();

                // Destroy the scope
                message.scope.$destroy();
            }, exitAnimationLength);
        };

        flashManager.add = function (message) {

            container.append(message.element);

            // Entering class is on flash message to begin
            // Remove after animation is complete
            $timeout(function () {

                // Remove the entry class from the message
                message.element.removeClass('entering');
            }, entryAnimationLength);
        };

        /*
         * Update the view
         */
        flashManager.updateView = function () {

            // Create the container if required
            if (stack.length && !container) {

                addContainer();
            }

            // Remove the container if required
            if (!stack.length && container) {

                removeContainer();
            }
        };

        var addContainer = function () {

            // Create the container element
            container = angular.element('<div class="uiFlashContainer" />');

            // Add to the document
            angular.element($document[0].body).append(container);
        };

        var removeContainer = function () {

            // Allow the final flash to animate out
            $timeout(function () {

                // Remove from the document
                container.remove()

                // Destroy
                container = null;
            }, exitAnimationLength);
                
        };

        /*
         * Whenever the stack changes
         */
        var onStackModified = function (stack) {

            flashManager.updateView();
        };

        // Hook up handler to stack watch
        $rootScope.$watchCollection(function () {

            return stack;
        }, onStackModified);

        return flashManager;
    }]);