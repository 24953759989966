'use strict';

angular
  .module('architizer.app')
  .factory('Product', ['$resource', 'Config', function($resource, Config) {

    return $resource(Config.apiUrlBase + 'products/:id', {}, {
      query: {
        isArray: true,

        // Pagination
        interceptor: {
          response: function (response) {

            response.resource.$pagination = response.data.$pagination;
            return response.resource;
          }
        }
      },
    }, { cancellable: true });
  }]);
