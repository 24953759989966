'use strict';

// preloads images and adds a class when they're done
angular
	.module('architizer.app')
	.directive('preload', ['$timeout', 'PreloaderService', function ($timeout, preloader) {

		return {
			restrict: 'A',
			replace: false,
			link: function ($scope, element) {

				// when the preloading is complete
				var success = function () {

					// add a class indicating preloading is complete
					element.addClass('preloaded');
				};

				// when preloading fails
				var failure = function (err) {

					// add a class indicating preloading failed
					element.addClass('preloadingFailed');
				};

				var _preload = function () {

					// get the image src if one exists
					var imageUrl = element.attr('src');

					// otherwise try the background image
					if (!imageUrl) {

						imageUrl = element.css('backgroundImage').substring(4, element.css('backgroundImage').length-1);

						// trim quotes off url (applied in Firefox)
						imageUrl = imageUrl.replace(/'|"/g, '');
					}

					// preload
					preloader.preloadImage(imageUrl, success, failure);
				};

				// add a class indicating this is a preloaded image
				element.addClass('hasPreload');

				// wait until templating is complete
				$timeout(_preload);
			}
		};
	}]);