(function() {
  'use strict';

  angular
    .module('architizer.app')
    .factory('LudwigService', LudwigService);

  LudwigService.$inject = [
    '$http',
    '$q',
    'Config',
    'ProductResponse',
    'ProductResponseDataTransformer',
    'SearchstaxService',
    'UtilsService'
  ];

  function LudwigService(
    $http,
    $q,
    Config,
    ProductResponse,
    ProductResponseDataTransformer,
    SearchstaxService,
    UtilsService
  ) {

    var service = this;

    // Data
    service.cachedProductTags = {};

    // Functions
    service.getBrandSuggestions = getBrandSuggestions;
    service.getRequestNameSuggestions = getRequestNameSuggestions;
    service.buildOpportunitiesQuery = buildOpportunitiesQuery;
    service.getEntitiesSuggestions = getEntitiesSuggestions;
    service.getProductRecommendations = getProductRecommendations;
    service.getProductResponseFacets = getProductResponseFacets;
    service.getProductTags = getProductTags;
    service.getProductRequests = getProductRequests;
    service.getEntityRecommendations = getEntityRecommendations;
    service._transformEntityRecommendations = transformEntityRecommendations;

    return service;

    ////////////////////////////////////////////////////////////////////////////////
    // Functions
    ///////////////////////////////////////////////////////////////////////////////

    /**
     * Typeahead function to get "Product Tags" from Solr documents
     *
     * This is used in CustomDataService to get options for Selectize elements
     *
     * This uses the /search/product_tags endpoint to get all "product tags"
     * from facet data on Solr documents for Product Requests
     */
    function getProductTags (fieldName) {
      // Fields from product_tags endpoint use the `_ent_ss` suffix, but CustomDataService doesn't include it, so we add it here
      fieldName = fieldName + '_ent_ss';

      // Set up a promise
      const deferred = $q.defer();

      // If we've already fetched this, try to use the cached data
      if (service.cachedProductTags[fieldName]) {
        deferred.resolve(service.cachedProductTags[fieldName]);
      } else {
        const url = Config.apiUrlBase + 'search/product_tags';

        // Make the request
        $http
        .get(url)
        .success(getProductTagsSuccess)
        .error(getProductTagsError);
      }

      return deferred.promise;

      // Success
      function getProductTagsSuccess (data) {
        // Store this data so it doesn't have to be re-fetched
        service.cachedProductTags = data;

        if (data[fieldName]) {
          // If the field is found in the response, resolve the promise with this field's data
          deferred.resolve(data[fieldName]);
        } else {
          // Reject the promise if this field isn't in the product_tags response
          // (This will cause the CustomDataService options() function to use its fallback stored options)
          deferred.reject();
        }
      }

      // Error
      function getProductTagsError (error) {
        deferred.reject(error);
      }
    }

    /**
     * Typeahead function to get Product Response suggestions from Ludwig API
     */
    function getProductResponseFacets (query, facetField) {
      var url = Config.apiUrlBase + 'search/product_responses/select';
      var config = {
        'params': {
          'q': '*:*',
          'facet': true,
          'facet.field': facetField,
          'facet.mincount': 1,
          'facet.contains.ignoreCase':true,
          'facet.contains': query,
          'fl': 'django_id',
        }
      };

      // Alias Util functions for easy use
      var unwrapFacets = UtilsService.unwrapFacets;

      // Set up a promise
      var deferred = $q.defer();

      // Make the request
      $http
      .get(url, config)
      .success(getSuggestionsSuccess)
      .error(getSuggestionsError);

      return deferred.promise;

      // Success
      function getSuggestionsSuccess ({ facet_counts: { facet_fields } }) {
        const facetDataArray = facet_fields[facetField];
        deferred.resolve(unwrapFacets(facetDataArray));
      }

      // Error
      function getSuggestionsError (error) {
        deferred.reject(error);
      }
    }


    /**
     *
     * Query function to get product requests from Search API
     * @param {Obj} searchParams: Dictionary of search params to forward to Solr
     */
    function getProductRequests (searchParams) {
      var url = Config.apiUrlBase + 'search/product_requests/select/';
      // Configure search params
      var defaultParams = {
        facet: true,
        'facet.sort': 'index',
        'facet.field': 'pseudotiers_ss',
      };
      searchParams = Object.assign(defaultParams, searchParams);

      // Make request
      var deferred = $q.defer();
      $http
      .get(url, { params: searchParams })
      .success(getProductRequestsSuccess)
      .error(getProductRequestsError);

      return deferred.promise;

      // Success
      function getProductRequestsSuccess (data) {
        return deferred.resolve(data);
      }

      // Error
      function getProductRequestsError (error) {
        deferred.reject(error);
      }

    }

    /**
     * Query function to get product recommendations from Search API
     * @param {Int} product_request_id: the request ID to search on
     * @param {Int} number: the number of product responses to return
     */
    function getProductRecommendations (product_request_id, number = 40) {

      // Configuration for Search Product Recommendations Endpoint
      var url = Config.apiUrlBase + 'search/product_recommendations/';
      var config = { params: { product_request_id } };

      // Set up a promise
      var deferred = $q.defer();

      // Make the request
      $http
      .get(url, config)
      .success(getRecommendationsSuccess)
      .error(getRecommendationsError);

      return deferred.promise;

      // Success
      function getRecommendationsSuccess (data) {
        // Get recommendations from response
        var recommendations = data.response.docs.slice(0, number);

        // If there are fewer than `number` results found, we need to use numFound as "shown hits"
        const shownHits = Math.min(data.response.numFound, number);

        // Send a SearchStax "track" event
        const ssAppName = 'productRecommendations';
        const trackData = {
          key: SearchstaxService.getApiKey(ssAppName),
          session: SearchstaxService.getSession(),
          query: data.responseHeader.params.q || '*:*',   // Pass '*:*' when no query (their API returns a 400 if query is empty)
          shownHits: shownHits,
          totalHits: data.response.numFound,
          latency: data.responseHeader.QTime,
        };

        SearchstaxService.track(trackData);

        // Add extra data and convert each recommendation to a ProductResponse $resource
        recommendations = recommendations.map((recommendation) => {
          // Transform from Solr document into Product Response data
          let transformed = ProductResponseDataTransformer(recommendation, { marking: 'N', saving: false });

          // Create ProductResponse $resource for each,
          transformed = (new ProductResponse(transformed));

          // Add "searchResultPosition" property, for use in SearchStax analytics
          transformed.searchResultPosition = recommendations.indexOf(recommendation);

          return transformed;
        });

        deferred.resolve(recommendations);
      }

      // Error
      function getRecommendationsError (error) {
        deferred.reject(error);
      }

    }

    /**
     * Typeahead function to get brand suggestions from Ludwig API
     */
    function getBrandSuggestions (query) {

      // Configuration for Ludwig Brand Typeahead
      var url = Config.apiUrlBase + 'search/brands/suggest/';
      var config = {'params': {'q': query}};
      var suggester = 'mySuggester';

      // Set up a promise
      var deferred = $q.defer();

      // Make the request
      $http
      .get(url, config)
      .success(getSuggestionsSuccess)
      .error(getSuggestionsError);

      return deferred.promise;

      // Success
      function getSuggestionsSuccess (response) {

        // Get suggestions from response
        var suggestions = response.suggest[suggester][query].suggestions;

        // Translate payload to JSON
        suggestions = suggestions.map(function (suggestion) {

          suggestion.payload = angular.fromJson(suggestion.payload);
          suggestion.id = suggestion.payload.architizer_id;

          // Set a flag if this brand doesn't exist in Architizer
          if (!suggestion.payload.architizer_id) {
            suggestion.id = suggestion.payload.domain;
            suggestion.create = true;
          }

          return suggestion;
        });

        deferred.resolve(suggestions);
      }

      // Error
      function getSuggestionsError (error) {
        deferred.reject(error);
      }
    }

    /**
     * Typeahead function to get entities suggestions from Ludwig API
     */
    function getEntitiesSuggestions (query) {
      // Configuration for Ludwig Brand Typeahead
      var url = Config.apiUrlBase + 'search/entities/suggest/';
      var config = {'params': {'q': query}};
      var suggester = 'mySuggester';

      // Alias Util functions for easy use
      var extendSuggestionsArrayWithRawTermField = UtilsService.extendSuggestionsArrayWithRawTermField;
      var dedupeSuggesterResults = UtilsService.dedupeSuggesterResults;

      // Set up a promise
      var deferred = $q.defer();

      // Make the request
      $http
      .get(url, config)
      .success(getSuggestionsSuccess)
      .error(getSuggestionsError);

      return deferred.promise;

      // Success
      function getSuggestionsSuccess (response) {
        var suggestions = response.suggest[suggester][query].suggestions;
        deferred.resolve(
          dedupeSuggesterResults(extendSuggestionsArrayWithRawTermField(suggestions))
        );
      }

      // Error
      function getSuggestionsError (error) {
        deferred.reject(error);
      }
    }

    /**
     * Typeahead function to get request suggestions from Ludwig API
     */
    function getRequestNameSuggestions (query) {

      // Configuration for Ludwig Brand Typeahead
      var url = Config.apiUrlBase + 'search/request_names/suggest/';
      var config = {'params': {'q': query}};
      var suggester = 'mySuggester';

      // Set up a promise
      var deferred = $q.defer();

      // Make the request
      $http
      .get(url, config)
      .success(getSuggestionsSuccess)
      .error(getSuggestionsError);

      return deferred.promise;

      // Success
      function getSuggestionsSuccess (response) {
        // Get suggestions from response
        var suggestions = response.suggest[suggester][query].suggestions;
        deferred.resolve(suggestions);
      }

      // Error
      function getSuggestionsError (error) {
        deferred.reject(error);
      }
    }

    // Get Entity Suggestions (for values for Selectize elements)
    function getEntityRecommendations (product_request_id) {
      const deferred = $q.defer();

      const url = Config.apiUrlBase + 'search/entity_recommendations/';
      const config = {};

      config.params = { product_request_id };

      $http
      .get(url, config)
      .success((entities) => { deferred.resolve(transformEntityRecommendations(entities)); })
      .error((error) => { deferred.reject(error); });

      return deferred.promise;
    }

    // Helpers
    function transformEntityRecommendations (entities) {
      let recommendations = {};
      let keys = Object.keys(entities);

      keys.forEach((key) => {
        // Add a suffix-removed key to recommendations with the value of the original
        recommendations[removeSuffix(key)] = entities[key];
      });

      return recommendations;

      function removeSuffix (key) {
        const fieldSuffix = '_ent_ss';
        return key.substring(0, key.indexOf(fieldSuffix));
      }
    }


    /**
     * Build query string for Opportunities endpoint
     */
    function buildOpportunitiesQuery (queryParameters = {}, user = {}) {
      let defaultParams = {
        cursorMark: '*',
        perPage: 10,
        sort: 'approved_dt desc, id desc',
        categories: [],
        phases: [],
        meta: [],
        searchQuery: '*:*',
      };

      // Extend input params with defaults
      queryParameters = angular.extend(defaultParams, queryParameters);

      // Translate queryParameters into Solr speak
      let paramsForSolr = {
        q: q(queryParameters.searchQuery),
        fq: fq(queryParameters, user),
        rows: queryParameters.perPage,
        sort: queryParameters.sort,
        cursorMark: queryParameters.cursorMark,
      };

      return paramsForSolr;

      ////////////////////////////////////////////////////////////////////////////////
      // Helpers
      ////////////////////////////////////////////////////////////////////////////////

      // Build Filter Query (fq=) string for the query
      function fq (
        queryParameters = {
          categories: [],
          phases: [],
          meta: []
        },
        user = {}
      ) {

        let filterQuery = [];

        // Translate Type IDs (product categories)
        if (queryParameters.categories.length) {
          let quotedCategories = queryParameters.categories.map((category)=>{return '"' + category + '"';});
          filterQuery.push('pseudotiers_ss:(' + quotedCategories.join(' OR ') + ')');
        }

        // Translate Phases (construction phase)
        if (queryParameters.phases.length) {
          filterQuery.push('phase_s:(' + queryParameters.phases.join(' ') + ')');
        }

        // Translate Meta (user-specific data) Filters
        if (user && queryParameters.meta.length) {
          let draftIds = user.product_request_ids_drafts;
          let favoriteIds = user.product_request_ids_favorites;
          let repliedIds = user.product_request_ids_replied;
          let brandId = user.admin_brand_ids.length ? user.admin_brand_ids[0] : null;
          let idSet = new Set();
          let brandShortlistFilter;

          // Check for each meta filter
          if (queryParameters.meta.includes('drafts')) {
            draftIds.forEach((id) => idSet.add(id));
          }

          if (queryParameters.meta.includes('favorited')) {
            favoriteIds.forEach((id) => idSet.add(id));
          }

          if (queryParameters.meta.includes('replied')) {
            repliedIds.forEach((id) => idSet.add(id));
          }

          if (queryParameters.meta.includes('shortlisted') && brandId) {
            const userBrandId = user.admin_brand_ids[0];
            brandShortlistFilter = `shortlist_brand_ids_is:${userBrandId}`;
          }


          // Push these into the query
          let ids = Array.from(idSet);
          if (ids.length) {
            // If there are IDs for these meta filters, join them
            let filterString = `django_id:(${ ids.join(' ') })`;
            if (brandShortlistFilter) {
              filterString = `${filterString} OR ${brandShortlistFilter}`;
            }

            filterQuery.push(filterString);

          } else if (brandShortlistFilter) {
            filterQuery.push(brandShortlistFilter);
          } else {
            /**
             * FIXME: Use something realistic for this field when no IDs match the given meta filter
             *
             * My stopgap is to push "hotdog" into the django_id field if there are no matching IDs
             *
             * We have to pass _something_ to the IDs field, so Solr will look for ID "hotdog"
             * and will find nothing.
             */
            const stopGapText = 'hotdog'
            filterQuery.push(`django_id:(${stopGapText})`)
          }
        }
        return filterQuery.join(' AND ');
      }

      // Build Query (q=) string for the query
      function q (searchQuery) {
        if (!searchQuery) {
          return '*:*';
        } else {
          return searchQuery;
        }
      }
    }
  }
}());
