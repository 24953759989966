'use strict';

angular
	.module('architizer.app')
	.filter('rolesForPermissions', ['AuthorizationService', function (authorization) {
	
		return function (input, type) {

			if (!input) {

				return;
			}

			var roleKeys = authorization.getRolesForPermissions(type, input);
			var roles = [];

			if (type != 'firm') {

				angular.forEach(roleKeys, function (roleKey) {

					roles.push(authorization.BRAND_ROLES[roleKey]);
				});
			}

			if (type != 'brand') {

				angular.forEach(roleKeys, function (roleKey) {

					roles.push(authorization.FIRM_ROLES[roleKey]);
				});
			}

			return roles;
		};
	}])

	/*
	 * Checks if a user has a permission in a provided list of 
	 * permissions using an OR match, across all a user's brands
	 * and firms. See the AuthorizationService for possible
	 * permissions
	 */
	.filter('hasPermission', ['AuthorizationService', function (authorization) {
	
		return function (user, permissions) {

			if (!user) {

				return false;
			}

			if (!user.permissions) {

				return false;
			}

			if (!angular.isArray(permissions)) {

				permissions = [permissions];
			}

			var hasPermission = false;
			angular.forEach(user.permissions.brands, function (brandPermissions) {

				angular.forEach(permissions, function (permission) {

					hasPermission = hasPermission || authorization.hasPermission(brandPermissions, permission);
				});
			});

			angular.forEach(user.permissions.firms, function (firmPermissions) {

				angular.forEach(permissions, function (permission) {

					hasPermission = hasPermission || authorization.hasPermission(firmPermissions, permission);
				});
			});

			return hasPermission;
		};
	}])

	/*
	 * Checks if a user has a permission in a provided list of 
	 * permissions using an OR match, for a specific brand
	 */
	.filter('hasPermissionForBrand', ['AuthorizationService', function (authorization) {
	
		return function (user, brandId, permissions) {

			if (!user) {

				return false;
			}

			if (!user.permissions) {

				return false;
			}

			return authorization.userHasPermissionForBrand(user, brandId, permissions);
		};
	}])

	/*
	 * Checks if a user has a permission in a provided list of 
	 * permissions using an OR match, for a specific firm
	 */
	.filter('hasPermissionForFirm', ['AuthorizationService', function (authorization) {
	
		return function (user, firmId, permissions) {

			if (!user) {

				return false;
			}

			if (!user.permissions) {

				return false;
			}

			return authorization.userHasPermissionForFirm(user, firmId, permissions);
		};
	}])

	/*
	 * Returns a boolean indicating if a user has access to Source
	 * through one of their firms or brands.
	 * The check may be limited to just firms or brands using the `type`
	 * parameter ('brand' or 'firm')
	 */
	.filter('hasSourceAccess', ['AuthorizationService', function (authorization) {

		return function (user, type) {

			if (!user) {

				return false;
			}

			var hasAccess = false;

			if (type != 'firm') {

				angular.forEach(user.permissions.brands, function (brandPermissions) {

					// Being able to change a product response is the most 
					// basic permission for brands
					if (authorization.hasPermission(
						brandPermissions, 
						authorization.BRAND_PERMISSIONS.CAN_CHANGE_BRAND_PRODUCT_RESPONSES
					)) {

						hasAccess = true;
					}
				});
			}

			if (type != 'brand') {

				angular.forEach(user.permissions.firms, function (firmPermissions) {

					// Being able to change a product request is the most 
					// basic permission for firms
					if (authorization.hasPermission(
						firmPermissions, 
						authorization.FIRM_PERMISSIONS.CAN_CHANGE_FIRM_PRODUCT_REQUESTS
					)) {
						
						hasAccess = true;
					}
				});
			}

			return hasAccess;
		};
	}])

	.filter('anonymizeIfSpecifier', [function () {
		
		return function (user) {

			if (true || _.keys(user.permissions.firms).length) {

				return user.first_name + ' (Specifier)';
			}

			else {

				return user.first_name + ' ' + user.last_name;
			}
		};
	}]);