'use strict';

angular
	.module('architizer.app')
	.filter('concat', function () {

		return function(input, delimiter) {

			if (!delimiter) {

				delimiter = ', ';
			}

			// concatenate and return
			return input.join(delimiter);
		};
	})

	.filter('concatProps', function () {

		return function(input, key, delimiter) {

			if (!delimiter) {

				delimiter = ', ';
			}

			// build an array of the requested properties
			var props = [];
			angular.forEach(input, function (obj) {

				props.push(obj[key]);
			});

			// concatenate and return
			return props.join(delimiter);
		};
	})

	.filter('capArray', [function () {

		return function (input, limit, overflowTemplate) {

			if (!input || input.length <= limit) {

				return input;
			}

			var result = input.slice(0, limit);

			// input could be an array of strings or objects
			// if an object, overflow template will be same format
			// if so, perform replace on all child keys
			var replaced;
			if (typeof overflowTemplate === 'object') {

				replaced = angular.copy(overflowTemplate);
				angular.forEach(replaced, function(val, key) {

					replaced[key] = val.replace('%s', input.length - limit);
				});
			}

			else {

				replaced = overflowTemplate.replace('%s', input.length - limit);
			}

			result.push(replaced);

			return result;
		};
	}])

	/*
	 * Flattens an object of the form:
	 *
	 * {
	 * 	label: 'Foo',
	 * 	child: {
	 * 		label: 'Bar',
	 * 		child: {
	 * 			label: 'Baz'
	 * 		}
	 * 	}
	 * }
	 *
	 * into 'Foo Bar Baz'. The valueKey is 'label' and the child
	 * key is 'child' in this example.
	 */

	.filter('flattenNest', function() {

		return function (input, valueKey, childKey, delimiter) {

			if (!delimiter) {

				delimiter = ' ';
			}

			var item = input;
			var values = [];

			while(item) {

				values.push(item[valueKey]);
				item = item[childKey];
			}

			return values.join(delimiter);
		};
	})

	/*
	 * Flattens an array of objects of the form:
	 *
	 * [{
	 * 	label: 'Foo',
	 * 	child: {
	 * 		label: 'Bar'
	 * 	}
	 * }, {
	 * 	label: 'Baz'
	 * }]
	 *
	 * into 'Foo: Bar, Baz'. The valueKey is 'label', the child
	 * key is 'child', the delimiter is ': ' and the
	 * nestDelimiter is ', ' in this example.
	 */

	.filter('flattenNests', ['$filter', function($filter) {

		return function (input, valueKey, childKey, delimiter, nestDelimiter) {

			var values = [];

			angular.forEach(input, function (nest) {

				var value = $filter('flattenNest')(nest, valueKey, childKey, delimiter);
				values.push(value);
			});

			return values.join(nestDelimiter);
		};
	}])


	/*
	 * Flattens an object of the form:
	 *
	 * {
	 * 	label: 'Foo',
	 * 	child: {
	 * 		label: 'Bar'
	 * 	}
	 * }
	 *
	 * into 'Bar'. The valueKey is 'label', and the child
	 * key is 'child' in this example.
	 */

	.filter('findLeaf', ['$filter', function($filter) {

		return function (input, valueKey, childKey) {

			var branch = input;
			var values = [];

			while(branch) {

				if (!branch[childKey]) {

					return branch[valueKey];
				}

				branch = branch[childKey];
			}
		};
	}])

	/*
	 * Flattens an array of objects of the form:
	 *
	 * [{
	 * 	label: 'Foo',
	 * 	child: {
	 * 		label: 'Bar'
	 * 	}
	 * }, {
	 * 	label: 'Baz'
	 * }]
	 *
	 * into ['Bar', 'Baz']. The valueKey is 'label', and the child
	 * key is 'child' in this example.
	 */

	.filter('findLeaves', ['$filter', function($filter) {

		return function (input, valueKey, childKey) {

			var values = [];

			angular.forEach(input, function (branch) {

				var value = $filter('findLeaf')(branch, valueKey, childKey);
				values.push(value);
			});

			return values;
		};
	}])


	/*
	 * Flattens an array of (objects/arrays) of the form:
	 *
	 * [
	 *		{ value: 'foo', a:... },
	 *		{ value: 'bar', a:... },
	 *		{ value: 'baz', a:... }
	 * ]
	 *
	 * into ['foo', 'bar', 'baz']. In this example, valueKey
	 * is 'value'.
	 */

	.filter('pick', [function() {

		return function (input, valueKey) {

			var picked = [];

			angular.forEach(input, function (item) {

				picked.push(item[valueKey]);
			});

			return picked;
		};
	}])


	/*
	 * Returns the unique elements in a collection
	 */

	.filter('unique', [function() {

		return function (input) {

			var unique = [];

			angular.forEach(input, function (item) {

				if (unique.indexOf(item) === -1) {

						unique.push(item);
				}
			});

			return unique;
		};
	}])
	
	
	/*
	 * Returns an array of the provided size (pass an empty array)
	 */

	.filter('range', [function() {

		return function (input, size) {

			return new Array(size);
		};
	}])
	
	.filter('find', [function() {

		return function(input, key, value) {

			return _.find(input, function(item) {
				return item[key] === value;
			});
		};
	}]);
